import PropTypes from 'prop-types'
import { FormControl } from 'baseui/form-control'
import { SIZE } from 'baseui/input'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'

const ForceEmail = ({ hidden, forceAddress, label, buttonText }) => {
  if (hidden) return null
  return (
    <FormControl label={label}>
      <SDButton
        size={SIZE.mini}
        block
        onClick={e => {
          e.preventDefault()
          forceAddress()
        }}
      >
        {buttonText}
      </SDButton>
    </FormControl>
  )
}

ForceEmail.propTypes = {
  hidden: PropTypes.bool,
  forceAddress: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
}

export default ForceEmail
