import PropTypes from 'prop-types'
import { Input } from 'baseui/input'
import { mergeOverrides, useStyletron } from 'baseui'
import { FloatingLabel, SDInputOverrides } from './styled-components'
import { Alert, Delete } from 'baseui/icon'
import IconCarbonCheckmarkOutline from '@/shared/icons/IconCarbonCheckmarkOutline'
import InfoIcon from '../icon_info.svg'

const SDInput = ({ label, overrides, status, ...rest }) => {
  const [css] = useStyletron();

  const iconMapping = {
    valid: <IconCarbonCheckmarkOutline size={24} color="green" />,
    info: (
      <img
        src={InfoIcon}
        alt="Info"
        style={{ width: 24, height: 24 }}
      />
    ),
    warning: <Alert size={24} color="orange" />,
    error: <Delete size={24} color="red" />,
  };

  const endEnhancer = status && iconMapping[status]
    ? () => (
      <div className="sdinput-end-enhancer">
        {iconMapping[status]}
      </div>
    )
    : null;

  const newOverrides = mergeOverrides(SDInputOverrides(), overrides)

  return (
    <div className={css({ position: 'relative' })}>
      {label && <FloatingLabel>{label}</FloatingLabel>}
      <Input {...rest} overrides={newOverrides} endEnhancer={endEnhancer} />
    </div>
  );
}

SDInput.propTypes = {
  label: PropTypes.node,
  overrides: PropTypes.object,
  status: PropTypes.oneOf(['valid', 'info', 'warning', 'error']),
}

export default SDInput
