export default function isValidZohoId(zohoId) {
  if (!zohoId) return false;

  if (!/^[1-9][0-9]*$/.test(zohoId)) return false;

  const minId = BigInt("1499247000000184017");
  const maxId = BigInt("1500000000000000000");

  try {
    const value = BigInt(zohoId);
    return value >= minId && value <= maxId;
  } catch (error) {
    return false;
  }
}
