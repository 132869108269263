const addressSubscriptionData = {
  address: null,
  streetNumber: null,
  label: null,
  city: null,
  inseeCode: null,
  cityId: null,
  province: null,
  id: null,
  zipcode: null,
  housingType: null,
  household: null,
  occupancy: null,
  floor: null,
  tenantType: null,
  residenceType: null,
  apartmentNumber: null,
  additionalInformation: null,
  movingStartDate: null,
  providedByGas: null,
  addressType: 'main',
}

export default addressSubscriptionData
