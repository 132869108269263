import PropTypes from 'prop-types'
import { Select, SIZE, TYPE } from 'baseui/select'
import { mergeOverrides, useStyletron } from 'baseui'
import { SDSelectOverrides, FloatingLabel } from './styled-components'

const SDSelect = ({
  label,
  optionsList,
  selectedValue,
  onChangeHandler,
  placeholder = '',
  clearable = false,
  type = TYPE.select,
  disabled = false,
  hasError = false,
  overrides,
  ...rest
}) => {
  const [css] = useStyletron()
  const options = []
  const selected = []

  if (optionsList) {
    for (const [id, name] of Object.entries(optionsList)) {
      const data = { label: name, id }
      options.push(data)
      if (id === selectedValue) {
        selected.push(data)
      }
    }
  }

  const newOverrides = mergeOverrides(SDSelectOverrides(), overrides)

  return (
    <div className={css({ position: 'relative' })}>
      {label && <FloatingLabel>{label}</FloatingLabel>}
      <Select
        disabled={disabled}
        error={hasError}
        options={options}
        clearable={clearable}
        value={selected}
        onChange={onChangeHandler}
        placeholder={placeholder}
        type={type}
        maxDropdownHeight="320px"
        labelKey="label"
        valueKey="label"
        size={SIZE.compact}
        overrides={newOverrides}
        {...rest}
      />
    </div>
  )
}

SDSelect.propTypes = {
  label: PropTypes.string,
  optionsList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChangeHandler: PropTypes.func,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  overrides: PropTypes.object,
}

export default SDSelect
