import PropTypes from 'prop-types'

import { ValidationBadge, IconOverrides } from '../styled-components'
import IconCarbonCheckmarkOutline from '@/shared/icons/IconCarbonCheckmarkOutline'
import { useStyletron } from 'baseui'
import IconCarbonMisuseOutline from '@/shared/icons/IconCarbonMisuseOutline'

const ValidationItem = ({ status, text }) => {
  const [, theme] = useStyletron()
  const Icon = ({ isValid }) =>
    isValid ? (
      <IconCarbonCheckmarkOutline color={theme.colors.positive600} overrides={IconOverrides()} />
    ) : (
      <IconCarbonMisuseOutline color={theme.colors.negative600} overrides={IconOverrides()} />
    )

  Icon.propTypes = {
    isValid: PropTypes.bool,
  }

  return (
    <ValidationBadge status={status}>
      <Icon isValid={status === 'valid'} />
      {text}
    </ValidationBadge>
  )
}

ValidationItem.propTypes = {
  status: PropTypes.string,
  text: PropTypes.string,
}

export default ValidationItem
