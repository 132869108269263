import { useSnackbar } from 'baseui/snackbar'
import { showSnackbar } from './notification'

export const useSnackbarNotification = () => {
  const { enqueue } = useSnackbar()
  
  const showNotification = (options) => {
    showSnackbar(enqueue, options)
  }
  
  return showNotification
}