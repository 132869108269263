import { Route, Routes } from 'react-router-dom'
import EditForm from './SubscriptionForm/EditForm'
import CreateForm from '@/containers/SubscriptionFormPageNew/SubscriptionForm/CreateForm'

const SubscriptionForm = () => {
  return (
    <Routes>
      <Route path='/:id' element={<EditForm />} />
      <Route path='add' element={<CreateForm />} />
    </Routes>
  )
}

export default SubscriptionForm
