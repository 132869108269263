import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ValidationsList from '../ValidationsList/ValidationsList'
import ValidationItem from '../ValidationsList/ValidationItem'

const ContractDataRequired = ({ currentData, additionalData, customTitle, onlyAdditionalData }) => {
  const { t } = useTranslation()
  const title = customTitle ?? t('additionalSales:common.needed_fields')
  const additional = additionalData.map(data => {
    return (
      <ValidationItem
        key={data.name}
        text={data.name + ' ' + (data.description ?? '')}
        status={data.status === true ? 'valid' : 'invalid'}
      />
    )
  })

  if (onlyAdditionalData) {
    return (
      <ValidationsList title={title}>
        {additional}
      </ValidationsList>
    );
  }

  return (
    <>
      <ValidationsList title={title}>
        <ValidationItem
          text={t('additionalSales:list_data.zohoId')}
          status={currentData.zohoId ? 'valid' : 'invalid'}
        />
        <ValidationItem
          text={t('additionalSales:list_data.surname')}
          status={currentData.surname ? 'valid' : 'invalid'}
        />
        <ValidationItem text={t('additionalSales:list_data.phone')} status={currentData.phone1 ? 'valid' : 'invalid'} />
        {additional}
      </ValidationsList>
    </>
  )
}

ContractDataRequired.propTypes = {
  currentData: PropTypes.object,
  additionalData: PropTypes.any,
}

export default ContractDataRequired
