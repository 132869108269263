import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import {
  StyledNotification,
} from './styled-components'
import IconCarbonMisuseOutline from '@/shared/icons/IconCarbonMisuseOutline'
import IconCarbonVoiceActivate from '@/shared/icons/IconCarbonVoiceActivate'
import IconCarbonInformation from '@/shared/icons/IconCarbonInformation'
import IconCarbonRocket from '@/shared/icons/IconCarbonRocket'
import IconCustomAgentScript from '@/shared/icons/IconCustomAgentScript'
import HandIcon from './icon_hand.svg'
import WarningIcon from './icon_warning.svg'
import InfoIcon from './icon_record_voice.svg'

const SDNotification = ({
  children,
  icon,
  type,
  boldText = false,
  hidden,
  primaryText,
  border = false
}) => {
  const [, theme] = useStyletron()

  const iconProps = {
    size: 24,
    color:
      type === 'negative' || type === 'warning' || type === 'positive'
        ? theme.colors[type]
        : type === 'additionalSale'
          ? theme.colors.warning
          : theme.colors.primary,
  }

  const getIcon = () => {
    switch (type) {
      case 'negative':
        return <IconCarbonMisuseOutline {...iconProps} />
      case 'warning':
        return (
          <img src={WarningIcon} alt={primaryText} style={{ width: 24, height: 24 }} />
        )
      case 'info':
        return (
          <img src={InfoIcon} alt={primaryText} style={{ width: 24, height: 24 }} />
        )
      case 'positive':
        return (
          <img src={HandIcon} alt={primaryText} style={{ width: 24, height: 24 }} />
        )
      case 'script':
        return <IconCarbonVoiceActivate {...iconProps} />
      case 'agentScript':
        return <IconCustomAgentScript {...iconProps} />
      case 'additionalSale':
        return <IconCarbonRocket {...iconProps} />
      default:
        return <IconCarbonInformation {...iconProps} />
    }
  }

  const outputIcon = (() => {
    if (icon !== false) {
      if (typeof icon === 'function') {
        return icon(iconProps)
      }
      return getIcon()
    }
    return null
  })()

  if (hidden) return null

  return (
    <StyledNotification
      $type={type}
      $boldText={boldText}
      $border={border}
      style={{ width: '100%', flexDirection: 'row', alignItems: 'center' }}
    >
      <div style={{ flex: '0 0 auto', marginRight: '8px' }}>
        {outputIcon}
      </div>
      <div style={{ flex: '1 1 auto', display: 'flex', alignItems: 'center' }}>
        {primaryText}
      </div>
      {children}
    </StyledNotification>
  )
}

SDNotification.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.func, PropTypes.object]),
  type: PropTypes.oneOf([
    '',
    'additionalSale',
    'default',
    'negative',
    'positive',
    'script',
    'warning',
    'agentScript'
  ]),
  boldText: PropTypes.bool,
  hidden: PropTypes.bool,
  primaryText: PropTypes.string,
  border: PropTypes.string,
}

export default SDNotification
