import formatUTCDate from '@/shared/tools/formatUTCDate'

export const clientFormParametersGeneration = (
  customerData,
  id,
  country,
  action,
) => {
  const options = new URLSearchParams()

  if (action !== '') {
    options.append('action_requested', action)
  }

  const {
    personal: {
      zohoId,
      civility = '-1',
      lastName = '',
      firstName = '',
      email = '',
      phone1 = '',
      phone2 = '',
      secondName,
      civility2 = '-1',
      lastName2 = '',
      firstName2 = '',
    },
    preferences: {
      dataCollectionAuth,
      neverBounceResult,
      callbackTime,
      callbackDate,
    },
    professional: { socialReason, siret },
    additional: { comments = '' } = {},
  } = customerData

  options.append('id', id)
  options.append('zoho_id', zohoId)
  options.append('lang', country)

  // Preferences
  options.append('disclaimer', dataCollectionAuth ? '1' : '0')
  options.append('neverbounce_result', neverBounceResult)

  if (callbackTime !== '' && callbackDate !== '') {
    const date = new Date(`${callbackDate} ${callbackTime}`)
    options.append('web_callback', formatUTCDate(date) ?? '')
  }

  // Professional
  options.append('pro_social_reason', socialReason)
  if (siret?.length > 0) {
    options.append('pro_siret', siret)
  }

  // Personal data
  options.append('civility', civility)
  options.append('lastname', lastName)
  options.append('firstname', firstName)
  options.append('email', email)
  options.append('phone1', phone1)
  options.append('phone2', phone2)


  if (secondName) {
    options.append('civility2', civility2)
    options.append('lastname2', lastName2)
    options.append('firstname2', firstName2)
  }

  // Additional data
  if (comments !== '') {
    options.append('subscription_comments', comments)
  }

  return options
}
