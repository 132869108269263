const CAR_MINIMUM = {
  carElectricity: 1000,
  carGasMain: 1000,
  carGasSecondary: 200,
}

const METER_POWER_KVA_MINIMUM = 3

function getDefaultValues() {
  return {
    ...CAR_MINIMUM,
    minimumMeterPowerKva: METER_POWER_KVA_MINIMUM,
  }
}

// eslint-disable-next-line one-var
export const defaultValues = getDefaultValues()
