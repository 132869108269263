import { HOPButtonGroup } from '@/shared/components/HOPComponents/HOPButtonGroup'
import { FormControl } from 'baseui/form-control'
import SDButtonGroup from '@/shared/components/SDComponentsNewDesign/SDButtonGroup/SDButtonGroup'
import PropTypes from 'prop-types'
import { formControlOverrides } from '@/shared/components/SDComponentsNewDesign/styled-components'

const ClientType = ({
  clientTypeOptions,
  clientType,
  updateClientTypeHandler,
  mandatory,
  errorText,
  label,
  isEditable,
}) => {
  const finalOptions = []
  clientTypeOptions.forEach(option => {
    finalOptions.push({
      [option]: option,
    })
  })

  return (
    <FormControl label={label + mandatory} error={errorText} overrides={formControlOverrides}>
      <SDButtonGroup
        disabled={isEditable === false}
        onClickHandler={value => updateClientTypeHandler(value)}
        options={finalOptions}
        selectedValue={clientType}
        newTranslations={true}
        translationsPath='subscriptionForm:characteristics_module.contract_type.'
      />
    </FormControl>
  )
}

ClientType.propTypes = {
  clientTypeOptions: PropTypes.array,
  clientType: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  updateClientTypeHandler: PropTypes.func,
  mandatory: PropTypes.string,
  errorText: PropTypes.string,
  label: PropTypes.string,
  isEditable: PropTypes.bool,
}

export default ClientType
