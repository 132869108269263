import PropTypes from 'prop-types'
import { Tab, Tabs } from 'baseui/tabs-motion'
import Client from './Tabs/Client'
import useSubscriptionForm from './useSubscriptionForm'
import SecondTab from './Tabs/SecondTab'
import ThirdTab from './Tabs/ThirdTab'
import { Delete } from 'baseui/icon'
import useTabBar from './useTabBar'
import useTabBarStyle from './useTabBarStyle'
import SaleForm from '../Layouts/SaleForm'
import { ErrorBoundary } from 'react-error-boundary'
import { Suspense } from 'react'
import useScripts from './Scripts/useScripts'
import { Modal, ModalHeader, ModalBody } from 'baseui/modal'
import useAdditionalSaleScript from '@/containers/SubscriptionFormPage/AdditionalSalesPanel/useAdditionalSaleScript'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import ContractTimeline from './HistoricContracts/ContractTimeline'

const SubscriptionForm = ({ id, country, saveRoute }) => {
  const {
    activeTab,
    openedContracts,
    existingContracts,
    addContractModal,
    pushToOpenedContracts,
    removeFromOpenedContracts,
    updateActiveTabHandler,
    toggleContractModal,
    t,
  } = useTabBar()
  const { scripts, matchIcon } = useScripts()
  const { AdditionalSaleScript } = useAdditionalSaleScript()
  const {
    tabStyle,
    tabsOverrides,
    mainContainerStyle,
    tabsContainerStyle,
    rightColumnContainerStyle,
    rightColumnElemntStyle,
  } = useTabBarStyle()

  useSubscriptionForm({ applicationType: 'energie', id, saveRoute })

  return (
    <div style={mainContainerStyle}>
      <div style={tabsContainerStyle}>
        <Tabs
          activeKey={activeTab}
          onChange={({ activeKey }) => updateActiveTabHandler(activeKey)}
          overrides={tabsOverrides}
          activateOnFocus
        >
          <Tab title='Client' key='client' overrides={tabStyle}>
            <Client />
            <SecondTab />
            <ThirdTab />
          </Tab>
          {openedContracts.length > 0 &&
            openedContracts.map(contractId => {
              const contract = existingContracts[contractId]
              const contractKey = contract.id + '-' + contract.type
              return (
                <Tab
                  key={contractKey}
                  artwork={() => (
                    <Delete onClick={(e) => {
                      e.stopPropagation()
                      removeFromOpenedContracts({ id: contract.id, type: contract.type })}
                    } />
                  )}
                  title={`${t(`common:${contract.type}`)} ${contract.id}`}
                  overrides={tabStyle}
                >
                  <div>
                    <ErrorBoundary fallback={<p>Something went wrong - contact the support</p>}>
                      <Suspense fallback={<div>Loading...</div>}>
                        <SaleForm country={country} type={contract.type} />
                      </Suspense>
                    </ErrorBoundary>
                  </div>
                </Tab>
              )
            })}
          <Tab title='+ Ajouter' key='add-contract' overrides={tabStyle}>
            <></>
          </Tab>
        </Tabs>
      </div>
      <div style={rightColumnContainerStyle}>
        <div style={rightColumnElemntStyle}>
          <h1>Credit Booster</h1>
          {scripts.length === 0 && <div>No Credit booster</div>}
          {scripts.map(
            ([name, script]) =>
              script.length > 0 && (
                <Suspense fallback={<div>Loading...</div>} key={name}>
                  <AdditionalSaleScript
                    key={name}
                    icon={props => matchIcon(name, props)}
                    panel={name}
                    script={script}
                  />
                </Suspense>
              ),
          )}
        </div>
        <div style={rightColumnElemntStyle}>Aide completion</div>
        <div style={rightColumnElemntStyle}>
          <ContractTimeline />
        </div>
      </div>
      <Modal onClose={toggleContractModal} isOpen={addContractModal}>
        <ModalHeader>Ajouter un contrat</ModalHeader>
        <ModalBody>
          <SDButton onClick={() => pushToOpenedContracts({ type: 'energy', id: 'tempComeFromBoE1' })}>Energie</SDButton>
          <SDButton onClick={() => pushToOpenedContracts({ type: 'telecom', id: 'tempComeFromBoT1' })}>
            Telecom
          </SDButton>
          <SDButton onClick={() => pushToOpenedContracts({ type: 'insurance', id: 'tempComeFromBoI1' })}>
            Assurance
          </SDButton>
        </ModalBody>
      </Modal>
    </div>
  )
}

SubscriptionForm.propTypes = {
  id: PropTypes.string,
  saveRoute: PropTypes.string,
  token: PropTypes.string,
  country: PropTypes.string,
  loadViewParametersRoute: PropTypes.string,
}

export default SubscriptionForm
