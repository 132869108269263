import { useState } from 'react'
import { MaskedInput, SIZE } from 'baseui/input'
import PropTypes from 'prop-types'
import isValidDate from '@/shared/tools/isValidDate'
import isValidBirthdateAndMoreThan from '@/shared/tools/isValidBirthdate'
import { useTranslation } from 'react-i18next'
import { mergeOverrides } from 'baseui'
import { FloatingLabel, SDInputOverrides } from './styled-components'

const Birthdate = ({
  currentValue,
  error,
  mandatory,
  onChangeHandler,
  setErrors,
  removeErrors,
  disabled,
}) => {
  const { t } = useTranslation()
  const currentDate = new Date(currentValue)
  const day = currentValue ? ('0' + currentDate.getDate()).slice(-2) : ''
  const month = currentValue ? ('0' + (currentDate.getMonth() + 1)).slice(-2) : ''
  const display = currentValue
    ? `${day}/${month}/${currentDate.getFullYear()}`
    : ''
  const [date, setDate] = useState(display)

  const label = t('subscriptionForm:customer_module.birthdate.label') + mandatory

  const handleChange = (e) => {
    const value = e.target.value.replace(/\s/g, '')
    const [day, month, year] = e.target.value.split('/')
    const newDate = new Date(`${month}/${day}/${year} UTC`)

    setDate(e.target.value)

    if (value.length === 10) {
      if (isValidDate(newDate) && isValidBirthdateAndMoreThan(newDate, 18)) {
        onChangeHandler(newDate.toISOString().slice(0, 10))
        removeErrors('birthdate')
      } else {
        onChangeHandler('')
        setErrors({ birthdate: [t('subscriptionForm:customer_module.birthdate.error')] })
      }
    }
  }

  const newOverrides = mergeOverrides(SDInputOverrides(), {
    Input: {
      style: () => ({}),
    },
    InputContainer: {
      style: { paddingTop: '20px' },
    },
  })

  return (
    <div style={{ position: 'relative' }}>
      {label && <FloatingLabel>{label}</FloatingLabel>}
      <MaskedInput
        disabled={disabled}
        size={SIZE.compact}
        placeholder={t('subscriptionForm:customer_module.birthdate.placeholder')}
        mask="99/99/9999"
        value={date}
        onChange={handleChange}
        overrides={newOverrides}
      />
      {error && (
        <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>
          {error}
        </div>
      )}
    </div>
  )
}

Birthdate.propTypes = {
  currentValue: PropTypes.string,
  error: PropTypes.string,
  mandatory: PropTypes.string,
  onChangeHandler: PropTypes.func,
  setErrors: PropTypes.func,
  removeErrors: PropTypes.func,
  disabled: PropTypes.bool,
}

export default Birthdate
