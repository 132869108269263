import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { selectClientSubscriptionData } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormSelectors'
import { subscriptionFormActionsV2 } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormV2Sagas'
import { selectZohoModuleConfigurationData } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormConfigurationSelectors'

const useZohoMcpUnlocker = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { zohoId } = useSelector(selectClientSubscriptionData)
  const { isMcpLocked } = useSelector(selectZohoModuleConfigurationData)
  const { isMcpAgent, isAdmin } = useSelector(selectAuthData)
  const [unlockError, setUnlockError] = useState(null)
  const buttonLabel = isAdmin
    ? t('subscriptionForm:zoho_module.unblock_mcp_zoho_label_for_admin')
    : t('subscriptionForm:zoho_module.unblock_mcp_zoho_label_for_sale_agent')
  const blockedForAgent = isMcpLocked && !isMcpAgent
  const isBlockedBs = isMcpLocked
  const unlockZohoMcp = () => {
    dispatch({
      type: subscriptionFormActionsV2.ZOHO_MODULE_UNLOCK_MCP_CLIENT_V2,
      zohoId,
      t,
      setUnlockError,
    })
  }

  return {
    unlockZohoMcp,
    isBlockedBs,
    blockedForAgent,
    unlockError,
    isAdmin,
    isMcpAgent,
    buttonLabel,
  }
}

export default useZohoMcpUnlocker
