import { styled } from 'baseui'

export const SDInputOverrides = () => ({
  Input: {
    style: () => ({
      color: 'black',
      paddingRight: '.75rem',
      paddingLeft: '.45rem',
    }),
  },
  InputContainer: {
    style: { paddingTop: '0.625rem' },
  },
  EndEnhancer: {
    style: () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '2rem',
      padding: '0.625rem 0 0 0'
    }),
  },
})

export const FloatingLabel = styled('div', () => ({
  position: 'absolute',
  top: '0.375rem',
  left: '0.5rem',
  height: '1rem',
  fontSize: '0.75rem',
  color: 'gray',
  display: 'flex',
  alignItems: 'center',
  pointerEvents: 'none',
}))
