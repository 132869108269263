// relative to contract data that we want to store : fields values
const subscriptionData = {
  contractLoaded: false,
  mainAddress: {
    address: '',
    streetNumber: '',
    label: '',
    city: '',
    inseeCode: '',
    city_id: '',
    province: '',
    id: '',
    zipcode: '',
    isResident: '',
    housingType: '',
    household: '',
    occupancy: '',
    floor: '',
    tenantType: '',
    residenceType: '',
    appartmentNumber: '',
    additionalInformation: '',
    movingStartDate: '',
    providedByGas: '',
  },
  invoiceAddress: {
    showCheckbox: false,
    address: '',
    streetNumber: '',
    city: '',
    inseeCode: '',
    country: '',
    zipcode: '',
    id: '',
    residenceType: '',
    housingType: '',
    tenantType: '',
    floor: '',
    appartmentNumber: '',
    additionalInformation: '',
  },
  customer: {
    zohoId: '',
    name: '',
    name2: '',
    surname: '',
    surname2: '',
    email: '',
    neverbounce_result: null,
    secondName: false,
    dataCollectionAuth: false,
    civility: '',
    civility2: '',
    phone1: '',
    phone2: '',
    birthdate: '',
    callReason: '',
    referralFirstName: '',
    referralSecondName: '',
    isNewsletterRegistered: false,
    bacVoucher: false,
    isEkomiRegistered: false,
    pro_social_reason: '',
    pro_siret: '',
    pro_activity: '',
    pro_ape_code: '',
    web_callback: {
      callbackDate: '',
      callbackTime: '',
    },
    comments: '',
    tax_id_code: '',
  },
  billing: {
    iban: '',
    bic: '',
    businessCard: '',
    realPayment: '',
    frequency: '',
    paymentMode: '',
    paymentModeAlt: '',
    sendingMode: '',
    paymentDate: '',
    providersBlacklist: null,
    differentAccountHolder: false,
    accountHolderFirstname: '',
    accountHolderLastname: '',
    promoCode: '',
  },
}

export default subscriptionData
