import { useSelector } from 'react-redux'
import { 
  selectActiveTab, 
  selectActiveTabId,
  selectActiveTabType,
} from '../SubscriptionForm/SubscriptionFormSelectors'

const useRetrieveActiveContractIdAndType = () => {
  const activeTab = useSelector(selectActiveTab)
  const currentType = useSelector(selectActiveTabType)
  const currentId = useSelector(selectActiveTabId)

  console.log('useRetrieveActiveContractIdAndType', currentType, currentId)
  return {
    id: currentId,
    type: currentType,
    activeTab,
  }
}

export default useRetrieveActiveContractIdAndType
