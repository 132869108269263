import Customer from '../../Components/Customer/Customer'
import ZohoId from '../../Components/ZohoId/ZohoId'
import { Button } from 'baseui/button'
import useClient from './useClient'

const Client = () => {
  const { saveCustomer } = useClient()
  return (
    <div style={{ width: '100%' }}>
      <div>
        <Button style={{ marginRight: '8px' }} onClick={saveCustomer}>
          Sauver
        </Button>
      </div>
      <ZohoId />
      <Customer />
    </div>
  )
}

export default Client
