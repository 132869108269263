import PropTypes from 'prop-types'
import { SIZE } from 'baseui/button'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { useTranslation } from 'react-i18next'
import SDNotification from '@/shared/components/SDComponentsNewDesign/SDNotification/SDNotification'
import lock from '@/shared/components/SDComponentsNewDesign/icon_lock_open.svg'
import {
  StyledDisclaimer
} from './styled-components'

const Disclaimer = ({ checked, setChecked, disabled }) => {
  const { t } = useTranslation()
  const buttonText = checked
    ? t('subscriptionForm:customer_module.data_authorization.authorised')
    : t('subscriptionForm:customer_module.data_authorization.authorise')

  if (checked) {
    return (
      <StyledDisclaimer>
        <SDNotification type='positive'>
          {t('subscriptionForm:customer_module.data_authorization.caption')}
        </SDNotification>
      </StyledDisclaimer>
    )
  }

  return (
    <StyledDisclaimer>
      <SDNotification type='agentScript' primaryText={t('subscriptionForm:customer_module.data_authorization.label')}>
        <SDButton
          id='button-authorize-customer-module'
          startEnhancer={() => <img src={lock} alt='Zoho Logo' width='20' height='20' />}
          overrides={{
            BaseButton: {
              style: {
                width: '100%',
                marginTop: '1rem',
              },
            },
          }}
          disabled={disabled}
          size={SIZE.compact}
          onClick={event => {
            event.preventDefault()
            setChecked(true)
          }}
        >
          {buttonText}
        </SDButton>
      </SDNotification>
    </StyledDisclaimer>
  )
}

Disclaimer.propTypes = {
  checked: PropTypes.bool,
  errorText: PropTypes.string,
  mandatory: PropTypes.string,
  setChecked: PropTypes.func,
  disabled: PropTypes.bool,
}

export default Disclaimer
