import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import LogoHOP from '@/shared/icons/LogoHOP'
import SidebarButton from './bones/SidebarButton'
import { logoOverrides } from './styled-components'
import getIcon from './helpers/getIcon'
import getSelection from './helpers/getSelection'
import getAdminAuthorization from './helpers/getAdminAuthorization'
import moduleIsActive from './helpers/moduleIsActive'
import SidebarRouterButton from './bones/SidebarRouterButton'
import { useTranslation } from 'react-i18next'
import { clearAuth } from '@/containers/Login/AuthSlice'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { StyledDivider, SIZE } from 'baseui/divider'
import ApplicationCountry from './ApplicationCountry'

const SidebarMenu = ({ isAdmin, isSelectraAdmin, applicationType, error }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    loaderRoutesEnergy,
    loaderRoutes,
    loaderRoutesInsurance,
    modulesStatuses,
    modulesStatusesEnergy,
    modulesStatusesInsurance,
    country,
  } = useSelector(selectAuthData)
  const { getApplicationMenuRoutes, getModulesStatuses } = useBusinessType({
    loaderRoutesEnergy,
    loaderRoutesInsurance,
    loaderRoutes,
    modulesStatuses,
    modulesStatusesEnergy,
    modulesStatusesInsurance,
  })
  const routes = getApplicationMenuRoutes(applicationType)
  const modulesStatus = getModulesStatuses(applicationType)
  const links = []

  Object.keys(routes).forEach(key => {
    const isSelected = getSelection(routes[key])
    const icon = getIcon(key)
    const translation = t('menu:' + key)

    if (moduleIsActive(key, modulesStatus) && getAdminAuthorization(isAdmin, isSelectraAdmin, key)) {
      if (error === true && key !== 'logout') return
      if (key !== 'logout') {
        links.push(
          <Link key={'menu-link-' + key} to={routes[key]} id={key}>
            <SidebarRouterButton key={key} isSelected={isSelected} text={translation} icon={icon} />
          </Link>,
        )
      } else {
        links.push(
          <SidebarButton
            key={key}
            isSelected={getSelection(routes[key])}
            text={t('menu:' + key)}
            url={'#'}
            icon={getIcon(key)}
            clickHandler={async e => {
              e.preventDefault()
              dispatch(clearAuth())
              location.assign('/login?from=' + (window.location.pathname ?? ''))
            }}
          />,
        )
      }
    }
  })

  return (
    <>
      <LogoHOP overrides={logoOverrides()} />
      <ApplicationCountry lang={country} />
      {links}
      <div
        style={{
          width: '40px',
          borderRadius: '8px',
        }}
      >
        <StyledDivider $size={SIZE.section} />
      </div>
      {isAdmin && applicationType === 'telecom' && (
        <>
          <Link key={'menu-link-go-to-energy'} to={'/energie'} id='to-energy'>
            <SidebarRouterButton key='button-to-energy' text="Aller sur l'energie" icon={getIcon('energy-app')} />
          </Link>
          <Link key={'menu-link-go-to-insurance'} to={'/assurance'} id='to-insurance'>
            <SidebarRouterButton
              key='button-to-insurance'
              text="Aller sur l'assurance"
              icon={getIcon('insurance-app')}
            />
          </Link>
        </>
      )}
      {isAdmin && applicationType === 'energie' && (
        <>
          <Link key={'menu-link-go-to-telecom'} to={'/telecom'} id='to-telecom'>
            <SidebarRouterButton key='button-to-telecom' text='Aller sur le Telecom' icon={getIcon('telecom-app')} />
          </Link>
          <Link key={'menu-link-go-to-insurance'} to={'/assurance'} id='to-insurance'>
            <SidebarRouterButton
              key='button-to-insurance'
              text="Aller sur l'assurance"
              icon={getIcon('insurance-app')}
            />
          </Link>
        </>
      )}
      {isAdmin && applicationType === 'assurance' && (
        <>
          <Link key={'menu-link-go-to-energy'} to={'/energie'} id='to-energy'>
            <SidebarRouterButton key='button-to-energy' text="Aller sur l'energie" icon={getIcon('energy-app')} />
          </Link>
          <Link key={'menu-link-go-to-telecom'} to={'/telecom'} id='to-telecom'>
            <SidebarRouterButton key='button-to-telecom' text='Aller sur le Telecom' icon={getIcon('telecom-app')} />
          </Link>
        </>
      )}
    </>
  )
}

SidebarMenu.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isSelectraAdmin: PropTypes.bool.isRequired,
  applicationType: PropTypes.string.isRequired,
  error: PropTypes.bool,
}

export default SidebarMenu
