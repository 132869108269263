export const formControlOverrides = {
  Label: {
    style: () => ({
      fontFamily: 'Family/Body',
      fontSize: '16px',
      lineHeight: '160%',
      color: '#58595B',
    }),
  },
}
