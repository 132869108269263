import additionalSalesDefaultState from './additionalSalesDefaultState'

export const getZohoId = model =>
  model?.zoho_contract_detail_id || model?.zoho_id || ''

const mapAdditionalSalesData = viewParameters => {
  const currentSubscriber = viewParameters.current_subscriber
  return {
    ...additionalSalesDefaultState,
    leadVerisureTelecom: getZohoIdAndProductId(currentSubscriber?.sale_verisure_telecom),
    leadAssistanceOptions: retrieveAssistanceOptionData(currentSubscriber),
    leadCarbonOffset: retrieveCarbonOffsetData(currentSubscriber),
    leadConnectedDevices: retrieveVoltalisData(currentSubscriber),
    leadTelecomComparator: mapGenericLeadWithCallback(currentSubscriber?.sale_telecom_comparator),
    leadTelecomFree: mapGenericLeadWithCallback(currentSubscriber?.sale_telecom_free),
    leadTelecomEnergy: mapGenericLeadWithCallback(currentSubscriber?.sale_telecom_energy),
    leadTelecomSat: mapGenericLeadWithCallback(currentSubscriber?.sale_telecom_sat),
    leadSurveillanceCamera: retrieveSurveillanceCameraData(currentSubscriber),
    leadProtected: retrieveProtectedData(currentSubscriber),
    leadAssuranceProTelco: retrieveAssuranceProTelcoData(currentSubscriber),
    leadAssurance: retrieveAssuranceData(currentSubscriber, viewParameters.country ?? 'fr'),
    leadAxaAssurance: retrieveAxaAssuranceData(currentSubscriber),
    leadOffshoreEnergie: retrieveLeadOffshoreData(currentSubscriber?.sale_offshore_energie),
    leadOffshoreTelecom: retrieveLeadOffshoreData(currentSubscriber?.sale_offshore_telecom),
    leadPartnership: mapGenericLeadWithCallback(currentSubscriber?.sale_partnership),
    leadEnergyPro: retrieveLeadEnergyProData(currentSubscriber?.sale_energy_pro),
    leadBank: retrieveBankData(currentSubscriber),
    leadTelecom: retrieveLeadTelecomData(currentSubscriber),
    leadComparateur: retrieveLeadComparateurData(currentSubscriber),
    leadBankinAssurance: retrieveLeadBankinAssuranceData(currentSubscriber),
    leadTransferMuterLoger: retrieveTransferMuterLogerData(currentSubscriber),
    leadMonConseillerPerso: retrieveMonConseillerPersoData(currentSubscriber),
    leadMyChauffage: retrieveMyChauffageData(currentSubscriber),
    leadVerisure: simpleZohoIdMapping(currentSubscriber?.sale_verisure),
    leadProxiservePAC: simpleZohoIdMapping(currentSubscriber?.sale_proxiserve_p_a_c),
    leadAccessoriesSfr: retrieveAccessoriesSfrData(currentSubscriber),
    leadIsolation: simpleZohoIdMapping(currentSubscriber?.sale_isolation),
    leadEkwateurSolarKit: simpleZohoIdMapping(currentSubscriber?.sale_ekwateur_solar_kit),
    leadGroupPurchase: simpleZohoIdMapping(currentSubscriber?.sale_group_purchase),
    leadGroupPurchaseTelecom: simpleZohoIdMapping(currentSubscriber?.sale_group_purchase_telecom),
    leadAssistancePro: simpleZohoIdMapping(currentSubscriber?.sale_assistance_pro),
    leadGasBottle: simpleZohoIdMapping(currentSubscriber?.sale_gas_bottle),
    leadFloraAssuranceEthias: simpleLeadTypeAndZohoIdMappingNewStructure(
      currentSubscriber?.sale_flora_assurance_ethias,
    ),
    leadFloraAssuranceLead: simpleLeadTypeAndZohoIdMappingNewStructure(currentSubscriber?.sale_flora_assurance_lead),
    leadYagoAssuranceHouse: simpleLeadTypeAndZohoIdMappingNewStructure(currentSubscriber?.sale_yago_assurance_house),
    leadYagoAssuranceCar: simpleLeadTypeAndZohoIdMappingNewStructure(currentSubscriber?.sale_yago_assurance_car),
    leadYagoAssuranceMoto: simpleLeadTypeAndZohoIdMappingNewStructure(currentSubscriber?.sale_yago_assurance_moto),
    leadSantevetAssurance: simpleLeadTypeAndZohoIdMappingNewStructure(currentSubscriber?.sale_sante_vet_assurance),
    leadEthias: simpleZohoIdMapping(currentSubscriber?.sale_ethias),
    leadElite: simpleLeadTypeAndZohoIdMappingNewStructure(currentSubscriber?.sale_elite),
    leadEngieBrussels: simpleLeadTypeAndZohoIdMappingNewStructure(currentSubscriber?.sale_engie_brussels),
    leadVerisureTelecomBe: verisureTelecomBeMapping(currentSubscriber?.sale_verisure_be),
    leadBoilerEneco: retrieveBoilerWithOrderNumberData(currentSubscriber?.sale_boiler_eneco),
    leadBoilerEngie: retrieveBoilerWithOrderNumberData(currentSubscriber?.sale_boiler_engie),
    leadBoilerHomeserve: simpleLeadTypeAndZohoIdMappingNewStructure(currentSubscriber?.sale_boiler_homeserve),
    leadMesAllocs: simpleZohoIdMapping(currentSubscriber?.sale_administrative_service),
    leadEdfInteraction: interactionData(currentSubscriber?.edf_interaction ?? ''),
    leadWater: interactionData(currentSubscriber?.water_interaction ?? ''),
    leadTucoenergie: retrieveTucoenergieData(currentSubscriber),
    leadAssuranceEmprunteur: simpleLeadTypeAndZohoIdMapping(currentSubscriber?.sale_assurance_emprunteur),
    leadProxiserve: simpleLeadTypeAndZohoIdMapping(currentSubscriber?.sale_boiler_proxiserve),
    leadProxiserveRadiator: simpleZohoIdMapping(currentSubscriber?.sale_energy_renovation_radiator),
    leadProxiserveWaterHeater: simpleZohoIdMapping(currentSubscriber?.sale_energy_renovation_water_heater),
    leadBankSG: simpleZohoIdMapping(currentSubscriber?.sale_bank_s_g),
    leadBankPanorabanques: simpleZohoIdMapping(currentSubscriber?.sale_bank_panorabanques),
    leadSolarPanelsOtovoTE: simpleLeadTypeAndZohoIdMapping(currentSubscriber?.sale_solar_panels_otovo_t_e),
    leadNextories: retrieveNextoriesData(currentSubscriber),
    leadYouStock: simpleZohoIdMapping(currentSubscriber?.you_stock),
    leadMuterLoger: retrieveMuterLogerData(currentSubscriber),
    leadEnergyRenovation: retrieveLeadEnergyRenovationData(currentSubscriber),
    leadChargingPoint: retrieveLeadChargingPointData(currentSubscriber),
    leadAssurancePro: retrieveAssuranceProData(currentSubscriber),
    leadEngieHomeService: retrieveEngieHomeServiceData(currentSubscriber),
    leadGaranka: retrieveGarankaData(currentSubscriber),
    leadVoltalisBizdev: retrieveLeadVoltalisBizdevData(currentSubscriber),
    leadCresus: retrieveLeadCresusData(currentSubscriber),
    leadEnergyProPlaceDesEnergies: retrieveLeadEnergyProPlaceDesEnergiesData(currentSubscriber),
    leadActivationMySelectra: simpleZohoIdMapping(currentSubscriber?.sale_activation_my_selectra),
    leadAxaSmartphone: getZohoIdAndProductId(currentSubscriber?.sale_axa_smartphone),
    leadHomyos: retrieveLeadHomyosData(currentSubscriber),
    leadAssuranceEmprunteurMeilleurTaux: getZohoIdAndProductId(
      currentSubscriber?.sale_assurance_emprunteur_meilleur_taux,
    ),
  }
}

export const getZohoIdAndProductId = sale => {
  if (!sale) {
    return {
      zohoId: '',
      productId: '',
    }
  }

  return {
    zohoId: getZohoId(sale),
    productId: sale.product?.id ?? sale.product_id ?? '',
  }
}

export const retrieveLeadHomyosData = currentSubscriber => {
  return {
    zohoId: getZohoId(currentSubscriber?.sale_homyos),
    productId: currentSubscriber?.sale_homyos?.product_id ?? '',
    typeFormCreated: currentSubscriber?.sale_homyos?.attributes?.typeFormCreated ?? '',
  }
}

export const verisureTelecomBeMapping = currentSale => {
  return {
    ...simpleLeadTypeAndZohoIdMappingNewStructure(currentSale),
    orderNumber: currentSale?.attributes?.orderNumber ?? generateRandomOrderNumber(),
  }
}

export const generateRandomOrderNumber = () => {
  return Math.floor(Math.random() * 10000000000) + 1
}

export const retrieveLeadEnergyProPlaceDesEnergiesData = currentSubscriber => {
  let leadCallbackDate = ''
  let leadCallbackTime = ''
  if (currentSubscriber?.sale_energy_pro_place_des_energies?.callback) {
    [leadCallbackDate, leadCallbackTime] = currentSubscriber.sale_energy_pro_place_des_energies.callback.split(' ')
  }

  return {
    leadZohoId: getZohoId(currentSubscriber?.sale_energy_pro_place_des_energies),
    leadCommitmentEndDate: currentSubscriber?.sale_energy_pro_place_des_energies?.attributes?.commitmentEndDate ?? '',
    leadType: currentSubscriber?.sale_energy_pro_place_des_energies?.attributes?.leadType ?? '',
    leadSitesNumber: currentSubscriber?.sale_energy_pro_place_des_energies?.attributes?.sitesNumber ?? '',
    leadCurrentProvider: currentSubscriber?.sale_energy_pro_place_des_energies?.attributes?.currentProvider ?? '',
    leadDescription: currentSubscriber?.sale_energy_pro_place_des_energies?.description ?? '',
    errorPartnerSync: currentSubscriber?.sale_energy_pro_place_des_energies?.error_partner_sync ?? '',
    leadCallbackDate,
    leadCallbackTime,
  }
}

export const retrieveLeadVoltalisBizdevData = currentSubscriber => {
  return {
    zohoId: getZohoId(currentSubscriber?.sale_voltalis_bizdev),
    customerAgreement: currentSubscriber?.sale_voltalis_bizdev?.attributes?.customerAgreement ?? '',
    recapAgreement: currentSubscriber?.sale_voltalis_bizdev?.attributes?.recapAgreement ?? '',
  }
}

export const retrieveLeadCresusData = currentSubscriber => {
  return {
    zohoId: getZohoId(currentSubscriber?.sale_cresus),
  }
}

export const retrieveGarankaData = currentSubscriber => {
  return {
    zohoId: getZohoId(currentSubscriber?.sale_boiler_garanka),
    leadType: currentSubscriber?.sale_boiler_garanka?.lead_type ?? '',
    customerAgreement: '',
    offerId: '',
    buildingType: '',
    buildingAge: '',
    boilerDisposition: '',
    boilerTechnology: '',
    boilerAge: '',
    boilerBrand: '',
  }
}

export const retrieveEngieHomeServiceData = currentSubscriber => {
  return {
    zohoId: getZohoId(currentSubscriber?.sale_boiler_engie_home_service),
    leadType: currentSubscriber?.sale_boiler_engie_home_service?.lead_type ?? '',
    errorPartnerSync: currentSubscriber?.sale_boiler_engie_home_service?.error_partner_sync ?? '',
    offer: '',
    boilerAge: '',
    customerAcceptation: '',
  }
}

export const retrieveAssuranceProData = currentSubscriber => {
  let callBackDate = ''
  let callBackTime = ''
  if (currentSubscriber?.sale_assurance_pro?.callback) {
    [callBackDate, callBackTime] = currentSubscriber.sale_assurance_pro.callback.split(' ')
  }

  return {
    zohoId: getZohoId(currentSubscriber?.sale_assurance_pro),
    callbackDate: callBackDate,
    callbackTime: callBackTime,
  }
}

export const retrieveLeadChargingPointData = currentSubscriber => {
  return {
    zohoId: getZohoId(currentSubscriber?.sale_charging_point),
    chargingStationProvider: currentSubscriber?.sale_charging_point?.attributes?.chargingStationProvider ?? '',
  }
}

export const retrieveLeadEnergyRenovationData = currentSubscriber => {
  return {
    ...mapGenericLeadWithCallback(currentSubscriber?.sale_energy_renovation_default),
    motivation: currentSubscriber?.sale_energy_renovation_default?.attributes?.motivation ?? '',
  }
}

export const retrieveMuterLogerData = currentSubscriber => {
  return {
    zohoId: getZohoId(currentSubscriber?.sale_moving_muter_loger),
    departureCity: currentSubscriber?.sale_moving_muter_loger?.departure_city ?? '',
  }
}

export const retrieveBoilerWithOrderNumberData = model => {
  if (!model) {
    return {
      zohoId: '',
      leadType: '',
      orderNumber: '',
    }
  }

  return {
    zohoId: getZohoId(model),
    leadType: model.product?.name ?? '',
    orderNumber: model.attributes?.orderNumber ?? '',
  }
}

export const retrieveNextoriesData = currentSubscriber => {
  return {
    offerId: currentSubscriber?.sale_moving_nextories?.offer_id ?? '',
    departureCountry: currentSubscriber?.sale_moving_nextories?.departure_country ?? '',
    departureCity: currentSubscriber?.sale_moving_nextories?.departure_city ?? '',
    departureZipcode: currentSubscriber?.sale_moving_nextories?.departure_zipcode ?? '',
    movingDate: currentSubscriber?.sale_moving_nextories?.moving_date ?? '',
    movingDescription: currentSubscriber?.sale_moving_nextories?.moving_description ?? '',
    zohoId: getZohoId(currentSubscriber?.sale_moving_nextories),
    errorPartnerSync: currentSubscriber?.sale_moving_nextories?.error_partner_sync ?? '',
  }
}

export const simpleLeadTypeAndZohoIdMappingNewStructure = model => {
  if (!model) {
    return {
      zohoId: '',
      leadType: '',
    }
  }

  return {
    zohoId: getZohoId(model),
    leadType: model.product?.name ?? '',
  }
}

export const simpleLeadTypeAndZohoIdMapping = model => {
  if (!model) {
    return {
      zohoId: '',
      leadType: '',
    }
  }

  return {
    zohoId: getZohoId(model),
    leadType: model.lead_type ?? '',
  }
}

export const retrieveTucoenergieData = currentSubscriber => {
  return {
    zohoId: getZohoId(currentSubscriber?.sale_tucoenergie),
    typeCivility: currentSubscriber?.sale_tucoenergie?.attributes?.typeCivility ?? '',
    typeOccupation: currentSubscriber?.sale_tucoenergie?.attributes?.typeOccupation ?? '',
    typeRequest: currentSubscriber?.sale_tucoenergie?.attributes?.typeRequest ?? '',
    leadStatus: currentSubscriber?.sale_tucoenergie?.status_code ?? '',
    errorPartnerSync: currentSubscriber?.sale_tucoenergie?.error_partner_sync ?? '',
  }
}

export const interactionData = zohoId => {
  return {
    zohoId: zohoId ?? '',
  }
}

export const simpleZohoIdMapping = model => {
  return {
    zohoId: getZohoId(model),
  }
}

export const retrieveMyChauffageData = currentSubscriber => {
  return {
    zohoId: getZohoId(currentSubscriber?.sale_boiler_my_chauffage),
    leadType: currentSubscriber?.sale_boiler_my_chauffage?.lead_type ?? '',
    errorPartnerSync: currentSubscriber?.sale_boiler_my_chauffage?.error_partner_sync ?? '',
    isOwner: '',
    installationType: '',
    installationConditionValidated: '',
    firstCustomerAgreement: '',
    secondCustomerAgreement: '',
    boilerReason: '',
  }
}

export const retrieveMonConseillerPersoData = currentSubscriber => {
  return {
    zohoId: getZohoId(currentSubscriber?.sale_selectra_service),
    offer: currentSubscriber?.sale_selectra_service?.selectra_service_id ?? '',
    partnerId: currentSubscriber?.sale_selectra_service?.partner_id ?? '',
    statusCode: currentSubscriber?.sale_selectra_service?.status_code ?? '',
    errorPartnerSync: currentSubscriber?.sale_selectra_service?.error_partner_sync ?? '',
  }
}

export const retrieveTransferMuterLogerData = currentSubscriber => {
  return {
    ...mapGenericLeadWithCallback(currentSubscriber?.sale_moving_transfer_muter_loger),
    moreThan10Employees: null,
  }
}

export const retrieveLeadTelecomData = currentSubscriber => {
  return {
    ...mapGenericLeadWithCallback(currentSubscriber?.sale_telecom),
    type: currentSubscriber?.sale_telecom?.lead_type ?? '',
  }
}

export const retrieveLeadComparateurData = currentSubscriber => {
  return {
    ...mapGenericLeadWithCallback(currentSubscriber?.sale_comparateur),
    type: currentSubscriber?.sale_comparateur?.attributes?.leadType ?? '',
    description: currentSubscriber?.sale_comparateur?.description ?? '',
  }
}

export const retrieveLeadBankinAssuranceData = currentSubscriber => {
  return {
    ...mapGenericLeadWithCallback(currentSubscriber?.sale_bankin_assurance),
    type: currentSubscriber?.sale_bankin_assurance?.attributes?.leadType ?? '',
    description: currentSubscriber?.sale_bankin_assurance?.description ?? '',
  }
}

export const retrieveBankData = currentSubscriber => {
  let callbackDate = ''
  let callbackTime = ''
  if (currentSubscriber?.sale_bank?.callback) {
    [callbackDate, callbackTime] = currentSubscriber.sale_bank.callback.split(' ')
  }
  return {
    zohoId: getZohoId(currentSubscriber?.sale_bank),
    callbackDate: callbackDate ?? '',
    callbackTime: callbackTime ?? '',
  }
}

export const retrieveLeadOffshoreData = currentModel => {
  return {
    zohoId: getZohoId(currentModel),
    currentProvider: currentModel?.attributes?.current_provider ?? '',
  }
}

export const retrieveAxaAssuranceData = currentSubscriber => {
  return {
    zohoId: getZohoId(currentSubscriber?.sale_axa_assurance),
    leadType: currentSubscriber?.sale_axa_assurance?.product?.name ?? '',
    imei: currentSubscriber?.sale_axa_assurance?.attributes?.imei ?? '',
    deviceStatus: currentSubscriber?.sale_axa_assurance?.attributes?.deviceStatus ?? '',
    productId: currentSubscriber?.sale_axa_assurance?.attributes?.productId ?? '',
    axaEmailAlreadySent: currentSubscriber?.sale_axa_assurance?.attributes?.emailSent ?? false,
  }
}

export const retrieveAssuranceData = (currentSubscriber, country) => {
  const saleModel = country === 'be'
    ? currentSubscriber?.sale_assurance_new
    : currentSubscriber?.sale_assurance_default
  return {
    ...mapGenericLeadWithCallback(saleModel),
    leadType: saleModel ? saleModel.lead_type ?? '' : '',
    professionalCategory: '',
  }
}

export const retrieveAssuranceProTelcoData = currentSubscriber => {
  let callBackDate = ''
  let callBackTime = ''
  if (currentSubscriber?.sale_assurance_pro_telco?.callback) {
    [callBackDate, callBackTime] = currentSubscriber.sale_assurance_pro_telco.callback.split(' ')
  }

  return {
    zohoId: getZohoId(currentSubscriber?.sale_assurance_pro_telco),
    productId: currentSubscriber?.sale_assurance_pro_telco?.product_id ?? '',
    callbackDate: callBackDate,
    callbackTime: callBackTime,
  }
}

export const retrieveProtectedData = currentSubscriber => {
  return {
    zohoId: getZohoId(currentSubscriber?.sale_protected),
    invoiceFrequency: currentSubscriber?.sale_protected?.attributes?.invoiceFrequency ?? '',
    smsCode: currentSubscriber?.sale_protected?.attributes?.code ?? '',
    errorPartnerSync: currentSubscriber?.sale_protected?.error_partner_sync ?? '',
    productId: currentSubscriber?.sale_protected?.product_id ?? '',
    statusCode: currentSubscriber?.sale_protected?.status_code ?? null,
  }
}

export const retrieveSurveillanceCameraData = currentSubscriber => {
  return {
    ...mapGenericLeadWithCallback(currentSubscriber?.sale_surveillance_camera),
    type: currentSubscriber?.sale_surveillance_camera?.lead_type ?? '',
  }
}

export const retrieveVoltalisData = currentSubscriber => {
  return {
    zohoId: getZohoId(currentSubscriber?.sale_connected_devices),
    heaterCount: currentSubscriber?.sale_connected_devices?.heater_count ?? '',
    callbackDate: currentSubscriber?.sale_connected_devices?.callback_date ?? '',
    callbackDetail: currentSubscriber?.sale_connected_devices?.callback_detail ?? '',
    constructionYear: currentSubscriber?.sale_connected_devices?.construction_year ?? '',
    errorPartnerSync: currentSubscriber?.sale_connected_devices?.error_partner_sync ?? '',
    isMainResidence: '',
    isHeatedElectricity: '',
    isMoreThan1Radiator: '',
    customerCallbackAgreement: '',
    customerInstallationAgreement: '',
  }
}

export const retrieveAssistanceOptionData = currentSubscriber => {
  return {
    zohoId: getZohoId(currentSubscriber?.sale_assistance_option_id),
    assistanceId: currentSubscriber?.sale_assistance_option_id?.assistance_id ?? '',
    customerAcceptation: '',
    axaEmailAlreadySent: currentSubscriber?.sale_assistance_option_id?.email_sent ?? false,
    delayActivation: currentSubscriber?.sale_assistance_option_id?.delay_activation_at ?? '',
    language: currentSubscriber?.sale_assistance_option_id?.language ?? null,
  }
}

export const retrieveCarbonOffsetData = currentSubscriber => {
  const zohoId = getZohoId(currentSubscriber?.sale_carbon_offset)
  return {
    zohoId,
    offsetId: currentSubscriber?.sale_carbon_offset?.carbon_offset_id ?? '',
    customerAcceptation: zohoId ? '1' : '',
    language: currentSubscriber?.sale_carbon_offset?.language ?? null,
  }
}

export const retrieveAccessoriesSfrData = currentSubscriber => {
  return {
    zohoId: getZohoId(currentSubscriber?.sale_accessories_sfr),
    product: currentSubscriber?.sale_accessories_sfr?.attributes?.product ?? '',
    saleId: currentSubscriber?.sale_accessories_sfr?.attributes?.saleId ?? '',
    productId: currentSubscriber?.sale_accessories_sfr?.product_id ?? '',
  }
}

export const retrieveLeadEnergyProData = sale => {
  if (!sale) {
    return {
      zohoId: '',
      callbackDate: '',
      callbackTime: '',
      description: '',
      commitmentEndDate: '',
    }
  }

  let callBackDate = ''
  let callBackTime = ''
  if (sale.callback) {
    [callBackDate, callBackTime] = sale.callback.split(' ')
  }

  return {
    zohoId: getZohoId(sale),
    callbackDate: callBackDate,
    callbackTime: callBackTime,
    description: sale.description ?? '',
    commitmentEndDate: sale.attributes?.commitmentEndDate ?? '',
  }
}

export const mapGenericLeadWithCallback = model => {
  if (!model) {
    return {
      zohoId: '',
      callbackDate: '',
      callbackTime: '',
      description: '',
    }
  }

  let callBackDate = ''
  let callBackTime = ''
  if (model.callback) {
    [callBackDate, callBackTime] = model.callback.split(' ')
  }
  return {
    zohoId: getZohoId(model),
    leadType: model.product?.name ?? '',
    callbackDate: callBackDate,
    callbackTime: callBackTime,
    description: model.description ?? '',
  }
}

export default mapAdditionalSalesData
