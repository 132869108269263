// relative to contract data that we want to store : fields values
const insuranceSubscriptionData = {
  contract: {
    providerId: '',
    offerId: '',
    providerExternalId: '',
    annualPremium: '',
    annualCommission: '',
  },
  characteristics: {
    productType: '',
    prestationType: '',
  },
}

export default insuranceSubscriptionData
