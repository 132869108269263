import { 
  selectOpenedContracts, 
  selectExistingContracts, 
  selectConfig, 
  selectGroupedContractsByDate,
 } from './SubscriptionFormSelectors'
import { useDispatch, useSelector } from 'react-redux'
import {
  addContractToOpenedContracts,
  removeContractFromOpenedContracts,
  toggleAddContractModal,
  updateActiveTab,
} from './SubscriptionFormSlice'
import { subscriptionFormActionsV2 } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormV2Sagas'
import { useSnackbar } from 'baseui/snackbar'
import { useTranslation } from 'react-i18next'

const useTabBar = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { activeTab, addContractModal } = useSelector(selectConfig)
  const openedContracts = useSelector(selectOpenedContracts)
  const existingContracts = useSelector(selectExistingContracts)
  const groupedContractsByDate = useSelector(selectGroupedContractsByDate)
  const { enqueue } = useSnackbar();

  const pushToOpenedContracts = contract => {
    const wasAlreadyLoaded = contract.config?.loadedFromBackend === true

    if (!wasAlreadyLoaded) {
      dispatch({
        type: subscriptionFormActionsV2.CUSTOMER_MODULE_LOAD_CONTRACT_DATA,
        contractId: contract.id,
        enqueue,
      });
      return
    }

    dispatch(addContractToOpenedContracts(contract))
  }
  const removeFromOpenedContracts = contract => {
    dispatch(removeContractFromOpenedContracts(contract))
  }

  const toggleContractModal = () => {
    dispatch(toggleAddContractModal())
  }

  const updateActiveTabHandler = activeKey => {
    if (activeKey === 'add-contract') {
      toggleContractModal()
      return
    }
    dispatch(updateActiveTab(activeKey))
  }

  return {
    activeTab,
    openedContracts,
    existingContracts,
    groupedContractsByDate,
    addContractModal,
    pushToOpenedContracts,
    removeFromOpenedContracts,
    updateActiveTabHandler,
    toggleContractModal,
    t,
  }
}

export default useTabBar
