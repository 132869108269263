import { useDispatch } from 'react-redux'
import {
  addCommonFormErrors,
  deleteCommonFormErrors,
} from '@/containers/SubscriptionFormPageNew/SubscriptionForm/FormConfigurationV2Slice'

const useFormErrorManagement = () => {
  const dispatch = useDispatch()
  const setErrors = error => {
    dispatch(addCommonFormErrors(error))
  }

  const removeError = errorKey => {
    dispatch(deleteCommonFormErrors(errorKey))
  }
  return { setErrors, removeError }
}
export default useFormErrorManagement
