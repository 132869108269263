import { FormControl } from 'baseui/form-control'
import { HOPInput } from '@/shared/components/HOPComponents/HOPInput'
import HOPSelect from '@/shared/components/HOPComponents/HOPSelect'
import HOPGroupedSelect from '@/shared/components/HOPComponents/HOPGroupedSelect'
import { SIZE } from 'baseui/button'

import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { Select } from 'baseui/select'
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox'
import useAddEditModal from './useAddEditModal'

const AddEditModal = ({ setIsOpen }) => {
  const {
    t,
    close,
    organizations,
    offices,
    formErrors,
    selectedUser,
    updateSelectedUser,
    sitesOptions,
    submitHandler,
    roleList,
    languageList,
    convertList,
    convertOrganizationsList,
    findSelectedOrganization,
    generateSitesSelectedValues,
    nameOfOrganization,
    isSelectraOrganisation,
    nameOfOffice,
    ipAddressWhitelists,
  } = useAddEditModal({ setIsOpen })

  return (
    <form onSubmit={submitHandler}>
      <FormControl label={t('usersManagement:modal.name')} error={formErrors.name ? formErrors.name[0] : ''}>
        <HOPInput
          currentValue={selectedUser.name}
          handleValue={value => {
            updateSelectedUser({ name: value })
          }}
        />
      </FormControl>
      <FormControl
        label={t('usersManagement:modal.firstname')}
        error={formErrors.firstname ? formErrors.firstname[0] : ''}
      >
        <HOPInput
          currentValue={selectedUser.firstname}
          handleValue={value => {
            updateSelectedUser({ firstname: value })
          }}
        />
      </FormControl>
      <FormControl label={t('usersManagement:modal.email')} error={formErrors.email ? formErrors.email[0] : ''}>
        <HOPInput
          currentValue={selectedUser.email}
          handleValue={value => {
            updateSelectedUser({ email: value })
          }}
        />
      </FormControl>
      <FormControl
        label={t('usersManagement:modal.language')}
        error={formErrors.language ? formErrors.language[0] : ''}
      >
        <HOPSelect
          optionsList={convertList(languageList)}
          placeholder={selectedUser.language ? selectedUser.language : t('common:choose')}
          selectedValue={selectedUser.language ?? ''}
          onChangeHandler={params => updateSelectedUser({ language: params.value[0]?.id })}
        />
      </FormControl>
      <FormControl label={t('usersManagement:modal.role_type')} error={formErrors.role ? formErrors.role[0] : ''}>
        <HOPSelect
          optionsList={convertList(roleList)}
          placeholder={selectedUser.role ? selectedUser.role : t('common:choose')}
          selectedValue={selectedUser.role ?? ''}
          onChangeHandler={params => updateSelectedUser({ role: params.value[0]?.id })}
        />
      </FormControl>
      <FormControl
        label={t('usersManagement:modal.organisation')}
        error={formErrors.organisation_id ? formErrors.organisation_id[0] : ''}
      >
        <HOPGroupedSelect
          optionsList={convertOrganizationsList(organizations)}
          placeholder={
            selectedUser.organisation_id ? nameOfOrganization(selectedUser.organisation_id) : t('common:choose')
          }
          selectedValue={findSelectedOrganization(
            organizations,
            selectedUser.organisation_id ? selectedUser.organisation_id : '',
          )}
          onChangeHandler={params => updateSelectedUser({ organisation_id: params.value[0]?.id })}
        />
      </FormControl>

      <FormControl
        label={t('usersManagement:modal.ip_address_whitelist')}
        error={formErrors.ip_address_whitelist_id ? formErrors.ip_address_whitelist_id[0] : ''}
      >
        <HOPSelect
          optionsList={convertList(ipAddressWhitelists)}
          placeholder={t('common:choose')}
          selectedValue={selectedUser.ip_address_whitelist_id ? selectedUser.ip_address_whitelist_id.toString() : ''}
          onChangeHandler={params => updateSelectedUser({ ip_address_whitelist_id: params.value[0]?.id })}
        />
      </FormControl>

      {selectedUser.organisation_id && isSelectraOrganisation(selectedUser.organisation_id) && (
        <FormControl
          label={t('usersManagement:modal.office')}
          error={formErrors.office_uuid ? formErrors.office_uuid[0] : ''}
        >
          <HOPSelect
            optionsList={convertList(offices)}
            placeholder={selectedUser.office_uuid ? nameOfOffice(selectedUser.office_uuid) : t('common:choose')}
            selectedValue={selectedUser.office_uuid ? selectedUser.office_uuid.toString() : ''}
            onChangeHandler={params => updateSelectedUser({ office_uuid: params.value[0]?.id })}
          />
        </FormControl>
      )}
      {!selectedUser.id && (
        <FormControl
          label={t('usersManagement:modal.password')}
          error={formErrors.password ? formErrors.password[0] : ''}
        >
          <HOPInput handleValue={value => updateSelectedUser({ password: value })} />
        </FormControl>
      )}
      <FormControl label={t('usersManagement:modal.zoho_id')} error={formErrors.zoho_id ? formErrors.zoho_id[0] : ''}>
        <HOPInput
          currentValue={selectedUser.zoho_id ?? ''}
          handleValue={value => updateSelectedUser({ zoho_id: value })}
        />
      </FormControl>
      <FormControl label={t('usersManagement:modal.tde_id')} error={formErrors.tde_id ? formErrors.tde_id[0] : ''}>
        <HOPInput
          currentValue={selectedUser.tde_id ?? ''}
          handleValue={value => updateSelectedUser({ tde_id: value })}
        />
      </FormControl>
      <FormControl
        label={t('usersManagement:modal.hangouts_name')}
        error={formErrors.hangouts_space_name ? formErrors.hangouts_space_name[0] : ''}
      >
        <HOPInput
          currentValue={selectedUser.hangouts_space_name ?? ''}
          handleValue={value => updateSelectedUser({ hangouts_space_name: value })}
        />
      </FormControl>
      <FormControl
        label={t('usersManagement:modal.calendly_url')}
        error={formErrors.calendly_url ? formErrors.calendly_url[0] : ''}
      >
        <HOPInput
          currentValue={selectedUser.calendly_url ?? ''}
          handleValue={value => updateSelectedUser({ calendly_url: value })}
        />
      </FormControl>
      <Checkbox
        overrides={{
          Root: {
            style: () => ({
              marginBottom: '1.5rem',
            }),
          },
        }}
        checked={selectedUser.is_cousin_hub}
        onChange={e => updateSelectedUser({ is_cousin_hub: e.target.checked })}
        labelPlacement={LABEL_PLACEMENT.right}
      >
        {t('usersManagement:modal.cousin_hub')}
      </Checkbox>
      <FormControl label={t('usersManagement:modal.authorized_sites')}>
        <Select
          multi
          labelKey='label'
          valueKey='id'
          options={sitesOptions}
          value={generateSitesSelectedValues(selectedUser.authorized_sites)}
          onChange={({ value }) => updateSelectedUser({ authorized_sites: value })}
        />
      </FormControl>
      <SDButton
        disabled={false}
        isLoading={false}
        size={SIZE.compact}
        block={false}
        overrides={{
          BaseButton: {
            style: () => ({
              marginRight: '.5rem',
            }),
          },
        }}
        type='submit'
      >
        {selectedUser.id ? t('common:edit') : t('common:create')}
      </SDButton>
      <SDButton size={SIZE.compact} block={false} type='button' onClick={close}>
        {t('common:cancel')}
      </SDButton>
    </form>
  )
}

export default AddEditModal
