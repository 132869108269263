export const fieldsInformation = {
  address: 'CharacteristicsModule',
  addressType: 'CharacteristicsModule',
  bic: 'BillingPaymentModule',
  city: 'CharacteristicsModule',
  cityId: 'CharacteristicsModule',
  civility2: 'CustomerModule',
  civility: 'CustomerModule',
  dataCollectionAuth: 'CustomerModule',
  email: 'CustomerModule',
  productType: 'CharacteristicsModule',
  iban: 'BillingPaymentModule',
  name2: 'CustomerModule',
  name: 'CustomerModule',
  firstname: 'CustomerModule',
  offer_id: 'OfferModule',
  offerId: 'OfferModule',
  pdlNo: 'PDLModule',
  phone1: 'CustomerModule',
  phone2: 'CustomerModule',
  prestationType: 'CharacteristicsModule',
  providerId: 'OfferModule',
  socialReason: 'CustomerModule',
  streetNumber: 'CharacteristicsModule',
  surname2: 'CustomerModule',
  surname: 'CustomerModule',
  lastname: 'CustomerModule',
  zipcode: 'CharacteristicsModule',
  zohoId: 'ZohoModule',
  zoho_id: 'ZohoModule',
  meter_id_elec: 'PDLModule',
  comments: 'ConsumptionModule',
  city_id: 'CharacteristicsModule',
  imei: 'AdditionalInformationModule',
  accountHolderFirstname: 'BillingPaymentModule',
  accountHolderLastname: 'BillingPaymentModule',
  providersBlacklist: 'BillingPaymentModule',
  activity: 'CustomerModule',
  additionalInformation: 'AddressModule',
  alreadyClient: 'PropaneModule',
  apeCode: 'CustomerModule',
  appartmentNumber: 'AddressModule',
  billAmountElec: 'PDLModule',
  billAmountGas: 'PCEModule',
  billingFrequency: 'BillingPaymentModule',
  birthdate: 'CustomerModule',
  birthday: 'CustomerModule',
  bottleNumber: 'PropaneModule',
  bottleSize: 'PropaneModule',
  bypass_retractation_elec: 'EffectiveDateModule',
  car: 'PropaneModule',
  car_elec_base: 'ConsumptionModule',
  car_elec_hc: 'ConsumptionModule',
  carHc: 'ConsumptionModule',
  car_elec_hp: 'ConsumptionModule',
  carHp: 'ConsumptionModule',
  car_gas: 'ConsumptionModule',
  carBase: 'ConsumptionModule',
  carGas: 'ConsumptionModule',
  communicationPreference: 'AdditionalInformationModule',
  contact_time_propane: 'PropaneModule',
  contactTime: 'PropaneModule',
  contractType: 'CharacteristicsModule',
  cooking: 'ConsumptionModule',
  country: 'AddressModule',
  current_use_propane: 'PropaneModule',
  currentUse: 'PropaneModule',
  debordement: 'OfferModule',
  disclaimerCommitmentPro: 'CharacteristicsModule',
  disclaimerCommitmentProOui: 'CharacteristicsModule',
  effective_date_elec: 'EffectiveDateModule',
  elecEffectiveDate: 'EffectiveDateModule',
  elecIndex: 'PDLModule',
  elecMeterType: 'PDLModule',
  elecReadingDate: 'PDLModule',
  energyOriginElec: 'OfferModule',
  energyOriginGas: 'OfferModule',
  energySupplierElec: 'PDLModule',
  energySupplierGas: 'PCEModule',
  energySupplierPropane: 'PropaneModule',
  energyType: 'CharacteristicsModule',
  energy_type: 'CharacteristicsModule',
  floor: 'AddressModule',
  gasEffectiveDate: 'EffectiveDateModule',
  gasIndex: 'PCEModule',
  gasMeterType: 'PCEModule',
  gasReadingDate: 'PCEModule',
  heating: 'ConsumptionModule',
  hotwater: 'ConsumptionModule',
  household: 'ConsumptionModule',
  housingType: 'AddressModule',
  installation_age_propane: 'PropaneModule',
  invoice_sending_mode: 'BillingPaymentModule',
  invoiceSendingMode: 'BillingPaymentModule',
  invoiceStreetName: 'BillingPaymentModule',
  medicalNeeds: 'AdditionalInformationModule',
  meter_option_elec: 'PDLModule',
  meter_power_elec: 'PDLModule',
  meterPower: 'PDLModule',
  modificationReason: 'ConsumptionModule',
  monthly_amount: 'ConsumptionModule',
  monthlyAmount: 'ConsumptionModule',
  movingStartDate: 'AddressModule',
  occupancy: 'ConsumptionModule',
  paymentDate: 'BillingPaymentModule',
  paymentMode: 'BillingPaymentModule',
  pceNo: 'PCEModule',
  presence: 'PropaneModule',
  presence_propane: 'PropaneModule',
  bottle_size_propane: 'PropaneModule',
  bottle_number_propane: 'PropaneModule',
  project_deadline_propane: 'PropaneModule',
  quoteReason: 'PropaneModule',
  reading_date_elec: 'PDLModule',
  realPayment: 'BillingPaymentModule',
  residenceType: 'AddressModule',
  serviceNeeds: 'AdditionalInformationModule',
  siret: 'CustomerModule',
  pro_siret: 'CustomerModule',
  surface: 'ConsumptionModule',
  tank_access_propane: 'PropaneModule',
  tankAccess: 'PropaneModule',
  tariffOption: 'PDLModule',
  tenantType: 'AddressModule',
  meter_id_gas: 'PCEModule',
  cooking_energy: 'ConsumptionModule',
  heating_energy: 'ConsumptionModule',
  hotwater_energy: 'ConsumptionModule',
  number_of_occupants: 'ConsumptionModule',
  conso_residence_type: 'ConsumptionModule',
  residence_presence_main: 'ConsumptionModule',
  saleIdMandatory: 'OfferModule',
  product_type: 'CharacteristicsModule',
  commitmentEndDate: 'OfferModule',
  annual_premium: 'OfferModule',
  annual_commission: 'OfferModule',
}
