import { styled } from 'baseui'

export const StyledNotification = styled('div', ({ $theme, $type, $boldText, $border }) => {
  const outputColour = {}
  let borderStyle = 'solid'

  switch ($type) {
    case 'negative':
      outputColour.text = $theme.colors.negative
      outputColour.bg = $theme.colors.negative150
      outputColour.shadow = $theme.colors.negative400
      break
    case 'info':
      outputColour.text = '#58595B'
      outputColour.bg = '#E9EEFB'
      outputColour.shadow = $theme.colors.negative400
      break
    case 'positive':
      outputColour.text = '#58595B'
      outputColour.bg = '#D1F8CB'
      outputColour.shadow = $theme.colors.positive400
      break
    case 'warning':
    case 'additionalSale':
      outputColour.text = $theme.colors.grey
      outputColour.bg = $theme.colors.warning150
      outputColour.shadow = $theme.colors.warning300
      break
    case 'script':
    case 'agentScript':
    default:
      outputColour.text = $theme.colors.grey
      outputColour.bg = $theme.colors.bg050
      outputColour.shadow = $theme.colors.primary400
      borderStyle = 'dashed'
      break
  }

  return {
    display: 'flex',
    padding: '8px',
    borderRadius: '8px',
    border: $border !== false ? `1px ${borderStyle} ${outputColour.shadow}` : 'none',
    backgroundColor: outputColour.bg,
    color: outputColour.text,
    ...$theme.typography.ParagraphXSmall,
    fontWeight: $boldText ? 'bold' : 'normal',
    fontSize: $boldText ? '1rem' : '0.875rem',
    margin: '10px 0 10px 0',
  }
})
