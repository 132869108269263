export const SDButtonGroupOverrides = (direction, error) => {
    return {
      Root: {
        style: ({ $theme }) => {
          const outlineStyle = error ? `0.125rem solid #015AA3` : 'none'

          return {
            flexDirection: direction ?? 'initial',
            padding: '.1875rem',
            backgroundColor: '#EDEEEE',

            border: `solid 1px #EDEEEE`,
            outline: outlineStyle,
            outlineOffset: '-.125rem',

            width: '100%',
            borderTopRightRadius: $theme.borders.radius200,
            borderBottomRightRadius: $theme.borders.radius200,
            borderBottomLeftRadius: $theme.borders.radius200,
            borderTopLeftRadius: $theme.borders.radius200,
          }
        },
      },
    }
  },
  SDButtonGroupButtonOverrides = () => {
    return {
      BaseButton: {
        style: ({ $theme, $disabled, $isSelected }) => {
          const bgColour = $isSelected ? "#FFFFFF" : "#EDEEEE"
          const textColor = $disabled ? "#AAAAAA" : ($isSelected ? "#015AA3" : 'inherit')
          const border = $isSelected ? "1px solid #015AA3" : "1px solid transparent"
          const hoverOutline = $disabled ? 'none' : "0.125rem solid #015AA3"

          return {
            flex: '1 1 auto',
            padding: '.5rem',
            color: textColor,
            backgroundColor: bgColour,
            border,
            borderRadius: '.375rem',
            ':hover': {
              outline: hoverOutline,
              outlineOffset: '-.125rem',
            },
            ':focus': {
              outline: hoverOutline,
              outlineOffset: '-.125rem',
            },
            ':disabled': {
              backgroundColor: bgColour,
            },
            ':disabled :hover': {
              backgroundColor: bgColour,
            },
            ':disabled :focus': {
              backgroundColor: bgColour,
            },
            ':not(:last-child)': {
              marginRight: '.1875rem',
            },
          }
        },
      },
    }
  }
