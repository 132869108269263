import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addScript, removeScript } from '../SubscriptionFormSlice'
import { useTranslation } from 'react-i18next'
import { isPropaneContract } from '../SubscriptionFormSelectors'

const useSurveillanceCameraScript = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isPropane = useSelector(isPropaneContract)

  useEffect(() => {
    if (!isPropane) {
      dispatch(removeScript('surveillanceCamera'))
      return
    }
    dispatch(
      addScript({
        name: 'surveillanceCamera',
        script: t('subscriptionForm:characteristics_module.script_lead_camera.propane_house'),
      }),
    )
  }, [isPropane])
}

export default useSurveillanceCameraScript
