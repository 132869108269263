const billingSubscriptionData = {
  iban: null,
  bic: null,
  businessCard: null,
  realPayment: null,
  frequency: null,
  paymentMode: null,
  paymentModeAlt: null,
  sendingMode: null,
  paymentDate: null,
  providersBlacklist: null,
  differentAccountHolder: false,
  accountHolderFirstname: null,
  accountHolderLastname: null,
  promoCode: null,
}

export default billingSubscriptionData
