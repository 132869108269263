import { styled } from 'baseui';

export const StyledIcon = styled('img', () => ({
  width: '1.5rem',
  height: '1.5rem',
  marginRight: '.25rem',
}));

export const StyledTitle = styled('h3', () => ({
  flexGrow: 1,
  margin: 0,
  fontFamily: 'Family/Body',
  fontWeight: 600,
  fontSize: '18px',
  letterSpacing: '-1.4%',
  color: '#343535',
  height: '1.5rem'
}));

export const StyledPanelWrapper = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  marginBottom: '0.9375rem',
}));

export const StyledHeaderContainer = styled('div', () => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}));

export const PanelOverrides = {
  Header: {
    style: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '0rem',
    },
  },
  PanelContainer: {
    style: {
      border: 'none',
      padding: '0.6rem',
    },
  },
  Content: {
    style: {
      margin: '1.25rem 0 0 0',
      padding: 0,
    },
  },
};
