export const snackBarOverrides = (kind = 'success') => ({
  Root: {
    style: ({ $theme }) => ({
      backgroundColor: 'none',
      borderRadius: $theme.borders.radius400,
      boxShadow: $theme.lighting.shadow700,
    }),
  },
  Content: {
    style: ({ $theme }) => {
      const typeStyles = {
        success: {
          background: $theme.colors.positive150,
          border: $theme.colors.positive,
          text: $theme.colors.positive,
        },
        warning: {
          background: $theme.colors.warning150,
          border: $theme.colors.warning,
          text: $theme.colors.warning,
        },
        error: {
          background: $theme.colors.negative150,
          border: $theme.colors.negative,
          text: $theme.colors.negative,
        },
      }

      const currentStyle = typeStyles[kind] || typeStyles.success

      return {
        padding: '1rem',
        backgroundColor: currentStyle.background,
        border: `2px solid ${currentStyle.border}`,
        color: currentStyle.text,
        borderRadius: $theme.borders.radius400,
      }
    },
  },
  StartEnhancerContainer: {
    style: () => ({
      paddingLeft: '0',
      marginRight: '0.5rem',
    }),
  },
  Message: {
    style: ({ $theme }) => ({
      margin: '0',
      padding: '0',
      ...$theme.typography.ParagraphSmall,
    }),
  },
})
