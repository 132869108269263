import PropTypes from 'prop-types'

import { FormControl } from 'baseui/form-control'
import { MaskedInput, SIZE } from 'baseui/input'

import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { useTranslation } from 'react-i18next'
import { formControlOverrides } from '@/shared/components/SDComponentsNewDesign/styled-components'

const ProSiret = ({
  currentValue,
  error,
  mandatory,
  onChangeHandler,
  removeErrors,
  siretHandler,
  disabled,
  forceValidation = true,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <FormControl
        label={t('subscriptionForm:customer_module.proSiret.label') + mandatory}
        error={error}
        overrides={formControlOverrides}
      >
        <MaskedInput
          disabled={disabled}
          size={SIZE.compact}
          mask='999 999 999 99999'
          value={currentValue}
          onChange={e => {
            removeErrors('pro_siret')
            removeErrors('siret')
            onChangeHandler(e.target.value.replace(/\s/g, ''))
          }}
        />
      </FormControl>

      {error !== '' && forceValidation && (
        <FormControl label={t('subscriptionForm:customer_module.proSiret.force_label')}>
          <SDButton
            block={true}
            onClick={event => {
              event.preventDefault()
              removeErrors('pro_siret')
              removeErrors('siret')
            }}
          >
            {t('subscriptionForm:customer_module.proSiret.force')}
          </SDButton>
        </FormControl>
      )}

      {disabled === false && (
        <FormControl>
          <SDButton
            isLoading={siretHandler.loader}
            block={true}
            onClick={event => {
              event.preventDefault()
              siretHandler.handler()
            }}
          >
            {t('subscriptionForm:customer_module.proSiret.validate')}
          </SDButton>
        </FormControl>
      )}
    </>
  )
}

ProSiret.propTypes = {
  currentValue: PropTypes.string,
  error: PropTypes.string,
  mandatory: PropTypes.string,
  onChangeHandler: PropTypes.func,
  removeErrors: PropTypes.func,
  siretHandler: PropTypes.object,
  disabled: PropTypes.bool,
  forceValidation: PropTypes.bool,
}

export default ProSiret
