import { createSelector } from 'reselect'
import { IS_DUAL, IS_ELEC, IS_GAS, IS_PROPANE } from './Common/constants'
import { parseTab } from '../../../shared/tools/activeUtil'
import { capitalize } from '../../../shared/tools/stringFunctions'

export const selectSubscriptionForm = state => state.formV2
// USED ONES
// example to fetch a given data: useSelector(selectAdditionalSaleDataByName('voltalis'))
export const selectAdditionalSaleDataByName = name => state => state.formV2.additionalSales[name] ?? null
export const selectSubscriptionFormData = createSelector(selectSubscriptionForm, data => data)
export const selectOpenedContracts = createSelector(selectSubscriptionForm, data => data.openedContracts)
export const selectScripts = createSelector(selectSubscriptionForm, data => data.scripts)
export const selectExistingContracts = createSelector(selectSubscriptionForm, data => data.contracts)
export const selectGroupedContractsByDate = createSelector(
  selectExistingContracts,
  (contracts) => {
    const groups = {}
    
    Object.values(contracts || {}).forEach(contract => {
      if (!contract?.createdAt) return
      const date = contract.createdAt.split(' ')[0]
      groups[date] = groups[date] || []
      groups[date].push(contract)
    })
    
    return Object.entries(groups)
      .map(([date, contracts]) => ({ date, contracts }))
      .sort((a, b) => new Date(a.date) - new Date(b.date))
  }
)
export const selectClientSubscriptionData = createSelector(selectSubscriptionForm, data => data.client)
export const selectPersonalClientSubscriptionData = createSelector(selectSubscriptionForm, data => data.client.personal)
export const selectPreferencesClientSubscriptionData = createSelector(
  selectSubscriptionForm,
  data => data.client.preferences,
)
export const selectProfessionalClientSubscriptionData = createSelector(
  selectSubscriptionForm,
  data => data.client.professional,
)
export const selectAdditionalClientSubscriptionData = createSelector(
  selectSubscriptionForm,
  data => data.client.additional,
)
export const selectConfig = createSelector(selectSubscriptionForm, data => data.config)
export const selectActiveTab = createSelector(selectConfig, data => data.activeTab ?? '')
export const selectActiveTabParts = createSelector(
  selectActiveTab,
  (activeTab) => parseTab(activeTab)
);
export const selectActiveTabId = createSelector(
  selectActiveTabParts,
  parts => parts.id
)
export const selectActiveTabType = createSelector(
  selectActiveTabParts,
  (parts) => capitalize(parts.type)
)
export const selectCurrentContractActive = createSelector(
  selectActiveTab,
  selectOpenedContracts,
  (activeTab, openedContracts) => openedContracts[activeTab] ?? {},
)
export const selectCharacteristicsCurrentContractActive = createSelector(
  selectCurrentContractActive,
  currentContract => currentContract?.characteristics ?? {},
)

export const selectContractCurrentContractActive = createSelector(
  selectCurrentContractActive,
  currentContract => currentContract?.contract ?? {},
)

export const isGasContract = createSelector(selectCurrentContractActive, currentContract => {
  const energyType = currentContract?.characteristics?.energyType ?? false
  return energyType === IS_GAS
})

export const isElecContract = createSelector(selectCurrentContractActive, currentContract => {
  const energyType = currentContract?.characteristics?.energyType ?? false
  return energyType === IS_ELEC
})

export const isDualContract = createSelector(selectCurrentContractActive, currentContract => {
  const energyType = currentContract?.characteristics?.energyType ?? false
  return energyType === IS_DUAL
})

export const isPropaneContract = createSelector(selectCurrentContractActive, currentContract => {
  const energyType = currentContract?.characteristics?.energyType ?? false
  return energyType === IS_PROPANE
})

export const isGasOrDualContract = createSelector(isGasContract, isDualContract, (isGasContract, isDualContract) => {
  return isGasContract || isDualContract
})

// pending not used-reworked now
export const selectAllAdditionalSalesData = createSelector(selectSubscriptionForm, data => data.additionalSales)
export const selectBillingSubscriptionData = createSelector(selectSubscriptionForm, data => data.billing)
export const selectMainAddressSubscriptionData = createSelector(
  selectSubscriptionForm,
  // data => data.address.mainAddress,
  data => {}, // todo
)
export const selectInvoiceAddressSubscriptionData = createSelector(
  selectSubscriptionForm,
  // data => data.address.invoiceAddress,
  data => {}, // todo
)
export const selectEnergySubscriptionData = createSelector(selectSubscriptionForm, data => data.energy)
export const selectElectricityMeterSubscriptionData = createSelector(
  selectSubscriptionForm,
  data => {}, // todo
  // data => data.energy.elecMeter,
)
export const selectGasMeterSubscriptionData = createSelector(
  selectSubscriptionForm,
  data => {}, // todo data => data.energy.gasMeter
)
export const selectTelecomSubscriptionData = createSelector(
  selectSubscriptionForm,
  data => {}, // todo data => data.telecom
)
export const selectPropaneSubscriptionData = createSelector(
  selectSubscriptionForm,
  data => {}, // todo //data => data.energy.propane
)
export const selectEnergyConsumptionData = createSelector(
  selectSubscriptionForm,
  data => {}, // todo //data => data.energy.consumption
)
export const selectEnergyEstimationData = createSelector(
  selectSubscriptionForm,
  data => {}, // todo data => data.energy.estimation
)
