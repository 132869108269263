import PanelInfoBlock from '@/shared/components/Panel/bones/PanelInfoBlock'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { updateAdditionalSalesModuleConfiguration } from '../SubscriptionFormConfigurationSlice'

const useAdditionalSaleScript = () => {
  const dispatch = useDispatch()
  const scrollToAdditionalSaleTab = event => {
    event.preventDefault()
    const panelName = event.target.dataset.panel
    dispatch(
      updateAdditionalSalesModuleConfiguration({
        tabToShow: panelName,
        scroll: true,
      }),
    )
  }
  const AdditionalSaleScript = ({ panel, script, icon }) => {
    return (
      <PanelInfoBlock type={'additionalSale'} icon={icon}>
        <a onClick={scrollToAdditionalSaleTab} data-panel={panel}>
          {script}
        </a>
      </PanelInfoBlock>
    )
  }

  AdditionalSaleScript.propTypes = {
    icon: PropTypes.func,
    panel: PropTypes.string,
    script: PropTypes.string,
  }

  return {
    scrollToAdditionalSaleTab,
    AdditionalSaleScript,
  }
}
export default useAdditionalSaleScript
