const ZipcodeAvailable = zipcode => {
  const zipcodes = {
    14110: { agency: '2B', city: 'ALENCON' },
    14410: { agency: '2B', city: 'ALENCON' },
    14700: { agency: '2B', city: 'ALENCON' },
    27130: { agency: '2B', city: 'ALENCON' },
    27135: { agency: '2B', city: 'ALENCON' },
    27160: { agency: '2B', city: 'ALENCON' },
    27250: { agency: '2B', city: 'ALENCON' },
    27330: { agency: '2B', city: 'ALENCON' },
    27390: { agency: '2B', city: 'ALENCON' },
    27570: { agency: '2B', city: 'ALENCON' },
    27580: { agency: '2B', city: 'ALENCON' },
    27820: { agency: '2B', city: 'ALENCON' },
    28160: { agency: '2B', city: 'ALENCON' },
    28240: { agency: '2B', city: 'ALENCON' },
    28250: { agency: '2B', city: 'ALENCON' },
    28330: { agency: '2B', city: 'ALENCON' },
    28340: { agency: '2B', city: 'ALENCON' },
    28420: { agency: '2B', city: 'ALENCON' },
    53110: { agency: '2B', city: 'ALENCON' },
    53140: { agency: '2B', city: 'ALENCON' },
    53250: { agency: '2B', city: 'ALENCON' },
    53370: { agency: '2B', city: 'ALENCON' },
    53700: { agency: '2B', city: 'ALENCON' },
    61000: { agency: '2B', city: 'ALENCON' },
    61001: { agency: '2B', city: 'ALENCON' },
    61003: { agency: '2B', city: 'ALENCON' },
    61004: { agency: '2B', city: 'ALENCON' },
    61005: { agency: '2B', city: 'ALENCON' },
    61007: { agency: '2B', city: 'ALENCON' },
    61008: { agency: '2B', city: 'ALENCON' },
    61014: { agency: '2B', city: 'ALENCON' },
    61017: { agency: '2B', city: 'ALENCON' },
    61021: { agency: '2B', city: 'ALENCON' },
    61041: { agency: '2B', city: 'ALENCON' },
    61100: { agency: '2B', city: 'ALENCON' },
    61102: { agency: '2B', city: 'ALENCON' },
    61110: { agency: '2B', city: 'ALENCON' },
    61120: { agency: '2B', city: 'ALENCON' },
    61130: { agency: '2B', city: 'ALENCON' },
    61140: { agency: '2B', city: 'ALENCON' },
    61150: { agency: '2B', city: 'ALENCON' },
    61160: { agency: '2B', city: 'ALENCON' },
    61170: { agency: '2B', city: 'ALENCON' },
    61190: { agency: '2B', city: 'ALENCON' },
    61200: { agency: '2B', city: 'ALENCON' },
    61201: { agency: '2B', city: 'ALENCON' },
    61202: { agency: '2B', city: 'ALENCON' },
    61203: { agency: '2B', city: 'ALENCON' },
    61210: { agency: '2B', city: 'ALENCON' },
    61220: { agency: '2B', city: 'ALENCON' },
    61230: { agency: '2B', city: 'ALENCON' },
    61240: { agency: '2B', city: 'ALENCON' },
    61250: { agency: '2B', city: 'ALENCON' },
    61260: { agency: '2B', city: 'ALENCON' },
    61270: { agency: '2B', city: 'ALENCON' },
    61290: { agency: '2B', city: 'ALENCON' },
    61300: { agency: '2B', city: 'ALENCON' },
    61302: { agency: '2B', city: 'ALENCON' },
    61303: { agency: '2B', city: 'ALENCON' },
    61304: { agency: '2B', city: 'ALENCON' },
    61305: { agency: '2B', city: 'ALENCON' },
    61310: { agency: '2B', city: 'ALENCON' },
    61320: { agency: '2B', city: 'ALENCON' },
    61330: { agency: '2B', city: 'ALENCON' },
    61340: { agency: '2B', city: 'ALENCON' },
    61350: { agency: '2B', city: 'ALENCON' },
    61360: { agency: '2B', city: 'ALENCON' },
    61370: { agency: '2B', city: 'ALENCON' },
    61380: { agency: '2B', city: 'ALENCON' },
    61390: { agency: '2B', city: 'ALENCON' },
    61400: { agency: '2B', city: 'ALENCON' },
    61410: { agency: '2B', city: 'ALENCON' },
    61420: { agency: '2B', city: 'ALENCON' },
    61430: { agency: '2B', city: 'ALENCON' },
    61440: { agency: '2B', city: 'ALENCON' },
    61450: { agency: '2B', city: 'ALENCON' },
    61470: { agency: '2B', city: 'ALENCON' },
    61490: { agency: '2B', city: 'ALENCON' },
    61500: { agency: '2B', city: 'ALENCON' },
    61550: { agency: '2B', city: 'ALENCON' },
    61560: { agency: '2B', city: 'ALENCON' },
    61570: { agency: '2B', city: 'ALENCON' },
    61600: { agency: '2B', city: 'ALENCON' },
    61700: { agency: '2B', city: 'ALENCON' },
    61790: { agency: '2B', city: 'ALENCON' },
    61800: { agency: '2B', city: 'ALENCON' },
    72022: { agency: '2B', city: 'ALENCON' },
    72130: { agency: '2B', city: 'ALENCON' },
    72140: { agency: '2B', city: 'ALENCON' },
    72600: { agency: '2B', city: 'ALENCON' },
    72610: { agency: '2B', city: 'ALENCON' },
    14620: { agency: '2B', city: 'ALENCON' },
    14690: { agency: '2B', city: 'ALENCON' },
    14420: { agency: '2B', city: 'ALENCON' },
    14770: { agency: '2B', city: 'ALENCON' },
    14170: { agency: '2B', city: 'ALENCON' },
    14190: { agency: '2B', city: 'ALENCON' },
    14540: { agency: '2B', city: 'ALENCON' },
    14680: { agency: '2B', city: 'ALENCON' },
    14140: { agency: '2B', city: 'ALENCON' },
    14220: { agency: '2B', city: 'ALENCON' },
    14570: { agency: '2B', city: 'ALENCON' },
    16000: { agency: '5D', city: 'ANGOULEME' },
    16001: { agency: '5D', city: 'ANGOULEME' },
    16002: { agency: '5D', city: 'ANGOULEME' },
    16003: { agency: '5D', city: 'ANGOULEME' },
    16004: { agency: '5D', city: 'ANGOULEME' },
    16005: { agency: '5D', city: 'ANGOULEME' },
    16006: { agency: '5D', city: 'ANGOULEME' },
    16007: { agency: '5D', city: 'ANGOULEME' },
    16008: { agency: '5D', city: 'ANGOULEME' },
    16010: { agency: '5D', city: 'ANGOULEME' },
    16011: { agency: '5D', city: 'ANGOULEME' },
    16012: { agency: '5D', city: 'ANGOULEME' },
    16013: { agency: '5D', city: 'ANGOULEME' },
    16014: { agency: '5D', city: 'ANGOULEME' },
    16015: { agency: '5D', city: 'ANGOULEME' },
    16016: { agency: '5D', city: 'ANGOULEME' },
    16017: { agency: '5D', city: 'ANGOULEME' },
    16018: { agency: '5D', city: 'ANGOULEME' },
    16019: { agency: '5D', city: 'ANGOULEME' },
    16020: { agency: '5D', city: 'ANGOULEME' },
    16021: { agency: '5D', city: 'ANGOULEME' },
    16022: { agency: '5D', city: 'ANGOULEME' },
    16023: { agency: '5D', city: 'ANGOULEME' },
    16024: { agency: '5D', city: 'ANGOULEME' },
    16025: { agency: '5D', city: 'ANGOULEME' },
    16026: { agency: '5D', city: 'ANGOULEME' },
    16110: { agency: '5D', city: 'ANGOULEME' },
    16120: { agency: '5D', city: 'ANGOULEME' },
    16140: { agency: '5D', city: 'ANGOULEME' },
    16150: { agency: '5D', city: 'ANGOULEME' },
    16160: { agency: '5D', city: 'ANGOULEME' },
    16190: { agency: '5D', city: 'ANGOULEME' },
    16210: { agency: '5D', city: 'ANGOULEME' },
    16220: { agency: '5D', city: 'ANGOULEME' },
    16230: { agency: '5D', city: 'ANGOULEME' },
    16250: { agency: '5D', city: 'ANGOULEME' },
    16260: { agency: '5D', city: 'ANGOULEME' },
    16270: { agency: '5D', city: 'ANGOULEME' },
    16290: { agency: '5D', city: 'ANGOULEME' },
    16310: { agency: '5D', city: 'ANGOULEME' },
    16320: { agency: '5D', city: 'ANGOULEME' },
    16330: { agency: '5D', city: 'ANGOULEME' },
    16340: { agency: '5D', city: 'ANGOULEME' },
    16350: { agency: '5D', city: 'ANGOULEME' },
    16380: { agency: '5D', city: 'ANGOULEME' },
    16390: { agency: '5D', city: 'ANGOULEME' },
    16400: { agency: '5D', city: 'ANGOULEME' },
    16410: { agency: '5D', city: 'ANGOULEME' },
    16430: { agency: '5D', city: 'ANGOULEME' },
    16440: { agency: '5D', city: 'ANGOULEME' },
    16450: { agency: '5D', city: 'ANGOULEME' },
    16460: { agency: '5D', city: 'ANGOULEME' },
    16470: { agency: '5D', city: 'ANGOULEME' },
    16510: { agency: '5D', city: 'ANGOULEME' },
    16560: { agency: '5D', city: 'ANGOULEME' },
    16570: { agency: '5D', city: 'ANGOULEME' },
    16590: { agency: '5D', city: 'ANGOULEME' },
    16600: { agency: '5D', city: 'ANGOULEME' },
    16620: { agency: '5D', city: 'ANGOULEME' },
    16700: { agency: '5D', city: 'ANGOULEME' },
    16710: { agency: '5D', city: 'ANGOULEME' },
    16730: { agency: '5D', city: 'ANGOULEME' },
    16800: { agency: '5D', city: 'ANGOULEME' },
    16901: { agency: '5D', city: 'ANGOULEME' },
    16902: { agency: '5D', city: 'ANGOULEME' },
    16903: { agency: '5D', city: 'ANGOULEME' },
    16904: { agency: '5D', city: 'ANGOULEME' },
    16905: { agency: '5D', city: 'ANGOULEME' },
    16906: { agency: '5D', city: 'ANGOULEME' },
    16907: { agency: '5D', city: 'ANGOULEME' },
    16908: { agency: '5D', city: 'ANGOULEME' },
    16909: { agency: '5D', city: 'ANGOULEME' },
    16910: { agency: '5D', city: 'ANGOULEME' },
    16911: { agency: '5D', city: 'ANGOULEME' },
    16912: { agency: '5D', city: 'ANGOULEME' },
    16913: { agency: '5D', city: 'ANGOULEME' },
    16914: { agency: '5D', city: 'ANGOULEME' },
    16915: { agency: '5D', city: 'ANGOULEME' },
    16916: { agency: '5D', city: 'ANGOULEME' },
    16917: { agency: '5D', city: 'ANGOULEME' },
    16918: { agency: '5D', city: 'ANGOULEME' },
    16919: { agency: '5D', city: 'ANGOULEME' },
    16920: { agency: '5D', city: 'ANGOULEME' },
    16929: { agency: '5D', city: 'ANGOULEME' },
    16950: { agency: '5D', city: 'ANGOULEME' },
    16951: { agency: '5D', city: 'ANGOULEME' },
    16952: { agency: '5D', city: 'ANGOULEME' },
    16953: { agency: '5D', city: 'ANGOULEME' },
    16954: { agency: '5D', city: 'ANGOULEME' },
    16955: { agency: '5D', city: 'ANGOULEME' },
    16959: { agency: '5D', city: 'ANGOULEME' },
    16999: { agency: '5D', city: 'ANGOULEME' },
    24340: { agency: '5D', city: 'ANGOULEME' },
    16240: { agency: '5D', city: 'ANGOULEME' },
    16360: { agency: '5D', city: 'ANGOULEME' },
    16480: { agency: '5D', city: 'ANGOULEME' },
    16490: { agency: '5D', city: 'ANGOULEME' },
    24300: { agency: '5D', city: 'ANGOULEME' },
    24320: { agency: '5D', city: 'ANGOULEME' },
    24360: { agency: '5D', city: 'ANGOULEME' },
    1200: { agency: '4E', city: 'ANNECY' },
    73310: { agency: '4E', city: 'ANNECY' },
    74000: { agency: '4E', city: 'ANNECY' },
    74001: { agency: '4E', city: 'ANNECY' },
    74002: { agency: '4E', city: 'ANNECY' },
    74003: { agency: '4E', city: 'ANNECY' },
    74004: { agency: '4E', city: 'ANNECY' },
    74005: { agency: '4E', city: 'ANNECY' },
    74006: { agency: '4E', city: 'ANNECY' },
    74007: { agency: '4E', city: 'ANNECY' },
    74008: { agency: '4E', city: 'ANNECY' },
    74009: { agency: '4E', city: 'ANNECY' },
    74010: { agency: '4E', city: 'ANNECY' },
    74011: { agency: '4E', city: 'ANNECY' },
    74012: { agency: '4E', city: 'ANNECY' },
    74013: { agency: '4E', city: 'ANNECY' },
    74014: { agency: '4E', city: 'ANNECY' },
    74015: { agency: '4E', city: 'ANNECY' },
    74016: { agency: '4E', city: 'ANNECY' },
    74020: { agency: '4E', city: 'ANNECY' },
    74021: { agency: '4E', city: 'ANNECY' },
    74024: { agency: '4E', city: 'ANNECY' },
    74025: { agency: '4E', city: 'ANNECY' },
    74034: { agency: '4E', city: 'ANNECY' },
    74036: { agency: '4E', city: 'ANNECY' },
    74040: { agency: '4E', city: 'ANNECY' },
    74041: { agency: '4E', city: 'ANNECY' },
    74054: { agency: '4E', city: 'ANNECY' },
    74055: { agency: '4E', city: 'ANNECY' },
    74059: { agency: '4E', city: 'ANNECY' },
    74150: { agency: '4E', city: 'ANNECY' },
    74151: { agency: '4E', city: 'ANNECY' },
    74152: { agency: '4E', city: 'ANNECY' },
    74153: { agency: '4E', city: 'ANNECY' },
    74154: { agency: '4E', city: 'ANNECY' },
    74155: { agency: '4E', city: 'ANNECY' },
    74156: { agency: '4E', city: 'ANNECY' },
    74157: { agency: '4E', city: 'ANNECY' },
    74158: { agency: '4E', city: 'ANNECY' },
    74159: { agency: '4E', city: 'ANNECY' },
    74210: { agency: '4E', city: 'ANNECY' },
    74220: { agency: '4E', city: 'ANNECY' },
    74230: { agency: '4E', city: 'ANNECY' },
    74270: { agency: '4E', city: 'ANNECY' },
    74290: { agency: '4E', city: 'ANNECY' },
    74320: { agency: '4E', city: 'ANNECY' },
    74330: { agency: '4E', city: 'ANNECY' },
    74331: { agency: '4E', city: 'ANNECY' },
    74332: { agency: '4E', city: 'ANNECY' },
    74333: { agency: '4E', city: 'ANNECY' },
    74334: { agency: '4E', city: 'ANNECY' },
    74337: { agency: '4E', city: 'ANNECY' },
    74338: { agency: '4E', city: 'ANNECY' },
    74339: { agency: '4E', city: 'ANNECY' },
    74370: { agency: '4E', city: 'ANNECY' },
    74371: { agency: '4E', city: 'ANNECY' },
    74372: { agency: '4E', city: 'ANNECY' },
    74373: { agency: '4E', city: 'ANNECY' },
    74374: { agency: '4E', city: 'ANNECY' },
    74410: { agency: '4E', city: 'ANNECY' },
    74450: { agency: '4E', city: 'ANNECY' },
    74540: { agency: '4E', city: 'ANNECY' },
    74570: { agency: '4E', city: 'ANNECY' },
    74600: { agency: '4E', city: 'ANNECY' },
    74601: { agency: '4E', city: 'ANNECY' },
    74602: { agency: '4E', city: 'ANNECY' },
    74603: { agency: '4E', city: 'ANNECY' },
    74604: { agency: '4E', city: 'ANNECY' },
    74605: { agency: '4E', city: 'ANNECY' },
    74606: { agency: '4E', city: 'ANNECY' },
    74607: { agency: '4E', city: 'ANNECY' },
    74609: { agency: '4E', city: 'ANNECY' },
    74650: { agency: '4E', city: 'ANNECY' },
    74910: { agency: '4E', city: 'ANNECY' },
    74940: { agency: '4E', city: 'ANNECY' },
    74941: { agency: '4E', city: 'ANNECY' },
    74942: { agency: '4E', city: 'ANNECY' },
    74943: { agency: '4E', city: 'ANNECY' },
    74944: { agency: '4E', city: 'ANNECY' },
    74945: { agency: '4E', city: 'ANNECY' },
    74946: { agency: '4E', city: 'ANNECY' },
    74949: { agency: '4E', city: 'ANNECY' },
    74960: { agency: '4E', city: 'ANNECY' },
    74961: { agency: '4E', city: 'ANNECY' },
    74962: { agency: '4E', city: 'ANNECY' },
    74963: { agency: '4E', city: 'ANNECY' },
    74964: { agency: '4E', city: 'ANNECY' },
    74966: { agency: '4E', city: 'ANNECY' },
    74969: { agency: '4E', city: 'ANNECY' },
    74981: { agency: '4E', city: 'ANNECY' },
    74982: { agency: '4E', city: 'ANNECY' },
    74984: { agency: '4E', city: 'ANNECY' },
    74985: { agency: '4E', city: 'ANNECY' },
    74986: { agency: '4E', city: 'ANNECY' },
    74987: { agency: '4E', city: 'ANNECY' },
    74988: { agency: '4E', city: 'ANNECY' },
    74989: { agency: '4E', city: 'ANNECY' },
    74990: { agency: '4E', city: 'ANNECY' },
    74991: { agency: '4E', city: 'ANNECY' },
    74993: { agency: '4E', city: 'ANNECY' },
    74994: { agency: '4E', city: 'ANNECY' },
    74995: { agency: '4E', city: 'ANNECY' },
    74996: { agency: '4E', city: 'ANNECY' },
    74997: { agency: '4E', city: 'ANNECY' },
    74998: { agency: '4E', city: 'ANNECY' },
    74999: { agency: '4E', city: 'ANNECY' },
    1170: { agency: '4B', city: 'ANNEMASSE' },
    1171: { agency: '4B', city: 'ANNEMASSE' },
    1172: { agency: '4B', city: 'ANNEMASSE' },
    1173: { agency: '4B', city: 'ANNEMASSE' },
    1174: { agency: '4B', city: 'ANNEMASSE' },
    1210: { agency: '4B', city: 'ANNEMASSE' },
    1211: { agency: '4B', city: 'ANNEMASSE' },
    1212: { agency: '4B', city: 'ANNEMASSE' },
    1213: { agency: '4B', city: 'ANNEMASSE' },
    1216: { agency: '4B', city: 'ANNEMASSE' },
    1217: { agency: '4B', city: 'ANNEMASSE' },
    1220: { agency: '4B', city: 'ANNEMASSE' },
    1280: { agency: '4B', city: 'ANNEMASSE' },
    1550: { agency: '4B', city: 'ANNEMASSE' },
    1630: { agency: '4B', city: 'ANNEMASSE' },
    1631: { agency: '4B', city: 'ANNEMASSE' },
    1632: { agency: '4B', city: 'ANNEMASSE' },
    1633: { agency: '4B', city: 'ANNEMASSE' },
    1637: { agency: '4B', city: 'ANNEMASSE' },
    1638: { agency: '4B', city: 'ANNEMASSE' },
    1710: { agency: '4B', city: 'ANNEMASSE' },
    74100: { agency: '4B', city: 'ANNEMASSE' },
    74101: { agency: '4B', city: 'ANNEMASSE' },
    74102: { agency: '4B', city: 'ANNEMASSE' },
    74103: { agency: '4B', city: 'ANNEMASSE' },
    74104: { agency: '4B', city: 'ANNEMASSE' },
    74105: { agency: '4B', city: 'ANNEMASSE' },
    74106: { agency: '4B', city: 'ANNEMASSE' },
    74107: { agency: '4B', city: 'ANNEMASSE' },
    74108: { agency: '4B', city: 'ANNEMASSE' },
    74109: { agency: '4B', city: 'ANNEMASSE' },
    74111: { agency: '4B', city: 'ANNEMASSE' },
    74112: { agency: '4B', city: 'ANNEMASSE' },
    74113: { agency: '4B', city: 'ANNEMASSE' },
    74130: { agency: '4B', city: 'ANNEMASSE' },
    74131: { agency: '4B', city: 'ANNEMASSE' },
    74132: { agency: '4B', city: 'ANNEMASSE' },
    74133: { agency: '4B', city: 'ANNEMASSE' },
    74134: { agency: '4B', city: 'ANNEMASSE' },
    74135: { agency: '4B', city: 'ANNEMASSE' },
    74136: { agency: '4B', city: 'ANNEMASSE' },
    74137: { agency: '4B', city: 'ANNEMASSE' },
    74139: { agency: '4B', city: 'ANNEMASSE' },
    74140: { agency: '4B', city: 'ANNEMASSE' },
    74160: { agency: '4B', city: 'ANNEMASSE' },
    74161: { agency: '4B', city: 'ANNEMASSE' },
    74162: { agency: '4B', city: 'ANNEMASSE' },
    74163: { agency: '4B', city: 'ANNEMASSE' },
    74164: { agency: '4B', city: 'ANNEMASSE' },
    74165: { agency: '4B', city: 'ANNEMASSE' },
    74166: { agency: '4B', city: 'ANNEMASSE' },
    74169: { agency: '4B', city: 'ANNEMASSE' },
    74190: { agency: '4B', city: 'ANNEMASSE' },
    74200: { agency: '4B', city: 'ANNEMASSE' },
    74240: { agency: '4B', city: 'ANNEMASSE' },
    74250: { agency: '4B', city: 'ANNEMASSE' },
    74300: { agency: '4B', city: 'ANNEMASSE' },
    74310: { agency: '4B', city: 'ANNEMASSE' },
    74350: { agency: '4B', city: 'ANNEMASSE' },
    74380: { agency: '4B', city: 'ANNEMASSE' },
    74400: { agency: '4B', city: 'ANNEMASSE' },
    74420: { agency: '4B', city: 'ANNEMASSE' },
    74440: { agency: '4B', city: 'ANNEMASSE' },
    74460: { agency: '4B', city: 'ANNEMASSE' },
    74470: { agency: '4B', city: 'ANNEMASSE' },
    74490: { agency: '4B', city: 'ANNEMASSE' },
    74500: { agency: '4B', city: 'ANNEMASSE' },
    74520: { agency: '4B', city: 'ANNEMASSE' },
    74550: { agency: '4B', city: 'ANNEMASSE' },
    74560: { agency: '4B', city: 'ANNEMASSE' },
    74580: { agency: '4B', city: 'ANNEMASSE' },
    74700: { agency: '4B', city: 'ANNEMASSE' },
    74800: { agency: '4B', city: 'ANNEMASSE' },
    74801: { agency: '4B', city: 'ANNEMASSE' },
    74802: { agency: '4B', city: 'ANNEMASSE' },
    74803: { agency: '4B', city: 'ANNEMASSE' },
    74804: { agency: '4B', city: 'ANNEMASSE' },
    74805: { agency: '4B', city: 'ANNEMASSE' },
    74806: { agency: '4B', city: 'ANNEMASSE' },
    74807: { agency: '4B', city: 'ANNEMASSE' },
    74808: { agency: '4B', city: 'ANNEMASSE' },
    74809: { agency: '4B', city: 'ANNEMASSE' },
    74890: { agency: '4B', city: 'ANNEMASSE' },
    74930: { agency: '4B', city: 'ANNEMASSE' },
    74950: { agency: '4B', city: 'ANNEMASSE' },
    74970: { agency: '4B', city: 'ANNEMASSE' },
    1000: { agency: '4W', city: 'BOURG EN BRESSE' },
    1001: { agency: '4W', city: 'BOURG EN BRESSE' },
    1002: { agency: '4W', city: 'BOURG EN BRESSE' },
    1003: { agency: '4W', city: 'BOURG EN BRESSE' },
    1004: { agency: '4W', city: 'BOURG EN BRESSE' },
    1005: { agency: '4W', city: 'BOURG EN BRESSE' },
    1006: { agency: '4W', city: 'BOURG EN BRESSE' },
    1007: { agency: '4W', city: 'BOURG EN BRESSE' },
    1008: { agency: '4W', city: 'BOURG EN BRESSE' },
    1009: { agency: '4W', city: 'BOURG EN BRESSE' },
    1010: { agency: '4W', city: 'BOURG EN BRESSE' },
    1011: { agency: '4W', city: 'BOURG EN BRESSE' },
    1012: { agency: '4W', city: 'BOURG EN BRESSE' },
    1013: { agency: '4W', city: 'BOURG EN BRESSE' },
    1014: { agency: '4W', city: 'BOURG EN BRESSE' },
    1015: { agency: '4W', city: 'BOURG EN BRESSE' },
    1016: { agency: '4W', city: 'BOURG EN BRESSE' },
    1017: { agency: '4W', city: 'BOURG EN BRESSE' },
    1018: { agency: '4W', city: 'BOURG EN BRESSE' },
    1019: { agency: '4W', city: 'BOURG EN BRESSE' },
    1020: { agency: '4W', city: 'BOURG EN BRESSE' },
    1059: { agency: '4W', city: 'BOURG EN BRESSE' },
    1060: { agency: '4W', city: 'BOURG EN BRESSE' },
    1061: { agency: '4W', city: 'BOURG EN BRESSE' },
    1069: { agency: '4W', city: 'BOURG EN BRESSE' },
    1160: { agency: '4W', city: 'BOURG EN BRESSE' },
    1190: { agency: '4W', city: 'BOURG EN BRESSE' },
    1240: { agency: '4W', city: 'BOURG EN BRESSE' },
    1250: { agency: '4W', city: 'BOURG EN BRESSE' },
    1270: { agency: '4W', city: 'BOURG EN BRESSE' },
    1290: { agency: '4W', city: 'BOURG EN BRESSE' },
    1310: { agency: '4W', city: 'BOURG EN BRESSE' },
    1320: { agency: '4W', city: 'BOURG EN BRESSE' },
    1330: { agency: '4W', city: 'BOURG EN BRESSE' },
    1340: { agency: '4W', city: 'BOURG EN BRESSE' },
    1370: { agency: '4W', city: 'BOURG EN BRESSE' },
    1380: { agency: '4W', city: 'BOURG EN BRESSE' },
    1400: { agency: '4W', city: 'BOURG EN BRESSE' },
    1430: { agency: '4W', city: 'BOURG EN BRESSE' },
    1440: { agency: '4W', city: 'BOURG EN BRESSE' },
    1441: { agency: '4W', city: 'BOURG EN BRESSE' },
    1442: { agency: '4W', city: 'BOURG EN BRESSE' },
    1443: { agency: '4W', city: 'BOURG EN BRESSE' },
    1450: { agency: '4W', city: 'BOURG EN BRESSE' },
    1460: { agency: '4W', city: 'BOURG EN BRESSE' },
    1500: { agency: '4W', city: 'BOURG EN BRESSE' },
    1540: { agency: '4W', city: 'BOURG EN BRESSE' },
    1560: { agency: '4W', city: 'BOURG EN BRESSE' },
    1570: { agency: '4W', city: 'BOURG EN BRESSE' },
    1580: { agency: '4W', city: 'BOURG EN BRESSE' },
    1640: { agency: '4W', city: 'BOURG EN BRESSE' },
    1660: { agency: '4W', city: 'BOURG EN BRESSE' },
    1750: { agency: '4W', city: 'BOURG EN BRESSE' },
    1851: { agency: '4W', city: 'BOURG EN BRESSE' },
    1960: { agency: '4W', city: 'BOURG EN BRESSE' },
    1990: { agency: '4W', city: 'BOURG EN BRESSE' },
    39160: { agency: '4W', city: 'BOURG EN BRESSE' },
    39240: { agency: '4W', city: 'BOURG EN BRESSE' },
    39320: { agency: '4W', city: 'BOURG EN BRESSE' },
    71000: { agency: '4W', city: 'BOURG EN BRESSE' },
    71118: { agency: '4W', city: 'BOURG EN BRESSE' },
    71680: { agency: '4W', city: 'BOURG EN BRESSE' },
    71850: { agency: '4W', city: 'BOURG EN BRESSE' },
    71870: { agency: '4W', city: 'BOURG EN BRESSE' },
    38070: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38074: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38077: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38080: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38081: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38089: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38090: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38091: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38092: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38093: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38096: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38097: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38098: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38099: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38110: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38118: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38260: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38261: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38269: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38280: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38290: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38291: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38292: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38293: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38295: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38296: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38297: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38299: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38300: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38301: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38302: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38303: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38304: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38305: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38307: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38309: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38311: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38312: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38313: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38314: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38315: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38317: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38319: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38351: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38352: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38353: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38354: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38356: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38357: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38358: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38359: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38440: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38460: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38480: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38490: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38510: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38540: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38591: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38620: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38630: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38690: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38730: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38731: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38790: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38850: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    38890: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    73240: { agency: '4S', city: 'BOURGOIN JAILLEUX' },
    1300: { agency: '4D', city: 'CHAMBERY' },
    38380: { agency: '4D', city: 'CHAMBERY' },
    38530: { agency: '4D', city: 'CHAMBERY' },
    38570: { agency: '4D', city: 'CHAMBERY' },
    38660: { agency: '4D', city: 'CHAMBERY' },
    73000: { agency: '4D', city: 'CHAMBERY' },
    73001: { agency: '4D', city: 'CHAMBERY' },
    73002: { agency: '4D', city: 'CHAMBERY' },
    73003: { agency: '4D', city: 'CHAMBERY' },
    73004: { agency: '4D', city: 'CHAMBERY' },
    73005: { agency: '4D', city: 'CHAMBERY' },
    73006: { agency: '4D', city: 'CHAMBERY' },
    73007: { agency: '4D', city: 'CHAMBERY' },
    73008: { agency: '4D', city: 'CHAMBERY' },
    73009: { agency: '4D', city: 'CHAMBERY' },
    73010: { agency: '4D', city: 'CHAMBERY' },
    73011: { agency: '4D', city: 'CHAMBERY' },
    73014: { agency: '4D', city: 'CHAMBERY' },
    73015: { agency: '4D', city: 'CHAMBERY' },
    73016: { agency: '4D', city: 'CHAMBERY' },
    73017: { agency: '4D', city: 'CHAMBERY' },
    73018: { agency: '4D', city: 'CHAMBERY' },
    73019: { agency: '4D', city: 'CHAMBERY' },
    73020: { agency: '4D', city: 'CHAMBERY' },
    73021: { agency: '4D', city: 'CHAMBERY' },
    73022: { agency: '4D', city: 'CHAMBERY' },
    73024: { agency: '4D', city: 'CHAMBERY' },
    73025: { agency: '4D', city: 'CHAMBERY' },
    73026: { agency: '4D', city: 'CHAMBERY' },
    73031: { agency: '4D', city: 'CHAMBERY' },
    73065: { agency: '4D', city: 'CHAMBERY' },
    73073: { agency: '4D', city: 'CHAMBERY' },
    73079: { agency: '4D', city: 'CHAMBERY' },
    73089: { agency: '4D', city: 'CHAMBERY' },
    73091: { agency: '4D', city: 'CHAMBERY' },
    73094: { agency: '4D', city: 'CHAMBERY' },
    73100: { agency: '4D', city: 'CHAMBERY' },
    73101: { agency: '4D', city: 'CHAMBERY' },
    73102: { agency: '4D', city: 'CHAMBERY' },
    73103: { agency: '4D', city: 'CHAMBERY' },
    73104: { agency: '4D', city: 'CHAMBERY' },
    73105: { agency: '4D', city: 'CHAMBERY' },
    73106: { agency: '4D', city: 'CHAMBERY' },
    73107: { agency: '4D', city: 'CHAMBERY' },
    73108: { agency: '4D', city: 'CHAMBERY' },
    73109: { agency: '4D', city: 'CHAMBERY' },
    73110: { agency: '4D', city: 'CHAMBERY' },
    73160: { agency: '4D', city: 'CHAMBERY' },
    73170: { agency: '4D', city: 'CHAMBERY' },
    73190: { agency: '4D', city: 'CHAMBERY' },
    73191: { agency: '4D', city: 'CHAMBERY' },
    73192: { agency: '4D', city: 'CHAMBERY' },
    73193: { agency: '4D', city: 'CHAMBERY' },
    73199: { agency: '4D', city: 'CHAMBERY' },
    73200: { agency: '4D', city: 'CHAMBERY' },
    73220: { agency: '4D', city: 'CHAMBERY' },
    73230: { agency: '4D', city: 'CHAMBERY' },
    73231: { agency: '4D', city: 'CHAMBERY' },
    73232: { agency: '4D', city: 'CHAMBERY' },
    73235: { agency: '4D', city: 'CHAMBERY' },
    73239: { agency: '4D', city: 'CHAMBERY' },
    73250: { agency: '4D', city: 'CHAMBERY' },
    73290: { agency: '4D', city: 'CHAMBERY' },
    73291: { agency: '4D', city: 'CHAMBERY' },
    73292: { agency: '4D', city: 'CHAMBERY' },
    73294: { agency: '4D', city: 'CHAMBERY' },
    73295: { agency: '4D', city: 'CHAMBERY' },
    73299: { agency: '4D', city: 'CHAMBERY' },
    73330: { agency: '4D', city: 'CHAMBERY' },
    73360: { agency: '4D', city: 'CHAMBERY' },
    73370: { agency: '4D', city: 'CHAMBERY' },
    73371: { agency: '4D', city: 'CHAMBERY' },
    73372: { agency: '4D', city: 'CHAMBERY' },
    73373: { agency: '4D', city: 'CHAMBERY' },
    73374: { agency: '4D', city: 'CHAMBERY' },
    73375: { agency: '4D', city: 'CHAMBERY' },
    73376: { agency: '4D', city: 'CHAMBERY' },
    73377: { agency: '4D', city: 'CHAMBERY' },
    73378: { agency: '4D', city: 'CHAMBERY' },
    73379: { agency: '4D', city: 'CHAMBERY' },
    73381: { agency: '4D', city: 'CHAMBERY' },
    73382: { agency: '4D', city: 'CHAMBERY' },
    73390: { agency: '4D', city: 'CHAMBERY' },
    73400: { agency: '4D', city: 'CHAMBERY' },
    73410: { agency: '4D', city: 'CHAMBERY' },
    73420: { agency: '4D', city: 'CHAMBERY' },
    73460: { agency: '4D', city: 'CHAMBERY' },
    73470: { agency: '4D', city: 'CHAMBERY' },
    73490: { agency: '4D', city: 'CHAMBERY' },
    73491: { agency: '4D', city: 'CHAMBERY' },
    73492: { agency: '4D', city: 'CHAMBERY' },
    73493: { agency: '4D', city: 'CHAMBERY' },
    73494: { agency: '4D', city: 'CHAMBERY' },
    73499: { agency: '4D', city: 'CHAMBERY' },
    73610: { agency: '4D', city: 'CHAMBERY' },
    73630: { agency: '4D', city: 'CHAMBERY' },
    73800: { agency: '4D', city: 'CHAMBERY' },
    73801: { agency: '4D', city: 'CHAMBERY' },
    73802: { agency: '4D', city: 'CHAMBERY' },
    73804: { agency: '4D', city: 'CHAMBERY' },
    73805: { agency: '4D', city: 'CHAMBERY' },
    73806: { agency: '4D', city: 'CHAMBERY' },
    73807: { agency: '4D', city: 'CHAMBERY' },
    73808: { agency: '4D', city: 'CHAMBERY' },
    73809: { agency: '4D', city: 'CHAMBERY' },
    37000: { agency: '3A', city: 'CHAMBRAY' },
    37008: { agency: '3A', city: 'CHAMBRAY' },
    37009: { agency: '3A', city: 'CHAMBRAY' },
    37010: { agency: '3A', city: 'CHAMBRAY' },
    37011: { agency: '3A', city: 'CHAMBRAY' },
    37012: { agency: '3A', city: 'CHAMBRAY' },
    37013: { agency: '3A', city: 'CHAMBRAY' },
    37014: { agency: '3A', city: 'CHAMBRAY' },
    37015: { agency: '3A', city: 'CHAMBRAY' },
    37016: { agency: '3A', city: 'CHAMBRAY' },
    37017: { agency: '3A', city: 'CHAMBRAY' },
    37018: { agency: '3A', city: 'CHAMBRAY' },
    37019: { agency: '3A', city: 'CHAMBRAY' },
    37020: { agency: '3A', city: 'CHAMBRAY' },
    37025: { agency: '3A', city: 'CHAMBRAY' },
    37026: { agency: '3A', city: 'CHAMBRAY' },
    37027: { agency: '3A', city: 'CHAMBRAY' },
    37028: { agency: '3A', city: 'CHAMBRAY' },
    37029: { agency: '3A', city: 'CHAMBRAY' },
    37032: { agency: '3A', city: 'CHAMBRAY' },
    37033: { agency: '3A', city: 'CHAMBRAY' },
    37034: { agency: '3A', city: 'CHAMBRAY' },
    37035: { agency: '3A', city: 'CHAMBRAY' },
    37038: { agency: '3A', city: 'CHAMBRAY' },
    37039: { agency: '3A', city: 'CHAMBRAY' },
    37040: { agency: '3A', city: 'CHAMBRAY' },
    37041: { agency: '3A', city: 'CHAMBRAY' },
    37042: { agency: '3A', city: 'CHAMBRAY' },
    37043: { agency: '3A', city: 'CHAMBRAY' },
    37044: { agency: '3A', city: 'CHAMBRAY' },
    37045: { agency: '3A', city: 'CHAMBRAY' },
    37047: { agency: '3A', city: 'CHAMBRAY' },
    37048: { agency: '3A', city: 'CHAMBRAY' },
    37049: { agency: '3A', city: 'CHAMBRAY' },
    37058: { agency: '3A', city: 'CHAMBRAY' },
    37059: { agency: '3A', city: 'CHAMBRAY' },
    37060: { agency: '3A', city: 'CHAMBRAY' },
    37061: { agency: '3A', city: 'CHAMBRAY' },
    37071: { agency: '3A', city: 'CHAMBRAY' },
    37072: { agency: '3A', city: 'CHAMBRAY' },
    37073: { agency: '3A', city: 'CHAMBRAY' },
    37074: { agency: '3A', city: 'CHAMBRAY' },
    37075: { agency: '3A', city: 'CHAMBRAY' },
    37076: { agency: '3A', city: 'CHAMBRAY' },
    37077: { agency: '3A', city: 'CHAMBRAY' },
    37078: { agency: '3A', city: 'CHAMBRAY' },
    37079: { agency: '3A', city: 'CHAMBRAY' },
    37080: { agency: '3A', city: 'CHAMBRAY' },
    37081: { agency: '3A', city: 'CHAMBRAY' },
    37082: { agency: '3A', city: 'CHAMBRAY' },
    37089: { agency: '3A', city: 'CHAMBRAY' },
    37095: { agency: '3A', city: 'CHAMBRAY' },
    37096: { agency: '3A', city: 'CHAMBRAY' },
    37097: { agency: '3A', city: 'CHAMBRAY' },
    37098: { agency: '3A', city: 'CHAMBRAY' },
    37099: { agency: '3A', city: 'CHAMBRAY' },
    37100: { agency: '3A', city: 'CHAMBRAY' },
    37110: { agency: '3A', city: 'CHAMBRAY' },
    37130: { agency: '3A', city: 'CHAMBRAY' },
    37140: { agency: '3A', city: 'CHAMBRAY' },
    37150: { agency: '3A', city: 'CHAMBRAY' },
    37152: { agency: '3A', city: 'CHAMBRAY' },
    37170: { agency: '3A', city: 'CHAMBRAY' },
    37171: { agency: '3A', city: 'CHAMBRAY' },
    37172: { agency: '3A', city: 'CHAMBRAY' },
    37173: { agency: '3A', city: 'CHAMBRAY' },
    37174: { agency: '3A', city: 'CHAMBRAY' },
    37175: { agency: '3A', city: 'CHAMBRAY' },
    37179: { agency: '3A', city: 'CHAMBRAY' },
    37190: { agency: '3A', city: 'CHAMBRAY' },
    37200: { agency: '3A', city: 'CHAMBRAY' },
    37204: { agency: '3A', city: 'CHAMBRAY' },
    37205: { agency: '3A', city: 'CHAMBRAY' },
    37206: { agency: '3A', city: 'CHAMBRAY' },
    37209: { agency: '3A', city: 'CHAMBRAY' },
    37210: { agency: '3A', city: 'CHAMBRAY' },
    37220: { agency: '3A', city: 'CHAMBRAY' },
    37230: { agency: '3A', city: 'CHAMBRAY' },
    37240: { agency: '3A', city: 'CHAMBRAY' },
    37250: { agency: '3A', city: 'CHAMBRAY' },
    37260: { agency: '3A', city: 'CHAMBRAY' },
    37270: { agency: '3A', city: 'CHAMBRAY' },
    37300: { agency: '3A', city: 'CHAMBRAY' },
    37301: { agency: '3A', city: 'CHAMBRAY' },
    37302: { agency: '3A', city: 'CHAMBRAY' },
    37303: { agency: '3A', city: 'CHAMBRAY' },
    37304: { agency: '3A', city: 'CHAMBRAY' },
    37305: { agency: '3A', city: 'CHAMBRAY' },
    37306: { agency: '3A', city: 'CHAMBRAY' },
    37309: { agency: '3A', city: 'CHAMBRAY' },
    37310: { agency: '3A', city: 'CHAMBRAY' },
    37320: { agency: '3A', city: 'CHAMBRAY' },
    37321: { agency: '3A', city: 'CHAMBRAY' },
    37330: { agency: '3A', city: 'CHAMBRAY' },
    37340: { agency: '3A', city: 'CHAMBRAY' },
    37360: { agency: '3A', city: 'CHAMBRAY' },
    37370: { agency: '3A', city: 'CHAMBRAY' },
    37380: { agency: '3A', city: 'CHAMBRAY' },
    37390: { agency: '3A', city: 'CHAMBRAY' },
    37400: { agency: '3A', city: 'CHAMBRAY' },
    37401: { agency: '3A', city: 'CHAMBRAY' },
    37402: { agency: '3A', city: 'CHAMBRAY' },
    37403: { agency: '3A', city: 'CHAMBRAY' },
    37404: { agency: '3A', city: 'CHAMBRAY' },
    37409: { agency: '3A', city: 'CHAMBRAY' },
    37420: { agency: '3A', city: 'CHAMBRAY' },
    37460: { agency: '3A', city: 'CHAMBRAY' },
    37500: { agency: '3A', city: 'CHAMBRAY' },
    37510: { agency: '3A', city: 'CHAMBRAY' },
    37520: { agency: '3A', city: 'CHAMBRAY' },
    37521: { agency: '3A', city: 'CHAMBRAY' },
    37530: { agency: '3A', city: 'CHAMBRAY' },
    37540: { agency: '3A', city: 'CHAMBRAY' },
    37541: { agency: '3A', city: 'CHAMBRAY' },
    37542: { agency: '3A', city: 'CHAMBRAY' },
    37549: { agency: '3A', city: 'CHAMBRAY' },
    37550: { agency: '3A', city: 'CHAMBRAY' },
    37551: { agency: '3A', city: 'CHAMBRAY' },
    37552: { agency: '3A', city: 'CHAMBRAY' },
    37553: { agency: '3A', city: 'CHAMBRAY' },
    37554: { agency: '3A', city: 'CHAMBRAY' },
    37555: { agency: '3A', city: 'CHAMBRAY' },
    37556: { agency: '3A', city: 'CHAMBRAY' },
    37600: { agency: '3A', city: 'CHAMBRAY' },
    37700: { agency: '3A', city: 'CHAMBRAY' },
    37701: { agency: '3A', city: 'CHAMBRAY' },
    37702: { agency: '3A', city: 'CHAMBRAY' },
    37703: { agency: '3A', city: 'CHAMBRAY' },
    37705: { agency: '3A', city: 'CHAMBRAY' },
    37709: { agency: '3A', city: 'CHAMBRAY' },
    37910: { agency: '3A', city: 'CHAMBRAY' },
    37911: { agency: '3A', city: 'CHAMBRAY' },
    37912: { agency: '3A', city: 'CHAMBRAY' },
    37913: { agency: '3A', city: 'CHAMBRAY' },
    37915: { agency: '3A', city: 'CHAMBRAY' },
    37917: { agency: '3A', city: 'CHAMBRAY' },
    37921: { agency: '3A', city: 'CHAMBRAY' },
    37922: { agency: '3A', city: 'CHAMBRAY' },
    37924: { agency: '3A', city: 'CHAMBRAY' },
    37925: { agency: '3A', city: 'CHAMBRAY' },
    37926: { agency: '3A', city: 'CHAMBRAY' },
    37927: { agency: '3A', city: 'CHAMBRAY' },
    37928: { agency: '3A', city: 'CHAMBRAY' },
    37929: { agency: '3A', city: 'CHAMBRAY' },
    37931: { agency: '3A', city: 'CHAMBRAY' },
    37932: { agency: '3A', city: 'CHAMBRAY' },
    37941: { agency: '3A', city: 'CHAMBRAY' },
    37942: { agency: '3A', city: 'CHAMBRAY' },
    41000: { agency: '3A', city: 'CHAMBRAY' },
    41150: { agency: '3A', city: 'CHAMBRAY' },
    41190: { agency: '3A', city: 'CHAMBRAY' },
    41310: { agency: '3A', city: 'CHAMBRAY' },
    41400: { agency: '3A', city: 'CHAMBRAY' },
    41800: { agency: '3A', city: 'CHAMBRAY' },
    75000: { agency: '1B', city: 'CHARONNE' },
    75001: { agency: '1B', city: 'CHARONNE' },
    75002: { agency: '1B', city: 'CHARONNE' },
    75003: { agency: '1B', city: 'CHARONNE' },
    75004: { agency: '1B', city: 'CHARONNE' },
    75005: { agency: '1B', city: 'CHARONNE' },
    75006: { agency: '1B', city: 'CHARONNE' },
    75007: { agency: '1B', city: 'CHARONNE' },
    75008: { agency: '1B', city: 'CHARONNE' },
    75009: { agency: '1B', city: 'CHARONNE' },
    75010: { agency: '1B', city: 'CHARONNE' },
    75011: { agency: '1B', city: 'CHARONNE' },
    75012: { agency: '1B', city: 'CHARONNE' },
    75013: { agency: '1B', city: 'CHARONNE' },
    75014: { agency: '1B', city: 'CHARONNE' },
    75015: { agency: '1B', city: 'CHARONNE' },
    75016: { agency: '1B', city: 'CHARONNE' },
    75017: { agency: '1B', city: 'CHARONNE' },
    75018: { agency: '1B', city: 'CHARONNE' },
    75019: { agency: '1B', city: 'CHARONNE' },
    75020: { agency: '1B', city: 'CHARONNE' },
    75116: { agency: '1B', city: 'CHARONNE' },
    77090: { agency: '1B', city: 'CHARONNE' },
    77100: { agency: '1B', city: 'CHARONNE' },
    77111: { agency: '1B', city: 'CHARONNE' },
    77118: { agency: '1B', city: 'CHARONNE' },
    77120: { agency: '1B', city: 'CHARONNE' },
    77122: { agency: '1B', city: 'CHARONNE' },
    77124: { agency: '1B', city: 'CHARONNE' },
    77126: { agency: '1B', city: 'CHARONNE' },
    77131: { agency: '1B', city: 'CHARONNE' },
    77134: { agency: '1B', city: 'CHARONNE' },
    77135: { agency: '1B', city: 'CHARONNE' },
    77138: { agency: '1B', city: 'CHARONNE' },
    77139: { agency: '1B', city: 'CHARONNE' },
    77141: { agency: '1B', city: 'CHARONNE' },
    77144: { agency: '1B', city: 'CHARONNE' },
    77145: { agency: '1B', city: 'CHARONNE' },
    77150: { agency: '1B', city: 'CHARONNE' },
    77151: { agency: '1B', city: 'CHARONNE' },
    77154: { agency: '1B', city: 'CHARONNE' },
    77157: { agency: '1B', city: 'CHARONNE' },
    77160: { agency: '1B', city: 'CHARONNE' },
    77163: { agency: '1B', city: 'CHARONNE' },
    77164: { agency: '1B', city: 'CHARONNE' },
    77165: { agency: '1B', city: 'CHARONNE' },
    77166: { agency: '1B', city: 'CHARONNE' },
    77169: { agency: '1B', city: 'CHARONNE' },
    77171: { agency: '1B', city: 'CHARONNE' },
    77173: { agency: '1B', city: 'CHARONNE' },
    77174: { agency: '1B', city: 'CHARONNE' },
    77177: { agency: '1B', city: 'CHARONNE' },
    77178: { agency: '1B', city: 'CHARONNE' },
    77181: { agency: '1B', city: 'CHARONNE' },
    77183: { agency: '1B', city: 'CHARONNE' },
    77184: { agency: '1B', city: 'CHARONNE' },
    77185: { agency: '1B', city: 'CHARONNE' },
    77186: { agency: '1B', city: 'CHARONNE' },
    77200: { agency: '1B', city: 'CHARONNE' },
    77220: { agency: '1B', city: 'CHARONNE' },
    77230: { agency: '1B', city: 'CHARONNE' },
    77260: { agency: '1B', city: 'CHARONNE' },
    77270: { agency: '1B', city: 'CHARONNE' },
    77280: { agency: '1B', city: 'CHARONNE' },
    77290: { agency: '1B', city: 'CHARONNE' },
    77320: { agency: '1B', city: 'CHARONNE' },
    77330: { agency: '1B', city: 'CHARONNE' },
    77340: { agency: '1B', city: 'CHARONNE' },
    77360: { agency: '1B', city: 'CHARONNE' },
    77400: { agency: '1B', city: 'CHARONNE' },
    77410: { agency: '1B', city: 'CHARONNE' },
    77420: { agency: '1B', city: 'CHARONNE' },
    77440: { agency: '1B', city: 'CHARONNE' },
    77450: { agency: '1B', city: 'CHARONNE' },
    77470: { agency: '1B', city: 'CHARONNE' },
    77480: { agency: '1B', city: 'CHARONNE' },
    77500: { agency: '1B', city: 'CHARONNE' },
    77510: { agency: '1B', city: 'CHARONNE' },
    77515: { agency: '1B', city: 'CHARONNE' },
    77540: { agency: '1B', city: 'CHARONNE' },
    77560: { agency: '1B', city: 'CHARONNE' },
    77580: { agency: '1B', city: 'CHARONNE' },
    77600: { agency: '1B', city: 'CHARONNE' },
    77610: { agency: '1B', city: 'CHARONNE' },
    77640: { agency: '1B', city: 'CHARONNE' },
    77660: { agency: '1B', city: 'CHARONNE' },
    77680: { agency: '1B', city: 'CHARONNE' },
    77700: { agency: '1B', city: 'CHARONNE' },
    77730: { agency: '1B', city: 'CHARONNE' },
    77750: { agency: '1B', city: 'CHARONNE' },
    77840: { agency: '1B', city: 'CHARONNE' },
    77860: { agency: '1B', city: 'CHARONNE' },
    77910: { agency: '1B', city: 'CHARONNE' },
    77970: { agency: '1B', city: 'CHARONNE' },
    77990: { agency: '1B', city: 'CHARONNE' },
    92000: { agency: '1B', city: 'CHARONNE' },
    92100: { agency: '1B', city: 'CHARONNE' },
    92110: { agency: '1B', city: 'CHARONNE' },
    92120: { agency: '1B', city: 'CHARONNE' },
    92130: { agency: '1B', city: 'CHARONNE' },
    92140: { agency: '1B', city: 'CHARONNE' },
    92150: { agency: '1B', city: 'CHARONNE' },
    92160: { agency: '1B', city: 'CHARONNE' },
    92170: { agency: '1B', city: 'CHARONNE' },
    92190: { agency: '1B', city: 'CHARONNE' },
    92200: { agency: '1B', city: 'CHARONNE' },
    92210: { agency: '1B', city: 'CHARONNE' },
    92220: { agency: '1B', city: 'CHARONNE' },
    92230: { agency: '1B', city: 'CHARONNE' },
    92240: { agency: '1B', city: 'CHARONNE' },
    92250: { agency: '1B', city: 'CHARONNE' },
    92260: { agency: '1B', city: 'CHARONNE' },
    92270: { agency: '1B', city: 'CHARONNE' },
    92290: { agency: '1B', city: 'CHARONNE' },
    92300: { agency: '1B', city: 'CHARONNE' },
    92310: { agency: '1B', city: 'CHARONNE' },
    92320: { agency: '1B', city: 'CHARONNE' },
    92330: { agency: '1B', city: 'CHARONNE' },
    92340: { agency: '1B', city: 'CHARONNE' },
    92350: { agency: '1B', city: 'CHARONNE' },
    92360: { agency: '1B', city: 'CHARONNE' },
    92370: { agency: '1B', city: 'CHARONNE' },
    92380: { agency: '1B', city: 'CHARONNE' },
    92390: { agency: '1B', city: 'CHARONNE' },
    92400: { agency: '1B', city: 'CHARONNE' },
    92410: { agency: '1B', city: 'CHARONNE' },
    92420: { agency: '1B', city: 'CHARONNE' },
    92430: { agency: '1B', city: 'CHARONNE' },
    92500: { agency: '1B', city: 'CHARONNE' },
    92600: { agency: '1B', city: 'CHARONNE' },
    92700: { agency: '1B', city: 'CHARONNE' },
    92800: { agency: '1B', city: 'CHARONNE' },
    93000: { agency: '1B', city: 'CHARONNE' },
    93100: { agency: '1B', city: 'CHARONNE' },
    93110: { agency: '1B', city: 'CHARONNE' },
    93120: { agency: '1B', city: 'CHARONNE' },
    93130: { agency: '1B', city: 'CHARONNE' },
    93140: { agency: '1B', city: 'CHARONNE' },
    93150: { agency: '1B', city: 'CHARONNE' },
    93160: { agency: '1B', city: 'CHARONNE' },
    93170: { agency: '1B', city: 'CHARONNE' },
    93190: { agency: '1B', city: 'CHARONNE' },
    93200: { agency: '1B', city: 'CHARONNE' },
    93210: { agency: '1B', city: 'CHARONNE' },
    93220: { agency: '1B', city: 'CHARONNE' },
    93230: { agency: '1B', city: 'CHARONNE' },
    93240: { agency: '1B', city: 'CHARONNE' },
    93250: { agency: '1B', city: 'CHARONNE' },
    93260: { agency: '1B', city: 'CHARONNE' },
    93270: { agency: '1B', city: 'CHARONNE' },
    93290: { agency: '1B', city: 'CHARONNE' },
    93300: { agency: '1B', city: 'CHARONNE' },
    93310: { agency: '1B', city: 'CHARONNE' },
    93320: { agency: '1B', city: 'CHARONNE' },
    93330: { agency: '1B', city: 'CHARONNE' },
    93340: { agency: '1B', city: 'CHARONNE' },
    93350: { agency: '1B', city: 'CHARONNE' },
    93360: { agency: '1B', city: 'CHARONNE' },
    93370: { agency: '1B', city: 'CHARONNE' },
    93380: { agency: '1B', city: 'CHARONNE' },
    93390: { agency: '1B', city: 'CHARONNE' },
    93400: { agency: '1B', city: 'CHARONNE' },
    93410: { agency: '1B', city: 'CHARONNE' },
    93420: { agency: '1B', city: 'CHARONNE' },
    93430: { agency: '1B', city: 'CHARONNE' },
    93440: { agency: '1B', city: 'CHARONNE' },
    93450: { agency: '1B', city: 'CHARONNE' },
    93460: { agency: '1B', city: 'CHARONNE' },
    93470: { agency: '1B', city: 'CHARONNE' },
    93500: { agency: '1B', city: 'CHARONNE' },
    93600: { agency: '1B', city: 'CHARONNE' },
    93700: { agency: '1B', city: 'CHARONNE' },
    93800: { agency: '1B', city: 'CHARONNE' },
    94000: { agency: '1B', city: 'CHARONNE' },
    94100: { agency: '1B', city: 'CHARONNE' },
    94110: { agency: '1B', city: 'CHARONNE' },
    94120: { agency: '1B', city: 'CHARONNE' },
    94130: { agency: '1B', city: 'CHARONNE' },
    94140: { agency: '1B', city: 'CHARONNE' },
    94150: { agency: '1B', city: 'CHARONNE' },
    94160: { agency: '1B', city: 'CHARONNE' },
    94170: { agency: '1B', city: 'CHARONNE' },
    94190: { agency: '1B', city: 'CHARONNE' },
    94200: { agency: '1B', city: 'CHARONNE' },
    94210: { agency: '1B', city: 'CHARONNE' },
    94220: { agency: '1B', city: 'CHARONNE' },
    94230: { agency: '1B', city: 'CHARONNE' },
    94240: { agency: '1B', city: 'CHARONNE' },
    94250: { agency: '1B', city: 'CHARONNE' },
    94260: { agency: '1B', city: 'CHARONNE' },
    94270: { agency: '1B', city: 'CHARONNE' },
    94290: { agency: '1B', city: 'CHARONNE' },
    94300: { agency: '1B', city: 'CHARONNE' },
    94310: { agency: '1B', city: 'CHARONNE' },
    94320: { agency: '1B', city: 'CHARONNE' },
    94340: { agency: '1B', city: 'CHARONNE' },
    94350: { agency: '1B', city: 'CHARONNE' },
    94360: { agency: '1B', city: 'CHARONNE' },
    94370: { agency: '1B', city: 'CHARONNE' },
    94380: { agency: '1B', city: 'CHARONNE' },
    94390: { agency: '1B', city: 'CHARONNE' },
    94400: { agency: '1B', city: 'CHARONNE' },
    94410: { agency: '1B', city: 'CHARONNE' },
    94420: { agency: '1B', city: 'CHARONNE' },
    94430: { agency: '1B', city: 'CHARONNE' },
    94440: { agency: '1B', city: 'CHARONNE' },
    94450: { agency: '1B', city: 'CHARONNE' },
    94460: { agency: '1B', city: 'CHARONNE' },
    94470: { agency: '1B', city: 'CHARONNE' },
    94480: { agency: '1B', city: 'CHARONNE' },
    94490: { agency: '1B', city: 'CHARONNE' },
    94500: { agency: '1B', city: 'CHARONNE' },
    94510: { agency: '1B', city: 'CHARONNE' },
    94520: { agency: '1B', city: 'CHARONNE' },
    94550: { agency: '1B', city: 'CHARONNE' },
    94600: { agency: '1B', city: 'CHARONNE' },
    94700: { agency: '1B', city: 'CHARONNE' },
    94800: { agency: '1B', city: 'CHARONNE' },
    94880: { agency: '1B', city: 'CHARONNE' },
    16100: { agency: '5E', city: 'CHATEAUBERNARD' },
    16101: { agency: '5E', city: 'CHATEAUBERNARD' },
    16102: { agency: '5E', city: 'CHATEAUBERNARD' },
    16103: { agency: '5E', city: 'CHATEAUBERNARD' },
    16104: { agency: '5E', city: 'CHATEAUBERNARD' },
    16105: { agency: '5E', city: 'CHATEAUBERNARD' },
    16106: { agency: '5E', city: 'CHATEAUBERNARD' },
    16107: { agency: '5E', city: 'CHATEAUBERNARD' },
    16108: { agency: '5E', city: 'CHATEAUBERNARD' },
    16109: { agency: '5E', city: 'CHATEAUBERNARD' },
    16111: { agency: '5E', city: 'CHATEAUBERNARD' },
    16112: { agency: '5E', city: 'CHATEAUBERNARD' },
    16113: { agency: '5E', city: 'CHATEAUBERNARD' },
    16121: { agency: '5E', city: 'CHATEAUBERNARD' },
    16130: { agency: '5E', city: 'CHATEAUBERNARD' },
    16170: { agency: '5E', city: 'CHATEAUBERNARD' },
    16200: { agency: '5E', city: 'CHATEAUBERNARD' },
    16300: { agency: '5E', city: 'CHATEAUBERNARD' },
    16370: { agency: '5E', city: 'CHATEAUBERNARD' },
    16720: { agency: '5E', city: 'CHATEAUBERNARD' },
    17490: { agency: '5E', city: 'CHATEAUBERNARD' },
    17520: { agency: '5E', city: 'CHATEAUBERNARD' },
    17610: { agency: '5E', city: 'CHATEAUBERNARD' },
    37120: { agency: '3E', city: 'CHATELLERAULT' },
    37160: { agency: '3E', city: 'CHATELLERAULT' },
    37290: { agency: '3E', city: 'CHATELLERAULT' },
    37350: { agency: '3E', city: 'CHATELLERAULT' },
    37800: { agency: '3E', city: 'CHATELLERAULT' },
    86100: { agency: '3E', city: 'CHATELLERAULT' },
    86101: { agency: '3E', city: 'CHATELLERAULT' },
    86102: { agency: '3E', city: 'CHATELLERAULT' },
    86103: { agency: '3E', city: 'CHATELLERAULT' },
    86104: { agency: '3E', city: 'CHATELLERAULT' },
    86105: { agency: '3E', city: 'CHATELLERAULT' },
    86106: { agency: '3E', city: 'CHATELLERAULT' },
    86107: { agency: '3E', city: 'CHATELLERAULT' },
    86108: { agency: '3E', city: 'CHATELLERAULT' },
    86109: { agency: '3E', city: 'CHATELLERAULT' },
    86110: { agency: '3E', city: 'CHATELLERAULT' },
    86117: { agency: '3E', city: 'CHATELLERAULT' },
    86130: { agency: '3E', city: 'CHATELLERAULT' },
    86131: { agency: '3E', city: 'CHATELLERAULT' },
    86132: { agency: '3E', city: 'CHATELLERAULT' },
    86133: { agency: '3E', city: 'CHATELLERAULT' },
    86134: { agency: '3E', city: 'CHATELLERAULT' },
    86135: { agency: '3E', city: 'CHATELLERAULT' },
    86137: { agency: '3E', city: 'CHATELLERAULT' },
    86138: { agency: '3E', city: 'CHATELLERAULT' },
    86139: { agency: '3E', city: 'CHATELLERAULT' },
    86140: { agency: '3E', city: 'CHATELLERAULT' },
    86170: { agency: '3E', city: 'CHATELLERAULT' },
    86220: { agency: '3E', city: 'CHATELLERAULT' },
    86230: { agency: '3E', city: 'CHATELLERAULT' },
    86270: { agency: '3E', city: 'CHATELLERAULT' },
    86300: { agency: '3E', city: 'CHATELLERAULT' },
    86360: { agency: '3E', city: 'CHATELLERAULT' },
    86361: { agency: '3E', city: 'CHATELLERAULT' },
    86362: { agency: '3E', city: 'CHATELLERAULT' },
    86369: { agency: '3E', city: 'CHATELLERAULT' },
    86380: { agency: '3E', city: 'CHATELLERAULT' },
    86420: { agency: '3E', city: 'CHATELLERAULT' },
    86450: { agency: '3E', city: 'CHATELLERAULT' },
    86540: { agency: '3E', city: 'CHATELLERAULT' },
    86800: { agency: '3E', city: 'CHATELLERAULT' },
    86960: { agency: '3E', city: 'CHATELLERAULT' },
    86961: { agency: '3E', city: 'CHATELLERAULT' },
    86962: { agency: '3E', city: 'CHATELLERAULT' },
    86963: { agency: '3E', city: 'CHATELLERAULT' },
    86964: { agency: '3E', city: 'CHATELLERAULT' },
    86969: { agency: '3E', city: 'CHATELLERAULT' },
    86980: { agency: '3E', city: 'CHATELLERAULT' },
    86981: { agency: '3E', city: 'CHATELLERAULT' },
    86982: { agency: '3E', city: 'CHATELLERAULT' },
    86983: { agency: '3E', city: 'CHATELLERAULT' },
    86984: { agency: '3E', city: 'CHATELLERAULT' },
    21000: { agency: '4J', city: 'DIJON' },
    21001: { agency: '4J', city: 'DIJON' },
    21002: { agency: '4J', city: 'DIJON' },
    21003: { agency: '4J', city: 'DIJON' },
    21004: { agency: '4J', city: 'DIJON' },
    21005: { agency: '4J', city: 'DIJON' },
    21006: { agency: '4J', city: 'DIJON' },
    21007: { agency: '4J', city: 'DIJON' },
    21008: { agency: '4J', city: 'DIJON' },
    21009: { agency: '4J', city: 'DIJON' },
    21010: { agency: '4J', city: 'DIJON' },
    21011: { agency: '4J', city: 'DIJON' },
    21012: { agency: '4J', city: 'DIJON' },
    21013: { agency: '4J', city: 'DIJON' },
    21014: { agency: '4J', city: 'DIJON' },
    21015: { agency: '4J', city: 'DIJON' },
    21016: { agency: '4J', city: 'DIJON' },
    21017: { agency: '4J', city: 'DIJON' },
    21018: { agency: '4J', city: 'DIJON' },
    21019: { agency: '4J', city: 'DIJON' },
    21021: { agency: '4J', city: 'DIJON' },
    21022: { agency: '4J', city: 'DIJON' },
    21023: { agency: '4J', city: 'DIJON' },
    21024: { agency: '4J', city: 'DIJON' },
    21025: { agency: '4J', city: 'DIJON' },
    21026: { agency: '4J', city: 'DIJON' },
    21027: { agency: '4J', city: 'DIJON' },
    21028: { agency: '4J', city: 'DIJON' },
    21029: { agency: '4J', city: 'DIJON' },
    21030: { agency: '4J', city: 'DIJON' },
    21031: { agency: '4J', city: 'DIJON' },
    21032: { agency: '4J', city: 'DIJON' },
    21033: { agency: '4J', city: 'DIJON' },
    21034: { agency: '4J', city: 'DIJON' },
    21035: { agency: '4J', city: 'DIJON' },
    21036: { agency: '4J', city: 'DIJON' },
    21037: { agency: '4J', city: 'DIJON' },
    21038: { agency: '4J', city: 'DIJON' },
    21039: { agency: '4J', city: 'DIJON' },
    21040: { agency: '4J', city: 'DIJON' },
    21041: { agency: '4J', city: 'DIJON' },
    21042: { agency: '4J', city: 'DIJON' },
    21043: { agency: '4J', city: 'DIJON' },
    21044: { agency: '4J', city: 'DIJON' },
    21045: { agency: '4J', city: 'DIJON' },
    21046: { agency: '4J', city: 'DIJON' },
    21047: { agency: '4J', city: 'DIJON' },
    21048: { agency: '4J', city: 'DIJON' },
    21049: { agency: '4J', city: 'DIJON' },
    21051: { agency: '4J', city: 'DIJON' },
    21052: { agency: '4J', city: 'DIJON' },
    21053: { agency: '4J', city: 'DIJON' },
    21054: { agency: '4J', city: 'DIJON' },
    21055: { agency: '4J', city: 'DIJON' },
    21056: { agency: '4J', city: 'DIJON' },
    21057: { agency: '4J', city: 'DIJON' },
    21058: { agency: '4J', city: 'DIJON' },
    21059: { agency: '4J', city: 'DIJON' },
    21060: { agency: '4J', city: 'DIJON' },
    21061: { agency: '4J', city: 'DIJON' },
    21062: { agency: '4J', city: 'DIJON' },
    21063: { agency: '4J', city: 'DIJON' },
    21065: { agency: '4J', city: 'DIJON' },
    21066: { agency: '4J', city: 'DIJON' },
    21067: { agency: '4J', city: 'DIJON' },
    21068: { agency: '4J', city: 'DIJON' },
    21069: { agency: '4J', city: 'DIJON' },
    21070: { agency: '4J', city: 'DIJON' },
    21071: { agency: '4J', city: 'DIJON' },
    21072: { agency: '4J', city: 'DIJON' },
    21073: { agency: '4J', city: 'DIJON' },
    21074: { agency: '4J', city: 'DIJON' },
    21075: { agency: '4J', city: 'DIJON' },
    21076: { agency: '4J', city: 'DIJON' },
    21077: { agency: '4J', city: 'DIJON' },
    21078: { agency: '4J', city: 'DIJON' },
    21079: { agency: '4J', city: 'DIJON' },
    21080: { agency: '4J', city: 'DIJON' },
    21081: { agency: '4J', city: 'DIJON' },
    21083: { agency: '4J', city: 'DIJON' },
    21084: { agency: '4J', city: 'DIJON' },
    21085: { agency: '4J', city: 'DIJON' },
    21086: { agency: '4J', city: 'DIJON' },
    21088: { agency: '4J', city: 'DIJON' },
    21089: { agency: '4J', city: 'DIJON' },
    21092: { agency: '4J', city: 'DIJON' },
    21093: { agency: '4J', city: 'DIJON' },
    21097: { agency: '4J', city: 'DIJON' },
    21098: { agency: '4J', city: 'DIJON' },
    21110: { agency: '4J', city: 'DIJON' },
    21120: { agency: '4J', city: 'DIJON' },
    21121: { agency: '4J', city: 'DIJON' },
    21130: { agency: '4J', city: 'DIJON' },
    21160: { agency: '4J', city: 'DIJON' },
    21170: { agency: '4J', city: 'DIJON' },
    21190: { agency: '4J', city: 'DIJON' },
    21200: { agency: '4J', city: 'DIJON' },
    21201: { agency: '4J', city: 'DIJON' },
    21202: { agency: '4J', city: 'DIJON' },
    21203: { agency: '4J', city: 'DIJON' },
    21204: { agency: '4J', city: 'DIJON' },
    21205: { agency: '4J', city: 'DIJON' },
    21206: { agency: '4J', city: 'DIJON' },
    21207: { agency: '4J', city: 'DIJON' },
    21208: { agency: '4J', city: 'DIJON' },
    21209: { agency: '4J', city: 'DIJON' },
    21219: { agency: '4J', city: 'DIJON' },
    21220: { agency: '4J', city: 'DIJON' },
    21230: { agency: '4J', city: 'DIJON' },
    21240: { agency: '4J', city: 'DIJON' },
    21250: { agency: '4J', city: 'DIJON' },
    21260: { agency: '4J', city: 'DIJON' },
    21270: { agency: '4J', city: 'DIJON' },
    21300: { agency: '4J', city: 'DIJON' },
    21301: { agency: '4J', city: 'DIJON' },
    21302: { agency: '4J', city: 'DIJON' },
    21303: { agency: '4J', city: 'DIJON' },
    21304: { agency: '4J', city: 'DIJON' },
    21305: { agency: '4J', city: 'DIJON' },
    21309: { agency: '4J', city: 'DIJON' },
    21310: { agency: '4J', city: 'DIJON' },
    21320: { agency: '4J', city: 'DIJON' },
    21340: { agency: '4J', city: 'DIJON' },
    21360: { agency: '4J', city: 'DIJON' },
    21370: { agency: '4J', city: 'DIJON' },
    21380: { agency: '4J', city: 'DIJON' },
    21410: { agency: '4J', city: 'DIJON' },
    21420: { agency: '4J', city: 'DIJON' },
    21440: { agency: '4J', city: 'DIJON' },
    21470: { agency: '4J', city: 'DIJON' },
    21490: { agency: '4J', city: 'DIJON' },
    21540: { agency: '4J', city: 'DIJON' },
    21550: { agency: '4J', city: 'DIJON' },
    21560: { agency: '4J', city: 'DIJON' },
    21590: { agency: '4J', city: 'DIJON' },
    21600: { agency: '4J', city: 'DIJON' },
    21601: { agency: '4J', city: 'DIJON' },
    21602: { agency: '4J', city: 'DIJON' },
    21603: { agency: '4J', city: 'DIJON' },
    21604: { agency: '4J', city: 'DIJON' },
    21609: { agency: '4J', city: 'DIJON' },
    21610: { agency: '4J', city: 'DIJON' },
    21630: { agency: '4J', city: 'DIJON' },
    21640: { agency: '4J', city: 'DIJON' },
    21700: { agency: '4J', city: 'DIJON' },
    21701: { agency: '4J', city: 'DIJON' },
    21702: { agency: '4J', city: 'DIJON' },
    21703: { agency: '4J', city: 'DIJON' },
    21704: { agency: '4J', city: 'DIJON' },
    21705: { agency: '4J', city: 'DIJON' },
    21709: { agency: '4J', city: 'DIJON' },
    21719: { agency: '4J', city: 'DIJON' },
    21760: { agency: '4J', city: 'DIJON' },
    21800: { agency: '4J', city: 'DIJON' },
    21801: { agency: '4J', city: 'DIJON' },
    21802: { agency: '4J', city: 'DIJON' },
    21803: { agency: '4J', city: 'DIJON' },
    21806: { agency: '4J', city: 'DIJON' },
    21809: { agency: '4J', city: 'DIJON' },
    21820: { agency: '4J', city: 'DIJON' },
    21850: { agency: '4J', city: 'DIJON' },
    21900: { agency: '4J', city: 'DIJON' },
    21910: { agency: '4J', city: 'DIJON' },
    21998: { agency: '4J', city: 'DIJON' },
    25610: { agency: '4J', city: 'DIJON' },
    39100: { agency: '4J', city: 'DIJON' },
    39101: { agency: '4J', city: 'DIJON' },
    39102: { agency: '4J', city: 'DIJON' },
    39103: { agency: '4J', city: 'DIJON' },
    39104: { agency: '4J', city: 'DIJON' },
    39105: { agency: '4J', city: 'DIJON' },
    39106: { agency: '4J', city: 'DIJON' },
    39107: { agency: '4J', city: 'DIJON' },
    39108: { agency: '4J', city: 'DIJON' },
    39109: { agency: '4J', city: 'DIJON' },
    39120: { agency: '4J', city: 'DIJON' },
    39180: { agency: '4J', city: 'DIJON' },
    39181: { agency: '4J', city: 'DIJON' },
    39189: { agency: '4J', city: 'DIJON' },
    39290: { agency: '4J', city: 'DIJON' },
    39350: { agency: '4J', city: 'DIJON' },
    39380: { agency: '4J', city: 'DIJON' },
    39410: { agency: '4J', city: 'DIJON' },
    39500: { agency: '4J', city: 'DIJON' },
    39501: { agency: '4J', city: 'DIJON' },
    39502: { agency: '4J', city: 'DIJON' },
    39600: { agency: '4J', city: 'DIJON' },
    39700: { agency: '4J', city: 'DIJON' },
    70100: { agency: '4J', city: 'DIJON' },
    70140: { agency: '4J', city: 'DIJON' },
    71100: { agency: '4J', city: 'DIJON' },
    71150: { agency: '4J', city: 'DIJON' },
    71270: { agency: '4J', city: 'DIJON' },
    71350: { agency: '4J', city: 'DIJON' },
    71360: { agency: '4J', city: 'DIJON' },
    71380: { agency: '4J', city: 'DIJON' },
    71490: { agency: '4J', city: 'DIJON' },
    71510: { agency: '4J', city: 'DIJON' },
    71530: { agency: '4J', city: 'DIJON' },
    71590: { agency: '4J', city: 'DIJON' },
    71620: { agency: '4J', city: 'DIJON' },
    71640: { agency: '4J', city: 'DIJON' },
    71880: { agency: '4J', city: 'DIJON' },
    25410: { agency: '4J', city: 'DIJON' },
    25170: { agency: '4J', city: 'DIJON' },
    70150: { agency: '4J', city: 'DIJON' },
    70700: { agency: '4J', city: 'DIJON' },
    21580: { agency: '4J', city: 'DIJON' },
    21290: { agency: '4J', city: 'DIJON' },
    21510: { agency: '4J', city: 'DIJON' },
    52190: { agency: '4J', city: 'DIJON' },
    52250: { agency: '4J', city: 'DIJON' },
    52500: { agency: '4J', city: 'DIJON' },
    52160: { agency: '4J', city: 'DIJON' },
    52600: { agency: '4J', city: 'DIJON' },
    70600: { agency: '4J', city: 'DIJON' },
    21390: { agency: '4J', city: 'DIJON' },
    21150: { agency: '4J', city: 'DIJON' },
    21350: { agency: '4J', city: 'DIJON' },
    21450: { agency: '4J', city: 'DIJON' },
    21690: { agency: '4J', city: 'DIJON' },
    21140: { agency: '4J', city: 'DIJON' },
    21210: { agency: '4J', city: 'DIJON' },
    28000: { agency: '1A', city: 'ELANCOURT' },
    28130: { agency: '1A', city: 'ELANCOURT' },
    28170: { agency: '1A', city: 'ELANCOURT' },
    28230: { agency: '1A', city: 'ELANCOURT' },
    28300: { agency: '1A', city: 'ELANCOURT' },
    28320: { agency: '1A', city: 'ELANCOURT' },
    60000: { agency: '1A', city: 'ELANCOURT' },
    60100: { agency: '1A', city: 'ELANCOURT' },
    60110: { agency: '1A', city: 'ELANCOURT' },
    60117: { agency: '1A', city: 'ELANCOURT' },
    60119: { agency: '1A', city: 'ELANCOURT' },
    60123: { agency: '1A', city: 'ELANCOURT' },
    60126: { agency: '1A', city: 'ELANCOURT' },
    60127: { agency: '1A', city: 'ELANCOURT' },
    60128: { agency: '1A', city: 'ELANCOURT' },
    60129: { agency: '1A', city: 'ELANCOURT' },
    60134: { agency: '1A', city: 'ELANCOURT' },
    60140: { agency: '1A', city: 'ELANCOURT' },
    60141: { agency: '1A', city: 'ELANCOURT' },
    60149: { agency: '1A', city: 'ELANCOURT' },
    60155: { agency: '1A', city: 'ELANCOURT' },
    60160: { agency: '1A', city: 'ELANCOURT' },
    60173: { agency: '1A', city: 'ELANCOURT' },
    60175: { agency: '1A', city: 'ELANCOURT' },
    60180: { agency: '1A', city: 'ELANCOURT' },
    60190: { agency: '1A', city: 'ELANCOURT' },
    60200: { agency: '1A', city: 'ELANCOURT' },
    60230: { agency: '1A', city: 'ELANCOURT' },
    60240: { agency: '1A', city: 'ELANCOURT' },
    60250: { agency: '1A', city: 'ELANCOURT' },
    60260: { agency: '1A', city: 'ELANCOURT' },
    60270: { agency: '1A', city: 'ELANCOURT' },
    60290: { agency: '1A', city: 'ELANCOURT' },
    60300: { agency: '1A', city: 'ELANCOURT' },
    60320: { agency: '1A', city: 'ELANCOURT' },
    60330: { agency: '1A', city: 'ELANCOURT' },
    60340: { agency: '1A', city: 'ELANCOURT' },
    60350: { agency: '1A', city: 'ELANCOURT' },
    60370: { agency: '1A', city: 'ELANCOURT' },
    60390: { agency: '1A', city: 'ELANCOURT' },
    60410: { agency: '1A', city: 'ELANCOURT' },
    60430: { agency: '1A', city: 'ELANCOURT' },
    60440: { agency: '1A', city: 'ELANCOURT' },
    60460: { agency: '1A', city: 'ELANCOURT' },
    60500: { agency: '1A', city: 'ELANCOURT' },
    60510: { agency: '1A', city: 'ELANCOURT' },
    60520: { agency: '1A', city: 'ELANCOURT' },
    60530: { agency: '1A', city: 'ELANCOURT' },
    60540: { agency: '1A', city: 'ELANCOURT' },
    60550: { agency: '1A', city: 'ELANCOURT' },
    60560: { agency: '1A', city: 'ELANCOURT' },
    60570: { agency: '1A', city: 'ELANCOURT' },
    60580: { agency: '1A', city: 'ELANCOURT' },
    60590: { agency: '1A', city: 'ELANCOURT' },
    60600: { agency: '1A', city: 'ELANCOURT' },
    60610: { agency: '1A', city: 'ELANCOURT' },
    60620: { agency: '1A', city: 'ELANCOURT' },
    60650: { agency: '1A', city: 'ELANCOURT' },
    60660: { agency: '1A', city: 'ELANCOURT' },
    60680: { agency: '1A', city: 'ELANCOURT' },
    60700: { agency: '1A', city: 'ELANCOURT' },
    60710: { agency: '1A', city: 'ELANCOURT' },
    60730: { agency: '1A', city: 'ELANCOURT' },
    60740: { agency: '1A', city: 'ELANCOURT' },
    60790: { agency: '1A', city: 'ELANCOURT' },
    60800: { agency: '1A', city: 'ELANCOURT' },
    60810: { agency: '1A', city: 'ELANCOURT' },
    60820: { agency: '1A', city: 'ELANCOURT' },
    60840: { agency: '1A', city: 'ELANCOURT' },
    60850: { agency: '1A', city: 'ELANCOURT' },
    60870: { agency: '1A', city: 'ELANCOURT' },
    60880: { agency: '1A', city: 'ELANCOURT' },
    60890: { agency: '1A', city: 'ELANCOURT' },
    60930: { agency: '1A', city: 'ELANCOURT' },
    60940: { agency: '1A', city: 'ELANCOURT' },
    60950: { agency: '1A', city: 'ELANCOURT' },
    77000: { agency: '1A', city: 'ELANCOURT' },
    77114: { agency: '1A', city: 'ELANCOURT' },
    77115: { agency: '1A', city: 'ELANCOURT' },
    77116: { agency: '1A', city: 'ELANCOURT' },
    77123: { agency: '1A', city: 'ELANCOURT' },
    77127: { agency: '1A', city: 'ELANCOURT' },
    77130: { agency: '1A', city: 'ELANCOURT' },
    77133: { agency: '1A', city: 'ELANCOURT' },
    77148: { agency: '1A', city: 'ELANCOURT' },
    77170: { agency: '1A', city: 'ELANCOURT' },
    77176: { agency: '1A', city: 'ELANCOURT' },
    77190: { agency: '1A', city: 'ELANCOURT' },
    77210: { agency: '1A', city: 'ELANCOURT' },
    77240: { agency: '1A', city: 'ELANCOURT' },
    77250: { agency: '1A', city: 'ELANCOURT' },
    77300: { agency: '1A', city: 'ELANCOURT' },
    77310: { agency: '1A', city: 'ELANCOURT' },
    77350: { agency: '1A', city: 'ELANCOURT' },
    77370: { agency: '1A', city: 'ELANCOURT' },
    77380: { agency: '1A', city: 'ELANCOURT' },
    77390: { agency: '1A', city: 'ELANCOURT' },
    77430: { agency: '1A', city: 'ELANCOURT' },
    77520: { agency: '1A', city: 'ELANCOURT' },
    77550: { agency: '1A', city: 'ELANCOURT' },
    77590: { agency: '1A', city: 'ELANCOURT' },
    77630: { agency: '1A', city: 'ELANCOURT' },
    77650: { agency: '1A', city: 'ELANCOURT' },
    77670: { agency: '1A', city: 'ELANCOURT' },
    77690: { agency: '1A', city: 'ELANCOURT' },
    77720: { agency: '1A', city: 'ELANCOURT' },
    77760: { agency: '1A', city: 'ELANCOURT' },
    77770: { agency: '1A', city: 'ELANCOURT' },
    77780: { agency: '1A', city: 'ELANCOURT' },
    77810: { agency: '1A', city: 'ELANCOURT' },
    77820: { agency: '1A', city: 'ELANCOURT' },
    77830: { agency: '1A', city: 'ELANCOURT' },
    77850: { agency: '1A', city: 'ELANCOURT' },
    77870: { agency: '1A', city: 'ELANCOURT' },
    77880: { agency: '1A', city: 'ELANCOURT' },
    77920: { agency: '1A', city: 'ELANCOURT' },
    77930: { agency: '1A', city: 'ELANCOURT' },
    77940: { agency: '1A', city: 'ELANCOURT' },
    77950: { agency: '1A', city: 'ELANCOURT' },
    78000: { agency: '1A', city: 'ELANCOURT' },
    78100: { agency: '1A', city: 'ELANCOURT' },
    78110: { agency: '1A', city: 'ELANCOURT' },
    78112: { agency: '1A', city: 'ELANCOURT' },
    78113: { agency: '1A', city: 'ELANCOURT' },
    78114: { agency: '1A', city: 'ELANCOURT' },
    78117: { agency: '1A', city: 'ELANCOURT' },
    78120: { agency: '1A', city: 'ELANCOURT' },
    78121: { agency: '1A', city: 'ELANCOURT' },
    78124: { agency: '1A', city: 'ELANCOURT' },
    78125: { agency: '1A', city: 'ELANCOURT' },
    78126: { agency: '1A', city: 'ELANCOURT' },
    78130: { agency: '1A', city: 'ELANCOURT' },
    78150: { agency: '1A', city: 'ELANCOURT' },
    78160: { agency: '1A', city: 'ELANCOURT' },
    78170: { agency: '1A', city: 'ELANCOURT' },
    78180: { agency: '1A', city: 'ELANCOURT' },
    78190: { agency: '1A', city: 'ELANCOURT' },
    78210: { agency: '1A', city: 'ELANCOURT' },
    78220: { agency: '1A', city: 'ELANCOURT' },
    78230: { agency: '1A', city: 'ELANCOURT' },
    78240: { agency: '1A', city: 'ELANCOURT' },
    78250: { agency: '1A', city: 'ELANCOURT' },
    78260: { agency: '1A', city: 'ELANCOURT' },
    78280: { agency: '1A', city: 'ELANCOURT' },
    78290: { agency: '1A', city: 'ELANCOURT' },
    78300: { agency: '1A', city: 'ELANCOURT' },
    78310: { agency: '1A', city: 'ELANCOURT' },
    78320: { agency: '1A', city: 'ELANCOURT' },
    78330: { agency: '1A', city: 'ELANCOURT' },
    78340: { agency: '1A', city: 'ELANCOURT' },
    78350: { agency: '1A', city: 'ELANCOURT' },
    78360: { agency: '1A', city: 'ELANCOURT' },
    78370: { agency: '1A', city: 'ELANCOURT' },
    78380: { agency: '1A', city: 'ELANCOURT' },
    78390: { agency: '1A', city: 'ELANCOURT' },
    78400: { agency: '1A', city: 'ELANCOURT' },
    78410: { agency: '1A', city: 'ELANCOURT' },
    78420: { agency: '1A', city: 'ELANCOURT' },
    78430: { agency: '1A', city: 'ELANCOURT' },
    78450: { agency: '1A', city: 'ELANCOURT' },
    78460: { agency: '1A', city: 'ELANCOURT' },
    78470: { agency: '1A', city: 'ELANCOURT' },
    78480: { agency: '1A', city: 'ELANCOURT' },
    78490: { agency: '1A', city: 'ELANCOURT' },
    78500: { agency: '1A', city: 'ELANCOURT' },
    78510: { agency: '1A', city: 'ELANCOURT' },
    78530: { agency: '1A', city: 'ELANCOURT' },
    78540: { agency: '1A', city: 'ELANCOURT' },
    78560: { agency: '1A', city: 'ELANCOURT' },
    78570: { agency: '1A', city: 'ELANCOURT' },
    78590: { agency: '1A', city: 'ELANCOURT' },
    78600: { agency: '1A', city: 'ELANCOURT' },
    78610: { agency: '1A', city: 'ELANCOURT' },
    78620: { agency: '1A', city: 'ELANCOURT' },
    78630: { agency: '1A', city: 'ELANCOURT' },
    78640: { agency: '1A', city: 'ELANCOURT' },
    78650: { agency: '1A', city: 'ELANCOURT' },
    78660: { agency: '1A', city: 'ELANCOURT' },
    78670: { agency: '1A', city: 'ELANCOURT' },
    78680: { agency: '1A', city: 'ELANCOURT' },
    78690: { agency: '1A', city: 'ELANCOURT' },
    78700: { agency: '1A', city: 'ELANCOURT' },
    78720: { agency: '1A', city: 'ELANCOURT' },
    78730: { agency: '1A', city: 'ELANCOURT' },
    78740: { agency: '1A', city: 'ELANCOURT' },
    78750: { agency: '1A', city: 'ELANCOURT' },
    78760: { agency: '1A', city: 'ELANCOURT' },
    78770: { agency: '1A', city: 'ELANCOURT' },
    78780: { agency: '1A', city: 'ELANCOURT' },
    78800: { agency: '1A', city: 'ELANCOURT' },
    78810: { agency: '1A', city: 'ELANCOURT' },
    78830: { agency: '1A', city: 'ELANCOURT' },
    78850: { agency: '1A', city: 'ELANCOURT' },
    78860: { agency: '1A', city: 'ELANCOURT' },
    78870: { agency: '1A', city: 'ELANCOURT' },
    78890: { agency: '1A', city: 'ELANCOURT' },
    78910: { agency: '1A', city: 'ELANCOURT' },
    78940: { agency: '1A', city: 'ELANCOURT' },
    78955: { agency: '1A', city: 'ELANCOURT' },
    78960: { agency: '1A', city: 'ELANCOURT' },
    78990: { agency: '1A', city: 'ELANCOURT' },
    91000: { agency: '1A', city: 'ELANCOURT' },
    91070: { agency: '1A', city: 'ELANCOURT' },
    91080: { agency: '1A', city: 'ELANCOURT' },
    91090: { agency: '1A', city: 'ELANCOURT' },
    91100: { agency: '1A', city: 'ELANCOURT' },
    91120: { agency: '1A', city: 'ELANCOURT' },
    91130: { agency: '1A', city: 'ELANCOURT' },
    91140: { agency: '1A', city: 'ELANCOURT' },
    91150: { agency: '1A', city: 'ELANCOURT' },
    91160: { agency: '1A', city: 'ELANCOURT' },
    91170: { agency: '1A', city: 'ELANCOURT' },
    91180: { agency: '1A', city: 'ELANCOURT' },
    91190: { agency: '1A', city: 'ELANCOURT' },
    91200: { agency: '1A', city: 'ELANCOURT' },
    91210: { agency: '1A', city: 'ELANCOURT' },
    91220: { agency: '1A', city: 'ELANCOURT' },
    91230: { agency: '1A', city: 'ELANCOURT' },
    91240: { agency: '1A', city: 'ELANCOURT' },
    91250: { agency: '1A', city: 'ELANCOURT' },
    91260: { agency: '1A', city: 'ELANCOURT' },
    91270: { agency: '1A', city: 'ELANCOURT' },
    91280: { agency: '1A', city: 'ELANCOURT' },
    91290: { agency: '1A', city: 'ELANCOURT' },
    91300: { agency: '1A', city: 'ELANCOURT' },
    91310: { agency: '1A', city: 'ELANCOURT' },
    91320: { agency: '1A', city: 'ELANCOURT' },
    91330: { agency: '1A', city: 'ELANCOURT' },
    91340: { agency: '1A', city: 'ELANCOURT' },
    91350: { agency: '1A', city: 'ELANCOURT' },
    91360: { agency: '1A', city: 'ELANCOURT' },
    91370: { agency: '1A', city: 'ELANCOURT' },
    91380: { agency: '1A', city: 'ELANCOURT' },
    91390: { agency: '1A', city: 'ELANCOURT' },
    91400: { agency: '1A', city: 'ELANCOURT' },
    91410: { agency: '1A', city: 'ELANCOURT' },
    91420: { agency: '1A', city: 'ELANCOURT' },
    91430: { agency: '1A', city: 'ELANCOURT' },
    91440: { agency: '1A', city: 'ELANCOURT' },
    91450: { agency: '1A', city: 'ELANCOURT' },
    91460: { agency: '1A', city: 'ELANCOURT' },
    91470: { agency: '1A', city: 'ELANCOURT' },
    91480: { agency: '1A', city: 'ELANCOURT' },
    91490: { agency: '1A', city: 'ELANCOURT' },
    91510: { agency: '1A', city: 'ELANCOURT' },
    91520: { agency: '1A', city: 'ELANCOURT' },
    91530: { agency: '1A', city: 'ELANCOURT' },
    91540: { agency: '1A', city: 'ELANCOURT' },
    91550: { agency: '1A', city: 'ELANCOURT' },
    91560: { agency: '1A', city: 'ELANCOURT' },
    91570: { agency: '1A', city: 'ELANCOURT' },
    91580: { agency: '1A', city: 'ELANCOURT' },
    91590: { agency: '1A', city: 'ELANCOURT' },
    91600: { agency: '1A', city: 'ELANCOURT' },
    91610: { agency: '1A', city: 'ELANCOURT' },
    91620: { agency: '1A', city: 'ELANCOURT' },
    91630: { agency: '1A', city: 'ELANCOURT' },
    91640: { agency: '1A', city: 'ELANCOURT' },
    91650: { agency: '1A', city: 'ELANCOURT' },
    91660: { agency: '1A', city: 'ELANCOURT' },
    91670: { agency: '1A', city: 'ELANCOURT' },
    91680: { agency: '1A', city: 'ELANCOURT' },
    91690: { agency: '1A', city: 'ELANCOURT' },
    91700: { agency: '1A', city: 'ELANCOURT' },
    91710: { agency: '1A', city: 'ELANCOURT' },
    91720: { agency: '1A', city: 'ELANCOURT' },
    91730: { agency: '1A', city: 'ELANCOURT' },
    91740: { agency: '1A', city: 'ELANCOURT' },
    91750: { agency: '1A', city: 'ELANCOURT' },
    91760: { agency: '1A', city: 'ELANCOURT' },
    91770: { agency: '1A', city: 'ELANCOURT' },
    91780: { agency: '1A', city: 'ELANCOURT' },
    91790: { agency: '1A', city: 'ELANCOURT' },
    91800: { agency: '1A', city: 'ELANCOURT' },
    91810: { agency: '1A', city: 'ELANCOURT' },
    91820: { agency: '1A', city: 'ELANCOURT' },
    91830: { agency: '1A', city: 'ELANCOURT' },
    91840: { agency: '1A', city: 'ELANCOURT' },
    91850: { agency: '1A', city: 'ELANCOURT' },
    91860: { agency: '1A', city: 'ELANCOURT' },
    91870: { agency: '1A', city: 'ELANCOURT' },
    91880: { agency: '1A', city: 'ELANCOURT' },
    91890: { agency: '1A', city: 'ELANCOURT' },
    91910: { agency: '1A', city: 'ELANCOURT' },
    91930: { agency: '1A', city: 'ELANCOURT' },
    91940: { agency: '1A', city: 'ELANCOURT' },
    95000: { agency: '1A', city: 'ELANCOURT' },
    95100: { agency: '1A', city: 'ELANCOURT' },
    95110: { agency: '1A', city: 'ELANCOURT' },
    95120: { agency: '1A', city: 'ELANCOURT' },
    95130: { agency: '1A', city: 'ELANCOURT' },
    95140: { agency: '1A', city: 'ELANCOURT' },
    95150: { agency: '1A', city: 'ELANCOURT' },
    95160: { agency: '1A', city: 'ELANCOURT' },
    95170: { agency: '1A', city: 'ELANCOURT' },
    95180: { agency: '1A', city: 'ELANCOURT' },
    95190: { agency: '1A', city: 'ELANCOURT' },
    95200: { agency: '1A', city: 'ELANCOURT' },
    95210: { agency: '1A', city: 'ELANCOURT' },
    95220: { agency: '1A', city: 'ELANCOURT' },
    95230: { agency: '1A', city: 'ELANCOURT' },
    95240: { agency: '1A', city: 'ELANCOURT' },
    95250: { agency: '1A', city: 'ELANCOURT' },
    95260: { agency: '1A', city: 'ELANCOURT' },
    95270: { agency: '1A', city: 'ELANCOURT' },
    95280: { agency: '1A', city: 'ELANCOURT' },
    95290: { agency: '1A', city: 'ELANCOURT' },
    95300: { agency: '1A', city: 'ELANCOURT' },
    95310: { agency: '1A', city: 'ELANCOURT' },
    95320: { agency: '1A', city: 'ELANCOURT' },
    95330: { agency: '1A', city: 'ELANCOURT' },
    95340: { agency: '1A', city: 'ELANCOURT' },
    95350: { agency: '1A', city: 'ELANCOURT' },
    95360: { agency: '1A', city: 'ELANCOURT' },
    95370: { agency: '1A', city: 'ELANCOURT' },
    95380: { agency: '1A', city: 'ELANCOURT' },
    95390: { agency: '1A', city: 'ELANCOURT' },
    95400: { agency: '1A', city: 'ELANCOURT' },
    95410: { agency: '1A', city: 'ELANCOURT' },
    95430: { agency: '1A', city: 'ELANCOURT' },
    95440: { agency: '1A', city: 'ELANCOURT' },
    95450: { agency: '1A', city: 'ELANCOURT' },
    95460: { agency: '1A', city: 'ELANCOURT' },
    95470: { agency: '1A', city: 'ELANCOURT' },
    95480: { agency: '1A', city: 'ELANCOURT' },
    95490: { agency: '1A', city: 'ELANCOURT' },
    95500: { agency: '1A', city: 'ELANCOURT' },
    95520: { agency: '1A', city: 'ELANCOURT' },
    95530: { agency: '1A', city: 'ELANCOURT' },
    95540: { agency: '1A', city: 'ELANCOURT' },
    95550: { agency: '1A', city: 'ELANCOURT' },
    95560: { agency: '1A', city: 'ELANCOURT' },
    95570: { agency: '1A', city: 'ELANCOURT' },
    95580: { agency: '1A', city: 'ELANCOURT' },
    95590: { agency: '1A', city: 'ELANCOURT' },
    95600: { agency: '1A', city: 'ELANCOURT' },
    95610: { agency: '1A', city: 'ELANCOURT' },
    95620: { agency: '1A', city: 'ELANCOURT' },
    95630: { agency: '1A', city: 'ELANCOURT' },
    95640: { agency: '1A', city: 'ELANCOURT' },
    95650: { agency: '1A', city: 'ELANCOURT' },
    95660: { agency: '1A', city: 'ELANCOURT' },
    95670: { agency: '1A', city: 'ELANCOURT' },
    95680: { agency: '1A', city: 'ELANCOURT' },
    95690: { agency: '1A', city: 'ELANCOURT' },
    95700: { agency: '1A', city: 'ELANCOURT' },
    95720: { agency: '1A', city: 'ELANCOURT' },
    95740: { agency: '1A', city: 'ELANCOURT' },
    95750: { agency: '1A', city: 'ELANCOURT' },
    95760: { agency: '1A', city: 'ELANCOURT' },
    95800: { agency: '1A', city: 'ELANCOURT' },
    95810: { agency: '1A', city: 'ELANCOURT' },
    95820: { agency: '1A', city: 'ELANCOURT' },
    95830: { agency: '1A', city: 'ELANCOURT' },
    95840: { agency: '1A', city: 'ELANCOURT' },
    95850: { agency: '1A', city: 'ELANCOURT' },
    95870: { agency: '1A', city: 'ELANCOURT' },
    95880: { agency: '1A', city: 'ELANCOURT' },
    27000: { agency: '2D', city: 'EVREUX' },
    27001: { agency: '2D', city: 'EVREUX' },
    27002: { agency: '2D', city: 'EVREUX' },
    27003: { agency: '2D', city: 'EVREUX' },
    27004: { agency: '2D', city: 'EVREUX' },
    27005: { agency: '2D', city: 'EVREUX' },
    27006: { agency: '2D', city: 'EVREUX' },
    27007: { agency: '2D', city: 'EVREUX' },
    27008: { agency: '2D', city: 'EVREUX' },
    27009: { agency: '2D', city: 'EVREUX' },
    27010: { agency: '2D', city: 'EVREUX' },
    27012: { agency: '2D', city: 'EVREUX' },
    27013: { agency: '2D', city: 'EVREUX' },
    27015: { agency: '2D', city: 'EVREUX' },
    27016: { agency: '2D', city: 'EVREUX' },
    27017: { agency: '2D', city: 'EVREUX' },
    27018: { agency: '2D', city: 'EVREUX' },
    27019: { agency: '2D', city: 'EVREUX' },
    27021: { agency: '2D', city: 'EVREUX' },
    27022: { agency: '2D', city: 'EVREUX' },
    27023: { agency: '2D', city: 'EVREUX' },
    27025: { agency: '2D', city: 'EVREUX' },
    27026: { agency: '2D', city: 'EVREUX' },
    27030: { agency: '2D', city: 'EVREUX' },
    27031: { agency: '2D', city: 'EVREUX' },
    27032: { agency: '2D', city: 'EVREUX' },
    27033: { agency: '2D', city: 'EVREUX' },
    27034: { agency: '2D', city: 'EVREUX' },
    27035: { agency: '2D', city: 'EVREUX' },
    27036: { agency: '2D', city: 'EVREUX' },
    27037: { agency: '2D', city: 'EVREUX' },
    27038: { agency: '2D', city: 'EVREUX' },
    27039: { agency: '2D', city: 'EVREUX' },
    27040: { agency: '2D', city: 'EVREUX' },
    27090: { agency: '2D', city: 'EVREUX' },
    27091: { agency: '2D', city: 'EVREUX' },
    27092: { agency: '2D', city: 'EVREUX' },
    27093: { agency: '2D', city: 'EVREUX' },
    27094: { agency: '2D', city: 'EVREUX' },
    27095: { agency: '2D', city: 'EVREUX' },
    27096: { agency: '2D', city: 'EVREUX' },
    27097: { agency: '2D', city: 'EVREUX' },
    27098: { agency: '2D', city: 'EVREUX' },
    27099: { agency: '2D', city: 'EVREUX' },
    27100: { agency: '2D', city: 'EVREUX' },
    27101: { agency: '2D', city: 'EVREUX' },
    27102: { agency: '2D', city: 'EVREUX' },
    27103: { agency: '2D', city: 'EVREUX' },
    27104: { agency: '2D', city: 'EVREUX' },
    27105: { agency: '2D', city: 'EVREUX' },
    27106: { agency: '2D', city: 'EVREUX' },
    27107: { agency: '2D', city: 'EVREUX' },
    27108: { agency: '2D', city: 'EVREUX' },
    27109: { agency: '2D', city: 'EVREUX' },
    27110: { agency: '2D', city: 'EVREUX' },
    27120: { agency: '2D', city: 'EVREUX' },
    27121: { agency: '2D', city: 'EVREUX' },
    27122: { agency: '2D', city: 'EVREUX' },
    27127: { agency: '2D', city: 'EVREUX' },
    27140: { agency: '2D', city: 'EVREUX' },
    27170: { agency: '2D', city: 'EVREUX' },
    27180: { agency: '2D', city: 'EVREUX' },
    27190: { agency: '2D', city: 'EVREUX' },
    27200: { agency: '2D', city: 'EVREUX' },
    27220: { agency: '2D', city: 'EVREUX' },
    27230: { agency: '2D', city: 'EVREUX' },
    27240: { agency: '2D', city: 'EVREUX' },
    27260: { agency: '2D', city: 'EVREUX' },
    27290: { agency: '2D', city: 'EVREUX' },
    27300: { agency: '2D', city: 'EVREUX' },
    27320: { agency: '2D', city: 'EVREUX' },
    27370: { agency: '2D', city: 'EVREUX' },
    27400: { agency: '2D', city: 'EVREUX' },
    27401: { agency: '2D', city: 'EVREUX' },
    27402: { agency: '2D', city: 'EVREUX' },
    27403: { agency: '2D', city: 'EVREUX' },
    27404: { agency: '2D', city: 'EVREUX' },
    27405: { agency: '2D', city: 'EVREUX' },
    27406: { agency: '2D', city: 'EVREUX' },
    27407: { agency: '2D', city: 'EVREUX' },
    27409: { agency: '2D', city: 'EVREUX' },
    27410: { agency: '2D', city: 'EVREUX' },
    27420: { agency: '2D', city: 'EVREUX' },
    27430: { agency: '2D', city: 'EVREUX' },
    27440: { agency: '2D', city: 'EVREUX' },
    27450: { agency: '2D', city: 'EVREUX' },
    27460: { agency: '2D', city: 'EVREUX' },
    27470: { agency: '2D', city: 'EVREUX' },
    27490: { agency: '2D', city: 'EVREUX' },
    27510: { agency: '2D', city: 'EVREUX' },
    27530: { agency: '2D', city: 'EVREUX' },
    27540: { agency: '2D', city: 'EVREUX' },
    27550: { agency: '2D', city: 'EVREUX' },
    27560: { agency: '2D', city: 'EVREUX' },
    27590: { agency: '2D', city: 'EVREUX' },
    27600: { agency: '2D', city: 'EVREUX' },
    27607: { agency: '2D', city: 'EVREUX' },
    27610: { agency: '2D', city: 'EVREUX' },
    27620: { agency: '2D', city: 'EVREUX' },
    27630: { agency: '2D', city: 'EVREUX' },
    27640: { agency: '2D', city: 'EVREUX' },
    27650: { agency: '2D', city: 'EVREUX' },
    27660: { agency: '2D', city: 'EVREUX' },
    27700: { agency: '2D', city: 'EVREUX' },
    27710: { agency: '2D', city: 'EVREUX' },
    27720: { agency: '2D', city: 'EVREUX' },
    27730: { agency: '2D', city: 'EVREUX' },
    27740: { agency: '2D', city: 'EVREUX' },
    27750: { agency: '2D', city: 'EVREUX' },
    27770: { agency: '2D', city: 'EVREUX' },
    27780: { agency: '2D', city: 'EVREUX' },
    27790: { agency: '2D', city: 'EVREUX' },
    27800: { agency: '2D', city: 'EVREUX' },
    27810: { agency: '2D', city: 'EVREUX' },
    27830: { agency: '2D', city: 'EVREUX' },
    27850: { agency: '2D', city: 'EVREUX' },
    27870: { agency: '2D', city: 'EVREUX' },
    27890: { agency: '2D', city: 'EVREUX' },
    27909: { agency: '2D', city: 'EVREUX' },
    27910: { agency: '2D', city: 'EVREUX' },
    27911: { agency: '2D', city: 'EVREUX' },
    27914: { agency: '2D', city: 'EVREUX' },
    27920: { agency: '2D', city: 'EVREUX' },
    27924: { agency: '2D', city: 'EVREUX' },
    27928: { agency: '2D', city: 'EVREUX' },
    27929: { agency: '2D', city: 'EVREUX' },
    27930: { agency: '2D', city: 'EVREUX' },
    27931: { agency: '2D', city: 'EVREUX' },
    27932: { agency: '2D', city: 'EVREUX' },
    27933: { agency: '2D', city: 'EVREUX' },
    27936: { agency: '2D', city: 'EVREUX' },
    27939: { agency: '2D', city: 'EVREUX' },
    27940: { agency: '2D', city: 'EVREUX' },
    27949: { agency: '2D', city: 'EVREUX' },
    27950: { agency: '2D', city: 'EVREUX' },
    28100: { agency: '2D', city: 'EVREUX' },
    28210: { agency: '2D', city: 'EVREUX' },
    28260: { agency: '2D', city: 'EVREUX' },
    28270: { agency: '2D', city: 'EVREUX' },
    28350: { agency: '2D', city: 'EVREUX' },
    28380: { agency: '2D', city: 'EVREUX' },
    28410: { agency: '2D', city: 'EVREUX' },
    28500: { agency: '2D', city: 'EVREUX' },
    78111: { agency: '2D', city: 'EVREUX' },
    78140: { agency: '2D', city: 'EVREUX' },
    78200: { agency: '2D', city: 'EVREUX' },
    78201: { agency: '2D', city: 'EVREUX' },
    78270: { agency: '2D', city: 'EVREUX' },
    78440: { agency: '2D', city: 'EVREUX' },
    78520: { agency: '2D', city: 'EVREUX' },
    78550: { agency: '2D', city: 'EVREUX' },
    78580: { agency: '2D', city: 'EVREUX' },
    78710: { agency: '2D', city: 'EVREUX' },
    78711: { agency: '2D', city: 'EVREUX' },
    78790: { agency: '2D', city: 'EVREUX' },
    78820: { agency: '2D', city: 'EVREUX' },
    78840: { agency: '2D', city: 'EVREUX' },
    78920: { agency: '2D', city: 'EVREUX' },
    78930: { agency: '2D', city: 'EVREUX' },
    78950: { agency: '2D', city: 'EVREUX' },
    78970: { agency: '2D', city: 'EVREUX' },
    78980: { agency: '2D', city: 'EVREUX' },
    95420: { agency: '2D', city: 'EVREUX' },
    95510: { agency: '2D', city: 'EVREUX' },
    95710: { agency: '2D', city: 'EVREUX' },
    95770: { agency: '2D', city: 'EVREUX' },
    95780: { agency: '2D', city: 'EVREUX' },
    38000: { agency: '4P', city: 'GRENOBLE' },
    38001: { agency: '4P', city: 'GRENOBLE' },
    38002: { agency: '4P', city: 'GRENOBLE' },
    38003: { agency: '4P', city: 'GRENOBLE' },
    38004: { agency: '4P', city: 'GRENOBLE' },
    38005: { agency: '4P', city: 'GRENOBLE' },
    38006: { agency: '4P', city: 'GRENOBLE' },
    38007: { agency: '4P', city: 'GRENOBLE' },
    38008: { agency: '4P', city: 'GRENOBLE' },
    38009: { agency: '4P', city: 'GRENOBLE' },
    38010: { agency: '4P', city: 'GRENOBLE' },
    38011: { agency: '4P', city: 'GRENOBLE' },
    38012: { agency: '4P', city: 'GRENOBLE' },
    38013: { agency: '4P', city: 'GRENOBLE' },
    38014: { agency: '4P', city: 'GRENOBLE' },
    38015: { agency: '4P', city: 'GRENOBLE' },
    38016: { agency: '4P', city: 'GRENOBLE' },
    38017: { agency: '4P', city: 'GRENOBLE' },
    38018: { agency: '4P', city: 'GRENOBLE' },
    38019: { agency: '4P', city: 'GRENOBLE' },
    38021: { agency: '4P', city: 'GRENOBLE' },
    38022: { agency: '4P', city: 'GRENOBLE' },
    38023: { agency: '4P', city: 'GRENOBLE' },
    38024: { agency: '4P', city: 'GRENOBLE' },
    38025: { agency: '4P', city: 'GRENOBLE' },
    38026: { agency: '4P', city: 'GRENOBLE' },
    38027: { agency: '4P', city: 'GRENOBLE' },
    38028: { agency: '4P', city: 'GRENOBLE' },
    38029: { agency: '4P', city: 'GRENOBLE' },
    38030: { agency: '4P', city: 'GRENOBLE' },
    38031: { agency: '4P', city: 'GRENOBLE' },
    38032: { agency: '4P', city: 'GRENOBLE' },
    38033: { agency: '4P', city: 'GRENOBLE' },
    38034: { agency: '4P', city: 'GRENOBLE' },
    38035: { agency: '4P', city: 'GRENOBLE' },
    38036: { agency: '4P', city: 'GRENOBLE' },
    38037: { agency: '4P', city: 'GRENOBLE' },
    38039: { agency: '4P', city: 'GRENOBLE' },
    38040: { agency: '4P', city: 'GRENOBLE' },
    38041: { agency: '4P', city: 'GRENOBLE' },
    38042: { agency: '4P', city: 'GRENOBLE' },
    38043: { agency: '4P', city: 'GRENOBLE' },
    38044: { agency: '4P', city: 'GRENOBLE' },
    38045: { agency: '4P', city: 'GRENOBLE' },
    38046: { agency: '4P', city: 'GRENOBLE' },
    38047: { agency: '4P', city: 'GRENOBLE' },
    38048: { agency: '4P', city: 'GRENOBLE' },
    38049: { agency: '4P', city: 'GRENOBLE' },
    38050: { agency: '4P', city: 'GRENOBLE' },
    38051: { agency: '4P', city: 'GRENOBLE' },
    38052: { agency: '4P', city: 'GRENOBLE' },
    38053: { agency: '4P', city: 'GRENOBLE' },
    38054: { agency: '4P', city: 'GRENOBLE' },
    38056: { agency: '4P', city: 'GRENOBLE' },
    38058: { agency: '4P', city: 'GRENOBLE' },
    38059: { agency: '4P', city: 'GRENOBLE' },
    38061: { agency: '4P', city: 'GRENOBLE' },
    38063: { agency: '4P', city: 'GRENOBLE' },
    38065: { agency: '4P', city: 'GRENOBLE' },
    38066: { agency: '4P', city: 'GRENOBLE' },
    38067: { agency: '4P', city: 'GRENOBLE' },
    38069: { agency: '4P', city: 'GRENOBLE' },
    38100: { agency: '4P', city: 'GRENOBLE' },
    38113: { agency: '4P', city: 'GRENOBLE' },
    38120: { agency: '4P', city: 'GRENOBLE' },
    38130: { agency: '4P', city: 'GRENOBLE' },
    38134: { agency: '4P', city: 'GRENOBLE' },
    38140: { agency: '4P', city: 'GRENOBLE' },
    38170: { agency: '4P', city: 'GRENOBLE' },
    38171: { agency: '4P', city: 'GRENOBLE' },
    38172: { agency: '4P', city: 'GRENOBLE' },
    38173: { agency: '4P', city: 'GRENOBLE' },
    38174: { agency: '4P', city: 'GRENOBLE' },
    38176: { agency: '4P', city: 'GRENOBLE' },
    38179: { agency: '4P', city: 'GRENOBLE' },
    38180: { agency: '4P', city: 'GRENOBLE' },
    38190: { agency: '4P', city: 'GRENOBLE' },
    38191: { agency: '4P', city: 'GRENOBLE' },
    38196: { agency: '4P', city: 'GRENOBLE' },
    38197: { agency: '4P', city: 'GRENOBLE' },
    38210: { agency: '4P', city: 'GRENOBLE' },
    38220: { agency: '4P', city: 'GRENOBLE' },
    38240: { agency: '4P', city: 'GRENOBLE' },
    38241: { agency: '4P', city: 'GRENOBLE' },
    38242: { agency: '4P', city: 'GRENOBLE' },
    38243: { agency: '4P', city: 'GRENOBLE' },
    38244: { agency: '4P', city: 'GRENOBLE' },
    38246: { agency: '4P', city: 'GRENOBLE' },
    38249: { agency: '4P', city: 'GRENOBLE' },
    38256: { agency: '4P', city: 'GRENOBLE' },
    38320: { agency: '4P', city: 'GRENOBLE' },
    38321: { agency: '4P', city: 'GRENOBLE' },
    38322: { agency: '4P', city: 'GRENOBLE' },
    38326: { agency: '4P', city: 'GRENOBLE' },
    38327: { agency: '4P', city: 'GRENOBLE' },
    38329: { agency: '4P', city: 'GRENOBLE' },
    38330: { agency: '4P', city: 'GRENOBLE' },
    38331: { agency: '4P', city: 'GRENOBLE' },
    38332: { agency: '4P', city: 'GRENOBLE' },
    38333: { agency: '4P', city: 'GRENOBLE' },
    38334: { agency: '4P', city: 'GRENOBLE' },
    38340: { agency: '4P', city: 'GRENOBLE' },
    38341: { agency: '4P', city: 'GRENOBLE' },
    38342: { agency: '4P', city: 'GRENOBLE' },
    38343: { agency: '4P', city: 'GRENOBLE' },
    38345: { agency: '4P', city: 'GRENOBLE' },
    38346: { agency: '4P', city: 'GRENOBLE' },
    38347: { agency: '4P', city: 'GRENOBLE' },
    38349: { agency: '4P', city: 'GRENOBLE' },
    38360: { agency: '4P', city: 'GRENOBLE' },
    38361: { agency: '4P', city: 'GRENOBLE' },
    38400: { agency: '4P', city: 'GRENOBLE' },
    38401: { agency: '4P', city: 'GRENOBLE' },
    38402: { agency: '4P', city: 'GRENOBLE' },
    38403: { agency: '4P', city: 'GRENOBLE' },
    38404: { agency: '4P', city: 'GRENOBLE' },
    38405: { agency: '4P', city: 'GRENOBLE' },
    38406: { agency: '4P', city: 'GRENOBLE' },
    38407: { agency: '4P', city: 'GRENOBLE' },
    38408: { agency: '4P', city: 'GRENOBLE' },
    38409: { agency: '4P', city: 'GRENOBLE' },
    38410: { agency: '4P', city: 'GRENOBLE' },
    38420: { agency: '4P', city: 'GRENOBLE' },
    38430: { agency: '4P', city: 'GRENOBLE' },
    38431: { agency: '4P', city: 'GRENOBLE' },
    38432: { agency: '4P', city: 'GRENOBLE' },
    38433: { agency: '4P', city: 'GRENOBLE' },
    38434: { agency: '4P', city: 'GRENOBLE' },
    38435: { agency: '4P', city: 'GRENOBLE' },
    38436: { agency: '4P', city: 'GRENOBLE' },
    38437: { agency: '4P', city: 'GRENOBLE' },
    38439: { agency: '4P', city: 'GRENOBLE' },
    38450: { agency: '4P', city: 'GRENOBLE' },
    38500: { agency: '4P', city: 'GRENOBLE' },
    38501: { agency: '4P', city: 'GRENOBLE' },
    38502: { agency: '4P', city: 'GRENOBLE' },
    38503: { agency: '4P', city: 'GRENOBLE' },
    38504: { agency: '4P', city: 'GRENOBLE' },
    38505: { agency: '4P', city: 'GRENOBLE' },
    38506: { agency: '4P', city: 'GRENOBLE' },
    38507: { agency: '4P', city: 'GRENOBLE' },
    38508: { agency: '4P', city: 'GRENOBLE' },
    38509: { agency: '4P', city: 'GRENOBLE' },
    38511: { agency: '4P', city: 'GRENOBLE' },
    38516: { agency: '4P', city: 'GRENOBLE' },
    38519: { agency: '4P', city: 'GRENOBLE' },
    38521: { agency: '4P', city: 'GRENOBLE' },
    38522: { agency: '4P', city: 'GRENOBLE' },
    38523: { agency: '4P', city: 'GRENOBLE' },
    38524: { agency: '4P', city: 'GRENOBLE' },
    38526: { agency: '4P', city: 'GRENOBLE' },
    38529: { agency: '4P', city: 'GRENOBLE' },
    38560: { agency: '4P', city: 'GRENOBLE' },
    38600: { agency: '4P', city: 'GRENOBLE' },
    38601: { agency: '4P', city: 'GRENOBLE' },
    38602: { agency: '4P', city: 'GRENOBLE' },
    38603: { agency: '4P', city: 'GRENOBLE' },
    38609: { agency: '4P', city: 'GRENOBLE' },
    38610: { agency: '4P', city: 'GRENOBLE' },
    38640: { agency: '4P', city: 'GRENOBLE' },
    38680: { agency: '4P', city: 'GRENOBLE' },
    38700: { agency: '4P', city: 'GRENOBLE' },
    38701: { agency: '4P', city: 'GRENOBLE' },
    38702: { agency: '4P', city: 'GRENOBLE' },
    38706: { agency: '4P', city: 'GRENOBLE' },
    38707: { agency: '4P', city: 'GRENOBLE' },
    38708: { agency: '4P', city: 'GRENOBLE' },
    38709: { agency: '4P', city: 'GRENOBLE' },
    38760: { agency: '4P', city: 'GRENOBLE' },
    38761: { agency: '4P', city: 'GRENOBLE' },
    38763: { agency: '4P', city: 'GRENOBLE' },
    38767: { agency: '4P', city: 'GRENOBLE' },
    38769: { agency: '4P', city: 'GRENOBLE' },
    38800: { agency: '4P', city: 'GRENOBLE' },
    38801: { agency: '4P', city: 'GRENOBLE' },
    38802: { agency: '4P', city: 'GRENOBLE' },
    38803: { agency: '4P', city: 'GRENOBLE' },
    38816: { agency: '4P', city: 'GRENOBLE' },
    38817: { agency: '4P', city: 'GRENOBLE' },
    38821: { agency: '4P', city: 'GRENOBLE' },
    38900: { agency: '4P', city: 'GRENOBLE' },
    38913: { agency: '4P', city: 'GRENOBLE' },
    38920: { agency: '4P', city: 'GRENOBLE' },
    38921: { agency: '4P', city: 'GRENOBLE' },
    38926: { agency: '4P', city: 'GRENOBLE' },
    38927: { agency: '4P', city: 'GRENOBLE' },
    38941: { agency: '4P', city: 'GRENOBLE' },
    38943: { agency: '4P', city: 'GRENOBLE' },
    38944: { agency: '4P', city: 'GRENOBLE' },
    38950: { agency: '4P', city: 'GRENOBLE' },
    38960: { agency: '4P', city: 'GRENOBLE' },
    28400: { agency: '2A', city: 'LE MANS' },
    49150: { agency: '2A', city: 'LE MANS' },
    49430: { agency: '2A', city: 'LE MANS' },
    49490: { agency: '2A', city: 'LE MANS' },
    49640: { agency: '2A', city: 'LE MANS' },
    53270: { agency: '2A', city: 'LE MANS' },
    53290: { agency: '2A', city: 'LE MANS' },
    53340: { agency: '2A', city: 'LE MANS' },
    72000: { agency: '2A', city: 'LE MANS' },
    72002: { agency: '2A', city: 'LE MANS' },
    72003: { agency: '2A', city: 'LE MANS' },
    72005: { agency: '2A', city: 'LE MANS' },
    72006: { agency: '2A', city: 'LE MANS' },
    72015: { agency: '2A', city: 'LE MANS' },
    72021: { agency: '2A', city: 'LE MANS' },
    72025: { agency: '2A', city: 'LE MANS' },
    72030: { agency: '2A', city: 'LE MANS' },
    72042: { agency: '2A', city: 'LE MANS' },
    72044: { agency: '2A', city: 'LE MANS' },
    72100: { agency: '2A', city: 'LE MANS' },
    72110: { agency: '2A', city: 'LE MANS' },
    72120: { agency: '2A', city: 'LE MANS' },
    72150: { agency: '2A', city: 'LE MANS' },
    72160: { agency: '2A', city: 'LE MANS' },
    72170: { agency: '2A', city: 'LE MANS' },
    72190: { agency: '2A', city: 'LE MANS' },
    72200: { agency: '2A', city: 'LE MANS' },
    72202: { agency: '2A', city: 'LE MANS' },
    72210: { agency: '2A', city: 'LE MANS' },
    72220: { agency: '2A', city: 'LE MANS' },
    72230: { agency: '2A', city: 'LE MANS' },
    72232: { agency: '2A', city: 'LE MANS' },
    72240: { agency: '2A', city: 'LE MANS' },
    72250: { agency: '2A', city: 'LE MANS' },
    72260: { agency: '2A', city: 'LE MANS' },
    72270: { agency: '2A', city: 'LE MANS' },
    72290: { agency: '2A', city: 'LE MANS' },
    72300: { agency: '2A', city: 'LE MANS' },
    72302: { agency: '2A', city: 'LE MANS' },
    72310: { agency: '2A', city: 'LE MANS' },
    72320: { agency: '2A', city: 'LE MANS' },
    72330: { agency: '2A', city: 'LE MANS' },
    72340: { agency: '2A', city: 'LE MANS' },
    72350: { agency: '2A', city: 'LE MANS' },
    72360: { agency: '2A', city: 'LE MANS' },
    72370: { agency: '2A', city: 'LE MANS' },
    72380: { agency: '2A', city: 'LE MANS' },
    72390: { agency: '2A', city: 'LE MANS' },
    72400: { agency: '2A', city: 'LE MANS' },
    72430: { agency: '2A', city: 'LE MANS' },
    72440: { agency: '2A', city: 'LE MANS' },
    72450: { agency: '2A', city: 'LE MANS' },
    72460: { agency: '2A', city: 'LE MANS' },
    72470: { agency: '2A', city: 'LE MANS' },
    72500: { agency: '2A', city: 'LE MANS' },
    72510: { agency: '2A', city: 'LE MANS' },
    72530: { agency: '2A', city: 'LE MANS' },
    72540: { agency: '2A', city: 'LE MANS' },
    72550: { agency: '2A', city: 'LE MANS' },
    72560: { agency: '2A', city: 'LE MANS' },
    72590: { agency: '2A', city: 'LE MANS' },
    72650: { agency: '2A', city: 'LE MANS' },
    72670: { agency: '2A', city: 'LE MANS' },
    72700: { agency: '2A', city: 'LE MANS' },
    72800: { agency: '2A', city: 'LE MANS' },
    7000: { agency: '4Y', city: 'LORIOL' },
    7001: { agency: '4Y', city: 'LORIOL' },
    7002: { agency: '4Y', city: 'LORIOL' },
    7003: { agency: '4Y', city: 'LORIOL' },
    7004: { agency: '4Y', city: 'LORIOL' },
    7005: { agency: '4Y', city: 'LORIOL' },
    7006: { agency: '4Y', city: 'LORIOL' },
    7007: { agency: '4Y', city: 'LORIOL' },
    7120: { agency: '4Y', city: 'LORIOL' },
    7130: { agency: '4Y', city: 'LORIOL' },
    7131: { agency: '4Y', city: 'LORIOL' },
    7132: { agency: '4Y', city: 'LORIOL' },
    7133: { agency: '4Y', city: 'LORIOL' },
    7134: { agency: '4Y', city: 'LORIOL' },
    7135: { agency: '4Y', city: 'LORIOL' },
    7136: { agency: '4Y', city: 'LORIOL' },
    7137: { agency: '4Y', city: 'LORIOL' },
    7138: { agency: '4Y', city: 'LORIOL' },
    7139: { agency: '4Y', city: 'LORIOL' },
    7150: { agency: '4Y', city: 'LORIOL' },
    7170: { agency: '4Y', city: 'LORIOL' },
    7190: { agency: '4Y', city: 'LORIOL' },
    7200: { agency: '4Y', city: 'LORIOL' },
    7201: { agency: '4Y', city: 'LORIOL' },
    7202: { agency: '4Y', city: 'LORIOL' },
    7203: { agency: '4Y', city: 'LORIOL' },
    7204: { agency: '4Y', city: 'LORIOL' },
    7205: { agency: '4Y', city: 'LORIOL' },
    7206: { agency: '4Y', city: 'LORIOL' },
    7207: { agency: '4Y', city: 'LORIOL' },
    7208: { agency: '4Y', city: 'LORIOL' },
    7209: { agency: '4Y', city: 'LORIOL' },
    7210: { agency: '4Y', city: 'LORIOL' },
    7220: { agency: '4Y', city: 'LORIOL' },
    7250: { agency: '4Y', city: 'LORIOL' },
    7350: { agency: '4Y', city: 'LORIOL' },
    7400: { agency: '4Y', city: 'LORIOL' },
    7401: { agency: '4Y', city: 'LORIOL' },
    7402: { agency: '4Y', city: 'LORIOL' },
    7403: { agency: '4Y', city: 'LORIOL' },
    7404: { agency: '4Y', city: 'LORIOL' },
    7405: { agency: '4Y', city: 'LORIOL' },
    7406: { agency: '4Y', city: 'LORIOL' },
    7407: { agency: '4Y', city: 'LORIOL' },
    7408: { agency: '4Y', city: 'LORIOL' },
    7409: { agency: '4Y', city: 'LORIOL' },
    7500: { agency: '4Y', city: 'LORIOL' },
    7501: { agency: '4Y', city: 'LORIOL' },
    7502: { agency: '4Y', city: 'LORIOL' },
    7503: { agency: '4Y', city: 'LORIOL' },
    7504: { agency: '4Y', city: 'LORIOL' },
    7505: { agency: '4Y', city: 'LORIOL' },
    7506: { agency: '4Y', city: 'LORIOL' },
    7507: { agency: '4Y', city: 'LORIOL' },
    7508: { agency: '4Y', city: 'LORIOL' },
    7509: { agency: '4Y', city: 'LORIOL' },
    7580: { agency: '4Y', city: 'LORIOL' },
    7700: { agency: '4Y', city: 'LORIOL' },
    7800: { agency: '4Y', city: 'LORIOL' },
    26000: { agency: '4Y', city: 'LORIOL' },
    26001: { agency: '4Y', city: 'LORIOL' },
    26002: { agency: '4Y', city: 'LORIOL' },
    26003: { agency: '4Y', city: 'LORIOL' },
    26004: { agency: '4Y', city: 'LORIOL' },
    26005: { agency: '4Y', city: 'LORIOL' },
    26006: { agency: '4Y', city: 'LORIOL' },
    26007: { agency: '4Y', city: 'LORIOL' },
    26008: { agency: '4Y', city: 'LORIOL' },
    26009: { agency: '4Y', city: 'LORIOL' },
    26010: { agency: '4Y', city: 'LORIOL' },
    26011: { agency: '4Y', city: 'LORIOL' },
    26012: { agency: '4Y', city: 'LORIOL' },
    26013: { agency: '4Y', city: 'LORIOL' },
    26014: { agency: '4Y', city: 'LORIOL' },
    26015: { agency: '4Y', city: 'LORIOL' },
    26021: { agency: '4Y', city: 'LORIOL' },
    26022: { agency: '4Y', city: 'LORIOL' },
    26023: { agency: '4Y', city: 'LORIOL' },
    26024: { agency: '4Y', city: 'LORIOL' },
    26025: { agency: '4Y', city: 'LORIOL' },
    26026: { agency: '4Y', city: 'LORIOL' },
    26027: { agency: '4Y', city: 'LORIOL' },
    26028: { agency: '4Y', city: 'LORIOL' },
    26029: { agency: '4Y', city: 'LORIOL' },
    26030: { agency: '4Y', city: 'LORIOL' },
    26031: { agency: '4Y', city: 'LORIOL' },
    26032: { agency: '4Y', city: 'LORIOL' },
    26110: { agency: '4Y', city: 'LORIOL' },
    26130: { agency: '4Y', city: 'LORIOL' },
    26131: { agency: '4Y', city: 'LORIOL' },
    26160: { agency: '4Y', city: 'LORIOL' },
    26200: { agency: '4Y', city: 'LORIOL' },
    26201: { agency: '4Y', city: 'LORIOL' },
    26202: { agency: '4Y', city: 'LORIOL' },
    26203: { agency: '4Y', city: 'LORIOL' },
    26204: { agency: '4Y', city: 'LORIOL' },
    26205: { agency: '4Y', city: 'LORIOL' },
    26206: { agency: '4Y', city: 'LORIOL' },
    26207: { agency: '4Y', city: 'LORIOL' },
    26208: { agency: '4Y', city: 'LORIOL' },
    26209: { agency: '4Y', city: 'LORIOL' },
    26215: { agency: '4Y', city: 'LORIOL' },
    26216: { agency: '4Y', city: 'LORIOL' },
    26230: { agency: '4Y', city: 'LORIOL' },
    26250: { agency: '4Y', city: 'LORIOL' },
    26270: { agency: '4Y', city: 'LORIOL' },
    26290: { agency: '4Y', city: 'LORIOL' },
    26400: { agency: '4Y', city: 'LORIOL' },
    26401: { agency: '4Y', city: 'LORIOL' },
    26402: { agency: '4Y', city: 'LORIOL' },
    26409: { agency: '4Y', city: 'LORIOL' },
    26450: { agency: '4Y', city: 'LORIOL' },
    26700: { agency: '4Y', city: 'LORIOL' },
    26701: { agency: '4Y', city: 'LORIOL' },
    26702: { agency: '4Y', city: 'LORIOL' },
    26709: { agency: '4Y', city: 'LORIOL' },
    26740: { agency: '4Y', city: 'LORIOL' },
    26760: { agency: '4Y', city: 'LORIOL' },
    26761: { agency: '4Y', city: 'LORIOL' },
    26770: { agency: '4Y', city: 'LORIOL' },
    26780: { agency: '4Y', city: 'LORIOL' },
    26800: { agency: '4Y', city: 'LORIOL' },
    26801: { agency: '4Y', city: 'LORIOL' },
    26802: { agency: '4Y', city: 'LORIOL' },
    26809: { agency: '4Y', city: 'LORIOL' },
    26901: { agency: '4Y', city: 'LORIOL' },
    26902: { agency: '4Y', city: 'LORIOL' },
    26903: { agency: '4Y', city: 'LORIOL' },
    26904: { agency: '4Y', city: 'LORIOL' },
    26905: { agency: '4Y', city: 'LORIOL' },
    26906: { agency: '4Y', city: 'LORIOL' },
    26907: { agency: '4Y', city: 'LORIOL' },
    26909: { agency: '4Y', city: 'LORIOL' },
    26950: { agency: '4Y', city: 'LORIOL' },
    26951: { agency: '4Y', city: 'LORIOL' },
    26952: { agency: '4Y', city: 'LORIOL' },
    26953: { agency: '4Y', city: 'LORIOL' },
    26954: { agency: '4Y', city: 'LORIOL' },
    26955: { agency: '4Y', city: 'LORIOL' },
    26956: { agency: '4Y', city: 'LORIOL' },
    26958: { agency: '4Y', city: 'LORIOL' },
    84500: { agency: '4Y', city: 'LORIOL' },
    84600: { agency: '4Y', city: 'LORIOL' },
    84840: { agency: '4Y', city: 'LORIOL' },
    30220: { agency: '5C', city: 'MONTPELLIER' },
    30240: { agency: '5C', city: 'MONTPELLIER' },
    30250: { agency: '5C', city: 'MONTPELLIER' },
    30251: { agency: '5C', city: 'MONTPELLIER' },
    30252: { agency: '5C', city: 'MONTPELLIER' },
    30253: { agency: '5C', city: 'MONTPELLIER' },
    30255: { agency: '5C', city: 'MONTPELLIER' },
    30260: { agency: '5C', city: 'MONTPELLIER' },
    30660: { agency: '5C', city: 'MONTPELLIER' },
    34000: { agency: '5C', city: 'MONTPELLIER' },
    34001: { agency: '5C', city: 'MONTPELLIER' },
    34002: { agency: '5C', city: 'MONTPELLIER' },
    34003: { agency: '5C', city: 'MONTPELLIER' },
    34004: { agency: '5C', city: 'MONTPELLIER' },
    34006: { agency: '5C', city: 'MONTPELLIER' },
    34007: { agency: '5C', city: 'MONTPELLIER' },
    34008: { agency: '5C', city: 'MONTPELLIER' },
    34009: { agency: '5C', city: 'MONTPELLIER' },
    34010: { agency: '5C', city: 'MONTPELLIER' },
    34011: { agency: '5C', city: 'MONTPELLIER' },
    34012: { agency: '5C', city: 'MONTPELLIER' },
    34023: { agency: '5C', city: 'MONTPELLIER' },
    34024: { agency: '5C', city: 'MONTPELLIER' },
    34025: { agency: '5C', city: 'MONTPELLIER' },
    34026: { agency: '5C', city: 'MONTPELLIER' },
    34027: { agency: '5C', city: 'MONTPELLIER' },
    34028: { agency: '5C', city: 'MONTPELLIER' },
    34030: { agency: '5C', city: 'MONTPELLIER' },
    34032: { agency: '5C', city: 'MONTPELLIER' },
    34033: { agency: '5C', city: 'MONTPELLIER' },
    34034: { agency: '5C', city: 'MONTPELLIER' },
    34035: { agency: '5C', city: 'MONTPELLIER' },
    34037: { agency: '5C', city: 'MONTPELLIER' },
    34038: { agency: '5C', city: 'MONTPELLIER' },
    34039: { agency: '5C', city: 'MONTPELLIER' },
    34040: { agency: '5C', city: 'MONTPELLIER' },
    34041: { agency: '5C', city: 'MONTPELLIER' },
    34042: { agency: '5C', city: 'MONTPELLIER' },
    34043: { agency: '5C', city: 'MONTPELLIER' },
    34045: { agency: '5C', city: 'MONTPELLIER' },
    34046: { agency: '5C', city: 'MONTPELLIER' },
    34048: { agency: '5C', city: 'MONTPELLIER' },
    34049: { agency: '5C', city: 'MONTPELLIER' },
    34051: { agency: '5C', city: 'MONTPELLIER' },
    34053: { agency: '5C', city: 'MONTPELLIER' },
    34054: { agency: '5C', city: 'MONTPELLIER' },
    34055: { agency: '5C', city: 'MONTPELLIER' },
    34056: { agency: '5C', city: 'MONTPELLIER' },
    34058: { agency: '5C', city: 'MONTPELLIER' },
    34060: { agency: '5C', city: 'MONTPELLIER' },
    34061: { agency: '5C', city: 'MONTPELLIER' },
    34062: { agency: '5C', city: 'MONTPELLIER' },
    34063: { agency: '5C', city: 'MONTPELLIER' },
    34064: { agency: '5C', city: 'MONTPELLIER' },
    34065: { agency: '5C', city: 'MONTPELLIER' },
    34066: { agency: '5C', city: 'MONTPELLIER' },
    34067: { agency: '5C', city: 'MONTPELLIER' },
    34068: { agency: '5C', city: 'MONTPELLIER' },
    34070: { agency: '5C', city: 'MONTPELLIER' },
    34071: { agency: '5C', city: 'MONTPELLIER' },
    34072: { agency: '5C', city: 'MONTPELLIER' },
    34073: { agency: '5C', city: 'MONTPELLIER' },
    34074: { agency: '5C', city: 'MONTPELLIER' },
    34075: { agency: '5C', city: 'MONTPELLIER' },
    34076: { agency: '5C', city: 'MONTPELLIER' },
    34077: { agency: '5C', city: 'MONTPELLIER' },
    34078: { agency: '5C', city: 'MONTPELLIER' },
    34079: { agency: '5C', city: 'MONTPELLIER' },
    34080: { agency: '5C', city: 'MONTPELLIER' },
    34082: { agency: '5C', city: 'MONTPELLIER' },
    34083: { agency: '5C', city: 'MONTPELLIER' },
    34085: { agency: '5C', city: 'MONTPELLIER' },
    34086: { agency: '5C', city: 'MONTPELLIER' },
    34087: { agency: '5C', city: 'MONTPELLIER' },
    34089: { agency: '5C', city: 'MONTPELLIER' },
    34090: { agency: '5C', city: 'MONTPELLIER' },
    34091: { agency: '5C', city: 'MONTPELLIER' },
    34092: { agency: '5C', city: 'MONTPELLIER' },
    34093: { agency: '5C', city: 'MONTPELLIER' },
    34094: { agency: '5C', city: 'MONTPELLIER' },
    34095: { agency: '5C', city: 'MONTPELLIER' },
    34096: { agency: '5C', city: 'MONTPELLIER' },
    34097: { agency: '5C', city: 'MONTPELLIER' },
    34098: { agency: '5C', city: 'MONTPELLIER' },
    34099: { agency: '5C', city: 'MONTPELLIER' },
    34110: { agency: '5C', city: 'MONTPELLIER' },
    34111: { agency: '5C', city: 'MONTPELLIER' },
    34112: { agency: '5C', city: 'MONTPELLIER' },
    34113: { agency: '5C', city: 'MONTPELLIER' },
    34114: { agency: '5C', city: 'MONTPELLIER' },
    34115: { agency: '5C', city: 'MONTPELLIER' },
    34116: { agency: '5C', city: 'MONTPELLIER' },
    34117: { agency: '5C', city: 'MONTPELLIER' },
    34118: { agency: '5C', city: 'MONTPELLIER' },
    34119: { agency: '5C', city: 'MONTPELLIER' },
    34130: { agency: '5C', city: 'MONTPELLIER' },
    34131: { agency: '5C', city: 'MONTPELLIER' },
    34132: { agency: '5C', city: 'MONTPELLIER' },
    34137: { agency: '5C', city: 'MONTPELLIER' },
    34138: { agency: '5C', city: 'MONTPELLIER' },
    34139: { agency: '5C', city: 'MONTPELLIER' },
    34140: { agency: '5C', city: 'MONTPELLIER' },
    34150: { agency: '5C', city: 'MONTPELLIER' },
    34160: { agency: '5C', city: 'MONTPELLIER' },
    34170: { agency: '5C', city: 'MONTPELLIER' },
    34171: { agency: '5C', city: 'MONTPELLIER' },
    34172: { agency: '5C', city: 'MONTPELLIER' },
    34173: { agency: '5C', city: 'MONTPELLIER' },
    34174: { agency: '5C', city: 'MONTPELLIER' },
    34178: { agency: '5C', city: 'MONTPELLIER' },
    34179: { agency: '5C', city: 'MONTPELLIER' },
    34181: { agency: '5C', city: 'MONTPELLIER' },
    34183: { agency: '5C', city: 'MONTPELLIER' },
    34184: { agency: '5C', city: 'MONTPELLIER' },
    34186: { agency: '5C', city: 'MONTPELLIER' },
    34187: { agency: '5C', city: 'MONTPELLIER' },
    34190: { agency: '5C', city: 'MONTPELLIER' },
    34191: { agency: '5C', city: 'MONTPELLIER' },
    34192: { agency: '5C', city: 'MONTPELLIER' },
    34193: { agency: '5C', city: 'MONTPELLIER' },
    34194: { agency: '5C', city: 'MONTPELLIER' },
    34195: { agency: '5C', city: 'MONTPELLIER' },
    34196: { agency: '5C', city: 'MONTPELLIER' },
    34197: { agency: '5C', city: 'MONTPELLIER' },
    34198: { agency: '5C', city: 'MONTPELLIER' },
    34199: { agency: '5C', city: 'MONTPELLIER' },
    34200: { agency: '5C', city: 'MONTPELLIER' },
    34230: { agency: '5C', city: 'MONTPELLIER' },
    34250: { agency: '5C', city: 'MONTPELLIER' },
    34261: { agency: '5C', city: 'MONTPELLIER' },
    34262: { agency: '5C', city: 'MONTPELLIER' },
    34263: { agency: '5C', city: 'MONTPELLIER' },
    34264: { agency: '5C', city: 'MONTPELLIER' },
    34265: { agency: '5C', city: 'MONTPELLIER' },
    34266: { agency: '5C', city: 'MONTPELLIER' },
    34267: { agency: '5C', city: 'MONTPELLIER' },
    34270: { agency: '5C', city: 'MONTPELLIER' },
    34274: { agency: '5C', city: 'MONTPELLIER' },
    34280: { agency: '5C', city: 'MONTPELLIER' },
    34293: { agency: '5C', city: 'MONTPELLIER' },
    34294: { agency: '5C', city: 'MONTPELLIER' },
    34295: { agency: '5C', city: 'MONTPELLIER' },
    34296: { agency: '5C', city: 'MONTPELLIER' },
    34297: { agency: '5C', city: 'MONTPELLIER' },
    34298: { agency: '5C', city: 'MONTPELLIER' },
    34299: { agency: '5C', city: 'MONTPELLIER' },
    34380: { agency: '5C', city: 'MONTPELLIER' },
    34391: { agency: '5C', city: 'MONTPELLIER' },
    34392: { agency: '5C', city: 'MONTPELLIER' },
    34393: { agency: '5C', city: 'MONTPELLIER' },
    34394: { agency: '5C', city: 'MONTPELLIER' },
    34396: { agency: '5C', city: 'MONTPELLIER' },
    34397: { agency: '5C', city: 'MONTPELLIER' },
    34398: { agency: '5C', city: 'MONTPELLIER' },
    34399: { agency: '5C', city: 'MONTPELLIER' },
    34400: { agency: '5C', city: 'MONTPELLIER' },
    34401: { agency: '5C', city: 'MONTPELLIER' },
    34402: { agency: '5C', city: 'MONTPELLIER' },
    34403: { agency: '5C', city: 'MONTPELLIER' },
    34404: { agency: '5C', city: 'MONTPELLIER' },
    34409: { agency: '5C', city: 'MONTPELLIER' },
    34430: { agency: '5C', city: 'MONTPELLIER' },
    34431: { agency: '5C', city: 'MONTPELLIER' },
    34433: { agency: '5C', city: 'MONTPELLIER' },
    34434: { agency: '5C', city: 'MONTPELLIER' },
    34435: { agency: '5C', city: 'MONTPELLIER' },
    34438: { agency: '5C', city: 'MONTPELLIER' },
    34439: { agency: '5C', city: 'MONTPELLIER' },
    34470: { agency: '5C', city: 'MONTPELLIER' },
    34473: { agency: '5C', city: 'MONTPELLIER' },
    34477: { agency: '5C', city: 'MONTPELLIER' },
    34530: { agency: '5C', city: 'MONTPELLIER' },
    34540: { agency: '5C', city: 'MONTPELLIER' },
    34560: { agency: '5C', city: 'MONTPELLIER' },
    34570: { agency: '5C', city: 'MONTPELLIER' },
    34590: { agency: '5C', city: 'MONTPELLIER' },
    34660: { agency: '5C', city: 'MONTPELLIER' },
    34670: { agency: '5C', city: 'MONTPELLIER' },
    34671: { agency: '5C', city: 'MONTPELLIER' },
    34680: { agency: '5C', city: 'MONTPELLIER' },
    34690: { agency: '5C', city: 'MONTPELLIER' },
    34725: { agency: '5C', city: 'MONTPELLIER' },
    34730: { agency: '5C', city: 'MONTPELLIER' },
    34740: { agency: '5C', city: 'MONTPELLIER' },
    34741: { agency: '5C', city: 'MONTPELLIER' },
    34742: { agency: '5C', city: 'MONTPELLIER' },
    34747: { agency: '5C', city: 'MONTPELLIER' },
    34748: { agency: '5C', city: 'MONTPELLIER' },
    34749: { agency: '5C', city: 'MONTPELLIER' },
    34750: { agency: '5C', city: 'MONTPELLIER' },
    34751: { agency: '5C', city: 'MONTPELLIER' },
    34753: { agency: '5C', city: 'MONTPELLIER' },
    34770: { agency: '5C', city: 'MONTPELLIER' },
    34790: { agency: '5C', city: 'MONTPELLIER' },
    34800: { agency: '5C', city: 'MONTPELLIER' },
    34820: { agency: '5C', city: 'MONTPELLIER' },
    34830: { agency: '5C', city: 'MONTPELLIER' },
    34871: { agency: '5C', city: 'MONTPELLIER' },
    34872: { agency: '5C', city: 'MONTPELLIER' },
    34873: { agency: '5C', city: 'MONTPELLIER' },
    34874: { agency: '5C', city: 'MONTPELLIER' },
    34875: { agency: '5C', city: 'MONTPELLIER' },
    34880: { agency: '5C', city: 'MONTPELLIER' },
    34900: { agency: '5C', city: 'MONTPELLIER' },
    34920: { agency: '5C', city: 'MONTPELLIER' },
    34923: { agency: '5C', city: 'MONTPELLIER' },
    34925: { agency: '5C', city: 'MONTPELLIER' },
    34926: { agency: '5C', city: 'MONTPELLIER' },
    34932: { agency: '5C', city: 'MONTPELLIER' },
    34933: { agency: '5C', city: 'MONTPELLIER' },
    34934: { agency: '5C', city: 'MONTPELLIER' },
    34935: { agency: '5C', city: 'MONTPELLIER' },
    34937: { agency: '5C', city: 'MONTPELLIER' },
    34938: { agency: '5C', city: 'MONTPELLIER' },
    34939: { agency: '5C', city: 'MONTPELLIER' },
    34940: { agency: '5C', city: 'MONTPELLIER' },
    34942: { agency: '5C', city: 'MONTPELLIER' },
    34943: { agency: '5C', city: 'MONTPELLIER' },
    34944: { agency: '5C', city: 'MONTPELLIER' },
    34948: { agency: '5C', city: 'MONTPELLIER' },
    34949: { agency: '5C', city: 'MONTPELLIER' },
    34954: { agency: '5C', city: 'MONTPELLIER' },
    34955: { agency: '5C', city: 'MONTPELLIER' },
    34956: { agency: '5C', city: 'MONTPELLIER' },
    34957: { agency: '5C', city: 'MONTPELLIER' },
    34958: { agency: '5C', city: 'MONTPELLIER' },
    34959: { agency: '5C', city: 'MONTPELLIER' },
    34960: { agency: '5C', city: 'MONTPELLIER' },
    34961: { agency: '5C', city: 'MONTPELLIER' },
    34962: { agency: '5C', city: 'MONTPELLIER' },
    34964: { agency: '5C', city: 'MONTPELLIER' },
    34965: { agency: '5C', city: 'MONTPELLIER' },
    34967: { agency: '5C', city: 'MONTPELLIER' },
    34970: { agency: '5C', city: 'MONTPELLIER' },
    34972: { agency: '5C', city: 'MONTPELLIER' },
    34973: { agency: '5C', city: 'MONTPELLIER' },
    34974: { agency: '5C', city: 'MONTPELLIER' },
    34975: { agency: '5C', city: 'MONTPELLIER' },
    34977: { agency: '5C', city: 'MONTPELLIER' },
    34980: { agency: '5C', city: 'MONTPELLIER' },
    34981: { agency: '5C', city: 'MONTPELLIER' },
    34982: { agency: '5C', city: 'MONTPELLIER' },
    34983: { agency: '5C', city: 'MONTPELLIER' },
    34984: { agency: '5C', city: 'MONTPELLIER' },
    34985: { agency: '5C', city: 'MONTPELLIER' },
    34986: { agency: '5C', city: 'MONTPELLIER' },
    34987: { agency: '5C', city: 'MONTPELLIER' },
    34989: { agency: '5C', city: 'MONTPELLIER' },
    34990: { agency: '5C', city: 'MONTPELLIER' },
    17000: { agency: '5I', city: 'PERIGNY' },
    17001: { agency: '5I', city: 'PERIGNY' },
    17002: { agency: '5I', city: 'PERIGNY' },
    17003: { agency: '5I', city: 'PERIGNY' },
    17004: { agency: '5I', city: 'PERIGNY' },
    17005: { agency: '5I', city: 'PERIGNY' },
    17006: { agency: '5I', city: 'PERIGNY' },
    17007: { agency: '5I', city: 'PERIGNY' },
    17008: { agency: '5I', city: 'PERIGNY' },
    17009: { agency: '5I', city: 'PERIGNY' },
    17010: { agency: '5I', city: 'PERIGNY' },
    17011: { agency: '5I', city: 'PERIGNY' },
    17012: { agency: '5I', city: 'PERIGNY' },
    17013: { agency: '5I', city: 'PERIGNY' },
    17014: { agency: '5I', city: 'PERIGNY' },
    17015: { agency: '5I', city: 'PERIGNY' },
    17016: { agency: '5I', city: 'PERIGNY' },
    17017: { agency: '5I', city: 'PERIGNY' },
    17018: { agency: '5I', city: 'PERIGNY' },
    17019: { agency: '5I', city: 'PERIGNY' },
    17020: { agency: '5I', city: 'PERIGNY' },
    17021: { agency: '5I', city: 'PERIGNY' },
    17022: { agency: '5I', city: 'PERIGNY' },
    17023: { agency: '5I', city: 'PERIGNY' },
    17024: { agency: '5I', city: 'PERIGNY' },
    17025: { agency: '5I', city: 'PERIGNY' },
    17026: { agency: '5I', city: 'PERIGNY' },
    17027: { agency: '5I', city: 'PERIGNY' },
    17028: { agency: '5I', city: 'PERIGNY' },
    17029: { agency: '5I', city: 'PERIGNY' },
    17030: { agency: '5I', city: 'PERIGNY' },
    17031: { agency: '5I', city: 'PERIGNY' },
    17032: { agency: '5I', city: 'PERIGNY' },
    17033: { agency: '5I', city: 'PERIGNY' },
    17034: { agency: '5I', city: 'PERIGNY' },
    17035: { agency: '5I', city: 'PERIGNY' },
    17036: { agency: '5I', city: 'PERIGNY' },
    17037: { agency: '5I', city: 'PERIGNY' },
    17038: { agency: '5I', city: 'PERIGNY' },
    17039: { agency: '5I', city: 'PERIGNY' },
    17040: { agency: '5I', city: 'PERIGNY' },
    17041: { agency: '5I', city: 'PERIGNY' },
    17042: { agency: '5I', city: 'PERIGNY' },
    17043: { agency: '5I', city: 'PERIGNY' },
    17044: { agency: '5I', city: 'PERIGNY' },
    17045: { agency: '5I', city: 'PERIGNY' },
    17051: { agency: '5I', city: 'PERIGNY' },
    17052: { agency: '5I', city: 'PERIGNY' },
    17053: { agency: '5I', city: 'PERIGNY' },
    17054: { agency: '5I', city: 'PERIGNY' },
    17055: { agency: '5I', city: 'PERIGNY' },
    17056: { agency: '5I', city: 'PERIGNY' },
    17071: { agency: '5I', city: 'PERIGNY' },
    17072: { agency: '5I', city: 'PERIGNY' },
    17073: { agency: '5I', city: 'PERIGNY' },
    17074: { agency: '5I', city: 'PERIGNY' },
    17075: { agency: '5I', city: 'PERIGNY' },
    17076: { agency: '5I', city: 'PERIGNY' },
    17078: { agency: '5I', city: 'PERIGNY' },
    17079: { agency: '5I', city: 'PERIGNY' },
    17086: { agency: '5I', city: 'PERIGNY' },
    17087: { agency: '5I', city: 'PERIGNY' },
    17088: { agency: '5I', city: 'PERIGNY' },
    17089: { agency: '5I', city: 'PERIGNY' },
    17111: { agency: '5I', city: 'PERIGNY' },
    17137: { agency: '5I', city: 'PERIGNY' },
    17138: { agency: '5I', city: 'PERIGNY' },
    17139: { agency: '5I', city: 'PERIGNY' },
    17140: { agency: '5I', city: 'PERIGNY' },
    17170: { agency: '5I', city: 'PERIGNY' },
    17180: { agency: '5I', city: 'PERIGNY' },
    17181: { agency: '5I', city: 'PERIGNY' },
    17182: { agency: '5I', city: 'PERIGNY' },
    17183: { agency: '5I', city: 'PERIGNY' },
    17184: { agency: '5I', city: 'PERIGNY' },
    17185: { agency: '5I', city: 'PERIGNY' },
    17186: { agency: '5I', city: 'PERIGNY' },
    17187: { agency: '5I', city: 'PERIGNY' },
    17188: { agency: '5I', city: 'PERIGNY' },
    17189: { agency: '5I', city: 'PERIGNY' },
    17220: { agency: '5I', city: 'PERIGNY' },
    17230: { agency: '5I', city: 'PERIGNY' },
    17281: { agency: '5I', city: 'PERIGNY' },
    17284: { agency: '5I', city: 'PERIGNY' },
    17285: { agency: '5I', city: 'PERIGNY' },
    17289: { agency: '5I', city: 'PERIGNY' },
    17410: { agency: '5I', city: 'PERIGNY' },
    17441: { agency: '5I', city: 'PERIGNY' },
    17442: { agency: '5I', city: 'PERIGNY' },
    17443: { agency: '5I', city: 'PERIGNY' },
    17444: { agency: '5I', city: 'PERIGNY' },
    17445: { agency: '5I', city: 'PERIGNY' },
    17446: { agency: '5I', city: 'PERIGNY' },
    17449: { agency: '5I', city: 'PERIGNY' },
    17540: { agency: '5I', city: 'PERIGNY' },
    17580: { agency: '5I', city: 'PERIGNY' },
    17590: { agency: '5I', city: 'PERIGNY' },
    17630: { agency: '5I', city: 'PERIGNY' },
    17670: { agency: '5I', city: 'PERIGNY' },
    17690: { agency: '5I', city: 'PERIGNY' },
    17740: { agency: '5I', city: 'PERIGNY' },
    17880: { agency: '5I', city: 'PERIGNY' },
    17940: { agency: '5I', city: 'PERIGNY' },
    85450: { agency: '5I', city: 'PERIGNY' },
    85770: { agency: '5I', city: 'PERIGNY' },
    79120: { agency: '3D', city: 'POITIERS' },
    79200: { agency: '3D', city: 'POITIERS' },
    79340: { agency: '3D', city: 'POITIERS' },
    79390: { agency: '3D', city: 'POITIERS' },
    79400: { agency: '3D', city: 'POITIERS' },
    79420: { agency: '3D', city: 'POITIERS' },
    79600: { agency: '3D', city: 'POITIERS' },
    79800: { agency: '3D', city: 'POITIERS' },
    86000: { agency: '3D', city: 'POITIERS' },
    86001: { agency: '3D', city: 'POITIERS' },
    86002: { agency: '3D', city: 'POITIERS' },
    86003: { agency: '3D', city: 'POITIERS' },
    86004: { agency: '3D', city: 'POITIERS' },
    86005: { agency: '3D', city: 'POITIERS' },
    86006: { agency: '3D', city: 'POITIERS' },
    86007: { agency: '3D', city: 'POITIERS' },
    86008: { agency: '3D', city: 'POITIERS' },
    86009: { agency: '3D', city: 'POITIERS' },
    86010: { agency: '3D', city: 'POITIERS' },
    86011: { agency: '3D', city: 'POITIERS' },
    86012: { agency: '3D', city: 'POITIERS' },
    86013: { agency: '3D', city: 'POITIERS' },
    86020: { agency: '3D', city: 'POITIERS' },
    86021: { agency: '3D', city: 'POITIERS' },
    86022: { agency: '3D', city: 'POITIERS' },
    86023: { agency: '3D', city: 'POITIERS' },
    86029: { agency: '3D', city: 'POITIERS' },
    86030: { agency: '3D', city: 'POITIERS' },
    86034: { agency: '3D', city: 'POITIERS' },
    86035: { agency: '3D', city: 'POITIERS' },
    86036: { agency: '3D', city: 'POITIERS' },
    86038: { agency: '3D', city: 'POITIERS' },
    86042: { agency: '3D', city: 'POITIERS' },
    86043: { agency: '3D', city: 'POITIERS' },
    86044: { agency: '3D', city: 'POITIERS' },
    86045: { agency: '3D', city: 'POITIERS' },
    86046: { agency: '3D', city: 'POITIERS' },
    86047: { agency: '3D', city: 'POITIERS' },
    86050: { agency: '3D', city: 'POITIERS' },
    86051: { agency: '3D', city: 'POITIERS' },
    86052: { agency: '3D', city: 'POITIERS' },
    86053: { agency: '3D', city: 'POITIERS' },
    86054: { agency: '3D', city: 'POITIERS' },
    86055: { agency: '3D', city: 'POITIERS' },
    86056: { agency: '3D', city: 'POITIERS' },
    86060: { agency: '3D', city: 'POITIERS' },
    86061: { agency: '3D', city: 'POITIERS' },
    86062: { agency: '3D', city: 'POITIERS' },
    86063: { agency: '3D', city: 'POITIERS' },
    86065: { agency: '3D', city: 'POITIERS' },
    86066: { agency: '3D', city: 'POITIERS' },
    86067: { agency: '3D', city: 'POITIERS' },
    86068: { agency: '3D', city: 'POITIERS' },
    86069: { agency: '3D', city: 'POITIERS' },
    86071: { agency: '3D', city: 'POITIERS' },
    86072: { agency: '3D', city: 'POITIERS' },
    86073: { agency: '3D', city: 'POITIERS' },
    86074: { agency: '3D', city: 'POITIERS' },
    86076: { agency: '3D', city: 'POITIERS' },
    86080: { agency: '3D', city: 'POITIERS' },
    86081: { agency: '3D', city: 'POITIERS' },
    86082: { agency: '3D', city: 'POITIERS' },
    86083: { agency: '3D', city: 'POITIERS' },
    86084: { agency: '3D', city: 'POITIERS' },
    86085: { agency: '3D', city: 'POITIERS' },
    86086: { agency: '3D', city: 'POITIERS' },
    86087: { agency: '3D', city: 'POITIERS' },
    86088: { agency: '3D', city: 'POITIERS' },
    86089: { agency: '3D', city: 'POITIERS' },
    86090: { agency: '3D', city: 'POITIERS' },
    86091: { agency: '3D', city: 'POITIERS' },
    86092: { agency: '3D', city: 'POITIERS' },
    86093: { agency: '3D', city: 'POITIERS' },
    86094: { agency: '3D', city: 'POITIERS' },
    86095: { agency: '3D', city: 'POITIERS' },
    86096: { agency: '3D', city: 'POITIERS' },
    86097: { agency: '3D', city: 'POITIERS' },
    86098: { agency: '3D', city: 'POITIERS' },
    86120: { agency: '3D', city: 'POITIERS' },
    86150: { agency: '3D', city: 'POITIERS' },
    86160: { agency: '3D', city: 'POITIERS' },
    86180: { agency: '3D', city: 'POITIERS' },
    86190: { agency: '3D', city: 'POITIERS' },
    86200: { agency: '3D', city: 'POITIERS' },
    86210: { agency: '3D', city: 'POITIERS' },
    86240: { agency: '3D', city: 'POITIERS' },
    86280: { agency: '3D', city: 'POITIERS' },
    86281: { agency: '3D', city: 'POITIERS' },
    86282: { agency: '3D', city: 'POITIERS' },
    86289: { agency: '3D', city: 'POITIERS' },
    86310: { agency: '3D', city: 'POITIERS' },
    86320: { agency: '3D', city: 'POITIERS' },
    86330: { agency: '3D', city: 'POITIERS' },
    86340: { agency: '3D', city: 'POITIERS' },
    86350: { agency: '3D', city: 'POITIERS' },
    86370: { agency: '3D', city: 'POITIERS' },
    86410: { agency: '3D', city: 'POITIERS' },
    86440: { agency: '3D', city: 'POITIERS' },
    86470: { agency: '3D', city: 'POITIERS' },
    86480: { agency: '3D', city: 'POITIERS' },
    86490: { agency: '3D', city: 'POITIERS' },
    86500: { agency: '3D', city: 'POITIERS' },
    86510: { agency: '3D', city: 'POITIERS' },
    86530: { agency: '3D', city: 'POITIERS' },
    86550: { agency: '3D', city: 'POITIERS' },
    86580: { agency: '3D', city: 'POITIERS' },
    86600: { agency: '3D', city: 'POITIERS' },
    86610: { agency: '3D', city: 'POITIERS' },
    86700: { agency: '3D', city: 'POITIERS' },
    86905: { agency: '3D', city: 'POITIERS' },
    86910: { agency: '3D', city: 'POITIERS' },
    79180: { agency: '3D', city: 'POITIERS' },
    79190: { agency: '3D', city: 'POITIERS' },
    79410: { agency: '3D', city: 'POITIERS' },
    86250: { agency: '3D', city: 'POITIERS' },
    86400: { agency: '3D', city: 'POITIERS' },
    86460: { agency: '3D', city: 'POITIERS' },
    17290: { agency: '5H', city: 'ROCHEFORT' },
    17300: { agency: '5H', city: 'ROCHEFORT' },
    17301: { agency: '5H', city: 'ROCHEFORT' },
    17302: { agency: '5H', city: 'ROCHEFORT' },
    17303: { agency: '5H', city: 'ROCHEFORT' },
    17304: { agency: '5H', city: 'ROCHEFORT' },
    17305: { agency: '5H', city: 'ROCHEFORT' },
    17306: { agency: '5H', city: 'ROCHEFORT' },
    17307: { agency: '5H', city: 'ROCHEFORT' },
    17308: { agency: '5H', city: 'ROCHEFORT' },
    17309: { agency: '5H', city: 'ROCHEFORT' },
    17311: { agency: '5H', city: 'ROCHEFORT' },
    17312: { agency: '5H', city: 'ROCHEFORT' },
    17313: { agency: '5H', city: 'ROCHEFORT' },
    17314: { agency: '5H', city: 'ROCHEFORT' },
    17320: { agency: '5H', city: 'ROCHEFORT' },
    17340: { agency: '5H', city: 'ROCHEFORT' },
    17430: { agency: '5H', city: 'ROCHEFORT' },
    17440: { agency: '5H', city: 'ROCHEFORT' },
    17450: { agency: '5H', city: 'ROCHEFORT' },
    17560: { agency: '5H', city: 'ROCHEFORT' },
    17620: { agency: '5H', city: 'ROCHEFORT' },
    17700: { agency: '5H', city: 'ROCHEFORT' },
    17730: { agency: '5H', city: 'ROCHEFORT' },
    17780: { agency: '5H', city: 'ROCHEFORT' },
    17870: { agency: '5H', city: 'ROCHEFORT' },
    7300: { agency: '4X', city: 'ROMANS SUR ISERE' },
    7301: { agency: '4X', city: 'ROMANS SUR ISERE' },
    7302: { agency: '4X', city: 'ROMANS SUR ISERE' },
    7303: { agency: '4X', city: 'ROMANS SUR ISERE' },
    7304: { agency: '4X', city: 'ROMANS SUR ISERE' },
    7305: { agency: '4X', city: 'ROMANS SUR ISERE' },
    7306: { agency: '4X', city: 'ROMANS SUR ISERE' },
    7307: { agency: '4X', city: 'ROMANS SUR ISERE' },
    7308: { agency: '4X', city: 'ROMANS SUR ISERE' },
    7309: { agency: '4X', city: 'ROMANS SUR ISERE' },
    7370: { agency: '4X', city: 'ROMANS SUR ISERE' },
    7610: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26100: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26101: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26102: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26103: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26104: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26105: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26106: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26107: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26108: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26109: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26120: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26190: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26199: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26240: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26241: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26242: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26243: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26244: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26249: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26260: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26300: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26301: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26302: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26303: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26304: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26309: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26320: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26330: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26350: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26380: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26390: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26500: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26501: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26502: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26503: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26504: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26509: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26530: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26540: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26600: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26601: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26602: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26603: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26609: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26730: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26750: { agency: '4X', city: 'ROMANS SUR ISERE' },
    38160: { agency: '4X', city: 'ROMANS SUR ISERE' },
    38161: { agency: '4X', city: 'ROMANS SUR ISERE' },
    38162: { agency: '4X', city: 'ROMANS SUR ISERE' },
    38163: { agency: '4X', city: 'ROMANS SUR ISERE' },
    38164: { agency: '4X', city: 'ROMANS SUR ISERE' },
    38165: { agency: '4X', city: 'ROMANS SUR ISERE' },
    38169: { agency: '4X', city: 'ROMANS SUR ISERE' },
    38840: { agency: '4X', city: 'ROMANS SUR ISERE' },
    38940: { agency: '4X', city: 'ROMANS SUR ISERE' },
    7340: { agency: '4X', city: 'ROMANS SUR ISERE' },
    7430: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26140: { agency: '4X', city: 'ROMANS SUR ISERE' },
    26210: { agency: '4X', city: 'ROMANS SUR ISERE' },
    27150: { agency: '2E', city: 'ROUEN' },
    27310: { agency: '2E', city: 'ROUEN' },
    27340: { agency: '2E', city: 'ROUEN' },
    27350: { agency: '2E', city: 'ROUEN' },
    27360: { agency: '2E', city: 'ROUEN' },
    27380: { agency: '2E', city: 'ROUEN' },
    27500: { agency: '2E', city: 'ROUEN' },
    27520: { agency: '2E', city: 'ROUEN' },
    27670: { agency: '2E', city: 'ROUEN' },
    27690: { agency: '2E', city: 'ROUEN' },
    76000: { agency: '2E', city: 'ROUEN' },
    76001: { agency: '2E', city: 'ROUEN' },
    76002: { agency: '2E', city: 'ROUEN' },
    76003: { agency: '2E', city: 'ROUEN' },
    76004: { agency: '2E', city: 'ROUEN' },
    76005: { agency: '2E', city: 'ROUEN' },
    76006: { agency: '2E', city: 'ROUEN' },
    76007: { agency: '2E', city: 'ROUEN' },
    76008: { agency: '2E', city: 'ROUEN' },
    76011: { agency: '2E', city: 'ROUEN' },
    76012: { agency: '2E', city: 'ROUEN' },
    76017: { agency: '2E', city: 'ROUEN' },
    76018: { agency: '2E', city: 'ROUEN' },
    76019: { agency: '2E', city: 'ROUEN' },
    76020: { agency: '2E', city: 'ROUEN' },
    76021: { agency: '2E', city: 'ROUEN' },
    76022: { agency: '2E', city: 'ROUEN' },
    76024: { agency: '2E', city: 'ROUEN' },
    76025: { agency: '2E', city: 'ROUEN' },
    76026: { agency: '2E', city: 'ROUEN' },
    76028: { agency: '2E', city: 'ROUEN' },
    76029: { agency: '2E', city: 'ROUEN' },
    76030: { agency: '2E', city: 'ROUEN' },
    76031: { agency: '2E', city: 'ROUEN' },
    76032: { agency: '2E', city: 'ROUEN' },
    76033: { agency: '2E', city: 'ROUEN' },
    76035: { agency: '2E', city: 'ROUEN' },
    76036: { agency: '2E', city: 'ROUEN' },
    76037: { agency: '2E', city: 'ROUEN' },
    76038: { agency: '2E', city: 'ROUEN' },
    76039: { agency: '2E', city: 'ROUEN' },
    76040: { agency: '2E', city: 'ROUEN' },
    76041: { agency: '2E', city: 'ROUEN' },
    76042: { agency: '2E', city: 'ROUEN' },
    76043: { agency: '2E', city: 'ROUEN' },
    76044: { agency: '2E', city: 'ROUEN' },
    76045: { agency: '2E', city: 'ROUEN' },
    76046: { agency: '2E', city: 'ROUEN' },
    76047: { agency: '2E', city: 'ROUEN' },
    76049: { agency: '2E', city: 'ROUEN' },
    76100: { agency: '2E', city: 'ROUEN' },
    76101: { agency: '2E', city: 'ROUEN' },
    76107: { agency: '2E', city: 'ROUEN' },
    76108: { agency: '2E', city: 'ROUEN' },
    76109: { agency: '2E', city: 'ROUEN' },
    76113: { agency: '2E', city: 'ROUEN' },
    76116: { agency: '2E', city: 'ROUEN' },
    76120: { agency: '2E', city: 'ROUEN' },
    76121: { agency: '2E', city: 'ROUEN' },
    76122: { agency: '2E', city: 'ROUEN' },
    76123: { agency: '2E', city: 'ROUEN' },
    76124: { agency: '2E', city: 'ROUEN' },
    76125: { agency: '2E', city: 'ROUEN' },
    76127: { agency: '2E', city: 'ROUEN' },
    76129: { agency: '2E', city: 'ROUEN' },
    76130: { agency: '2E', city: 'ROUEN' },
    76131: { agency: '2E', city: 'ROUEN' },
    76132: { agency: '2E', city: 'ROUEN' },
    76134: { agency: '2E', city: 'ROUEN' },
    76135: { agency: '2E', city: 'ROUEN' },
    76136: { agency: '2E', city: 'ROUEN' },
    76137: { agency: '2E', city: 'ROUEN' },
    76138: { agency: '2E', city: 'ROUEN' },
    76139: { agency: '2E', city: 'ROUEN' },
    76140: { agency: '2E', city: 'ROUEN' },
    76141: { agency: '2E', city: 'ROUEN' },
    76142: { agency: '2E', city: 'ROUEN' },
    76143: { agency: '2E', city: 'ROUEN' },
    76144: { agency: '2E', city: 'ROUEN' },
    76150: { agency: '2E', city: 'ROUEN' },
    76151: { agency: '2E', city: 'ROUEN' },
    76152: { agency: '2E', city: 'ROUEN' },
    76153: { agency: '2E', city: 'ROUEN' },
    76155: { agency: '2E', city: 'ROUEN' },
    76159: { agency: '2E', city: 'ROUEN' },
    76160: { agency: '2E', city: 'ROUEN' },
    76161: { agency: '2E', city: 'ROUEN' },
    76164: { agency: '2E', city: 'ROUEN' },
    76171: { agency: '2E', city: 'ROUEN' },
    76172: { agency: '2E', city: 'ROUEN' },
    76173: { agency: '2E', city: 'ROUEN' },
    76174: { agency: '2E', city: 'ROUEN' },
    76175: { agency: '2E', city: 'ROUEN' },
    76176: { agency: '2E', city: 'ROUEN' },
    76177: { agency: '2E', city: 'ROUEN' },
    76178: { agency: '2E', city: 'ROUEN' },
    76179: { agency: '2E', city: 'ROUEN' },
    76181: { agency: '2E', city: 'ROUEN' },
    76183: { agency: '2E', city: 'ROUEN' },
    76185: { agency: '2E', city: 'ROUEN' },
    76186: { agency: '2E', city: 'ROUEN' },
    76187: { agency: '2E', city: 'ROUEN' },
    76188: { agency: '2E', city: 'ROUEN' },
    76189: { agency: '2E', city: 'ROUEN' },
    76190: { agency: '2E', city: 'ROUEN' },
    76200: { agency: '2E', city: 'ROUEN' },
    76230: { agency: '2E', city: 'ROUEN' },
    76231: { agency: '2E', city: 'ROUEN' },
    76232: { agency: '2E', city: 'ROUEN' },
    76233: { agency: '2E', city: 'ROUEN' },
    76235: { agency: '2E', city: 'ROUEN' },
    76236: { agency: '2E', city: 'ROUEN' },
    76237: { agency: '2E', city: 'ROUEN' },
    76238: { agency: '2E', city: 'ROUEN' },
    76239: { agency: '2E', city: 'ROUEN' },
    76240: { agency: '2E', city: 'ROUEN' },
    76250: { agency: '2E', city: 'ROUEN' },
    76270: { agency: '2E', city: 'ROUEN' },
    76300: { agency: '2E', city: 'ROUEN' },
    76301: { agency: '2E', city: 'ROUEN' },
    76302: { agency: '2E', city: 'ROUEN' },
    76303: { agency: '2E', city: 'ROUEN' },
    76304: { agency: '2E', city: 'ROUEN' },
    76305: { agency: '2E', city: 'ROUEN' },
    76306: { agency: '2E', city: 'ROUEN' },
    76308: { agency: '2E', city: 'ROUEN' },
    76320: { agency: '2E', city: 'ROUEN' },
    76350: { agency: '2E', city: 'ROUEN' },
    76360: { agency: '2E', city: 'ROUEN' },
    76380: { agency: '2E', city: 'ROUEN' },
    76410: { agency: '2E', city: 'ROUEN' },
    76418: { agency: '2E', city: 'ROUEN' },
    76419: { agency: '2E', city: 'ROUEN' },
    76420: { agency: '2E', city: 'ROUEN' },
    76480: { agency: '2E', city: 'ROUEN' },
    76500: { agency: '2E', city: 'ROUEN' },
    76501: { agency: '2E', city: 'ROUEN' },
    76502: { agency: '2E', city: 'ROUEN' },
    76503: { agency: '2E', city: 'ROUEN' },
    76504: { agency: '2E', city: 'ROUEN' },
    76509: { agency: '2E', city: 'ROUEN' },
    76520: { agency: '2E', city: 'ROUEN' },
    76530: { agency: '2E', city: 'ROUEN' },
    76570: { agency: '2E', city: 'ROUEN' },
    76580: { agency: '2E', city: 'ROUEN' },
    76650: { agency: '2E', city: 'ROUEN' },
    76680: { agency: '2E', city: 'ROUEN' },
    76690: { agency: '2E', city: 'ROUEN' },
    76710: { agency: '2E', city: 'ROUEN' },
    76721: { agency: '2E', city: 'ROUEN' },
    76723: { agency: '2E', city: 'ROUEN' },
    76750: { agency: '2E', city: 'ROUEN' },
    76760: { agency: '2E', city: 'ROUEN' },
    76770: { agency: '2E', city: 'ROUEN' },
    76780: { agency: '2E', city: 'ROUEN' },
    76800: { agency: '2E', city: 'ROUEN' },
    76801: { agency: '2E', city: 'ROUEN' },
    76802: { agency: '2E', city: 'ROUEN' },
    76803: { agency: '2E', city: 'ROUEN' },
    76804: { agency: '2E', city: 'ROUEN' },
    76805: { agency: '2E', city: 'ROUEN' },
    76806: { agency: '2E', city: 'ROUEN' },
    76807: { agency: '2E', city: 'ROUEN' },
    76808: { agency: '2E', city: 'ROUEN' },
    76809: { agency: '2E', city: 'ROUEN' },
    76821: { agency: '2E', city: 'ROUEN' },
    76823: { agency: '2E', city: 'ROUEN' },
    76824: { agency: '2E', city: 'ROUEN' },
    76825: { agency: '2E', city: 'ROUEN' },
    76840: { agency: '2E', city: 'ROUEN' },
    76850: { agency: '2E', city: 'ROUEN' },
    76890: { agency: '2E', city: 'ROUEN' },
    76900: { agency: '2E', city: 'ROUEN' },
    76902: { agency: '2E', city: 'ROUEN' },
    76910: { agency: '2E', city: 'ROUEN' },
    76914: { agency: '2E', city: 'ROUEN' },
    76915: { agency: '2E', city: 'ROUEN' },
    76917: { agency: '2E', city: 'ROUEN' },
    76920: { agency: '2E', city: 'ROUEN' },
    76925: { agency: '2E', city: 'ROUEN' },
    76926: { agency: '2E', city: 'ROUEN' },
    76927: { agency: '2E', city: 'ROUEN' },
    76929: { agency: '2E', city: 'ROUEN' },
    76934: { agency: '2E', city: 'ROUEN' },
    76935: { agency: '2E', city: 'ROUEN' },
    76940: { agency: '2E', city: 'ROUEN' },
    76945: { agency: '2E', city: 'ROUEN' },
    76960: { agency: '2E', city: 'ROUEN' },
    27480: { agency: '2E', city: 'ROUEN' },
    27680: { agency: '2E', city: 'ROUEN' },
    76119: { agency: '2E', city: 'ROUEN' },
    76220: { agency: '2E', city: 'ROUEN' },
    76260: { agency: '2E', city: 'ROUEN' },
    76290: { agency: '2E', city: 'ROUEN' },
    76340: { agency: '2E', city: 'ROUEN' },
    76370: { agency: '2E', city: 'ROUEN' },
    76390: { agency: '2E', city: 'ROUEN' },
    76400: { agency: '2E', city: 'ROUEN' },
    76440: { agency: '2E', city: 'ROUEN' },
    76450: { agency: '2E', city: 'ROUEN' },
    76460: { agency: '2E', city: 'ROUEN' },
    76490: { agency: '2E', city: 'ROUEN' },
    76510: { agency: '2E', city: 'ROUEN' },
    76550: { agency: '2E', city: 'ROUEN' },
    76560: { agency: '2E', city: 'ROUEN' },
    76590: { agency: '2E', city: 'ROUEN' },
    76640: { agency: '2E', city: 'ROUEN' },
    76660: { agency: '2E', city: 'ROUEN' },
    76720: { agency: '2E', city: 'ROUEN' },
    76730: { agency: '2E', city: 'ROUEN' },
    76740: { agency: '2E', city: 'ROUEN' },
    76810: { agency: '2E', city: 'ROUEN' },
    76860: { agency: '2E', city: 'ROUEN' },
    76870: { agency: '2E', city: 'ROUEN' },
    76880: { agency: '2E', city: 'ROUEN' },
    76950: { agency: '2E', city: 'ROUEN' },
    76970: { agency: '2E', city: 'ROUEN' },
    17110: { agency: '5G', city: 'ROYAN' },
    17113: { agency: '5G', city: 'ROYAN' },
    17120: { agency: '5G', city: 'ROYAN' },
    17132: { agency: '5G', city: 'ROYAN' },
    17200: { agency: '5G', city: 'ROYAN' },
    17201: { agency: '5G', city: 'ROYAN' },
    17202: { agency: '5G', city: 'ROYAN' },
    17203: { agency: '5G', city: 'ROYAN' },
    17204: { agency: '5G', city: 'ROYAN' },
    17205: { agency: '5G', city: 'ROYAN' },
    17206: { agency: '5G', city: 'ROYAN' },
    17207: { agency: '5G', city: 'ROYAN' },
    17208: { agency: '5G', city: 'ROYAN' },
    17209: { agency: '5G', city: 'ROYAN' },
    17211: { agency: '5G', city: 'ROYAN' },
    17214: { agency: '5G', city: 'ROYAN' },
    17215: { agency: '5G', city: 'ROYAN' },
    17390: { agency: '5G', city: 'ROYAN' },
    17400: { agency: '5F', city: 'SAINTES' },
    17420: { agency: '5G', city: 'ROYAN' },
    17500: { agency: '5F', city: 'SAINTES' },
    17530: { agency: '5G', city: 'ROYAN' },
    17570: { agency: '5G', city: 'ROYAN' },
    17640: { agency: '5G', city: 'ROYAN' },
    17750: { agency: '5G', city: 'ROYAN' },
    17890: { agency: '5G', city: 'ROYAN' },
    17920: { agency: '5G', city: 'ROYAN' },
    17150: { agency: '5F', city: 'SAINTES' },
    17330: { agency: '5F', city: 'SAINTES' },
    17470: { agency: '5F', city: 'SAINTES' },
    17100: { agency: '5F', city: 'SAINTES' },
    17101: { agency: '5F', city: 'SAINTES' },
    17102: { agency: '5F', city: 'SAINTES' },
    17103: { agency: '5F', city: 'SAINTES' },
    17104: { agency: '5F', city: 'SAINTES' },
    17105: { agency: '5F', city: 'SAINTES' },
    17106: { agency: '5F', city: 'SAINTES' },
    17107: { agency: '5F', city: 'SAINTES' },
    17108: { agency: '5F', city: 'SAINTES' },
    17109: { agency: '5F', city: 'SAINTES' },
    17112: { agency: '5F', city: 'SAINTES' },
    17114: { agency: '5F', city: 'SAINTES' },
    17115: { agency: '5F', city: 'SAINTES' },
    17116: { agency: '5F', city: 'SAINTES' },
    17117: { agency: '5F', city: 'SAINTES' },
    17118: { agency: '5F', city: 'SAINTES' },
    17119: { agency: '5F', city: 'SAINTES' },
    17121: { agency: '5F', city: 'SAINTES' },
    17136: { agency: '5F', city: 'SAINTES' },
    17160: { agency: '5F', city: 'SAINTES' },
    17240: { agency: '5F', city: 'SAINTES' },
    17250: { agency: '5F', city: 'SAINTES' },
    17260: { agency: '5F', city: 'SAINTES' },
    17350: { agency: '5F', city: 'SAINTES' },
    17380: { agency: '5F', city: 'SAINTES' },
    17411: { agency: '5F', city: 'SAINTES' },
    17412: { agency: '5F', city: 'SAINTES' },
    17413: { agency: '5F', city: 'SAINTES' },
    17414: { agency: '5F', city: 'SAINTES' },
    17415: { agency: '5F', city: 'SAINTES' },
    17416: { agency: '5F', city: 'SAINTES' },
    17419: { agency: '5F', city: 'SAINTES' },
    17460: { agency: '5F', city: 'SAINTES' },
    17600: { agency: '5F', city: 'SAINTES' },
    17770: { agency: '5F', city: 'SAINTES' },
    17800: { agency: '5F', city: 'SAINTES' },
    17810: { agency: '5F', city: 'SAINTES' },
    54111: { agency: '1S', city: 'THIONVILLE' },
    54135: { agency: '1S', city: 'THIONVILLE' },
    54150: { agency: '1S', city: 'THIONVILLE' },
    54151: { agency: '1S', city: 'THIONVILLE' },
    54152: { agency: '1S', city: 'THIONVILLE' },
    54153: { agency: '1S', city: 'THIONVILLE' },
    54154: { agency: '1S', city: 'THIONVILLE' },
    54159: { agency: '1S', city: 'THIONVILLE' },
    54190: { agency: '1S', city: 'THIONVILLE' },
    54240: { agency: '1S', city: 'THIONVILLE' },
    54310: { agency: '1S', city: 'THIONVILLE' },
    54311: { agency: '1S', city: 'THIONVILLE' },
    54312: { agency: '1S', city: 'THIONVILLE' },
    54319: { agency: '1S', city: 'THIONVILLE' },
    54350: { agency: '1S', city: 'THIONVILLE' },
    54400: { agency: '1S', city: 'THIONVILLE' },
    54401: { agency: '1S', city: 'THIONVILLE' },
    54402: { agency: '1S', city: 'THIONVILLE' },
    54403: { agency: '1S', city: 'THIONVILLE' },
    54404: { agency: '1S', city: 'THIONVILLE' },
    54405: { agency: '1S', city: 'THIONVILLE' },
    54406: { agency: '1S', city: 'THIONVILLE' },
    54409: { agency: '1S', city: 'THIONVILLE' },
    54411: { agency: '1S', city: 'THIONVILLE' },
    54412: { agency: '1S', city: 'THIONVILLE' },
    54413: { agency: '1S', city: 'THIONVILLE' },
    54414: { agency: '1S', city: 'THIONVILLE' },
    54430: { agency: '1S', city: 'THIONVILLE' },
    54440: { agency: '1S', city: 'THIONVILLE' },
    54490: { agency: '1S', city: 'THIONVILLE' },
    54530: { agency: '1S', city: 'THIONVILLE' },
    54560: { agency: '1S', city: 'THIONVILLE' },
    54580: { agency: '1S', city: 'THIONVILLE' },
    54590: { agency: '1S', city: 'THIONVILLE' },
    54620: { agency: '1S', city: 'THIONVILLE' },
    54640: { agency: '1S', city: 'THIONVILLE' },
    54650: { agency: '1S', city: 'THIONVILLE' },
    54660: { agency: '1S', city: 'THIONVILLE' },
    54680: { agency: '1S', city: 'THIONVILLE' },
    54720: { agency: '1S', city: 'THIONVILLE' },
    54750: { agency: '1S', city: 'THIONVILLE' },
    54780: { agency: '1S', city: 'THIONVILLE' },
    54790: { agency: '1S', city: 'THIONVILLE' },
    54800: { agency: '1S', city: 'THIONVILLE' },
    54801: { agency: '1S', city: 'THIONVILLE' },
    54802: { agency: '1S', city: 'THIONVILLE' },
    54803: { agency: '1S', city: 'THIONVILLE' },
    54810: { agency: '1S', city: 'THIONVILLE' },
    54860: { agency: '1S', city: 'THIONVILLE' },
    54880: { agency: '1S', city: 'THIONVILLE' },
    54910: { agency: '1S', city: 'THIONVILLE' },
    54920: { agency: '1S', city: 'THIONVILLE' },
    54960: { agency: '1S', city: 'THIONVILLE' },
    54970: { agency: '1S', city: 'THIONVILLE' },
    54980: { agency: '1S', city: 'THIONVILLE' },
    55240: { agency: '1S', city: 'THIONVILLE' },
    57000: { agency: '1S', city: 'THIONVILLE' },
    57003: { agency: '1S', city: 'THIONVILLE' },
    57004: { agency: '1S', city: 'THIONVILLE' },
    57005: { agency: '1S', city: 'THIONVILLE' },
    57006: { agency: '1S', city: 'THIONVILLE' },
    57007: { agency: '1S', city: 'THIONVILLE' },
    57008: { agency: '1S', city: 'THIONVILLE' },
    57009: { agency: '1S', city: 'THIONVILLE' },
    57010: { agency: '1S', city: 'THIONVILLE' },
    57011: { agency: '1S', city: 'THIONVILLE' },
    57012: { agency: '1S', city: 'THIONVILLE' },
    57013: { agency: '1S', city: 'THIONVILLE' },
    57014: { agency: '1S', city: 'THIONVILLE' },
    57015: { agency: '1S', city: 'THIONVILLE' },
    57016: { agency: '1S', city: 'THIONVILLE' },
    57017: { agency: '1S', city: 'THIONVILLE' },
    57018: { agency: '1S', city: 'THIONVILLE' },
    57019: { agency: '1S', city: 'THIONVILLE' },
    57020: { agency: '1S', city: 'THIONVILLE' },
    57021: { agency: '1S', city: 'THIONVILLE' },
    57023: { agency: '1S', city: 'THIONVILLE' },
    57028: { agency: '1S', city: 'THIONVILLE' },
    57030: { agency: '1S', city: 'THIONVILLE' },
    57031: { agency: '1S', city: 'THIONVILLE' },
    57032: { agency: '1S', city: 'THIONVILLE' },
    57033: { agency: '1S', city: 'THIONVILLE' },
    57034: { agency: '1S', city: 'THIONVILLE' },
    57035: { agency: '1S', city: 'THIONVILLE' },
    57036: { agency: '1S', city: 'THIONVILLE' },
    57037: { agency: '1S', city: 'THIONVILLE' },
    57038: { agency: '1S', city: 'THIONVILLE' },
    57040: { agency: '1S', city: 'THIONVILLE' },
    57041: { agency: '1S', city: 'THIONVILLE' },
    57044: { agency: '1S', city: 'THIONVILLE' },
    57045: { agency: '1S', city: 'THIONVILLE' },
    57046: { agency: '1S', city: 'THIONVILLE' },
    57049: { agency: '1S', city: 'THIONVILLE' },
    57050: { agency: '1S', city: 'THIONVILLE' },
    57051: { agency: '1S', city: 'THIONVILLE' },
    57052: { agency: '1S', city: 'THIONVILLE' },
    57053: { agency: '1S', city: 'THIONVILLE' },
    57054: { agency: '1S', city: 'THIONVILLE' },
    57056: { agency: '1S', city: 'THIONVILLE' },
    57057: { agency: '1S', city: 'THIONVILLE' },
    57059: { agency: '1S', city: 'THIONVILLE' },
    57061: { agency: '1S', city: 'THIONVILLE' },
    57062: { agency: '1S', city: 'THIONVILLE' },
    57063: { agency: '1S', city: 'THIONVILLE' },
    57070: { agency: '1S', city: 'THIONVILLE' },
    57071: { agency: '1S', city: 'THIONVILLE' },
    57072: { agency: '1S', city: 'THIONVILLE' },
    57073: { agency: '1S', city: 'THIONVILLE' },
    57074: { agency: '1S', city: 'THIONVILLE' },
    57075: { agency: '1S', city: 'THIONVILLE' },
    57076: { agency: '1S', city: 'THIONVILLE' },
    57077: { agency: '1S', city: 'THIONVILLE' },
    57078: { agency: '1S', city: 'THIONVILLE' },
    57079: { agency: '1S', city: 'THIONVILLE' },
    57081: { agency: '1S', city: 'THIONVILLE' },
    57082: { agency: '1S', city: 'THIONVILLE' },
    57083: { agency: '1S', city: 'THIONVILLE' },
    57084: { agency: '1S', city: 'THIONVILLE' },
    57100: { agency: '1S', city: 'THIONVILLE' },
    57101: { agency: '1S', city: 'THIONVILLE' },
    57102: { agency: '1S', city: 'THIONVILLE' },
    57103: { agency: '1S', city: 'THIONVILLE' },
    57104: { agency: '1S', city: 'THIONVILLE' },
    57105: { agency: '1S', city: 'THIONVILLE' },
    57106: { agency: '1S', city: 'THIONVILLE' },
    57108: { agency: '1S', city: 'THIONVILLE' },
    57109: { agency: '1S', city: 'THIONVILLE' },
    57119: { agency: '1S', city: 'THIONVILLE' },
    57120: { agency: '1S', city: 'THIONVILLE' },
    57125: { agency: '1S', city: 'THIONVILLE' },
    57126: { agency: '1S', city: 'THIONVILLE' },
    57127: { agency: '1S', city: 'THIONVILLE' },
    57128: { agency: '1S', city: 'THIONVILLE' },
    57129: { agency: '1S', city: 'THIONVILLE' },
    57130: { agency: '1S', city: 'THIONVILLE' },
    57131: { agency: '1S', city: 'THIONVILLE' },
    57132: { agency: '1S', city: 'THIONVILLE' },
    57133: { agency: '1S', city: 'THIONVILLE' },
    57140: { agency: '1S', city: 'THIONVILLE' },
    57141: { agency: '1S', city: 'THIONVILLE' },
    57146: { agency: '1S', city: 'THIONVILLE' },
    57147: { agency: '1S', city: 'THIONVILLE' },
    57148: { agency: '1S', city: 'THIONVILLE' },
    57149: { agency: '1S', city: 'THIONVILLE' },
    57151: { agency: '1S', city: 'THIONVILLE' },
    57152: { agency: '1S', city: 'THIONVILLE' },
    57153: { agency: '1S', city: 'THIONVILLE' },
    57154: { agency: '1S', city: 'THIONVILLE' },
    57155: { agency: '1S', city: 'THIONVILLE' },
    57157: { agency: '1S', city: 'THIONVILLE' },
    57159: { agency: '1S', city: 'THIONVILLE' },
    57160: { agency: '1S', city: 'THIONVILLE' },
    57161: { agency: '1S', city: 'THIONVILLE' },
    57162: { agency: '1S', city: 'THIONVILLE' },
    57163: { agency: '1S', city: 'THIONVILLE' },
    57164: { agency: '1S', city: 'THIONVILLE' },
    57169: { agency: '1S', city: 'THIONVILLE' },
    57175: { agency: '1S', city: 'THIONVILLE' },
    57180: { agency: '1S', city: 'THIONVILLE' },
    57181: { agency: '1S', city: 'THIONVILLE' },
    57185: { agency: '1S', city: 'THIONVILLE' },
    57190: { agency: '1S', city: 'THIONVILLE' },
    57191: { agency: '1S', city: 'THIONVILLE' },
    57192: { agency: '1S', city: 'THIONVILLE' },
    57193: { agency: '1S', city: 'THIONVILLE' },
    57199: { agency: '1S', city: 'THIONVILLE' },
    57220: { agency: '1S', city: 'THIONVILLE' },
    57240: { agency: '1S', city: 'THIONVILLE' },
    57245: { agency: '1S', city: 'THIONVILLE' },
    57250: { agency: '1S', city: 'THIONVILLE' },
    57255: { agency: '1S', city: 'THIONVILLE' },
    57270: { agency: '1S', city: 'THIONVILLE' },
    57280: { agency: '1S', city: 'THIONVILLE' },
    57281: { agency: '1S', city: 'THIONVILLE' },
    57282: { agency: '1S', city: 'THIONVILLE' },
    57283: { agency: '1S', city: 'THIONVILLE' },
    57289: { agency: '1S', city: 'THIONVILLE' },
    57290: { agency: '1S', city: 'THIONVILLE' },
    57291: { agency: '1S', city: 'THIONVILLE' },
    57292: { agency: '1S', city: 'THIONVILLE' },
    57299: { agency: '1S', city: 'THIONVILLE' },
    57300: { agency: '1S', city: 'THIONVILLE' },
    57301: { agency: '1S', city: 'THIONVILLE' },
    57302: { agency: '1S', city: 'THIONVILLE' },
    57303: { agency: '1S', city: 'THIONVILLE' },
    57304: { agency: '1S', city: 'THIONVILLE' },
    57309: { agency: '1S', city: 'THIONVILLE' },
    57310: { agency: '1S', city: 'THIONVILLE' },
    57320: { agency: '1S', city: 'THIONVILLE' },
    57330: { agency: '1S', city: 'THIONVILLE' },
    57360: { agency: '1S', city: 'THIONVILLE' },
    57361: { agency: '1S', city: 'THIONVILLE' },
    57362: { agency: '1S', city: 'THIONVILLE' },
    57363: { agency: '1S', city: 'THIONVILLE' },
    57365: { agency: '1S', city: 'THIONVILLE' },
    57369: { agency: '1S', city: 'THIONVILLE' },
    57390: { agency: '1S', city: 'THIONVILLE' },
    57420: { agency: '1S', city: 'THIONVILLE' },
    57440: { agency: '1S', city: 'THIONVILLE' },
    57480: { agency: '1S', city: 'THIONVILLE' },
    57525: { agency: '1S', city: 'THIONVILLE' },
    57530: { agency: '1S', city: 'THIONVILLE' },
    57535: { agency: '1S', city: 'THIONVILLE' },
    57570: { agency: '1S', city: 'THIONVILLE' },
    57640: { agency: '1S', city: 'THIONVILLE' },
    57645: { agency: '1S', city: 'THIONVILLE' },
    57650: { agency: '1S', city: 'THIONVILLE' },
    57655: { agency: '1S', city: 'THIONVILLE' },
    57680: { agency: '1S', city: 'THIONVILLE' },
    57685: { agency: '1S', city: 'THIONVILLE' },
    57700: { agency: '1S', city: 'THIONVILLE' },
    57701: { agency: '1S', city: 'THIONVILLE' },
    57702: { agency: '1S', city: 'THIONVILLE' },
    57703: { agency: '1S', city: 'THIONVILLE' },
    57704: { agency: '1S', city: 'THIONVILLE' },
    57705: { agency: '1S', city: 'THIONVILLE' },
    57709: { agency: '1S', city: 'THIONVILLE' },
    57710: { agency: '1S', city: 'THIONVILLE' },
    57747: { agency: '1S', city: 'THIONVILLE' },
    57748: { agency: '1S', city: 'THIONVILLE' },
    57751: { agency: '1S', city: 'THIONVILLE' },
    57756: { agency: '1S', city: 'THIONVILLE' },
    57757: { agency: '1S', city: 'THIONVILLE' },
    57772: { agency: '1S', city: 'THIONVILLE' },
    57774: { agency: '1S', city: 'THIONVILLE' },
    57777: { agency: '1S', city: 'THIONVILLE' },
    57778: { agency: '1S', city: 'THIONVILLE' },
    57780: { agency: '1S', city: 'THIONVILLE' },
    57840: { agency: '1S', city: 'THIONVILLE' },
    57855: { agency: '1S', city: 'THIONVILLE' },
    57860: { agency: '1S', city: 'THIONVILLE' },
    57865: { agency: '1S', city: 'THIONVILLE' },
    57920: { agency: '1S', city: 'THIONVILLE' },
    57925: { agency: '1S', city: 'THIONVILLE' },
    57935: { agency: '1S', city: 'THIONVILLE' },
    57940: { agency: '1S', city: 'THIONVILLE' },
    57950: { agency: '1S', city: 'THIONVILLE' },
    57951: { agency: '1S', city: 'THIONVILLE' },
    57952: { agency: '1S', city: 'THIONVILLE' },
    57953: { agency: '1S', city: 'THIONVILLE' },
    57954: { agency: '1S', city: 'THIONVILLE' },
    57955: { agency: '1S', city: 'THIONVILLE' },
    57957: { agency: '1S', city: 'THIONVILLE' },
    57958: { agency: '1S', city: 'THIONVILLE' },
    57959: { agency: '1S', city: 'THIONVILLE' },
    57970: { agency: '1S', city: 'THIONVILLE' },
    57971: { agency: '1S', city: 'THIONVILLE' },
    57972: { agency: '1S', city: 'THIONVILLE' },
    57973: { agency: '1S', city: 'THIONVILLE' },
    57974: { agency: '1S', city: 'THIONVILLE' },
    57978: { agency: '1S', city: 'THIONVILLE' },
    57979: { agency: '1S', city: 'THIONVILLE' },
    31000: { agency: '5B', city: 'TOULOUSE' },
    31001: { agency: '5B', city: 'TOULOUSE' },
    31002: { agency: '5B', city: 'TOULOUSE' },
    31003: { agency: '5B', city: 'TOULOUSE' },
    31004: { agency: '5B', city: 'TOULOUSE' },
    31005: { agency: '5B', city: 'TOULOUSE' },
    31006: { agency: '5B', city: 'TOULOUSE' },
    31007: { agency: '5B', city: 'TOULOUSE' },
    31008: { agency: '5B', city: 'TOULOUSE' },
    31009: { agency: '5B', city: 'TOULOUSE' },
    31010: { agency: '5B', city: 'TOULOUSE' },
    31011: { agency: '5B', city: 'TOULOUSE' },
    31012: { agency: '5B', city: 'TOULOUSE' },
    31013: { agency: '5B', city: 'TOULOUSE' },
    31014: { agency: '5B', city: 'TOULOUSE' },
    31015: { agency: '5B', city: 'TOULOUSE' },
    31016: { agency: '5B', city: 'TOULOUSE' },
    31017: { agency: '5B', city: 'TOULOUSE' },
    31018: { agency: '5B', city: 'TOULOUSE' },
    31019: { agency: '5B', city: 'TOULOUSE' },
    31020: { agency: '5B', city: 'TOULOUSE' },
    31021: { agency: '5B', city: 'TOULOUSE' },
    31022: { agency: '5B', city: 'TOULOUSE' },
    31023: { agency: '5B', city: 'TOULOUSE' },
    31024: { agency: '5B', city: 'TOULOUSE' },
    31025: { agency: '5B', city: 'TOULOUSE' },
    31026: { agency: '5B', city: 'TOULOUSE' },
    31027: { agency: '5B', city: 'TOULOUSE' },
    31028: { agency: '5B', city: 'TOULOUSE' },
    31029: { agency: '5B', city: 'TOULOUSE' },
    31030: { agency: '5B', city: 'TOULOUSE' },
    31031: { agency: '5B', city: 'TOULOUSE' },
    31032: { agency: '5B', city: 'TOULOUSE' },
    31033: { agency: '5B', city: 'TOULOUSE' },
    31034: { agency: '5B', city: 'TOULOUSE' },
    31035: { agency: '5B', city: 'TOULOUSE' },
    31036: { agency: '5B', city: 'TOULOUSE' },
    31037: { agency: '5B', city: 'TOULOUSE' },
    31038: { agency: '5B', city: 'TOULOUSE' },
    31039: { agency: '5B', city: 'TOULOUSE' },
    31040: { agency: '5B', city: 'TOULOUSE' },
    31041: { agency: '5B', city: 'TOULOUSE' },
    31042: { agency: '5B', city: 'TOULOUSE' },
    31043: { agency: '5B', city: 'TOULOUSE' },
    31044: { agency: '5B', city: 'TOULOUSE' },
    31045: { agency: '5B', city: 'TOULOUSE' },
    31046: { agency: '5B', city: 'TOULOUSE' },
    31047: { agency: '5B', city: 'TOULOUSE' },
    31048: { agency: '5B', city: 'TOULOUSE' },
    31049: { agency: '5B', city: 'TOULOUSE' },
    31050: { agency: '5B', city: 'TOULOUSE' },
    31051: { agency: '5B', city: 'TOULOUSE' },
    31052: { agency: '5B', city: 'TOULOUSE' },
    31053: { agency: '5B', city: 'TOULOUSE' },
    31054: { agency: '5B', city: 'TOULOUSE' },
    31055: { agency: '5B', city: 'TOULOUSE' },
    31056: { agency: '5B', city: 'TOULOUSE' },
    31057: { agency: '5B', city: 'TOULOUSE' },
    31058: { agency: '5B', city: 'TOULOUSE' },
    31059: { agency: '5B', city: 'TOULOUSE' },
    31060: { agency: '5B', city: 'TOULOUSE' },
    31061: { agency: '5B', city: 'TOULOUSE' },
    31062: { agency: '5B', city: 'TOULOUSE' },
    31063: { agency: '5B', city: 'TOULOUSE' },
    31064: { agency: '5B', city: 'TOULOUSE' },
    31065: { agency: '5B', city: 'TOULOUSE' },
    31066: { agency: '5B', city: 'TOULOUSE' },
    31067: { agency: '5B', city: 'TOULOUSE' },
    31068: { agency: '5B', city: 'TOULOUSE' },
    31069: { agency: '5B', city: 'TOULOUSE' },
    31070: { agency: '5B', city: 'TOULOUSE' },
    31071: { agency: '5B', city: 'TOULOUSE' },
    31072: { agency: '5B', city: 'TOULOUSE' },
    31073: { agency: '5B', city: 'TOULOUSE' },
    31074: { agency: '5B', city: 'TOULOUSE' },
    31075: { agency: '5B', city: 'TOULOUSE' },
    31076: { agency: '5B', city: 'TOULOUSE' },
    31077: { agency: '5B', city: 'TOULOUSE' },
    31078: { agency: '5B', city: 'TOULOUSE' },
    31079: { agency: '5B', city: 'TOULOUSE' },
    31080: { agency: '5B', city: 'TOULOUSE' },
    31081: { agency: '5B', city: 'TOULOUSE' },
    31082: { agency: '5B', city: 'TOULOUSE' },
    31084: { agency: '5B', city: 'TOULOUSE' },
    31085: { agency: '5B', city: 'TOULOUSE' },
    31086: { agency: '5B', city: 'TOULOUSE' },
    31088: { agency: '5B', city: 'TOULOUSE' },
    31089: { agency: '5B', city: 'TOULOUSE' },
    31090: { agency: '5B', city: 'TOULOUSE' },
    31091: { agency: '5B', city: 'TOULOUSE' },
    31092: { agency: '5B', city: 'TOULOUSE' },
    31093: { agency: '5B', city: 'TOULOUSE' },
    31094: { agency: '5B', city: 'TOULOUSE' },
    31095: { agency: '5B', city: 'TOULOUSE' },
    31096: { agency: '5B', city: 'TOULOUSE' },
    31097: { agency: '5B', city: 'TOULOUSE' },
    31098: { agency: '5B', city: 'TOULOUSE' },
    31099: { agency: '5B', city: 'TOULOUSE' },
    31100: { agency: '5B', city: 'TOULOUSE' },
    31101: { agency: '5B', city: 'TOULOUSE' },
    31102: { agency: '5B', city: 'TOULOUSE' },
    31103: { agency: '5B', city: 'TOULOUSE' },
    31104: { agency: '5B', city: 'TOULOUSE' },
    31106: { agency: '5B', city: 'TOULOUSE' },
    31107: { agency: '5B', city: 'TOULOUSE' },
    31109: { agency: '5B', city: 'TOULOUSE' },
    31112: { agency: '5B', city: 'TOULOUSE' },
    31120: { agency: '5B', city: 'TOULOUSE' },
    31121: { agency: '5B', city: 'TOULOUSE' },
    31122: { agency: '5B', city: 'TOULOUSE' },
    31123: { agency: '5B', city: 'TOULOUSE' },
    31124: { agency: '5B', city: 'TOULOUSE' },
    31125: { agency: '5B', city: 'TOULOUSE' },
    31126: { agency: '5B', city: 'TOULOUSE' },
    31127: { agency: '5B', city: 'TOULOUSE' },
    31128: { agency: '5B', city: 'TOULOUSE' },
    31129: { agency: '5B', city: 'TOULOUSE' },
    31130: { agency: '5B', city: 'TOULOUSE' },
    31131: { agency: '5B', city: 'TOULOUSE' },
    31132: { agency: '5B', city: 'TOULOUSE' },
    31133: { agency: '5B', city: 'TOULOUSE' },
    31134: { agency: '5B', city: 'TOULOUSE' },
    31135: { agency: '5B', city: 'TOULOUSE' },
    31136: { agency: '5B', city: 'TOULOUSE' },
    31137: { agency: '5B', city: 'TOULOUSE' },
    31138: { agency: '5B', city: 'TOULOUSE' },
    31139: { agency: '5B', city: 'TOULOUSE' },
    31140: { agency: '5B', city: 'TOULOUSE' },
    31141: { agency: '5B', city: 'TOULOUSE' },
    31142: { agency: '5B', city: 'TOULOUSE' },
    31149: { agency: '5B', city: 'TOULOUSE' },
    31150: { agency: '5B', city: 'TOULOUSE' },
    31151: { agency: '5B', city: 'TOULOUSE' },
    31152: { agency: '5B', city: 'TOULOUSE' },
    31155: { agency: '5B', city: 'TOULOUSE' },
    31159: { agency: '5B', city: 'TOULOUSE' },
    31170: { agency: '5B', city: 'TOULOUSE' },
    31180: { agency: '5B', city: 'TOULOUSE' },
    31189: { agency: '5B', city: 'TOULOUSE' },
    31190: { agency: '5B', city: 'TOULOUSE' },
    31200: { agency: '5B', city: 'TOULOUSE' },
    31201: { agency: '5B', city: 'TOULOUSE' },
    31203: { agency: '5B', city: 'TOULOUSE' },
    31204: { agency: '5B', city: 'TOULOUSE' },
    31205: { agency: '5B', city: 'TOULOUSE' },
    31240: { agency: '5B', city: 'TOULOUSE' },
    31241: { agency: '5B', city: 'TOULOUSE' },
    31242: { agency: '5B', city: 'TOULOUSE' },
    31243: { agency: '5B', city: 'TOULOUSE' },
    31244: { agency: '5B', city: 'TOULOUSE' },
    31245: { agency: '5B', city: 'TOULOUSE' },
    31249: { agency: '5B', city: 'TOULOUSE' },
    31270: { agency: '5B', city: 'TOULOUSE' },
    31280: { agency: '5B', city: 'TOULOUSE' },
    31289: { agency: '5B', city: 'TOULOUSE' },
    31290: { agency: '5B', city: 'TOULOUSE' },
    31300: { agency: '5B', city: 'TOULOUSE' },
    31312: { agency: '5B', city: 'TOULOUSE' },
    31313: { agency: '5B', city: 'TOULOUSE' },
    31314: { agency: '5B', city: 'TOULOUSE' },
    31315: { agency: '5B', city: 'TOULOUSE' },
    31317: { agency: '5B', city: 'TOULOUSE' },
    31319: { agency: '5B', city: 'TOULOUSE' },
    31320: { agency: '5B', city: 'TOULOUSE' },
    31321: { agency: '5B', city: 'TOULOUSE' },
    31322: { agency: '5B', city: 'TOULOUSE' },
    31325: { agency: '5B', city: 'TOULOUSE' },
    31326: { agency: '5B', city: 'TOULOUSE' },
    31329: { agency: '5B', city: 'TOULOUSE' },
    31330: { agency: '5B', city: 'TOULOUSE' },
    31340: { agency: '5B', city: 'TOULOUSE' },
    31380: { agency: '5B', city: 'TOULOUSE' },
    31389: { agency: '5B', city: 'TOULOUSE' },
    31400: { agency: '5B', city: 'TOULOUSE' },
    31401: { agency: '5B', city: 'TOULOUSE' },
    31402: { agency: '5B', city: 'TOULOUSE' },
    31403: { agency: '5B', city: 'TOULOUSE' },
    31404: { agency: '5B', city: 'TOULOUSE' },
    31405: { agency: '5B', city: 'TOULOUSE' },
    31406: { agency: '5B', city: 'TOULOUSE' },
    31410: { agency: '5B', city: 'TOULOUSE' },
    31432: { agency: '5B', city: 'TOULOUSE' },
    31450: { agency: '5B', city: 'TOULOUSE' },
    31460: { agency: '5B', city: 'TOULOUSE' },
    31470: { agency: '5B', city: 'TOULOUSE' },
    31489: { agency: '5B', city: 'TOULOUSE' },
    31490: { agency: '5B', city: 'TOULOUSE' },
    31500: { agency: '5B', city: 'TOULOUSE' },
    31503: { agency: '5B', city: 'TOULOUSE' },
    31504: { agency: '5B', city: 'TOULOUSE' },
    31505: { agency: '5B', city: 'TOULOUSE' },
    31506: { agency: '5B', city: 'TOULOUSE' },
    31507: { agency: '5B', city: 'TOULOUSE' },
    31512: { agency: '5B', city: 'TOULOUSE' },
    31520: { agency: '5B', city: 'TOULOUSE' },
    31521: { agency: '5B', city: 'TOULOUSE' },
    31522: { agency: '5B', city: 'TOULOUSE' },
    31524: { agency: '5B', city: 'TOULOUSE' },
    31529: { agency: '5B', city: 'TOULOUSE' },
    31530: { agency: '5B', city: 'TOULOUSE' },
    31560: { agency: '5B', city: 'TOULOUSE' },
    31570: { agency: '5B', city: 'TOULOUSE' },
    31589: { agency: '5B', city: 'TOULOUSE' },
    31590: { agency: '5B', city: 'TOULOUSE' },
    31600: { agency: '5B', city: 'TOULOUSE' },
    31601: { agency: '5B', city: 'TOULOUSE' },
    31602: { agency: '5B', city: 'TOULOUSE' },
    31603: { agency: '5B', city: 'TOULOUSE' },
    31604: { agency: '5B', city: 'TOULOUSE' },
    31605: { agency: '5B', city: 'TOULOUSE' },
    31606: { agency: '5B', city: 'TOULOUSE' },
    31608: { agency: '5B', city: 'TOULOUSE' },
    31609: { agency: '5B', city: 'TOULOUSE' },
    31620: { agency: '5B', city: 'TOULOUSE' },
    31621: { agency: '5B', city: 'TOULOUSE' },
    31629: { agency: '5B', city: 'TOULOUSE' },
    31650: { agency: '5B', city: 'TOULOUSE' },
    31660: { agency: '5B', city: 'TOULOUSE' },
    31670: { agency: '5B', city: 'TOULOUSE' },
    31671: { agency: '5B', city: 'TOULOUSE' },
    31672: { agency: '5B', city: 'TOULOUSE' },
    31673: { agency: '5B', city: 'TOULOUSE' },
    31674: { agency: '5B', city: 'TOULOUSE' },
    31675: { agency: '5B', city: 'TOULOUSE' },
    31676: { agency: '5B', city: 'TOULOUSE' },
    31677: { agency: '5B', city: 'TOULOUSE' },
    31678: { agency: '5B', city: 'TOULOUSE' },
    31679: { agency: '5B', city: 'TOULOUSE' },
    31681: { agency: '5B', city: 'TOULOUSE' },
    31682: { agency: '5B', city: 'TOULOUSE' },
    31683: { agency: '5B', city: 'TOULOUSE' },
    31685: { agency: '5B', city: 'TOULOUSE' },
    31689: { agency: '5B', city: 'TOULOUSE' },
    31692: { agency: '5B', city: 'TOULOUSE' },
    31700: { agency: '5B', city: 'TOULOUSE' },
    31701: { agency: '5B', city: 'TOULOUSE' },
    31702: { agency: '5B', city: 'TOULOUSE' },
    31703: { agency: '5B', city: 'TOULOUSE' },
    31704: { agency: '5B', city: 'TOULOUSE' },
    31705: { agency: '5B', city: 'TOULOUSE' },
    31706: { agency: '5B', city: 'TOULOUSE' },
    31707: { agency: '5B', city: 'TOULOUSE' },
    31708: { agency: '5B', city: 'TOULOUSE' },
    31709: { agency: '5B', city: 'TOULOUSE' },
    31711: { agency: '5B', city: 'TOULOUSE' },
    31712: { agency: '5B', city: 'TOULOUSE' },
    31715: { agency: '5B', city: 'TOULOUSE' },
    31716: { agency: '5B', city: 'TOULOUSE' },
    31750: { agency: '5B', city: 'TOULOUSE' },
    31770: { agency: '5B', city: 'TOULOUSE' },
    31771: { agency: '5B', city: 'TOULOUSE' },
    31772: { agency: '5B', city: 'TOULOUSE' },
    31773: { agency: '5B', city: 'TOULOUSE' },
    31774: { agency: '5B', city: 'TOULOUSE' },
    31776: { agency: '5B', city: 'TOULOUSE' },
    31777: { agency: '5B', city: 'TOULOUSE' },
    31779: { agency: '5B', city: 'TOULOUSE' },
    31780: { agency: '5B', city: 'TOULOUSE' },
    31790: { agency: '5B', city: 'TOULOUSE' },
    31810: { agency: '5B', city: 'TOULOUSE' },
    31820: { agency: '5B', city: 'TOULOUSE' },
    31830: { agency: '5B', city: 'TOULOUSE' },
    31831: { agency: '5B', city: 'TOULOUSE' },
    31832: { agency: '5B', city: 'TOULOUSE' },
    31839: { agency: '5B', city: 'TOULOUSE' },
    31840: { agency: '5B', city: 'TOULOUSE' },
    31850: { agency: '5B', city: 'TOULOUSE' },
    31860: { agency: '5B', city: 'TOULOUSE' },
    31870: { agency: '5B', city: 'TOULOUSE' },
    31880: { agency: '5B', city: 'TOULOUSE' },
    31900: { agency: '5B', city: 'TOULOUSE' },
    31901: { agency: '5B', city: 'TOULOUSE' },
    31902: { agency: '5B', city: 'TOULOUSE' },
    31903: { agency: '5B', city: 'TOULOUSE' },
    31931: { agency: '5B', city: 'TOULOUSE' },
    31945: { agency: '5B', city: 'TOULOUSE' },
    31947: { agency: '5B', city: 'TOULOUSE' },
    31950: { agency: '5B', city: 'TOULOUSE' },
    31957: { agency: '5B', city: 'TOULOUSE' },
    31958: { agency: '5B', city: 'TOULOUSE' },
    31960: { agency: '5B', city: 'TOULOUSE' },
    31962: { agency: '5B', city: 'TOULOUSE' },
    31998: { agency: '5B', city: 'TOULOUSE' },
    31999: { agency: '5B', city: 'TOULOUSE' },
    32600: { agency: '5B', city: 'TOULOUSE' },
    81370: { agency: '5B', city: 'TOULOUSE' },
    81500: { agency: '5B', city: 'TOULOUSE' },
    81600: { agency: '5B', city: 'TOULOUSE' },
    82000: { agency: '5B', city: 'TOULOUSE' },
    82170: { agency: '5B', city: 'TOULOUSE' },
    82370: { agency: '5B', city: 'TOULOUSE' },
    82600: { agency: '5B', city: 'TOULOUSE' },
    82700: { agency: '5B', city: 'TOULOUSE' },
    82710: { agency: '5B', city: 'TOULOUSE' },
    18240: { agency: '3M', city: 'VILLEMANDEUR' },
    18410: { agency: '3M', city: 'VILLEMANDEUR' },
    45110: { agency: '3M', city: 'VILLEMANDEUR' },
    45120: { agency: '3M', city: 'VILLEMANDEUR' },
    45121: { agency: '3M', city: 'VILLEMANDEUR' },
    45122: { agency: '3M', city: 'VILLEMANDEUR' },
    45125: { agency: '3M', city: 'VILLEMANDEUR' },
    45129: { agency: '3M', city: 'VILLEMANDEUR' },
    45200: { agency: '3M', city: 'VILLEMANDEUR' },
    45201: { agency: '3M', city: 'VILLEMANDEUR' },
    45202: { agency: '3M', city: 'VILLEMANDEUR' },
    45203: { agency: '3M', city: 'VILLEMANDEUR' },
    45204: { agency: '3M', city: 'VILLEMANDEUR' },
    45205: { agency: '3M', city: 'VILLEMANDEUR' },
    45206: { agency: '3M', city: 'VILLEMANDEUR' },
    45207: { agency: '3M', city: 'VILLEMANDEUR' },
    45208: { agency: '3M', city: 'VILLEMANDEUR' },
    45209: { agency: '3M', city: 'VILLEMANDEUR' },
    45210: { agency: '3M', city: 'VILLEMANDEUR' },
    45211: { agency: '3M', city: 'VILLEMANDEUR' },
    45212: { agency: '3M', city: 'VILLEMANDEUR' },
    45213: { agency: '3M', city: 'VILLEMANDEUR' },
    45214: { agency: '3M', city: 'VILLEMANDEUR' },
    45220: { agency: '3M', city: 'VILLEMANDEUR' },
    45230: { agency: '3M', city: 'VILLEMANDEUR' },
    45250: { agency: '3M', city: 'VILLEMANDEUR' },
    45260: { agency: '3M', city: 'VILLEMANDEUR' },
    45270: { agency: '3M', city: 'VILLEMANDEUR' },
    45290: { agency: '3M', city: 'VILLEMANDEUR' },
    45300: { agency: '3M', city: 'VILLEMANDEUR' },
    45320: { agency: '3M', city: 'VILLEMANDEUR' },
    45330: { agency: '3M', city: 'VILLEMANDEUR' },
    45340: { agency: '3M', city: 'VILLEMANDEUR' },
    45360: { agency: '3M', city: 'VILLEMANDEUR' },
    45390: { agency: '3M', city: 'VILLEMANDEUR' },
    45420: { agency: '3M', city: 'VILLEMANDEUR' },
    45450: { agency: '3M', city: 'VILLEMANDEUR' },
    45460: { agency: '3M', city: 'VILLEMANDEUR' },
    45490: { agency: '3M', city: 'VILLEMANDEUR' },
    45500: { agency: '3M', city: 'VILLEMANDEUR' },
    45530: { agency: '3M', city: 'VILLEMANDEUR' },
    45570: { agency: '3M', city: 'VILLEMANDEUR' },
    45600: { agency: '3M', city: 'VILLEMANDEUR' },
    45620: { agency: '3M', city: 'VILLEMANDEUR' },
    45630: { agency: '3M', city: 'VILLEMANDEUR' },
    45680: { agency: '3M', city: 'VILLEMANDEUR' },
    45700: { agency: '3M', city: 'VILLEMANDEUR' },
    45701: { agency: '3M', city: 'VILLEMANDEUR' },
    45702: { agency: '3M', city: 'VILLEMANDEUR' },
    45709: { agency: '3M', city: 'VILLEMANDEUR' },
    45720: { agency: '3M', city: 'VILLEMANDEUR' },
    45730: { agency: '3M', city: 'VILLEMANDEUR' },
    58200: { agency: '3M', city: 'VILLEMANDEUR' },
    58310: { agency: '3M', city: 'VILLEMANDEUR' },
    58440: { agency: '3M', city: 'VILLEMANDEUR' },
    58540: { agency: '3M', city: 'VILLEMANDEUR' },
    77140: { agency: '3M', city: 'VILLEMANDEUR' },
    77167: { agency: '3M', city: 'VILLEMANDEUR' },
    77460: { agency: '3M', city: 'VILLEMANDEUR' },
    77570: { agency: '3M', city: 'VILLEMANDEUR' },
    77620: { agency: '3M', city: 'VILLEMANDEUR' },
    77710: { agency: '3M', city: 'VILLEMANDEUR' },
    77890: { agency: '3M', city: 'VILLEMANDEUR' },
    89120: { agency: '3M', city: 'VILLEMANDEUR' },
    89150: { agency: '3M', city: 'VILLEMANDEUR' },
    89170: { agency: '3M', city: 'VILLEMANDEUR' },
    89220: { agency: '3M', city: 'VILLEMANDEUR' },
    89690: { agency: '3M', city: 'VILLEMANDEUR' },
    1090: { agency: '4V', city: 'VILLEURBANNE' },
    1120: { agency: '4V', city: 'VILLEURBANNE' },
    1121: { agency: '4V', city: 'VILLEURBANNE' },
    1122: { agency: '4V', city: 'VILLEURBANNE' },
    1123: { agency: '4V', city: 'VILLEURBANNE' },
    1124: { agency: '4V', city: 'VILLEURBANNE' },
    1125: { agency: '4V', city: 'VILLEURBANNE' },
    1126: { agency: '4V', city: 'VILLEURBANNE' },
    1127: { agency: '4V', city: 'VILLEURBANNE' },
    1128: { agency: '4V', city: 'VILLEURBANNE' },
    1129: { agency: '4V', city: 'VILLEURBANNE' },
    1140: { agency: '4V', city: 'VILLEURBANNE' },
    1360: { agency: '4V', city: 'VILLEURBANNE' },
    1365: { agency: '4V', city: 'VILLEURBANNE' },
    1366: { agency: '4V', city: 'VILLEURBANNE' },
    1369: { agency: '4V', city: 'VILLEURBANNE' },
    1390: { agency: '4V', city: 'VILLEURBANNE' },
    1480: { agency: '4V', city: 'VILLEURBANNE' },
    1600: { agency: '4V', city: 'VILLEURBANNE' },
    1601: { agency: '4V', city: 'VILLEURBANNE' },
    1602: { agency: '4V', city: 'VILLEURBANNE' },
    1603: { agency: '4V', city: 'VILLEURBANNE' },
    1604: { agency: '4V', city: 'VILLEURBANNE' },
    1606: { agency: '4V', city: 'VILLEURBANNE' },
    1700: { agency: '4V', city: 'VILLEURBANNE' },
    1701: { agency: '4V', city: 'VILLEURBANNE' },
    1702: { agency: '4V', city: 'VILLEURBANNE' },
    1703: { agency: '4V', city: 'VILLEURBANNE' },
    1704: { agency: '4V', city: 'VILLEURBANNE' },
    1705: { agency: '4V', city: 'VILLEURBANNE' },
    1706: { agency: '4V', city: 'VILLEURBANNE' },
    1707: { agency: '4V', city: 'VILLEURBANNE' },
    1708: { agency: '4V', city: 'VILLEURBANNE' },
    1800: { agency: '4V', city: 'VILLEURBANNE' },
    1921: { agency: '4V', city: 'VILLEURBANNE' },
    38122: { agency: '4V', city: 'VILLEURBANNE' },
    38200: { agency: '4V', city: 'VILLEURBANNE' },
    38230: { agency: '4V', city: 'VILLEURBANNE' },
    38231: { agency: '4V', city: 'VILLEURBANNE' },
    38232: { agency: '4V', city: 'VILLEURBANNE' },
    38236: { agency: '4V', city: 'VILLEURBANNE' },
    38670: { agency: '4V', city: 'VILLEURBANNE' },
    38780: { agency: '4V', city: 'VILLEURBANNE' },
    69000: { agency: '4V', city: 'VILLEURBANNE' },
    69001: { agency: '4V', city: 'VILLEURBANNE' },
    69002: { agency: '4V', city: 'VILLEURBANNE' },
    69003: { agency: '4V', city: 'VILLEURBANNE' },
    69004: { agency: '4V', city: 'VILLEURBANNE' },
    69005: { agency: '4V', city: 'VILLEURBANNE' },
    69006: { agency: '4V', city: 'VILLEURBANNE' },
    69007: { agency: '4V', city: 'VILLEURBANNE' },
    69008: { agency: '4V', city: 'VILLEURBANNE' },
    69009: { agency: '4V', city: 'VILLEURBANNE' },
    69061: { agency: '4V', city: 'VILLEURBANNE' },
    69062: { agency: '4V', city: 'VILLEURBANNE' },
    69064: { agency: '4V', city: 'VILLEURBANNE' },
    69100: { agency: '4V', city: 'VILLEURBANNE' },
    69110: { agency: '4V', city: 'VILLEURBANNE' },
    69120: { agency: '4V', city: 'VILLEURBANNE' },
    69124: { agency: '4V', city: 'VILLEURBANNE' },
    69125: { agency: '4V', city: 'VILLEURBANNE' },
    69126: { agency: '4V', city: 'VILLEURBANNE' },
    69129: { agency: '4V', city: 'VILLEURBANNE' },
    69130: { agency: '4V', city: 'VILLEURBANNE' },
    69131: { agency: '4V', city: 'VILLEURBANNE' },
    69132: { agency: '4V', city: 'VILLEURBANNE' },
    69134: { agency: '4V', city: 'VILLEURBANNE' },
    69136: { agency: '4V', city: 'VILLEURBANNE' },
    69139: { agency: '4V', city: 'VILLEURBANNE' },
    69140: { agency: '4V', city: 'VILLEURBANNE' },
    69141: { agency: '4V', city: 'VILLEURBANNE' },
    69142: { agency: '4V', city: 'VILLEURBANNE' },
    69143: { agency: '4V', city: 'VILLEURBANNE' },
    69144: { agency: '4V', city: 'VILLEURBANNE' },
    69145: { agency: '4V', city: 'VILLEURBANNE' },
    69147: { agency: '4V', city: 'VILLEURBANNE' },
    69148: { agency: '4V', city: 'VILLEURBANNE' },
    69150: { agency: '4V', city: 'VILLEURBANNE' },
    69151: { agency: '4V', city: 'VILLEURBANNE' },
    69152: { agency: '4V', city: 'VILLEURBANNE' },
    69153: { agency: '4V', city: 'VILLEURBANNE' },
    69154: { agency: '4V', city: 'VILLEURBANNE' },
    69159: { agency: '4V', city: 'VILLEURBANNE' },
    69160: { agency: '4V', city: 'VILLEURBANNE' },
    69161: { agency: '4V', city: 'VILLEURBANNE' },
    69190: { agency: '4V', city: 'VILLEURBANNE' },
    69191: { agency: '4V', city: 'VILLEURBANNE' },
    69192: { agency: '4V', city: 'VILLEURBANNE' },
    69195: { agency: '4V', city: 'VILLEURBANNE' },
    69199: { agency: '4V', city: 'VILLEURBANNE' },
    69200: { agency: '4V', city: 'VILLEURBANNE' },
    69201: { agency: '4V', city: 'VILLEURBANNE' },
    69202: { agency: '4V', city: 'VILLEURBANNE' },
    69203: { agency: '4V', city: 'VILLEURBANNE' },
    69204: { agency: '4V', city: 'VILLEURBANNE' },
    69205: { agency: '4V', city: 'VILLEURBANNE' },
    69206: { agency: '4V', city: 'VILLEURBANNE' },
    69207: { agency: '4V', city: 'VILLEURBANNE' },
    69208: { agency: '4V', city: 'VILLEURBANNE' },
    69209: { agency: '4V', city: 'VILLEURBANNE' },
    69210: { agency: '4V', city: 'VILLEURBANNE' },
    69211: { agency: '4V', city: 'VILLEURBANNE' },
    69212: { agency: '4V', city: 'VILLEURBANNE' },
    69213: { agency: '4V', city: 'VILLEURBANNE' },
    69214: { agency: '4V', city: 'VILLEURBANNE' },
    69215: { agency: '4V', city: 'VILLEURBANNE' },
    69216: { agency: '4V', city: 'VILLEURBANNE' },
    69217: { agency: '4V', city: 'VILLEURBANNE' },
    69218: { agency: '4V', city: 'VILLEURBANNE' },
    69219: { agency: '4V', city: 'VILLEURBANNE' },
    69220: { agency: '4V', city: 'VILLEURBANNE' },
    69225: { agency: '4V', city: 'VILLEURBANNE' },
    69226: { agency: '4V', city: 'VILLEURBANNE' },
    69227: { agency: '4V', city: 'VILLEURBANNE' },
    69228: { agency: '4V', city: 'VILLEURBANNE' },
    69229: { agency: '4V', city: 'VILLEURBANNE' },
    69230: { agency: '4V', city: 'VILLEURBANNE' },
    69232: { agency: '4V', city: 'VILLEURBANNE' },
    69237: { agency: '4V', city: 'VILLEURBANNE' },
    69239: { agency: '4V', city: 'VILLEURBANNE' },
    69241: { agency: '4V', city: 'VILLEURBANNE' },
    69242: { agency: '4V', city: 'VILLEURBANNE' },
    69243: { agency: '4V', city: 'VILLEURBANNE' },
    69244: { agency: '4V', city: 'VILLEURBANNE' },
    69245: { agency: '4V', city: 'VILLEURBANNE' },
    69246: { agency: '4V', city: 'VILLEURBANNE' },
    69247: { agency: '4V', city: 'VILLEURBANNE' },
    69250: { agency: '4V', city: 'VILLEURBANNE' },
    69251: { agency: '4V', city: 'VILLEURBANNE' },
    69252: { agency: '4V', city: 'VILLEURBANNE' },
    69253: { agency: '4V', city: 'VILLEURBANNE' },
    69255: { agency: '4V', city: 'VILLEURBANNE' },
    69256: { agency: '4V', city: 'VILLEURBANNE' },
    69257: { agency: '4V', city: 'VILLEURBANNE' },
    69258: { agency: '4V', city: 'VILLEURBANNE' },
    69259: { agency: '4V', city: 'VILLEURBANNE' },
    69260: { agency: '4V', city: 'VILLEURBANNE' },
    69261: { agency: '4V', city: 'VILLEURBANNE' },
    69262: { agency: '4V', city: 'VILLEURBANNE' },
    69263: { agency: '4V', city: 'VILLEURBANNE' },
    69264: { agency: '4V', city: 'VILLEURBANNE' },
    69265: { agency: '4V', city: 'VILLEURBANNE' },
    69266: { agency: '4V', city: 'VILLEURBANNE' },
    69267: { agency: '4V', city: 'VILLEURBANNE' },
    69268: { agency: '4V', city: 'VILLEURBANNE' },
    69269: { agency: '4V', city: 'VILLEURBANNE' },
    69270: { agency: '4V', city: 'VILLEURBANNE' },
    69271: { agency: '4V', city: 'VILLEURBANNE' },
    69272: { agency: '4V', city: 'VILLEURBANNE' },
    69275: { agency: '4V', city: 'VILLEURBANNE' },
    69276: { agency: '4V', city: 'VILLEURBANNE' },
    69279: { agency: '4V', city: 'VILLEURBANNE' },
    69280: { agency: '4V', city: 'VILLEURBANNE' },
    69281: { agency: '4V', city: 'VILLEURBANNE' },
    69282: { agency: '4V', city: 'VILLEURBANNE' },
    69283: { agency: '4V', city: 'VILLEURBANNE' },
    69284: { agency: '4V', city: 'VILLEURBANNE' },
    69285: { agency: '4V', city: 'VILLEURBANNE' },
    69286: { agency: '4V', city: 'VILLEURBANNE' },
    69287: { agency: '4V', city: 'VILLEURBANNE' },
    69288: { agency: '4V', city: 'VILLEURBANNE' },
    69289: { agency: '4V', city: 'VILLEURBANNE' },
    69290: { agency: '4V', city: 'VILLEURBANNE' },
    69291: { agency: '4V', city: 'VILLEURBANNE' },
    69292: { agency: '4V', city: 'VILLEURBANNE' },
    69293: { agency: '4V', city: 'VILLEURBANNE' },
    69294: { agency: '4V', city: 'VILLEURBANNE' },
    69300: { agency: '4V', city: 'VILLEURBANNE' },
    69301: { agency: '4V', city: 'VILLEURBANNE' },
    69302: { agency: '4V', city: 'VILLEURBANNE' },
    69303: { agency: '4V', city: 'VILLEURBANNE' },
    69307: { agency: '4V', city: 'VILLEURBANNE' },
    69309: { agency: '4V', city: 'VILLEURBANNE' },
    69310: { agency: '4V', city: 'VILLEURBANNE' },
    69316: { agency: '4V', city: 'VILLEURBANNE' },
    69317: { agency: '4V', city: 'VILLEURBANNE' },
    69319: { agency: '4V', city: 'VILLEURBANNE' },
    69320: { agency: '4V', city: 'VILLEURBANNE' },
    69321: { agency: '4V', city: 'VILLEURBANNE' },
    69322: { agency: '4V', city: 'VILLEURBANNE' },
    69326: { agency: '4V', city: 'VILLEURBANNE' },
    69327: { agency: '4V', city: 'VILLEURBANNE' },
    69328: { agency: '4V', city: 'VILLEURBANNE' },
    69329: { agency: '4V', city: 'VILLEURBANNE' },
    69330: { agency: '4V', city: 'VILLEURBANNE' },
    69336: { agency: '4V', city: 'VILLEURBANNE' },
    69337: { agency: '4V', city: 'VILLEURBANNE' },
    69338: { agency: '4V', city: 'VILLEURBANNE' },
    69339: { agency: '4V', city: 'VILLEURBANNE' },
    69340: { agency: '4V', city: 'VILLEURBANNE' },
    69341: { agency: '4V', city: 'VILLEURBANNE' },
    69342: { agency: '4V', city: 'VILLEURBANNE' },
    69343: { agency: '4V', city: 'VILLEURBANNE' },
    69344: { agency: '4V', city: 'VILLEURBANNE' },
    69345: { agency: '4V', city: 'VILLEURBANNE' },
    69346: { agency: '4V', city: 'VILLEURBANNE' },
    69347: { agency: '4V', city: 'VILLEURBANNE' },
    69348: { agency: '4V', city: 'VILLEURBANNE' },
    69349: { agency: '4V', city: 'VILLEURBANNE' },
    69350: { agency: '4V', city: 'VILLEURBANNE' },
    69351: { agency: '4V', city: 'VILLEURBANNE' },
    69352: { agency: '4V', city: 'VILLEURBANNE' },
    69353: { agency: '4V', city: 'VILLEURBANNE' },
    69354: { agency: '4V', city: 'VILLEURBANNE' },
    69355: { agency: '4V', city: 'VILLEURBANNE' },
    69356: { agency: '4V', city: 'VILLEURBANNE' },
    69357: { agency: '4V', city: 'VILLEURBANNE' },
    69358: { agency: '4V', city: 'VILLEURBANNE' },
    69359: { agency: '4V', city: 'VILLEURBANNE' },
    69360: { agency: '4V', city: 'VILLEURBANNE' },
    69361: { agency: '4V', city: 'VILLEURBANNE' },
    69362: { agency: '4V', city: 'VILLEURBANNE' },
    69363: { agency: '4V', city: 'VILLEURBANNE' },
    69364: { agency: '4V', city: 'VILLEURBANNE' },
    69365: { agency: '4V', city: 'VILLEURBANNE' },
    69366: { agency: '4V', city: 'VILLEURBANNE' },
    69367: { agency: '4V', city: 'VILLEURBANNE' },
    69369: { agency: '4V', city: 'VILLEURBANNE' },
    69370: { agency: '4V', city: 'VILLEURBANNE' },
    69371: { agency: '4V', city: 'VILLEURBANNE' },
    69372: { agency: '4V', city: 'VILLEURBANNE' },
    69373: { agency: '4V', city: 'VILLEURBANNE' },
    69376: { agency: '4V', city: 'VILLEURBANNE' },
    69377: { agency: '4V', city: 'VILLEURBANNE' },
    69379: { agency: '4V', city: 'VILLEURBANNE' },
    69380: { agency: '4V', city: 'VILLEURBANNE' },
    69384: { agency: '4V', city: 'VILLEURBANNE' },
    69385: { agency: '4V', city: 'VILLEURBANNE' },
    69389: { agency: '4V', city: 'VILLEURBANNE' },
    69390: { agency: '4V', city: 'VILLEURBANNE' },
    69391: { agency: '4V', city: 'VILLEURBANNE' },
    69392: { agency: '4V', city: 'VILLEURBANNE' },
    69393: { agency: '4V', city: 'VILLEURBANNE' },
    69394: { agency: '4V', city: 'VILLEURBANNE' },
    69395: { agency: '4V', city: 'VILLEURBANNE' },
    69396: { agency: '4V', city: 'VILLEURBANNE' },
    69397: { agency: '4V', city: 'VILLEURBANNE' },
    69398: { agency: '4V', city: 'VILLEURBANNE' },
    69399: { agency: '4V', city: 'VILLEURBANNE' },
    69400: { agency: '4V', city: 'VILLEURBANNE' },
    69401: { agency: '4V', city: 'VILLEURBANNE' },
    69402: { agency: '4V', city: 'VILLEURBANNE' },
    69403: { agency: '4V', city: 'VILLEURBANNE' },
    69404: { agency: '4V', city: 'VILLEURBANNE' },
    69405: { agency: '4V', city: 'VILLEURBANNE' },
    69406: { agency: '4V', city: 'VILLEURBANNE' },
    69408: { agency: '4V', city: 'VILLEURBANNE' },
    69409: { agency: '4V', city: 'VILLEURBANNE' },
    69410: { agency: '4V', city: 'VILLEURBANNE' },
    69411: { agency: '4V', city: 'VILLEURBANNE' },
    69412: { agency: '4V', city: 'VILLEURBANNE' },
    69413: { agency: '4V', city: 'VILLEURBANNE' },
    69414: { agency: '4V', city: 'VILLEURBANNE' },
    69415: { agency: '4V', city: 'VILLEURBANNE' },
    69416: { agency: '4V', city: 'VILLEURBANNE' },
    69417: { agency: '4V', city: 'VILLEURBANNE' },
    69418: { agency: '4V', city: 'VILLEURBANNE' },
    69419: { agency: '4V', city: 'VILLEURBANNE' },
    69421: { agency: '4V', city: 'VILLEURBANNE' },
    69422: { agency: '4V', city: 'VILLEURBANNE' },
    69423: { agency: '4V', city: 'VILLEURBANNE' },
    69424: { agency: '4V', city: 'VILLEURBANNE' },
    69425: { agency: '4V', city: 'VILLEURBANNE' },
    69426: { agency: '4V', city: 'VILLEURBANNE' },
    69427: { agency: '4V', city: 'VILLEURBANNE' },
    69428: { agency: '4V', city: 'VILLEURBANNE' },
    69429: { agency: '4V', city: 'VILLEURBANNE' },
    69430: { agency: '4V', city: 'VILLEURBANNE' },
    69431: { agency: '4V', city: 'VILLEURBANNE' },
    69432: { agency: '4V', city: 'VILLEURBANNE' },
    69433: { agency: '4V', city: 'VILLEURBANNE' },
    69434: { agency: '4V', city: 'VILLEURBANNE' },
    69435: { agency: '4V', city: 'VILLEURBANNE' },
    69436: { agency: '4V', city: 'VILLEURBANNE' },
    69437: { agency: '4V', city: 'VILLEURBANNE' },
    69438: { agency: '4V', city: 'VILLEURBANNE' },
    69439: { agency: '4V', city: 'VILLEURBANNE' },
    69440: { agency: '4V', city: 'VILLEURBANNE' },
    69441: { agency: '4V', city: 'VILLEURBANNE' },
    69442: { agency: '4V', city: 'VILLEURBANNE' },
    69443: { agency: '4V', city: 'VILLEURBANNE' },
    69444: { agency: '4V', city: 'VILLEURBANNE' },
    69445: { agency: '4V', city: 'VILLEURBANNE' },
    69446: { agency: '4V', city: 'VILLEURBANNE' },
    69447: { agency: '4V', city: 'VILLEURBANNE' },
    69448: { agency: '4V', city: 'VILLEURBANNE' },
    69449: { agency: '4V', city: 'VILLEURBANNE' },
    69450: { agency: '4V', city: 'VILLEURBANNE' },
    69451: { agency: '4V', city: 'VILLEURBANNE' },
    69452: { agency: '4V', city: 'VILLEURBANNE' },
    69453: { agency: '4V', city: 'VILLEURBANNE' },
    69454: { agency: '4V', city: 'VILLEURBANNE' },
    69455: { agency: '4V', city: 'VILLEURBANNE' },
    69456: { agency: '4V', city: 'VILLEURBANNE' },
    69457: { agency: '4V', city: 'VILLEURBANNE' },
    69458: { agency: '4V', city: 'VILLEURBANNE' },
    69459: { agency: '4V', city: 'VILLEURBANNE' },
    69460: { agency: '4V', city: 'VILLEURBANNE' },
    69461: { agency: '4V', city: 'VILLEURBANNE' },
    69463: { agency: '4V', city: 'VILLEURBANNE' },
    69464: { agency: '4V', city: 'VILLEURBANNE' },
    69465: { agency: '4V', city: 'VILLEURBANNE' },
    69466: { agency: '4V', city: 'VILLEURBANNE' },
    69467: { agency: '4V', city: 'VILLEURBANNE' },
    69468: { agency: '4V', city: 'VILLEURBANNE' },
    69469: { agency: '4V', city: 'VILLEURBANNE' },
    69472: { agency: '4V', city: 'VILLEURBANNE' },
    69474: { agency: '4V', city: 'VILLEURBANNE' },
    69475: { agency: '4V', city: 'VILLEURBANNE' },
    69476: { agency: '4V', city: 'VILLEURBANNE' },
    69477: { agency: '4V', city: 'VILLEURBANNE' },
    69478: { agency: '4V', city: 'VILLEURBANNE' },
    69479: { agency: '4V', city: 'VILLEURBANNE' },
    69480: { agency: '4V', city: 'VILLEURBANNE' },
    69481: { agency: '4V', city: 'VILLEURBANNE' },
    69482: { agency: '4V', city: 'VILLEURBANNE' },
    69483: { agency: '4V', city: 'VILLEURBANNE' },
    69484: { agency: '4V', city: 'VILLEURBANNE' },
    69485: { agency: '4V', city: 'VILLEURBANNE' },
    69486: { agency: '4V', city: 'VILLEURBANNE' },
    69487: { agency: '4V', city: 'VILLEURBANNE' },
    69488: { agency: '4V', city: 'VILLEURBANNE' },
    69489: { agency: '4V', city: 'VILLEURBANNE' },
    69491: { agency: '4V', city: 'VILLEURBANNE' },
    69492: { agency: '4V', city: 'VILLEURBANNE' },
    69493: { agency: '4V', city: 'VILLEURBANNE' },
    69495: { agency: '4V', city: 'VILLEURBANNE' },
    69500: { agency: '4V', city: 'VILLEURBANNE' },
    69501: { agency: '4V', city: 'VILLEURBANNE' },
    69502: { agency: '4V', city: 'VILLEURBANNE' },
    69503: { agency: '4V', city: 'VILLEURBANNE' },
    69505: { agency: '4V', city: 'VILLEURBANNE' },
    69509: { agency: '4V', city: 'VILLEURBANNE' },
    69510: { agency: '4V', city: 'VILLEURBANNE' },
    69511: { agency: '4V', city: 'VILLEURBANNE' },
    69512: { agency: '4V', city: 'VILLEURBANNE' },
    69513: { agency: '4V', city: 'VILLEURBANNE' },
    69514: { agency: '4V', city: 'VILLEURBANNE' },
    69515: { agency: '4V', city: 'VILLEURBANNE' },
    69516: { agency: '4V', city: 'VILLEURBANNE' },
    69517: { agency: '4V', city: 'VILLEURBANNE' },
    69518: { agency: '4V', city: 'VILLEURBANNE' },
    69519: { agency: '4V', city: 'VILLEURBANNE' },
    69520: { agency: '4V', city: 'VILLEURBANNE' },
    69530: { agency: '4V', city: 'VILLEURBANNE' },
    69531: { agency: '4V', city: 'VILLEURBANNE' },
    69540: { agency: '4V', city: 'VILLEURBANNE' },
    69541: { agency: '4V', city: 'VILLEURBANNE' },
    69542: { agency: '4V', city: 'VILLEURBANNE' },
    69543: { agency: '4V', city: 'VILLEURBANNE' },
    69544: { agency: '4V', city: 'VILLEURBANNE' },
    69551: { agency: '4V', city: 'VILLEURBANNE' },
    69552: { agency: '4V', city: 'VILLEURBANNE' },
    69553: { agency: '4V', city: 'VILLEURBANNE' },
    69560: { agency: '4V', city: 'VILLEURBANNE' },
    69561: { agency: '4V', city: 'VILLEURBANNE' },
    69563: { agency: '4V', city: 'VILLEURBANNE' },
    69564: { agency: '4V', city: 'VILLEURBANNE' },
    69565: { agency: '4V', city: 'VILLEURBANNE' },
    69569: { agency: '4V', city: 'VILLEURBANNE' },
    69570: { agency: '4V', city: 'VILLEURBANNE' },
    69571: { agency: '4V', city: 'VILLEURBANNE' },
    69572: { agency: '4V', city: 'VILLEURBANNE' },
    69573: { agency: '4V', city: 'VILLEURBANNE' },
    69574: { agency: '4V', city: 'VILLEURBANNE' },
    69576: { agency: '4V', city: 'VILLEURBANNE' },
    69578: { agency: '4V', city: 'VILLEURBANNE' },
    69579: { agency: '4V', city: 'VILLEURBANNE' },
    69580: { agency: '4V', city: 'VILLEURBANNE' },
    69581: { agency: '4V', city: 'VILLEURBANNE' },
    69582: { agency: '4V', city: 'VILLEURBANNE' },
    69583: { agency: '4V', city: 'VILLEURBANNE' },
    69591: { agency: '4V', city: 'VILLEURBANNE' },
    69592: { agency: '4V', city: 'VILLEURBANNE' },
    69593: { agency: '4V', city: 'VILLEURBANNE' },
    69594: { agency: '4V', city: 'VILLEURBANNE' },
    69595: { agency: '4V', city: 'VILLEURBANNE' },
    69600: { agency: '4V', city: 'VILLEURBANNE' },
    69601: { agency: '4V', city: 'VILLEURBANNE' },
    69602: { agency: '4V', city: 'VILLEURBANNE' },
    69603: { agency: '4V', city: 'VILLEURBANNE' },
    69604: { agency: '4V', city: 'VILLEURBANNE' },
    69605: { agency: '4V', city: 'VILLEURBANNE' },
    69606: { agency: '4V', city: 'VILLEURBANNE' },
    69607: { agency: '4V', city: 'VILLEURBANNE' },
    69608: { agency: '4V', city: 'VILLEURBANNE' },
    69609: { agency: '4V', city: 'VILLEURBANNE' },
    69611: { agency: '4V', city: 'VILLEURBANNE' },
    69612: { agency: '4V', city: 'VILLEURBANNE' },
    69613: { agency: '4V', city: 'VILLEURBANNE' },
    69615: { agency: '4V', city: 'VILLEURBANNE' },
    69616: { agency: '4V', city: 'VILLEURBANNE' },
    69619: { agency: '4V', city: 'VILLEURBANNE' },
    69620: { agency: '4V', city: 'VILLEURBANNE' },
    69621: { agency: '4V', city: 'VILLEURBANNE' },
    69622: { agency: '4V', city: 'VILLEURBANNE' },
    69623: { agency: '4V', city: 'VILLEURBANNE' },
    69624: { agency: '4V', city: 'VILLEURBANNE' },
    69625: { agency: '4V', city: 'VILLEURBANNE' },
    69626: { agency: '4V', city: 'VILLEURBANNE' },
    69627: { agency: '4V', city: 'VILLEURBANNE' },
    69628: { agency: '4V', city: 'VILLEURBANNE' },
    69629: { agency: '4V', city: 'VILLEURBANNE' },
    69630: { agency: '4V', city: 'VILLEURBANNE' },
    69631: { agency: '4V', city: 'VILLEURBANNE' },
    69632: { agency: '4V', city: 'VILLEURBANNE' },
    69633: { agency: '4V', city: 'VILLEURBANNE' },
    69634: { agency: '4V', city: 'VILLEURBANNE' },
    69635: { agency: '4V', city: 'VILLEURBANNE' },
    69636: { agency: '4V', city: 'VILLEURBANNE' },
    69637: { agency: '4V', city: 'VILLEURBANNE' },
    69638: { agency: '4V', city: 'VILLEURBANNE' },
    69639: { agency: '4V', city: 'VILLEURBANNE' },
    69640: { agency: '4V', city: 'VILLEURBANNE' },
    69641: { agency: '4V', city: 'VILLEURBANNE' },
    69642: { agency: '4V', city: 'VILLEURBANNE' },
    69643: { agency: '4V', city: 'VILLEURBANNE' },
    69644: { agency: '4V', city: 'VILLEURBANNE' },
    69645: { agency: '4V', city: 'VILLEURBANNE' },
    69646: { agency: '4V', city: 'VILLEURBANNE' },
    69647: { agency: '4V', city: 'VILLEURBANNE' },
    69648: { agency: '4V', city: 'VILLEURBANNE' },
    69649: { agency: '4V', city: 'VILLEURBANNE' },
    69650: { agency: '4V', city: 'VILLEURBANNE' },
    69660: { agency: '4V', city: 'VILLEURBANNE' },
    69670: { agency: '4V', city: 'VILLEURBANNE' },
    69671: { agency: '4V', city: 'VILLEURBANNE' },
    69672: { agency: '4V', city: 'VILLEURBANNE' },
    69673: { agency: '4V', city: 'VILLEURBANNE' },
    69674: { agency: '4V', city: 'VILLEURBANNE' },
    69675: { agency: '4V', city: 'VILLEURBANNE' },
    69676: { agency: '4V', city: 'VILLEURBANNE' },
    69677: { agency: '4V', city: 'VILLEURBANNE' },
    69679: { agency: '4V', city: 'VILLEURBANNE' },
    69680: { agency: '4V', city: 'VILLEURBANNE' },
    69682: { agency: '4V', city: 'VILLEURBANNE' },
    69684: { agency: '4V', city: 'VILLEURBANNE' },
    69685: { agency: '4V', city: 'VILLEURBANNE' },
    69686: { agency: '4V', city: 'VILLEURBANNE' },
    69687: { agency: '4V', city: 'VILLEURBANNE' },
    69689: { agency: '4V', city: 'VILLEURBANNE' },
    69690: { agency: '4V', city: 'VILLEURBANNE' },
    69691: { agency: '4V', city: 'VILLEURBANNE' },
    69692: { agency: '4V', city: 'VILLEURBANNE' },
    69693: { agency: '4V', city: 'VILLEURBANNE' },
    69694: { agency: '4V', city: 'VILLEURBANNE' },
    69696: { agency: '4V', city: 'VILLEURBANNE' },
    69700: { agency: '4V', city: 'VILLEURBANNE' },
    69701: { agency: '4V', city: 'VILLEURBANNE' },
    69702: { agency: '4V', city: 'VILLEURBANNE' },
    69709: { agency: '4V', city: 'VILLEURBANNE' },
    69720: { agency: '4V', city: 'VILLEURBANNE' },
    69726: { agency: '4V', city: 'VILLEURBANNE' },
    69727: { agency: '4V', city: 'VILLEURBANNE' },
    69729: { agency: '4V', city: 'VILLEURBANNE' },
    69730: { agency: '4V', city: 'VILLEURBANNE' },
    69731: { agency: '4V', city: 'VILLEURBANNE' },
    69732: { agency: '4V', city: 'VILLEURBANNE' },
    69735: { agency: '4V', city: 'VILLEURBANNE' },
    69740: { agency: '4V', city: 'VILLEURBANNE' },
    69741: { agency: '4V', city: 'VILLEURBANNE' },
    69742: { agency: '4V', city: 'VILLEURBANNE' },
    69743: { agency: '4V', city: 'VILLEURBANNE' },
    69744: { agency: '4V', city: 'VILLEURBANNE' },
    69745: { agency: '4V', city: 'VILLEURBANNE' },
    69746: { agency: '4V', city: 'VILLEURBANNE' },
    69747: { agency: '4V', city: 'VILLEURBANNE' },
    69749: { agency: '4V', city: 'VILLEURBANNE' },
    69751: { agency: '4V', city: 'VILLEURBANNE' },
    69755: { agency: '4V', city: 'VILLEURBANNE' },
    69760: { agency: '4V', city: 'VILLEURBANNE' },
    69771: { agency: '4V', city: 'VILLEURBANNE' },
    69780: { agency: '4V', city: 'VILLEURBANNE' },
    69791: { agency: '4V', city: 'VILLEURBANNE' },
    69792: { agency: '4V', city: 'VILLEURBANNE' },
    69793: { agency: '4V', city: 'VILLEURBANNE' },
    69794: { agency: '4V', city: 'VILLEURBANNE' },
    69795: { agency: '4V', city: 'VILLEURBANNE' },
    69800: { agency: '4V', city: 'VILLEURBANNE' },
    69801: { agency: '4V', city: 'VILLEURBANNE' },
    69802: { agency: '4V', city: 'VILLEURBANNE' },
    69803: { agency: '4V', city: 'VILLEURBANNE' },
    69804: { agency: '4V', city: 'VILLEURBANNE' },
    69805: { agency: '4V', city: 'VILLEURBANNE' },
    69806: { agency: '4V', city: 'VILLEURBANNE' },
    69808: { agency: '4V', city: 'VILLEURBANNE' },
    69809: { agency: '4V', city: 'VILLEURBANNE' },
    69811: { agency: '4V', city: 'VILLEURBANNE' },
    69812: { agency: '4V', city: 'VILLEURBANNE' },
    69813: { agency: '4V', city: 'VILLEURBANNE' },
    69814: { agency: '4V', city: 'VILLEURBANNE' },
    69816: { agency: '4V', city: 'VILLEURBANNE' },
    69817: { agency: '4V', city: 'VILLEURBANNE' },
    69819: { agency: '4V', city: 'VILLEURBANNE' },
    69830: { agency: '4V', city: 'VILLEURBANNE' },
    69831: { agency: '4V', city: 'VILLEURBANNE' },
    69832: { agency: '4V', city: 'VILLEURBANNE' },
    69833: { agency: '4V', city: 'VILLEURBANNE' },
    69836: { agency: '4V', city: 'VILLEURBANNE' },
    69841: { agency: '4V', city: 'VILLEURBANNE' },
    69881: { agency: '4V', city: 'VILLEURBANNE' },
    69882: { agency: '4V', city: 'VILLEURBANNE' },
    69883: { agency: '4V', city: 'VILLEURBANNE' },
    69889: { agency: '4V', city: 'VILLEURBANNE' },
    69890: { agency: '4V', city: 'VILLEURBANNE' },
    69891: { agency: '4V', city: 'VILLEURBANNE' },
    69900: { agency: '4V', city: 'VILLEURBANNE' },
    69901: { agency: '4V', city: 'VILLEURBANNE' },
    69902: { agency: '4V', city: 'VILLEURBANNE' },
    69903: { agency: '4V', city: 'VILLEURBANNE' },
    69904: { agency: '4V', city: 'VILLEURBANNE' },
    69905: { agency: '4V', city: 'VILLEURBANNE' },
    69906: { agency: '4V', city: 'VILLEURBANNE' },
    69907: { agency: '4V', city: 'VILLEURBANNE' },
    69908: { agency: '4V', city: 'VILLEURBANNE' },
    69910: { agency: '4V', city: 'VILLEURBANNE' },
    69921: { agency: '4V', city: 'VILLEURBANNE' },
    69922: { agency: '4V', city: 'VILLEURBANNE' },
    69923: { agency: '4V', city: 'VILLEURBANNE' },
    69924: { agency: '4V', city: 'VILLEURBANNE' },
    69925: { agency: '4V', city: 'VILLEURBANNE' },
    69926: { agency: '4V', city: 'VILLEURBANNE' },
    69931: { agency: '4V', city: 'VILLEURBANNE' },
    69932: { agency: '4V', city: 'VILLEURBANNE' },
    69933: { agency: '4V', city: 'VILLEURBANNE' },
    69934: { agency: '4V', city: 'VILLEURBANNE' },
    69935: { agency: '4V', city: 'VILLEURBANNE' },
    69936: { agency: '4V', city: 'VILLEURBANNE' },
    69937: { agency: '4V', city: 'VILLEURBANNE' },
    69938: { agency: '4V', city: 'VILLEURBANNE' },
    69939: { agency: '4V', city: 'VILLEURBANNE' },
    69942: { agency: '4V', city: 'VILLEURBANNE' },
    69945: { agency: '4V', city: 'VILLEURBANNE' },
    69946: { agency: '4V', city: 'VILLEURBANNE' },
    69947: { agency: '4V', city: 'VILLEURBANNE' },
    69948: { agency: '4V', city: 'VILLEURBANNE' },
    69949: { agency: '4V', city: 'VILLEURBANNE' },
    69951: { agency: '4V', city: 'VILLEURBANNE' },
    69952: { agency: '4V', city: 'VILLEURBANNE' },
    69953: { agency: '4V', city: 'VILLEURBANNE' },
    69954: { agency: '4V', city: 'VILLEURBANNE' },
    69955: { agency: '4V', city: 'VILLEURBANNE' },
    69957: { agency: '4V', city: 'VILLEURBANNE' },
    69960: { agency: '4V', city: 'VILLEURBANNE' },
    69961: { agency: '4V', city: 'VILLEURBANNE' },
    69962: { agency: '4V', city: 'VILLEURBANNE' },
    69963: { agency: '4V', city: 'VILLEURBANNE' },
    69964: { agency: '4V', city: 'VILLEURBANNE' },
    69968: { agency: '4V', city: 'VILLEURBANNE' },
    69969: { agency: '4V', city: 'VILLEURBANNE' },
    69970: { agency: '4V', city: 'VILLEURBANNE' },
    69998: { agency: '4V', city: 'VILLEURBANNE' },
    42360: { agency: '4V', city: 'VILLEURBANNE' },
    42510: { agency: '4V', city: 'VILLEURBANNE' },
    42114: { agency: '4V', city: 'VILLEURBANNE' },
    42780: { agency: '4V', city: 'VILLEURBANNE' },
    69170: { agency: '4V', city: 'VILLEURBANNE' },
    69490: { agency: '4V', city: 'VILLEURBANNE' },
    69770: { agency: '4V', city: 'VILLEURBANNE' },
    69930: { agency: '4V', city: 'VILLEURBANNE' },
    38150: { agency: '4V', city: 'VILLEURBANNE' },
    38270: { agency: '4V', city: 'VILLEURBANNE' },
    38550: { agency: '4V', city: 'VILLEURBANNE' },
    76630: { agency: '2E', city: 'ROUEN' },
    76980: { agency: '2E', city: 'ROUEN' },
  }
  const zipcodeWithoutStartingZero = parseInt(zipcode)
  const agency = zipcodes[zipcodeWithoutStartingZero] ?? { agency: '', city: '' }

  return agency
}

export default ZipcodeAvailable
