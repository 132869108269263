import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectPageConfigurationData,
  selectPageConfigurationIsInitialized,
  selectPageConfigurationIsLoading,
} from '@/store/PageConfiguration/PageConfigurationSelectors'
import { mapTelecomSubscriptionData } from './Telecom/mapTelecomSubscriptionData'
import { mapEnergySubscriptionData } from './Energy/mapEnergySubscriptionData'
import { initializeContractData } from './SubscriptionFormSlice'
import mapAdditionalSalesData from './Common/mapAdditionalSalesData'
import { mapInsuranceSubscriptionData } from './Insurance/mapInsuranceSubscriptionData'
import {
  mapClientSubscriptionData
} from '@/containers/SubscriptionFormPageNew/SubscriptionForm/Client/mapClientSubscriptionData'
import {
  mapBillingSubscriptionData
} from '@/containers/SubscriptionFormPageNew/SubscriptionForm/Billing/mapBillingSubscriptionData'
import {
  mapAddressSubscriptionData
} from '@/containers/SubscriptionFormPageNew/SubscriptionForm/Address/mapAddressSubscriptionData'

const useSubscriptionForm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const initialData = useSelector(selectPageConfigurationData)
  const isInitialized = useSelector(selectPageConfigurationIsInitialized)
  const pageConfigurationIsLoading = useSelector(selectPageConfigurationIsLoading)

  useEffect(() => {
    if (!isInitialized || pageConfigurationIsLoading || !initialData) return
    const contractData = {
      client : mapClientSubscriptionData(initialData),
      billing : mapBillingSubscriptionData(initialData),
      address : mapAddressSubscriptionData(initialData),
      telecom: mapTelecomSubscriptionData(initialData),
      energy: mapEnergySubscriptionData(initialData),
      insurance: mapInsuranceSubscriptionData(initialData),
      contracts: initialData?.contact?.contracts ?? {},
      additionalSales: mapAdditionalSalesData(initialData),
    }

    dispatch(initializeContractData(contractData))

  }, [initialData])

  return { t }
}

export default useSubscriptionForm
