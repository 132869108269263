import { call, cancelled, put, select } from 'redux-saga/effects'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import getAPIConfig from '@/shared/tools/getAPIConfig'
import { updateCustomerModuleConfiguration } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/FormConfigurationV2Slice'
import {
  clientFormParametersGeneration
} from '@/containers/SubscriptionFormPageNew/SubscriptionForm/Client/clientFormParametersGeneration'
import { updateCommonConfiguration } from '@/containers/SubscriptionFormPage/SubscriptionFormConfigurationSlice'
import { updateClientData, addContractToOpenedContracts } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormSlice'
import { showSnackbar, SNACKBAR_TYPES } from '../../../../shared/tools/notifications/notification'

export const customerModuleActions = {
  CUSTOMER_MODULE_VERIFY_EMAIL_V2: 'CUSTOMER_MODULE_VERIFY_EMAIL_V2',
  CUSTOMER_MODULE_VERIFY_SIRET_V2: 'CUSTOMER_MODULE_VERIFY_SIRET_V2',
  CUSTOMER_MODULE_REGISTER_TO_NEWSLETTER_V2: 'CUSTOMER_MODULE_REGISTER_TO_NEWSLETTER_V2',
  CUSTOMER_MODULE_SAVE_V2: 'CUSTOMER_MODULE_SAVE_V2',
  CUSTOMER_ADD_CONTACT_V2: 'CUSTOMER_ADD_CONTACT_V2',
  CUSTOMER_MODULE_LOAD_CONTRACT_DATA: 'CUSTOMER_MODULE_LOAD_CONTRACT_DATA',
}

export function* verifyEmail({ email, callback }) {
  const controller = new AbortController()
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { verify_email_url: url } = yield select(selectPageConfigurationData)
  const fullUrl = `${url}?lang=${lang}&value=${encodeURIComponent(email)}`
  yield put(updateCustomerModuleConfiguration({ emailIsLoading: true }))
  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const signal = controller.signal
    const response = yield call(fetch, fullUrl, { ...config, signal })
    const result = yield call([response, response.json])
    yield put(updateCustomerModuleConfiguration({ emailIsLoading: false }))
    yield call(callback, result)
  } catch (error) {
    console.warn('verifyEmailError', error)
  } finally {
    if (yield cancelled()) {
      controller.abort()
      console.log('http call in verifyEmail aborted')
    }
  }
}

export function* verifySiret({ siret, callback }) {
  const controller = new AbortController()
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { verify_siret_url: url } = yield select(selectPageConfigurationData)
  const fullUrl = `${url}?lang=${lang}&siret=${encodeURIComponent(siret)}`
  yield put(updateCustomerModuleConfiguration({ siretIsLoading: true }))
  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const signal = controller.signal
    const response = yield call(fetch, fullUrl, { ...config, signal })
    const result = yield call([response, response.json])
    yield put(updateCustomerModuleConfiguration({ siretIsLoading: false }))
    yield call(callback, result)
  } catch (error) {
    console.warn('verifySiretError', error)
  } finally {
    if (yield cancelled()) {
      controller.abort()
      console.log('http call in verifySiret aborted')
    }
  }
}

export function* registerToNewsletter({ options, callback }) {
  const controller = new AbortController()
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { register_newsletter_url: url } = yield select(selectPageConfigurationData)

  try {
    const signal = controller.signal
    const config = yield call(getAPIConfig, { method: 'post', authTokenSession, body: options })
    const response = yield call(fetch, url + '?lang=' + lang, { ...config, signal })
    const result = yield call([response, response.json])

    yield call(callback, result, response.status)
  } catch (error) {
    console.warn('registerToNewsletterError', error)
  } finally {
    if (yield cancelled()) {
      controller.abort()
      console.log('http call in registerToNewsletter aborted')
    }
  }
}

export function* saveCustomer({ customerData }) {
  const controller = new AbortController()
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { save_route: url, country, id } = yield select(selectPageConfigurationData)

  const formParameters = clientFormParametersGeneration(
    customerData,
    id,
    country,
    'save'
  )

  const formParametersObject = Object.fromEntries(formParameters.entries());

  try {
    const signal = controller.signal
    const config = yield call(getAPIConfig, { method: 'post', authTokenSession, body: formParametersObject })
    const response = yield call(fetch, url + '?lang=' + lang, { ...config, signal })
    const result = yield call([response, response.json])
    const message = result.message ?? 'Unexpected save response message';
    const savedIsCorrect = response.status === 200

    yield put(updateCommonConfiguration({
      showSaveNotification: savedIsCorrect,
      formSubmitted: savedIsCorrect,
      errors: savedIsCorrect ? {} : { error: message }
    }));

  } catch (error) {
    console.warn('save customer data', error)
  } finally {
    if (yield cancelled()) {
      controller.abort()
      console.log('http call saving customer data aborted')
    }
  }
}

export function* addContact() {
  const controller = new AbortController();
  try {
    const { loaderRoutes, authTokenSession, country } = yield select(selectAuthData);
    const initUrl = loaderRoutes['add-route'];

    const searchParams = window.location.search || '';
    const separator = searchParams ? '&' : '?';
    const countryQuery = `country=${country}`;
    const url = `${initUrl}${searchParams}${separator}${countryQuery}`;

    const config = yield call(getAPIConfig, { method: 'GET', authTokenSession });
    const signal = controller.signal;
    const response = yield call(fetch, url, { ...config, signal });
    const result = yield call([response, response.json]);
    yield put(updateClientData({data: {zohoId: result.id ?? ''}, name: 'personal'}))
  } catch (error) {
    console.error('addContact error', error);
  } finally {
    if (yield cancelled()) {
      controller.abort();
      console.log('http call in addContact aborted');
    }
  }
}

export function* loadContractData({ contractId, enqueue }) {
  const controller = new AbortController()
  try {
    const { authTokenSession, extraRoutes } = yield select(selectAuthData)
    const { getContractDataUrl } = extraRoutes
    const url = getContractDataUrl.replace('*', contractId)
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const signal = controller.signal

    const response = yield call(fetch, url, { ...config, signal })
    const data = yield call([response, response.json])
    if (!response.ok) {
      throw new Error(data.error || `HTTP error! status: ${response.status}`)
    }
    yield put(addContractToOpenedContracts(data));
  } catch (error) {
    console.error('loadContractData error', error)
    yield call(showSnackbar, enqueue, {
      type: SNACKBAR_TYPES.ERROR,
      message: error.message || 'Error loading contract data',
    })
  } finally {
    if (yield cancelled()) {
      controller.abort()
      console.log('http call in loadContractData aborted')
    }
  }
}
