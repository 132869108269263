import { lazy } from 'react'
import useFormStatus from '@/containers/SubscriptionFormPageNew/SubscriptionForm/useFormStatus'

const salesFormAvailable = {
  'energy-be': lazy(() => import('./Energy/BELayout')),
  'energy-fr': lazy(() => import('./Energy/FRLayout')),
  'energy-it': lazy(() => import('./Energy/ITLayout')),
  'insurance-be': lazy(() => import('./Insurance/BELayout')),
  'insurance-fr': lazy(() => import('./Insurance/FRLayout')),
  'telecom-be': lazy(() => import('./Telecom/BELayout')),
  'telecom-fr': lazy(() => import('./Telecom/FRLayout')),
}

const SaleForm = ({ country, type }) => {
  const { zohoIsInValid, formIsLoaded } = useFormStatus()
  const formType = type + '-' + country
  console.log('form type to load', formType)
  const Element = salesFormAvailable[formType] ?? <div>Not implemented</div>
  if (zohoIsInValid) return <div>You need a valid Zoho id to continue</div>
  if (!formIsLoaded) return <div>Loading form data, please wait...</div>

  return <Element />
}

export default SaleForm
