import { useDispatch, useSelector } from 'react-redux'
import { selectClientSubscriptionData } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormSelectors'
import { updateCommonConfiguration } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/FormConfigurationV2Slice'
import { subscriptionFormActionsV2 } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormV2Sagas'

const useClient = () => {
  const dispatch = useDispatch()
  const customerData = useSelector(selectClientSubscriptionData)
  const saveCustomer = () => {
    dispatch(updateCommonConfiguration({ showSaveNotification: false, formSubmitted: true, errors: {} }))
    dispatch({
      type: subscriptionFormActionsV2.CUSTOMER_MODULE_SAVE_V2,
      customerData,
    })
  }
  return { saveCustomer }
}

export default useClient
