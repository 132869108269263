import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pageConfigurationActions } from '@/store/PageConfiguration/PageConfigurationSagas'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { useParams } from 'react-router-dom'
import SubscriptionForm from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionForm'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import FormStatusError from '@/containers/SubscriptionFormPage/SubscriptionForm/FormStatusError'
import CommonFallback from '@/shared/components/CommonFallback'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'

const EditForm = () => {
  const dispatch = useDispatch()
  const { id: idFromBackend, save_route: saveRoute } = useSelector(selectPageConfigurationData)
  const { loaderRoutes, loaderRoutesEnergy, loaderRoutesInsurance, authTokenSession, country } =
    useSelector(selectAuthData)
  const [loadStatus, setLoadStatus] = useState(null)
  const { getApplicationUrl } = useBusinessType({
    loaderRoutesEnergy,
    loaderRoutes,
    loaderRoutesInsurance,
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })
  const initUrl = getApplicationUrl('telesales-form-new') ?? ''
  const { id } = useParams()

  useEffect(() => {
    dispatch({ type: pageConfigurationActions.INITIALIZE, initUrl: initUrl.replace('*', id) })
  }, [])

  if (loadStatus) {
    return <FormStatusError statusCode={loadStatus} />
  }

  if (!idFromBackend) {
    return <CommonFallback />
  }

  return (
    <SubscriptionForm id={idFromBackend.toString()} country={country} saveRoute={saveRoute} token={authTokenSession} />
  )
}

export default EditForm
