import { ORIENTATION, Tab, Tabs } from 'baseui/tabs-motion'
import { Card, tabOverrides, tabsOverrides, Title, titleTabOverrides } from './styled-components'
import useAdditionalSalesContainer from './useAdditionalSalesContainer'
import SaleElement from './SaleElement'
import useAdditionalSaleFormData from './useAdditionalSaleFormData'

const AdditionalSalesContainer = () => {
  const {
    t,
    getSaleConfig,
    selectedSales,
    hideComponent,
    vaPrioritiesKeys,
    leadPrioritiesKeys,
    othersKeys,
    activeTab,
    setActiveTab,
    containerRef,
    isAvailableAdditionalSale,
    hasZohoIdAdditionalSale,
    showLeadPriorities,
    setShowLeadPriorities,
    showVaPriorities,
    setShowVaPriorities,
    showOthers,
    setShowOthers,
    renderTabTitle,
  } = useAdditionalSalesContainer()
  const { showOnlyAllowLeadMcp } = useAdditionalSaleFormData()

  const renderVATabs = (keys, isVisible) => {
    return (
      isVisible &&
      keys.map(key => {
        if (isAvailableAdditionalSale(key)) {
          const { title, filePath, vaType = 'sales', isBlockedIfMcpCreated = false } = getSaleConfig(key)
          return (
            <Tab
              title={title}
              key={key}
              id={'tab-' + key}
              overrides={tabOverrides(activeTab === key, hasZohoIdAdditionalSale(key))}
            >
              {isBlockedIfMcpCreated && showOnlyAllowLeadMcp ? (
                t('additionalSales:common.script_mcp_already_created')
              ) : (
                <SaleElement path={filePath} vaType={vaType} />
              )}
            </Tab>
          )
        }
      })
    )
  }

  if (hideComponent()) {
    return ''
  }

  return (
    <Card ref={containerRef}>
      <Title>
        <div dangerouslySetInnerHTML={{ __html: t('additionalSales:common.container_title') }} />
      </Title>
      <Tabs
        activeKey={activeTab}
        onChange={({ activeKey }) => setActiveTab(activeKey)}
        orientation={ORIENTATION.vertical}
        overrides={tabsOverrides(selectedSales.includes(activeTab))}
      >
        <Tab
          title={renderTabTitle(t('additionalSales:common.va_priority_title'), showVaPriorities)}
          onClick={() => setShowVaPriorities(!showVaPriorities)}
          overrides={titleTabOverrides(selectedSales.includes(activeTab))}
          id='tab-vaprio'
        />
        {renderVATabs(vaPrioritiesKeys, showVaPriorities)}
        <Tab
          title={renderTabTitle(t('additionalSales:common.lead_priority_title'), showLeadPriorities)}
          onClick={() => setShowLeadPriorities(!showLeadPriorities)}
          overrides={titleTabOverrides(selectedSales.includes(activeTab))}
          id='tab-leadprio'
        />
        {renderVATabs(leadPrioritiesKeys, showLeadPriorities)}
        <Tab
          title={renderTabTitle(t('additionalSales:common.va_secondary_title'), showOthers)}
          onClick={() => setShowOthers(!showOthers)}
          overrides={titleTabOverrides(selectedSales.includes(activeTab))}
          id='tab-leadothers'
        />
        {renderVATabs(othersKeys, showOthers)}
      </Tabs>
    </Card>
  )
}

export default AdditionalSalesContainer
