import { useState } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from 'baseui/form-control'
import { HOPInput } from '@/shared/components/HOPComponents/HOPInput'
import HOPSelect from '@/shared/components/HOPComponents/HOPSelect'
import { SIZE } from 'baseui/button'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { useTranslation } from 'react-i18next'

const AddEditModal = ({ data, onUpdate, onCancel, providerList, errors, loading }) => {
  const { t } = useTranslation()
  const [formValue, setFormValue] = useState(data)
  const providerTypeList = {
    box: t('providerOptionsManagement:box'),
    mobile: t('providerOptionsManagement:mobile'),
  }
  const formattedProviderList = providerList.reduce((convertedList, { uuid, label, name }) => {
    convertedList[uuid] = label ?? name ?? ''
    return convertedList
  }, {})
  const getProvideNameByUuid = uuid => {
    const foundObject = providerList.find(object => object.uuid === uuid)
    return foundObject ? foundObject.name : t('common:choose')
  }

  const submitHandler = event => {
    event.preventDefault()
    const values = {
      id: data.id ?? 0,
      name: formValue.name,
      product_type: formValue.product_type,
      provider_id: formValue.provider_id,
      zoho_id: formValue.zoho_id,
    }
    onUpdate(values, data.id)
  }

  return (
    <form onSubmit={submitHandler}>
      <FormControl label={t('providerOptionsManagement:modal.name')} error={errors.name ? errors.name[0] : ''}>
        <HOPInput
          currentValue={formValue.name}
          handleValue={value => {
            setFormValue({ ...formValue, name: value })
          }}
        />
      </FormControl>

      <FormControl label={t('providerOptionsManagement:modal.role_type')} error={errors.role ? errors.role[0] : ''}>
        <HOPSelect
          optionsList={providerTypeList}
          placeholder={t('common:choose')}
          selectedValue={formValue.product_type ?? ''}
          onChangeHandler={params => setFormValue({ ...formValue, product_type: params.value[0]?.id })}
        />
      </FormControl>
      <FormControl
        label={t('providerOptionsManagement:modal.provider')}
        error={errors.organisation_id ? errors.organisation_id[0] : ''}
      >
        <HOPSelect
          optionsList={formattedProviderList}
          placeholder={getProvideNameByUuid(formValue.provider_id ?? '')}
          selectedValue={formValue.provider_id}
          onChangeHandler={params => setFormValue({ ...formValue, provider_id: params.value[0]?.id })}
        />
      </FormControl>

      <FormControl label={t('common:zoho_id')} error={errors.name ? errors.name[0] : ''}>
        <HOPInput
          currentValue={formValue.zoho_id}
          handleValue={value => {
            setFormValue({ ...formValue, zoho_id: value })
          }}
        />
      </FormControl>

      <SDButton
        disabled={loading}
        isLoading={loading}
        size={SIZE.compact}
        block={false}
        overrides={{
          BaseButton: {
            style: () => ({
              marginRight: '.5rem',
            }),
          },
        }}
        type='submit'
      >
        {data.id ? t('common:edit') : t('common:create')}
      </SDButton>
      <SDButton size={SIZE.compact} block={false} type='button' onClick={onCancel}>
        {t('common:cancel')}
      </SDButton>
    </form>
  )
}

AddEditModal.propTypes = {
  data: PropTypes.object,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  providerList: PropTypes.array,
  errors: PropTypes.object,
  loading: PropTypes.bool,
}

export default AddEditModal
