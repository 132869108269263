import React from 'react'
import useContractTimelineStyles from './useContractTimelineStyles'
import useTabBar from '../useTabBar'

const ContractTimeline = () => {
  const {
    TimelineContainer,
    TimelineLine,
    TimelineDateGroup,
    TimelineDate,
    ContractsList,
    ContractItem,
    ContractType,
    ContractPrestationType,
    Title,
  } = useContractTimelineStyles()
  const {
    pushToOpenedContracts,
    groupedContractsByDate,
    t,
  } = useTabBar()

  const getTypeEmoji = (type) => {
    const emojis = { energy: '⚡', insurance: '🛡️', telecom: '📱' }
    return emojis[type] || '-'
  }

  return (
    <div>
      <Title>{t('subscriptionTools:historic_contracts.module_title')}</Title>
      <TimelineContainer>
        {groupedContractsByDate.map(({ date, contracts }, index) => (
          <div key={date}>
            {index === 0 && (
              <TimelineLine 
                $first={true}
                $last={index === groupedContractsByDate.length - 1}
                $itemsCount={contracts.length}
              />
            )}
            <TimelineDateGroup>
              <TimelineDate>{date}</TimelineDate>
              <ContractsList>
                {contracts.map(contract => (
                  <ContractItem key={contract.id} onClick={() => pushToOpenedContracts(contract)}>
                    <ContractType>
                      <span>{getTypeEmoji(contract.type)}</span>
                      <span>{t(`common:${contract.type}_sale`)} ({contract.characteristics.providerName})</span>
                    </ContractType>
                    <ContractPrestationType>{contract.characteristics.prestationType}</ContractPrestationType>
                  </ContractItem>
                ))}
              </ContractsList>
            </TimelineDateGroup>
          </div>
        ))}
      </TimelineContainer>
    </div>
  )
}

export default ContractTimeline
