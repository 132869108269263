// relative to contract data that we want to store : fields values
const energySubscriptionData = {
  contractLoaded: false,
  contract: {
    providerId: '',
    offerId: '',
    isDebordement: '',
    realPayment: '',
    mesEmergency: '',
    providerExternalId: '',
    disclaimerCommitmentPro: null,
    commitmentEndDate: null,
  },
  characteristics: {
    energyType: '',
    prestationType: '',
    contractType: '',
    cdfDelayed: 0,
  },
  elecMeter: {
    pdlNo: '',
    enedisAuth: '',
    consentDate: null,
    indexBaseHP: '',
    indexHC: '',
    tariffOption: '',
    originalTariffOption: '',
    meterPower: '',
    originalMeterPower: '',
    effectiveDate: '',
    retractionDelayBypass: false,
    energyOrigin: '',
    meterType: '',
    readingDate: '',
    billAmount: '',
    smartMeter: '',
    meterState: '',
    meterOpening: '',
    energySupplier: '',
    matricule: '',
  },
  gasMeter: {
    meterAuth: '',
    consentDate: null,
    pceNo: '',
    index: '',
    readingDate: '',
    effectiveDate: '',
    retractionDelayBypass: false,
    energyOrigin: '',
    meterType: '',
    billAmount: '',
    smartMeter: '',
    energySupplier: '',
  },
  propane: {
    energySupplier: '',
    car: '',
    explanations: '',
    alreadyClient: '',
    quoteReason: '',
    garden: false,
    gardenArea: '',
    tankType: '',
    tankAccess: '',
    bottleNumber: '',
    bottleSize: '',
    contactTime: '',
    deliveryFrequency: '',
    projectDeadline: '',
    installationAge: '',
    presence: '',
    currentUse: '',
    filling: '',
  },
  additionalInformation: {
    communicationPreference: '',
    medicalNeeds: '',
    serviceNeeds: '',
  },
  consumption: {
    carBase: '',
    carBaseOriginal: '',
    carHp: '',
    carHpOriginal: '',
    carHc: '',
    carHcOriginal: '',
    carGas: '',
    carGasOriginal: '',
    surface: '',
    heating: '',
    hotwater: '',
    cooking: '',
    monthlyAmount: '',
    modificationReason: '',
  },
  estimation: {
    housingType: null,
    occupancyFrequency: null,
    mainHome: null,
    numberDays: null,
    occupantsNumber: null,
    surface: null,
    recentInsulationWork: null,
    heatingMode: null,
    hotWaterMode: null,
    cookingMode: null,
    dishwasher: null,
    dryer: null,
    separateFreezer: null,
    heatPump: null,
    electricCar: null,
    electricCarDistance: null,
    airConditionersOneRoom: null,
    airConditionersMultipleRooms: null,
  },
}

export default energySubscriptionData
