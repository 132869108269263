import { createSlice } from '@reduxjs/toolkit'
import clientSubscriptionData from '@/containers/SubscriptionFormPageNew/SubscriptionForm/Client/clientSubscriptionData'
import telecomSubscriptionData from '@/containers/SubscriptionFormPageNew/SubscriptionForm/Telecom/telecomSubscriptionData'
import additionalSalesDefaultState from '@/containers/SubscriptionFormPageNew/SubscriptionForm/Common/additionalSalesDefaultState'
import insuranceSubscriptionData from '@/containers/SubscriptionFormPageNew/SubscriptionForm/Insurance/insuranceSubscriptionData'
import energySubscriptionData from '@/containers/SubscriptionFormPageNew/SubscriptionForm/Energy/energySubscriptionData'
import billingSubscriptionData from '@/containers/SubscriptionFormPageNew/SubscriptionForm/Billing/billingSubscriptionData'
import addressSubscriptionData from '@/containers/SubscriptionFormPageNew/SubscriptionForm/Address/addressSubscriptionData'
import { parseTab } from '../../../shared/tools/activeUtil'

const initialState = {
  client: { ...clientSubscriptionData },
  billing: { ...billingSubscriptionData },
  addresses: [
    { ...addressSubscriptionData, id: '1234' }, // example for dev testing
    { ...addressSubscriptionData, id: '4567' }, // example for dev testing
  ],
  pods: [], // example for dev testing
  contracts: {},
  additionalSales: [{ ...additionalSalesDefaultState }], // example for dev testing
  openedContracts: [],
  scripts: {},
  config: {
    activeTab: 'client',
    addContractModal: false,
  },
}

const initializeContractDataHandler = (state, action) => {
  state.client = action.payload.client
  state.contracts = action.payload.contracts
}

// dispatch(updateClientDataHandler({data: {firstName: 'Tomas'}, name: 'personal'}))
const updateClientDataHandler = (state, action) => {
  state.client[action.payload.name] = {
    ...state.client[action.payload.name],
    ...action.payload.data,
  }
}

const toggleAddContractModalHandler = state => {
  state.config.addContractModal = !state.config.addContractModal
}

const updateOpenedContractCharacteristicsDataHandler = (state, action) => {
    state.contracts[action.payload.id].characteristics = {
      ...(state.contracts[action.payload.id].characteristics || {}),
      ...action.payload.data
    };
}

const updateOpenedContractContractDataHandler = (state, action) => {
  if (!state.contracts[action.payload.id]) {
    console.error(`Contract ${action.payload.id} not found in state.contracts`)
    return
  }
  state.contracts[action.payload.id].contract = {
    ...state.contracts[action.payload.id].contract,
    ...action.payload.data,
  }
}

const updateActiveTabHandler = (state, action) => {
  state.config.activeTab = action.payload
}

const updateConfigHandler = (state, action) => {
  state.config = {
    ...state.config,
    ...action.payload,
  }
}

const addScriptHandler = (state, action) => {
  state.scripts[action.payload.name] = action.payload.script
}

const removeScriptHandler = (state, action) => {
  delete state.scripts[action.payload]
}

const addContractToOpenedContractsHandler = (state, action) => {
  const keyName = action.payload.id + '-' + action.payload.type
  state.contracts[action.payload.id] = action.payload 
  if (!state.openedContracts.includes(action.payload.id)) {
    state.openedContracts.push(action.payload.id);
  }
  state.config.activeTab = keyName
}

const removeContractFromOpenedContractsHandler = (state, action) => {
  state.openedContracts = state.openedContracts.filter(contractId => contractId !== action.payload.id)
  const { id } = parseTab(state.config.activeTab);

  if (id === action.payload.id) {
    state.config.activeTab = 'client'
  }
}

export const SubscriptionFormSlice = createSlice({
  name: 'formV2',
  initialState,
  reducers: {
    initializeContractData: initializeContractDataHandler,
    updateClientData: updateClientDataHandler,
    updateOpenedContractCharacteristicsData: updateOpenedContractCharacteristicsDataHandler,
    updateOpenedContractContractData: updateOpenedContractContractDataHandler,
    addContractToOpenedContracts: addContractToOpenedContractsHandler,
    removeContractFromOpenedContracts: removeContractFromOpenedContractsHandler,
    updateConfig: updateConfigHandler,
    updateActiveTab: updateActiveTabHandler,
    addScript: addScriptHandler,
    removeScript: removeScriptHandler,
    toggleAddContractModal: toggleAddContractModalHandler,
  },
})

export const {
  initializeContractData,
  updateClientData,
  updateOpenedContractCharacteristicsData,
  updateOpenedContractContractData,
  addContractToOpenedContracts,
  removeContractFromOpenedContracts,
  updateConfig,
  updateActiveTab,
  addScript,
  removeScript,
  toggleAddContractModal,
} = SubscriptionFormSlice.actions

export default SubscriptionFormSlice.reducer
