export const mapClientSubscriptionData = customerData => {
  const currentContract = customerData.contact ?? {}
  return retrieveClientData(currentContract)
}
export const retrieveClientData = currentContract => {
  return {
    contractLoaded: true,
    personal: {
      zohoId: currentContract.zoho_id ?? '',
      firstName: currentContract.firstname ?? '',
      firstName2: currentContract.firstname2 ?? '',
      lastName: currentContract.lastname ?? '',
      lastName2: currentContract.lastname2 ?? '',
      birthdate: currentContract.birthday ?? '',
      civility: currentContract.civility ?? '',
      civility2: currentContract.civility2 ?? '',
      email: currentContract.email ?? '',
      phone1: currentContract.phone1 ?? '',
      phone2: currentContract.phone2 ?? '',
    },
    preferences: {
      clientType: currentContract.is_professional === 1 ? 'pro' : 'part',
      dataCollectionAuth: currentContract.disclaimer === 1,
      neverBounceResult: currentContract.email?.length > 0 ? 'valid' : '',
      isNewsletterRegistered: currentContract.newsletter,
      isEkomiRegistered: currentContract.ekomi_interaction,
      callbackDate: currentContract.web_callback ? currentContract.web_callback.split('T')[0] : '',
      callbackTime: currentContract.web_callback ? currentContract.web_callback.split('T')[1] : '',
    },
    professional: {
      socialReason: currentContract.pro_social_reason ?? '',
      siret: currentContract.pro_siret ?? '',
      activity: currentContract.pro_activity ?? '',
      apeCode: currentContract.pro_ape_code ?? '',
      taxId: currentContract.banking?.tax_id_code ?? '',
    },
    additional: {
      callReason: currentContract.call_reason_id,
      comments: getLastComment(currentContract.comments ?? []),
    },
  }
}

export const filterLastComment = comments => {
  if (!comments.isArray && typeof comments === 'string') {
    return comments
  }
  let lastCommentTime = new Date(comments[0].created_at).getTime()
  let lastComment = comments[0].comment
  // eslint-disable-next-line func-names
  comments.forEach(function (item) {
    if (new Date(item.created_at).getTime() >= lastCommentTime) {
      lastCommentTime = new Date(item.created_at).getTime()
      lastComment = item.comment
    }
  })
  return lastComment ?? ''
}
export const getLastComment = comments => {
  if (comments.length === 0) {
    return ''
  }
  if (comments.length === 1) {
    return comments[0].comment ?? ''
  }
  return filterLastComment(comments)
}
