import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'baseui/snackbar'
import useHttp from '@/shared/hooks/useHttp'
import { Check } from 'baseui/icon'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { SIZE } from 'baseui/button'
import Title from '@/shared/components/PageTitle'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import { TYPE } from 'baseui/select'
import { Table } from 'baseui/table-semantic'
import SDPaginator from '@/shared/components/SDComponents/SDPaginator/SDPaginator'
import { Modal, ModalBody, ModalHeader } from 'baseui/modal'
import AddEditModal from '@/containers/ProviderOptionsManagement/AddEditModal'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { useSelector } from 'react-redux'

const ProviderOptionsManagement = () => {
  const { loaderRoutes, lang } = useSelector(selectAuthData)
  const initUrl = loaderRoutes['provider-options-management'] ?? ''
  const { t } = useTranslation()
  const { enqueue } = useSnackbar()
  const [page, setPage] = useState(1)
  const [searchValue, setSearchValue] = useState('')
  const [providerList, setProvidersList] = useState()
  const [formErrors, setFormErrors] = useState({})
  const flexGridItemProps = {
    display: 'flex',
    alignItems: 'center',
  }
  const [routes, setRoutes] = useState([])
  const [providerOptions, setProviderOptions] = useState([])
  const [selectedProviderOption, setSelectedProviderOption] = useState({
    id: 0,
    name: '',
  })
  const [isOpen, setIsOpen] = useState(false)
  const { sendRequest, getConfig } = useHttp()
  const { sendRequest: storeProviderOption, isLoading: upsertIsLoading } = useHttp()

  const updateProviderOption = providerOption => {
    setIsOpen(true)
    setSelectedProviderOption({
      id: providerOption?.id ?? 0,
      name: providerOption?.id ? providerOption.name : '',
      product_type: providerOption?.id ? providerOption.product_type : '',
      provider_id: providerOption?.id ? providerOption.provider_id : '',
      zoho_id: providerOption?.id ? providerOption.zoho_id : '',
    })
  }

  const initialize = () => {
    sendRequest(initUrl + '?lang=' + lang, getConfig('GET'), response => {
      setRoutes(response.routes)
      setProvidersList(response.providers)
    })
  }

  const loadProviderOptions = () => {
    if (!routes.list || routes.list === '') {
      return
    }
    const url = routes.list + '?page=' + (page ?? 1) + '&search=' + (searchValue ?? '')
    sendRequest(url + '&lang=' + lang, getConfig('GET'), response => setProviderOptions(response))
  }

  const switchDisableProviderOption = id => {
    const options = new URLSearchParams()
    const route = routes.switch_disable.replace('%id%', id)
    const config = getConfig('PUT')
    config.body = options
    sendRequest(route + '?lang=' + lang, config, response => {
      if (response.success === true) {
        loadProviderOptions()
      }
      if (response.error) {
        alert(response.error)
      }
    })
  }

  const updateData = (item, id) => {
    const options = new URLSearchParams()
    const route = routes.update.replace('%id%', id)
    const config = getConfig('PUT')

    options.append('id', id)
    options.append('name', item.name)
    options.append('product_type', item.product_type)
    options.append('provider_id', item.provider_id)
    options.append('zoho_id', item.zoho_id)

    config.body = options
    storeProviderOption(route + '?lang=' + lang, config, response => {
      if (response.success === true) {
        setIsOpen(false)
        enqueue({
          message: response.message,
          startEnhancer: ({ size }) => <Check size={size} />,
        })
        loadProviderOptions()
      }
      if (response.errors) {
        setFormErrors(response.errors)
      }
    })
  }

  const closeModal = () => {
    setFormErrors({})
    setIsOpen(false)
  }

  const transformProviderOption = () => {
    return providerOptions.data.map(providerOption => {
      return [
        providerOption.id,
        providerOption.name,
        providerOption.organizationName,
        providerOption.product_type === 'box'
          ? t('providerOptionsManagement:box')
          : t('providerOptionsManagement:mobile'),
        <SDButton key={providerOption.id} size={SIZE.compact} onClick={() => updateProviderOption(providerOption)}>
          {t('common:edit')}
        </SDButton>,
        <SDButton
          key={providerOption.id}
          size={SIZE.compact}
          onClick={() => {
            switchDisableProviderOption(providerOption.id)
          }}
        >
          {providerOption.disabled_at === null ? t('common:deactivate') : t('common:able')}
        </SDButton>,
      ]
    })
  }
  const COLUMNS = [
    t('providerOptionsManagement:table.id'),
    t('providerOptionsManagement:table.name'),
    t('providerOptionsManagement:table.provider_name'),
    t('providerOptionsManagement:table.product_type'),
    t('providerOptionsManagement:table.update'),
    '',
  ]

  useEffect(() => {
    initialize()
  }, [])

  useEffect(() => {
    loadProviderOptions()
  }, [page, searchValue, routes])

  if (!providerOptions.data) {
    return <p>{t('common:fetching_data')}</p>
  }

  return (
    <>
      <Title title={t('providerOptionsManagement:title')} />
      {providerOptions.total && (
        <h2>
          {providerOptions.total} {t('providerOptionsManagement:found')}
        </h2>
      )}
      <FlexGrid flexGridColumnCount={2} marginBottom='.5rem'>
        <FlexGridItem {...flexGridItemProps}>
          <SDButton key={0} block={false} size={SIZE.compact} onClick={() => updateProviderOption(null)}>
            {t('providerOptionsManagement:create_button')}
          </SDButton>
        </FlexGridItem>
        <FlexGridItem {...flexGridItemProps}>
          <SDInput
            id='providerOptionSearch'
            type={TYPE.search}
            placeholder={t('providerOptionsManagement:search_term')}
            onBlur={event => {
              setSearchValue(event.target.value)
            }}
          />
        </FlexGridItem>
      </FlexGrid>
      <Table columns={COLUMNS} data={transformProviderOption()} />
      {providerOptions.data && (
        <SDPaginator
          numPages={providerOptions.lastPage ?? 1}
          currentPage={providerOptions.currentPage ?? 1}
          onPageChange={({ nextPage }) => {
            if (parseInt(nextPage) <= parseInt(providerOptions.lastPage)) {
              setPage(nextPage)
            }
          }}
        />
      )}
      <Modal onClose={closeModal} isOpen={isOpen}>
        <ModalHeader>
          {selectedProviderOption.id === 0
            ? t('providerOptionsManagement:modal.header_create')
            : t('providerOptionsManagement:modal.header_update')}
        </ModalHeader>
        <ModalBody>
          <AddEditModal
            data={selectedProviderOption}
            onUpdate={updateData}
            onCancel={closeModal}
            errors={formErrors}
            loading={upsertIsLoading}
            providerList={providerList ?? []}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default ProviderOptionsManagement
