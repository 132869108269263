import PanelNew from '@/containers/SubscriptionFormPageNew/Panel/PanelNew'
import logo from './user.svg'
import isMandatory from '@/containers/SubscriptionFormPage/isMandatory'
import getError from '@/containers/SubscriptionFormPage/getError'
import Disclaimer from './Fields/Disclaimer/Disclaimer'
import useCustomer from './useCustomer'
import { modulesConfig } from '../../config'
import { FormControl } from 'baseui/form-control'
import { ParagraphMedium } from 'baseui/typography'
import optionsToArray from '@/shared/tools/optionsToArray'
import SecondName from '@/containers/SubscriptionFormPage/ModulesPanel/Modules/CustomerModule/Fields/SecondName'
import { IS_PROFESSIONAL } from '../../SubscriptionForm/Common/constants'
import ClientType from './Fields/ClientType'
import SDInput from '@/shared/components/SDComponentsNewDesign/SDInput/SDInput'
import Email from '@/containers/SubscriptionFormPageNew/Components/Customer/Fields/Email/Email'
import SDSelect from '@/shared/components/SDComponentsNewDesign/SDSelect/SDSelect'
import SDButtonGroup from '@/shared/components/SDComponentsNewDesign/SDButtonGroup/SDButtonGroup'
import NewsletterBonsPlans from '@/containers/SubscriptionFormPageNew/Components/Customer/Fields/NewsletterBonsPlans'
import { formControlOverrides } from '@/shared/components/SDComponentsNewDesign/styled-components'
import ProSiret from '@/containers/SubscriptionFormPageNew/Components/Customer/Fields/ProSiret'
import Birthdate from '@/containers/SubscriptionFormPageNew/Components/Customer/Fields/Birthdate/Birthdate'

const Customer = () => {
  const {
    t,
    customerUpdate,
    authorizeDataCollectionHandler,
    dataCollectionAuth,
    mandatoryFields,
    lastName,
    firstName,
    phone1,
    phone2,
    civility2,
    lastName2,
    firstName2,
    secondName,
    siret,
    socialReason,
    apeCode,
    activity,
    activityError,
    activitiesList,
    isEditable,
    errors,
    formIsLoaded,
    LoadingPanelNew,
    verifySiret,
    civility,
    civilityList,
    removeErrors,
    siretIsLoading,
    siretError,
    prefilledEmail,
    captionForceEmail,
    email,
    emailError,
    emailIsLoading,
    verifyEmail,
    isNewsletterRegistered,
    taxId,
    zohoIsInValid,
    clientTypeOptions,
    clientType,
    country,
    birthdate,
    setErrors,
  } = useCustomer()

  const config = modulesConfig[country].customer ?? {}
  const finalOptionsCivility = []
  civilityList.forEach(option => {
    finalOptionsCivility.push({
      [option]: option,
    })
  })

  if (!formIsLoaded) {
    return LoadingPanelNew(
      'CustomerModule',
      t('subscriptionForm:customer_module.module_title_new'),
      'CustomerModule',
      logo,
      3,
    )
  }

  if (zohoIsInValid) {
    return ''
  }

  if (!dataCollectionAuth) {
    return (
      <PanelNew
        logo={logo}
        alt={t('subscriptionForm:customer_module.module_title_new')}
        title={t('subscriptionForm:customer_module.module_title_new')}
        id='CustomerModule'
      >
        <Disclaimer
          disabled={isEditable === false}
          checked={dataCollectionAuth}
          errorText=''
          mandatory={isMandatory('dataCollectionAuth', mandatoryFields)}
          setChecked={authorizeDataCollectionHandler}
        />
      </PanelNew>
    )
  }

  return (
    <PanelNew
      logo={logo}
      alt={t('subscriptionForm:customer_module.module_title_new')}
      title={t('subscriptionForm:customer_module.module_title_new')}
      id='CustomerModule'
    >
      <Disclaimer
        disabled={isEditable === false}
        checked={dataCollectionAuth}
        mandatory={isMandatory('dataCollectionAuth', mandatoryFields)}
        setChecked={authorizeDataCollectionHandler}
      />
      <ClientType
        clientTypeOptions={clientTypeOptions}
        clientType={clientType}
        isEditable={isEditable}
        updateClientTypeHandler={value => customerUpdate({data: {clientType: value}, name: 'preferences'})}
        mandatory={isMandatory('contractType', mandatoryFields)}
        errorText={getError('contract_type', errors)}
        label={t('subscriptionForm:customer_module.client_type.label')}
      />

      <FormControl
        label={t('subscriptionForm:customer_module.civility.label') + isMandatory('civility', mandatoryFields)}
        error={getError('civility', errors)}
        overrides={formControlOverrides}
      >
        <SDButtonGroup
          disabled={isEditable === false}
          onClickHandler={value => customerUpdate({data: {civility: value}, name: 'personal'})}
          options={optionsToArray(civilityList ?? {})}
          selectedValue={civility}
        />
      </FormControl>

      {clientType === IS_PROFESSIONAL && (
        <>
          {config.siret && (
            <ProSiret
              disabled={isEditable === false}
              currentValue={siret ?? ''}
              onChangeHandler={value => customerUpdate({data: {siret: value}, name: 'professional'})}
              removeErrors={removeErrors}
              siretHandler={{
                handler: verifySiret,
                loader: siretIsLoading,
              }}
              mandatory={isMandatory('siret', mandatoryFields)}
              error={siretError}
            />
          )}

          <FormControl error={getError('socialReason', errors)}>
            <SDInput
              label={
                t('subscriptionForm:customer_module.proSocialReason.label') + isMandatory('socialReason', mandatoryFields)
              }
              disabled={isEditable === false}
              handleValue={value => customerUpdate( {data: {socialReason: value}, name: 'professional'})}
              value={socialReason ?? ''}
            />
          </FormControl>
          {config.apeCode && (
            <FormControl
              label={t('subscriptionForm:customer_module.proCodeApe.label') + isMandatory('apeCode', mandatoryFields)}
              error={getError('codeApe', errors)}
            >
              <SDInput
                label={t('subscriptionForm:customer_module.proCodeApe.label') + isMandatory('apeCode', mandatoryFields)}
                disabled={isEditable === false}
                handleValue={value => customerUpdate({data: {apeCode: value}, name: 'professional'})}
                value={apeCode ?? ''}
              />
            </FormControl>
          )}
          {config.activity && (
            <FormControl error={activityError}>
              <SDSelect
                label={t('subscriptionForm:customer_module.proActivity.label') + isMandatory('activity', mandatoryFields)}
                disabled={isEditable === false}
                optionsList={activitiesList}
                onChangeHandler={params => customerUpdate({data: {activity: params.option?.id ?? ''}, name: 'professional'})}
                placeholder={t('subscriptionForm:customer_module.proActivity.placeholder')}
                selectedValue={activity ? activity.toString() : ''}
                hasError={activityError !== ''}
              />
            </FormControl>
          )}
          <ParagraphMedium>{t('subscriptionForm:customer_module.proContact')}</ParagraphMedium>
        </>
      )}

      <FormControl error={getError('lastname', errors)}>
        <SDInput
          id='input-customer-lastname'
          label={
            isMandatory('surname', mandatoryFields)
              ? t('subscriptionForm:customer_module.lastname.label') + isMandatory('surname', mandatoryFields)
              : `${t('subscriptionForm:customer_module.lastname.label')} ${t('subscriptionForm:customer_module.lastname.optional')}`
          }
          disabled={isEditable === false}
          handleValue={value => customerUpdate({data: {lastName: value}, name: 'personal'})}
          onBlur={value => customerUpdate({ pdlEligibilityName: value })}
          value={lastName ?? ''}
          status='info'
        />
      </FormControl>

      <FormControl error={getError('firstname', errors)}>
        <SDInput
          id='input-customer-firstname'
          label={t('subscriptionForm:customer_module.firstname.label') + isMandatory('name', mandatoryFields)}
          disabled={isEditable === false}
          handleValue={value => customerUpdate({data: {firstName: value}, name: 'personal'})}
          value={firstName ?? ''}
        />
      </FormControl>

      <FormControl error={getError('birthdate', errors)}>
      <Birthdate
        disabled={isEditable === false}
        currentValue={birthdate ?? ''}
        error={getError('birthdate', errors)}
        mandatory={isMandatory('birthdate', mandatoryFields)}
        onChangeHandler={value => customerUpdate({ birthdate: value })}
        removeErrors={removeErrors}
        setErrors={setErrors}
      />
      </FormControl>

      <Email
        caption={prefilledEmail.status ? prefilledEmail.message : ''}
        disabled={isEditable === false}
        captionForceEmail={captionForceEmail}
        currentValue={email ?? ''}
        error={emailError}
        isLoading={emailIsLoading}
        mandatory={isMandatory('email', mandatoryFields)}
        onChangeHandler={value => customerUpdate({data: {email: value}, name: 'personal'})}
        updateNeverBounce={value => customerUpdate({data: {neverBounceResult: value}, name: 'preferences'})}
        removeErrors={removeErrors}
        verifyEmail={verifyEmail}
      />

      <NewsletterBonsPlans disabled={isEditable === false} isNewsletterRegistered={isNewsletterRegistered} />

      <FormControl error={getError('phone1', errors)}>
        <SDInput
          label={t('subscriptionForm:customer_module.phone1.label') + isMandatory('phone1', mandatoryFields)}
          id='input-customer-phone1'
          disabled={isEditable === false}
          handleValue={value => customerUpdate({data: {phone1: value}, name: 'personal'})}
          value={phone1 ?? ''}
        />
      </FormControl>

      <FormControl error={getError('phone2', errors)}>
        <SDInput
          label={t('subscriptionForm:customer_module.phone2.label') + isMandatory('phone2', mandatoryFields)}
          disabled={isEditable === false}
          handleValue={value => customerUpdate({data: {phone2: value}, name: 'personal'})}
          value={phone2 ?? ''}
        />
      </FormControl>

      <SecondName
        currentValue={secondName}
        onClickHandler={value => customerUpdate({data: {secondName: value}, name: 'personal'})}
        disabled={isEditable === false}
      >
        <FormControl
          label={t('subscriptionForm:customer_module.civility2.label') + isMandatory('civility2', mandatoryFields)}
          error={getError('civility2', errors)}
          overrides={formControlOverrides}
        >
          <SDButtonGroup
            disabled={isEditable === false}
            onClickHandler={value => customerUpdate({ data: { civility2: value }, name: 'personal' })}
            options={finalOptionsCivility}
            selectedValue={civility2}
            newTranslations={true}
            translationsPath='subscriptionForm:customer_module.civility.'
          />
        </FormControl>

        <FormControl error={getError('lastname', errors)}>
          <SDInput
            label={t('subscriptionForm:customer_module.lastname2.label') + isMandatory('surname2', mandatoryFields)}
            disabled={isEditable === false}
            handleValue={value => customerUpdate({data: {lastName2: value}, name: 'personal'})}
            value={lastName2 ?? ''}
          />
        </FormControl>

        <FormControl error={getError('firstname2', errors)}>
          <SDInput
            label={t('subscriptionForm:customer_module.firstname2.label') + isMandatory('name2', mandatoryFields)}
            disabled={isEditable === false}
            handleValue={value => customerUpdate({data: {firstName2: value}, name: 'personal'})}
            value={firstName2 ?? ''}
          />
        </FormControl>
      </SecondName>
      {config.taxId && (
        <FormControl error={getError('taxIdCode', errors)}>
          <SDInput
            label={t('subscriptionForm:customer_module.taxIdCode.label') + isMandatory('taxIdCode', mandatoryFields)}
            id='input-customer-tax-id-code'
            disabled={isEditable === false}
            handleValue={value => customerUpdate({data: {taxId: value}, name: 'professional'})}
            value={taxId ?? ''}
          />
        </FormControl>
      )}
    </PanelNew>
  )
}

export default Customer
