import { useTranslation } from 'react-i18next'
import useFormStatus from '@/containers/SubscriptionFormPageNew/SubscriptionForm/useFormStatus'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectPersonalClientSubscriptionData,
  selectPreferencesClientSubscriptionData,
  selectProfessionalClientSubscriptionData,
} from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormSelectors'
import { updateClientData } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormSlice'
import { subscriptionFormActionsV2 } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormV2Sagas'
import { selectCustomerModuleConfigurationData } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormConfigurationSelectors'
import { updateCustomerModuleConfiguration } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/FormConfigurationV2Slice'
import getError from '@/containers/SubscriptionFormPage/getError'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { useEffect } from 'react'
import useFormErrorManagement from '@/containers/SubscriptionFormPageNew/SubscriptionForm/useFormErrorManagement'
import { selectAuthCommonData } from '../../../Login/AuthSelectors'

const useCustomer = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { setErrors, removeErrors } = useFormErrorManagement()
  const { lang, country } = useSelector(selectAuthData)
  const { firstName, firstName2, lastName, lastName2, civility, civility2, phone2, phone1, email, secondName, zohoId, birthdate } =
    useSelector(selectPersonalClientSubscriptionData)

  const { dataCollectionAuth, isNewsletterRegistered, clientType } = useSelector(
    selectPreferencesClientSubscriptionData,
  )
  const { civilityList, clientTypeOptions, activitiesList } = useSelector(selectAuthCommonData)
  const { socialReason, siret, taxId, apeCode, activity } = useSelector(selectProfessionalClientSubscriptionData)
  const { emailIsLoading, prefilledEmail } = useSelector(selectCustomerModuleConfigurationData)
  const { formIsLoaded, LoadingPanelNew, zohoIsInValid, isEditable, mandatoryFields, errors } = useFormStatus()

  const customerUpdate = payload => {
    dispatch(updateClientData(payload))
  }
  const emailValidationHandler = response => {
    removeErrors('email')
    if (response.error) {
      setErrors({ email: [response.errorMessage] })
      customerUpdate({ data: { neverBounceResult: false }, name: 'preferences' })
      return
    }

    if (!response.valid) {
      setErrors({ email: [t('subscriptionForm:customer_module.email.error')] })
      customerUpdate({ data: { neverBounceResult: false }, name: 'preferences' })
      return
    }

    if (response.valid) {
      customerUpdate({ data: { neverBounceResult: 'valid' }, name: 'preferences' })
    }
  }

  const addContact = () => {
    dispatch({
      type: subscriptionFormActionsV2.CUSTOMER_ADD_CONTACT_V2,
    })
  }
  const verifyEmail = event => {
    dispatch({
      type: subscriptionFormActionsV2.CUSTOMER_MODULE_VERIFY_EMAIL_V2,
      email: event.target.value,
      callback: emailValidationHandler,
    })
  }

  const captionForceEmail = boolean => {
    dispatch(
      updateCustomerModuleConfiguration({
        prefilledEmail: {
          status: boolean,
          message: boolean ? t('subscriptionForm:customer_module.email.forcedCaption') : '',
        },
      }),
    )
  }

  const authorizeDataCollectionHandler = value => {
    if (value === true) {
      customerUpdate({ data: { dataCollectionAuth: value }, name: 'preferences' })
      return
    }
    customerUpdate({
      data: {
        name: '',
        surname: '',
        phone1: '',
        civility: '',
        birthdate: '',
      },
      name: 'personal',
    })
    customerUpdate({ data: { dataCollectionAuth: value }, name: 'preferences' })
  }

  useEffect(() => {
    if (location.pathname.endsWith('/add')) {
      addContact()
    }
  }, [location.pathname])

  return {
    formIsLoaded,
    LoadingPanelNew,
    zohoIsInValid,
    customerUpdate,
    isEditable,
    t,
    verifyEmail,
    setErrors,
    removeErrors,
    captionForceEmail,
    authorizeDataCollectionHandler,
    emailError: getError('email', errors),
    errors,
    emailIsLoading,
    mandatoryFields,
    dataCollectionAuth,
    firstName,
    lastName,
    lastName2,
    firstName2,
    secondName,
    civility,
    civility2,
    phone1,
    phone2,
    isNewsletterRegistered,
    email,
    civilityList,
    prefilledEmail,
    lang,
    socialReason,
    siret,
    activity,
    apeCode,
    taxId,
    activitiesList,
    clientTypeOptions,
    clientType,
    zohoId,
    birthdate,
    country,
  }
}

export default useCustomer
