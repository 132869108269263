import { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { PanelOverrides, StyledHeaderContainer, StyledIcon, StyledPanelWrapper, StyledTitle } from './styled-components'
import iconLogo from './unknown--filled.svg'
import { StatelessAccordion, Panel } from 'baseui/accordion'
import { ChevronUp, ChevronDown } from 'baseui/icon'

// eslint-disable-next-line react/prop-types
const CustomToggleIcon = ({$expanded, $style, ...restProps }) => {
  const mergedStyle = {
    ...$style,
    width: '32px',
    height: '32px',
  }
  return $expanded
    ? <ChevronUp {...restProps} style={mergedStyle} />
    : <ChevronDown {...restProps} style={mergedStyle} />
}

const PanelNew = props => {
  const panelRef = useRef()
  const logo = props.logo ?? iconLogo
  const alt = props.alt ?? 'Alternative text...'
  const title = props.title ?? 'Random child'
  const headerContent = props.headerContent ?? null
  const [expanded, setExpanded] = useState(['initial'])

  if (props.empty === true) {
    return <div ref={panelRef} />
  }

  return (
    <StyledPanelWrapper ref={panelRef} id={props.id}>
      <StatelessAccordion
        expanded={expanded}
        onChange={({ key, expanded: newExpanded }) => {
          setExpanded(newExpanded)
        }}
        accordion={true}
      >
        <Panel
          key='initial'
          overrides={{
            ...PanelOverrides,
            ToggleIcon: {
              component: CustomToggleIcon,
            },
          }}
          title={
            <StyledHeaderContainer>
              <StyledIcon src={logo} alt={alt} />
              <StyledTitle>{title}</StyledTitle>
              {headerContent && <StyledTitle>{headerContent}</StyledTitle>}
            </StyledHeaderContainer>
          }
        >
          {props.children}
        </Panel>
      </StatelessAccordion>
    </StyledPanelWrapper>
  )
}

PanelNew.propTypes = {
  alt: PropTypes.string,
  children: PropTypes.node,
  empty: PropTypes.bool,
  id: PropTypes.string,
  logo: PropTypes.node,
  title: PropTypes.string,
  type: PropTypes.string,
  headerContent: PropTypes.func,
}

export default PanelNew
