import PropTypes from 'prop-types'

import { ValidationCard, ValidationTitle } from '../styled-components'

const ValidationsList = ({ children, title }) => {
  return (
    <ValidationCard>
      <ValidationTitle>{title}</ValidationTitle>
      {children}
    </ValidationCard>
  )
}

ValidationsList.propTypes = {
  children: PropTypes.array,
}

export default ValidationsList
