import IconBSComplete from '@/shared/icons/StatusSteps/IconBSComplete'
import IconComplete from '@/shared/icons/StatusSteps/IconComplete'
import IconTransmission from '@/shared/icons/StatusSteps/IconTransmission'
import IconTransferred from '@/shared/icons/StatusSteps/IconTransferred'
import IconSigned from '@/shared/icons/StatusSteps/IconSigned'
import IconBSCompleteInProgress from '@/shared/icons/StatusSteps/IconBSCompleteInProgress'
import IconTransmissionInProgress from '@/shared/icons/StatusSteps/IconTransmissionInProgress'
import IconTransferredError from '@/shared/icons/StatusSteps/IconTransferredError'
import IconDossier from '@/shared/icons/StatusSteps/IconDossier'
import IconCompleteAbandon from '@/shared/icons/StatusSteps/IconCompleteAbandon'
import IconSignedInProgress from '@/shared/icons/StatusSteps/IconSignedInProgress'
import IconLineBlue from '@/shared/icons/StatusSteps/IconLineBlue'
import IconLineGreen from '@/shared/icons/StatusSteps/IconLineGreen'
import IconLineRed from '@/shared/icons/StatusSteps/IconLineRed'
import IconLineGrey from '@/shared/icons/StatusSteps/IconLineGrey'
import { useTranslation } from 'react-i18next'

export default function getSubscriptionStatusStep(status) {
  const GREY = '#DEE8F2'
  const BLUE = '#ADF9FF'
  const GREEN = '#BEFFD0'
  const RED = '#FFD5D5'
  const ICON_SIZE_STATUS = 40
  const ICON_SIZE_LINE = 40
  const { t } = useTranslation()
  switch (status) {
    case 'Données client à renseigner':
    case 'Souscription web a finaliser':
    case 'Données compteur a renseigner':
      return {
        step1: {
          icon: <IconComplete {...{ size: ICON_SIZE_STATUS }} />,
          color: GREEN,
          text: t('subscriptionForm:header.status_text_helper.completed'),
          iconLine: <IconLineGreen {...{ size: ICON_SIZE_LINE }} />,
        },
        step2: {
          icon: <IconBSCompleteInProgress {...{ size: ICON_SIZE_STATUS }} />,
          color: BLUE,
          text: t('subscriptionForm:header.status_text_helper.in_progress'),
          iconLine: <IconLineBlue {...{ size: ICON_SIZE_LINE }} />,
        },
        step3: {
          icon: <IconTransmission {...{ size: ICON_SIZE_STATUS }} />,
          color: GREY,
          text: t('subscriptionForm:header.status_text_helper.pending'),
          iconLine: <IconLineGrey {...{ size: ICON_SIZE_LINE }} />,
        },
        step4: {
          icon: <IconTransferred {...{ size: ICON_SIZE_STATUS }} />,
          color: GREY,
          text: t('subscriptionForm:header.status_text_helper.pending'),
          iconLine: <IconLineGrey {...{ size: ICON_SIZE_LINE }} />,
        },
        step5: {
          icon: <IconSigned {...{ size: ICON_SIZE_STATUS }} />,
          color: GREY,
          text: t('subscriptionForm:header.status_text_helper.pending'),
          iconLine: null,
        },
      }
    case 'BS complet':
    case 'Prêt pour la transmission':
      return {
        step1: {
          icon: <IconComplete {...{ size: ICON_SIZE_STATUS }} />,
          color: GREEN,
          text: t('subscriptionForm:header.status_text_helper.completed'),
          iconLine: <IconLineGreen {...{ size: ICON_SIZE_LINE }} />,
        },
        step2: {
          icon: <IconComplete {...{ size: ICON_SIZE_STATUS }} />,
          color: GREEN,
          text: t('subscriptionForm:header.status_text_helper.completed'),
          iconLine: <IconLineGreen {...{ size: ICON_SIZE_LINE }} />,
        },
        step3: {
          icon: <IconTransmissionInProgress {...{ size: ICON_SIZE_STATUS }} />,
          color: BLUE,
          text: t('subscriptionForm:header.status_text_helper.in_progress'),
          iconLine: <IconLineBlue {...{ size: ICON_SIZE_LINE }} />,
        },
        step4: {
          icon: <IconTransferred {...{ size: ICON_SIZE_STATUS }} />,
          color: GREY,
          text: t('subscriptionForm:header.status_text_helper.pending'),
          iconLine: <IconLineGrey {...{ size: ICON_SIZE_LINE }} />,
        },
        step5: {
          icon: <IconSigned {...{ size: ICON_SIZE_STATUS }} />,
          color: GREY,
          text: t('subscriptionForm:header.status_text_helper.pending'),
          iconLine: null,
        },
      }
    case 'Erreur lors du transfert':
    case 'Signé avec erreur':
      return {
        step1: {
          icon: <IconComplete {...{ size: ICON_SIZE_STATUS }} />,
          color: GREEN,
          text: t('subscriptionForm:header.status_text_helper.completed'),
          iconLine: <IconLineGreen {...{ size: ICON_SIZE_LINE }} />,
        },
        step2: {
          icon: <IconComplete {...{ size: ICON_SIZE_STATUS }} />,
          color: GREEN,
          text: t('subscriptionForm:header.status_text_helper.completed'),
          iconLine: <IconLineGreen {...{ size: ICON_SIZE_LINE }} />,
        },
        step3: {
          icon: <IconComplete {...{ size: ICON_SIZE_STATUS }} />,
          color: GREEN,
          text: t('subscriptionForm:header.status_text_helper.completed'),
          iconLine: <IconLineGreen {...{ size: ICON_SIZE_LINE }} />,
        },
        step4: {
          icon: <IconTransferredError {...{ size: ICON_SIZE_STATUS }} />,
          color: RED,
          text: t('subscriptionForm:header.status_text_helper.in_progress'),
          iconLine: <IconLineRed {...{ size: ICON_SIZE_LINE }} />,
        },
        step5: {
          icon: <IconSigned {...{ size: ICON_SIZE_STATUS }} />,
          color: GREY,
          text: t('subscriptionForm:header.status_text_helper.pending'),
          iconLine: null,
        },
      }
    case 'Rétractation par le fournisseur':
    case 'Rétractation par Selectra':
      return {
        step1: {
          icon: <IconCompleteAbandon {...{ size: ICON_SIZE_STATUS }} />,
          color: GREY,
          text: t('subscriptionForm:header.status_text_helper.completed'),
          iconLine: <IconLineBlue {...{ size: ICON_SIZE_LINE }} />,
        },
        step2: {
          step: 2,
          icon: <IconCompleteAbandon {...{ size: ICON_SIZE_STATUS }} />,
          color: GREY,
          text: t('subscriptionForm:header.status_text_helper.completed'),
          iconLine: <IconLineBlue {...{ size: ICON_SIZE_LINE }} />,
        },
        step3: {
          icon: <IconCompleteAbandon {...{ size: ICON_SIZE_STATUS }} />,
          color: GREY,
          text: t('subscriptionForm:header.status_text_helper.completed'),
          iconLine: <IconLineBlue {...{ size: ICON_SIZE_LINE }} />,
        },
        step4: {
          icon: <IconTransferredError {...{ size: ICON_SIZE_STATUS }} />,
          color: RED,
          text: t('subscriptionForm:header.status_text_helper.abandon'),
          iconLine: <IconLineBlue {...{ size: ICON_SIZE_LINE }} />,
        },
        step5: {
          icon: <IconSigned {...{ size: ICON_SIZE_STATUS }} />,
          color: GREY,
          text: t('subscriptionForm:header.status_text_helper.pending'),
          iconLine: null,
        },
      }
    case 'Activé':
    case 'Signé':
      return {
        step1: {
          icon: <IconComplete {...{ size: ICON_SIZE_STATUS }} />,
          color: GREEN,
          text: t('subscriptionForm:header.status_text_helper.completed'),
          iconLine: <IconLineGreen {...{ size: ICON_SIZE_LINE }} />,
        },
        step2: {
          icon: <IconComplete {...{ size: ICON_SIZE_STATUS }} />,
          color: GREEN,
          text: t('subscriptionForm:header.status_text_helper.completed'),
          iconLine: <IconLineGreen {...{ size: ICON_SIZE_LINE }} />,
        },
        step3: {
          icon: <IconComplete {...{ size: ICON_SIZE_STATUS }} />,
          color: GREEN,
          text: t('subscriptionForm:header.status_text_helper.completed'),
          iconLine: <IconLineGreen {...{ size: ICON_SIZE_LINE }} />,
        },
        step4: {
          icon: <IconComplete {...{ size: ICON_SIZE_STATUS }} />,
          color: GREEN,
          text: t('subscriptionForm:header.status_text_helper.completed'),
          iconLine: <IconLineGreen {...{ size: ICON_SIZE_LINE }} />,
        },
        step5: {
          icon: <IconComplete {...{ size: ICON_SIZE_STATUS }} />,
          color: GREEN,
          text: t('subscriptionForm:header.status_text_helper.completed'),
          iconLine: null,
        },
      }
    case 'Transféré':
      return {
        step1: {
          icon: <IconComplete {...{ size: ICON_SIZE_STATUS }} />,
          color: GREEN,
          text: t('subscriptionForm:header.status_text_helper.completed'),
          iconLine: <IconLineGreen {...{ size: ICON_SIZE_LINE }} />,
        },
        step2: {
          icon: <IconComplete {...{ size: ICON_SIZE_STATUS }} />,
          color: GREEN,
          text: t('subscriptionForm:header.status_text_helper.completed'),
          iconLine: <IconLineGreen {...{ size: ICON_SIZE_LINE }} />,
        },
        step3: {
          icon: <IconComplete {...{ size: ICON_SIZE_STATUS }} />,
          color: GREEN,
          text: t('subscriptionForm:header.status_text_helper.completed'),
          iconLine: <IconLineGreen {...{ size: ICON_SIZE_LINE }} />,
        },
        step4: {
          icon: <IconComplete {...{ size: ICON_SIZE_STATUS }} />,
          color: GREEN,
          text: t('subscriptionForm:header.status_text_helper.completed'),
          iconLine: <IconLineGreen {...{ size: ICON_SIZE_LINE }} />,
        },
        step5: {
          icon: <IconSignedInProgress {...{ size: ICON_SIZE_STATUS }} />,
          color: BLUE,
          text: t('subscriptionForm:header.status_text_helper.in_progress'),
          iconLine: null,
        },
      }
    default:
      return {
        step1: {
          icon: <IconDossier {...{ size: ICON_SIZE_STATUS }} />,
          color: BLUE,
          text: t('subscriptionForm:header.status_text_helper.in_progress'),
          iconLine: <IconLineBlue {...{ size: ICON_SIZE_LINE }} />,
        },
        step2: {
          icon: <IconBSComplete {...{ size: ICON_SIZE_STATUS }} />,
          color: GREY,
          text: t('subscriptionForm:header.status_text_helper.pending'),
          iconLine: <IconLineGrey {...{ size: ICON_SIZE_LINE }} />,
        },
        step3: {
          icon: <IconTransmission {...{ size: ICON_SIZE_STATUS }} />,
          color: GREY,
          text: t('subscriptionForm:header.status_text_helper.pending'),
          iconLine: <IconLineGrey {...{ size: ICON_SIZE_LINE }} />,
        },
        step4: {
          icon: <IconTransferred {...{ size: ICON_SIZE_STATUS }} />,
          color: GREY,
          text: t('subscriptionForm:header.status_text_helper.pending'),
          iconLine: <IconLineGrey {...{ size: ICON_SIZE_LINE }} />,
        },
        step5: {
          icon: <IconSigned {...{ size: ICON_SIZE_STATUS }} />,
          color: GREY,
          text: t('subscriptionForm:header.status_text_helper.pending'),
          iconLine: null,
        },
      }
  }
}
