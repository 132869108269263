import { Navigate } from 'react-router-dom'
import useCustomer from '@/containers/SubscriptionFormPageNew/Components/Customer/useCustomer'

const CreateForm = () => {
  const { zohoId } = useCustomer()

  if (zohoId === '') {
    return <p>Creation du contrat en cours v2....</p>
  }

  return <Navigate to={'/contact/' + zohoId} />
}

export default CreateForm
