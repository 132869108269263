import ZipcodeAvailable from '@/containers/SubscriptionFormPage/AdditionalSalesPanel/sales/GarankaSale/ZipcodeAvailable'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import { addScript, removeScript } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormSlice'
import { useTranslation } from 'react-i18next'
import { isGasOrDualContract } from '../SubscriptionFormSelectors'

const useGarankaEhsScript = () => {
  const dispatch = useDispatch()
  const { additionalSalesStatuses } = useSelector(selectPageConfigurationData)
  const isValidEnergy = useSelector(isGasOrDualContract)
  const statusEHS = true //additionalSalesStatuses?.leadEngieHomeService TODO: put back later
  const { t } = useTranslation()
  const zipcode = '75001' //todo: address?.zipcode ?? '')
  const isValidCity = true //address?.zipcode?.length > 0 to put back lster
  const defaultAgency = { city: '', agency: '' }
  const agencyAvailable = ZipcodeAvailable(zipcode) ?? defaultAgency
  const garankaIsAvailable = agencyAvailable.city.length > 0 && agencyAvailable.agency.length > 0

  console.log('garankaIsAvailable', isValidEnergy)

  useEffect(() => {
    dispatch(removeScript('leadGaranka'))
    dispatch(removeScript('leadEngieHomeService'))

    if (!isValidEnergy || !isValidCity || !statusEHS) {
      return
    }

    const endScript = garankaIsAvailable
      ? t('subscriptionForm:characteristics_module.boiler_script.garanka')
      : t('subscriptionForm:characteristics_module.boiler_script.engie_home')
    const leadType = garankaIsAvailable ? 'leadGaranka' : 'leadEngieHomeService'
    dispatch(
      addScript({
        name: leadType,
        script: t('subscriptionForm:characteristics_module.boiler_script.script') + ' ' + endScript,
      }),
    )
  }, [isValidEnergy, isValidCity, garankaIsAvailable, statusEHS]) //todo: add address in deps
}
export default useGarankaEhsScript
