import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addScript, removeScript } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormSlice'
import { useTranslation } from 'react-i18next'
import { selectCharacteristicsCurrentContractActive } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormSelectors'
import useRetrieveActiveContractIdAndType from '../../Layouts/useRetrieveActiveContractIdAndType'

const useLeadTelecomScript = () => {
  const dispatch = useDispatch()
  const { prestationType } = useSelector(selectCharacteristicsCurrentContractActive)
  const { t } = useTranslation()
  const { type } = useRetrieveActiveContractIdAndType()

  useEffect(() => {
    const lowerType = type?.toLowerCase()
    if (lowerType !== 'energy') {
      dispatch(removeScript('leadTelecom'))
      return
    }
    const telecomScript =
      prestationType === 'cdf'
        ? t('subscriptionForm:characteristics_module.prestation_type.lead_telecom_script.cdf')
        : t('subscriptionForm:characteristics_module.prestation_type.lead_telecom_script.mes_fmes')
    dispatch(addScript({ name: 'leadTelecom', script: telecomScript }))
  }, [type, prestationType])
}

export default useLeadTelecomScript
