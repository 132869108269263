import { styled } from 'baseui'

const useContractTimelineStyles = () => {
  const TimelineContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    padding: '0 0 0 1.5rem',
    marginTop: '0.25rem',
  })

  const TimelineLine = styled('div', ({ $first, $last, $height }) => ({
    position: 'absolute',
    left: '0.6875rem',
    width: '0.125rem',
    backgroundColor: '#B0B0B0',
    top: $first ? '1.25rem' : '0',
    height: $height || 'auto',
    bottom: $last ? '0.5rem' : '0',
    zIndex: 0,
  }))

  const TimelineDateGroup = styled('div', {
    position: 'relative',
    padding: '1rem 0 0.5rem 0',
    '::before': {
      content: '""',
      position: 'absolute',
      left: '-1.5rem',
      top: '1.25rem',
      width: '1.25rem',
      height: '1.25rem',
      borderRadius: '50%',
      backgroundColor: 'white',
      border: '0.125rem solid #B0B0B0',
      zIndex: 1,
    },
    '::after': {
      content: '""',
      position: 'absolute',
      left: '-1.125rem',
      top: '1.625rem',
      width: '0.5rem',
      height: '0.5rem',
      borderRadius: '50%',
      backgroundColor: '#B0B0B0',
      zIndex: 2,
    }
  })

  const TimelineDate = styled('div', {
    position: 'relative',
    fontWeight: 600,
    fontSize: '.94rem',
    color: '#555555',
    marginBottom: '0.4rem',
    paddingLeft: '0.5rem',
  })

  const ContractsList = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.4rem',
  })

  const ContractItem = styled('div', {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 0.75rem',
    borderRadius: '0.5rem',
    backgroundColor: '#F5F5F5',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    boxShadow: '0 0.0625rem 0.125rem rgba(0,0,0,0.05)',
    gap: '0.5rem',
    ':hover': {
      backgroundColor: '#EAEAEA',
      transform: 'translateX(0.125rem)',
    }
  })

  const ContractType = styled('span', {
    fontWeight: 600,
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    color: '#222222',
  })

  const ContractPrestationType = styled('span', {
    fontSize: '0.75rem',
    color: '#666666',
    marginLeft: 'auto',
    padding: '0.1875rem 0.5rem',
    backgroundColor: '#E5E5E5',
    borderRadius: '0.75rem',
    fontWeight: 500,
    alignSelf: 'center',
  })

  const Title = styled('h3', {
    margin: '0 0 0.50rem 0',
    fontSize: '1.125rem',
    fontWeight: 700,
    color: '#222222',
  })

  return {
    TimelineContainer,
    TimelineLine,
    TimelineDateGroup,
    TimelineDate,
    ContractsList,
    ContractItem,
    ContractType,
    ContractPrestationType,
    Title,
  }
}

export default useContractTimelineStyles