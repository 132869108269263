import { snackBarOverrides } from '@/containers/SubscriptionFormPage/ModulesPanel/styled-components'

export const SNACKBAR_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
}

export const showSnackbar = (enqueue, options) => {
  const {
    type = SNACKBAR_TYPES.SUCCESS,
    message = null,
    ...rest 
  } = options

  if (!message) {
    console.error('Snackbar message is required');
    return
  }

  enqueue({
    message,
    overrides: snackBarOverrides(type),
    autoHideDuration: 15000,
    ...rest,
  })
}