const InsuranceFieldsValidation = (
  field,
  validations,
  mandatoryFunction,
  offerConfig,
  customerData,
  billingData,
  mainAddressData,
  insuranceContractData,
  insuranceCharacteristicsData,
) => {
  const productType = insuranceCharacteristicsData.productType ?? ''
  const zipcode = mainAddressData.zipcode ?? ''
  switch (field) {
    case 'zohoId':
      return {
        isValid: validations.zohoId(customerData.zohoId ?? ''),
        isMandatory: true,
      }
    case 'productType':
      return {
        isValid: productType !== '',
        isMandatory: true,
      }
    case 'prestationType':
      return {
        isValid: validations.prestationType(insuranceCharacteristicsData.prestationType ?? ''),
        isMandatory: true,
      }
    case 'offerId':
      return {
        isValid: validations.offerId(insuranceContractData.offerId ?? 0),
        isMandatory: true,
      }
    case 'providerId':
      return {
        isValid: validations.providerId(insuranceContractData.providerId ?? 0),
        isMandatory: true,
      }
    case 'cityId':
      return {
        isValid: validations.cityId(mainAddressData.city ?? '', zipcode, mainAddressData.inseeCode ?? ''),
        isMandatory: true,
      }
    case 'dataCollectionAuth':
      return {
        isValid: validations.dataCollectionAuth(customerData.dataCollectionAuth ?? false),
        isMandatory: true,
      }
    case 'civility':
      return {
        isValid: validations.civility(customerData.civility ?? '', customerData.dataCollectionAuth ?? false),
        isMandatory: true,
      }
    case 'civility2':
      return {
        isValid: validations.civility2(
          customerData.civility2 ?? '',
          customerData.secondName,
          customerData.dataCollectionAuth ?? false,
        ),
        isMandatory: mandatoryFunction.civility2(customerData.secondName ?? false),
      }
    case 'name':
      return {
        isValid: validations.name(customerData.dataCollectionAuth ?? false, customerData.name ?? ''),
        isMandatory: true,
      }
    case 'name2':
      return {
        isValid: validations.name2(customerData.secondName, customerData.dataCollectionAuth, customerData.name2 ?? ''),
        isMandatory: mandatoryFunction.name2(customerData.secondName ?? false),
      }
    case 'surname':
      return {
        isValid: validations.surname(customerData.surname ?? ''),
        isMandatory: true,
      }
    case 'surname2':
      return {
        isValid: validations.surname2(
          customerData.secondName,
          customerData.dataCollectionAuth,
          customerData.surname2 ?? '',
        ),
        isMandatory: mandatoryFunction.surname2(customerData.secondName ?? false),
      }
    case 'phone1':
      return {
        isValid: validations.phone1(customerData.phone1 ?? ''),
        isMandatory: true,
      }
    case 'phone2':
      return {
        isValid: validations.phone2(customerData.phone2 ?? '', customerData.dataCollectionAuth ?? false),
        isMandatory: false,
      }
    case 'iban':
      return {
        isValid: validations.iban(billingData.iban ?? ''),
        isMandatory: false,
      }
    case 'bic':
      return {
        isValid: validations.bic(billingData.bic ?? ''),
        isMandatory: false,
      }
    case 'address':
      return {
        isValid: validations.address(mainAddressData.address?.length ?? ''),
        isMandatory: true,
      }
    case 'zipcode':
      return {
        isValid: validations.zipcode(zipcode),
        isMandatory: true,
      }
    case 'socialReason':
      return {
        isValid: validations.socialReason(customerData.pro_social_reason ?? ''),
        isMandatory: false,
      }
    case 'email':
      return {
        isValid: validations.email(customerData.email ?? '', customerData.dataCollectionAuth ?? false),
        isMandatory: true,
      }
    case 'tenantType':
      return {
        isValid: validations.tenantType(mainAddressData.tenantType?.length ?? ''),
        isMandatory: mandatoryFunction.tenantType(mainAddressData.city ?? ''),
      }
    case 'comments':
      return {
        isValid: validations.comments(customerData.comments),
        isMandatory: mandatoryFunction.comments(offerConfig),
      }
    case 'annualPremium':
      return {
        isValid: validations.commission(insuranceContractData.annualPremium ?? ''),
        isMandatory: false,
      }
    case 'annualCommission':
      return {
        isValid: validations.commission(insuranceContractData.annualCommission ?? ''),
        isMandatory: false,
      }
    default:
      console.error('No validation and mandatory info for field : ' + field)
      return false
  }
}

export default InsuranceFieldsValidation
