import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import { Spinner } from 'baseui/spinner'
import { FormControl } from 'baseui/form-control'
import SDButton from '@/shared/components/SDComponentsNewDesign/SDButton/SDButton'
import ContractDataRequired from '@/containers/SubscriptionFormPage/AdditionalSalesPanel/bones/ContractDataRequired'
import useNewsletterField
  from '@/containers/SubscriptionFormPage/ModulesPanel/Modules/CustomerModule/Fields/useNewsletterField'
import SDNotification from '@/shared/components/SDComponentsNewDesign/SDNotification/SDNotification'

const NewsletterBonsPlans = ({ isNewsletterRegistered, disabled }) => {
  const [css] = useStyletron()
  const {
    emailHasError,
    sendNewsletter,
    email,
    pdlNo,
    isDisabled,
    errorMessage,
    t,
    newsletterLoading,
    newsletterData,
    lang,
    additionalRequiredDataNewsletter,
    additionalRequiredDataMySelectra,
    zohoId,
    surname,
    phone1,
  } = useNewsletterField(disabled)

  const status = newsletterData?.status || '';
  const isFrench = lang === 'fr'
  const franceNoPdlNotRegistered = isFrench && !pdlNo && !isNewsletterRegistered
  const franceWithPdl = isFrench && pdlNo

  const getNewsletterButtonText = (t, isDisabled, status, email) => {
    if (!isDisabled) {
      return t('subscriptionForm:customer_module.newsletter.button')
    }
    if (status === 'success') {
      return t('subscriptionForm:customer_module.newsletter.button_submitted') + '(' + email + ')'
    }
    if (status === 'already_subscribed') {
      return t('subscriptionForm:customer_module.newsletter.button_submitted_already_subscribed')
    }
    if (status === 'already_sent_recently') {
      return t('subscriptionForm:customer_module.newsletter.button_submitted_recently')
    }
    return t('subscriptionForm:customer_module.newsletter.button')
  }

  return (
    <>
      <SDNotification type='warning' hidden={!franceNoPdlNotRegistered}>
        {t('subscriptionForm:customer_module.newsletter.not_personalized_warning')}
      </SDNotification>

      <SDNotification type='info'>
        {franceWithPdl
          ? t('subscriptionForm:customer_module.newsletter.label_personalized')
          : t('subscriptionForm:customer_module.newsletter.label')}
      </SDNotification>
      <ContractDataRequired
        currentData={{ zohoId, surname, phone1 }}
        additionalData={additionalRequiredDataNewsletter}
        customTitle={t('subscriptionForm:customer_module.newsletter.title_mandatory_fields_newsletter')}
      />
      <ContractDataRequired
        currentData={{}}
        additionalData={additionalRequiredDataMySelectra}
        customTitle={t('subscriptionForm:customer_module.newsletter.title_mandatory_fields_myselectra')}
        onlyAdditionalData={true}
      />

      <FormControl error={errorMessage}>
        <SDButton
          block
          isLoading={newsletterLoading}
          disabled={isDisabled || emailHasError}
          onClick={sendNewsletter}
          overrides={{
            BaseButton: {
              style: () => ({
                backgroundColor: 'orange',
                ':hover': {
                  backgroundColor: 'darkorange',
                },
              }),
            },
          }}
        >
          {getNewsletterButtonText(t, isDisabled, status, email)}
        </SDButton>
      </FormControl>

      {newsletterLoading && (
        <div
          className={css({
            alignItems: 'center',
            display: 'flex',
          })}
        >
          <Spinner size='32px' />
          <p
            className={css({
              marginLeft: '10px',
            })}
          >
            {t('subscriptionForm:customer_module.newsletter.loading_info')}
          </p>
        </div>
      )}

      {
        (status === 'error') && (
        <SDNotification type='negative'>
          {newsletterData?.response?.message}
        </SDNotification>
      )
      }

      {
        (status === 'already_subscribed' ||  status === 'already_sent_recently')  && (
        <SDNotification type='warning'>
          {newsletterData?.response?.message}
        </SDNotification>
      )
      }

      {
        status === 'success' && (
        <SDNotification type='positive'>
          {newsletterData.response?.message}
        </SDNotification>
      )
      }

      {
        emailHasError.length > 0 && (
        <SDNotification type='negative'>
          {t('subscriptionForm:customer_module.email.error')}
        </SDNotification>
      )
      }
    </>
  )
}

NewsletterBonsPlans.propTypes = {
  isNewsletterRegistered: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default NewsletterBonsPlans
