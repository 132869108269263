import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { mergeOverrides } from 'baseui'
import { ButtonGroup, SIZE } from 'baseui/button-group'
import { Button } from 'baseui/button'
import { SDButtonGroupOverrides, SDButtonGroupButtonOverrides } from './styled-components'

const SDButtonGroup = ({
  disabledOptions,
  onClickHandler,
  options,
  selectedValue,
  size,
  newTranslations = false,
  translationsPath = '',
  direction,
  error,
  overrides,
  ...rest
}) => {
  const { t } = useTranslation()
  const selected = []
  const buttons = []

  if (options.length > 0) {
    options.forEach((option, index) => {
      Object.keys(option).forEach(key => {
        const isDisabled = disabledOptions?.includes(key)

        if (Array.isArray(selectedValue)) {
          if (selectedValue.includes(key)) {
            selected.push(index)
          }
        } else if (key === selectedValue) {
          selected.push(index)
        }

        buttons.push(
          <ButtonGroupButton
            id={`checkbox-option-${key}`}
            key={key}
            disabled={isDisabled}
            onClick={event => {
              event.preventDefault()
              onClickHandler(key)
            }}
          >
            {newTranslations ? t(translationsPath + key) : option[key]}
          </ButtonGroupButton>
        )
      })
    })
  }

  const newOverrides = mergeOverrides(SDButtonGroupOverrides(direction, error), overrides)

  return (
    <ButtonGroup
      {...rest}
      size={size ? SIZE[size] : SIZE.compact}
      overrides={newOverrides}
      selected={selected}
    >
      {buttons}
    </ButtonGroup>
  )
}

// eslint-disable-next-line react/display-name
const ButtonGroupButton = React.forwardRef((props, ref) => {
  const newOverrides = mergeOverrides(SDButtonGroupButtonOverrides(), props.overrides)
  return (
    <Button {...props} ref={ref} overrides={newOverrides}>
      {props.children}
    </Button>
  )
})

SDButtonGroup.propTypes = {
  disabledOptions: PropTypes.array,
  newTranslations: PropTypes.bool,
  translationsPath: PropTypes.string,
  onClickHandler: PropTypes.func,
  options: PropTypes.array.isRequired,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  size: PropTypes.string,
  direction: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  overrides: PropTypes.object,
}

ButtonGroupButton.propTypes = {
  overrides: PropTypes.object,
  children: PropTypes.node,
}

export default SDButtonGroup
