import formatUTCDate from '@/shared/tools/formatUTCDate'

export const energyFormParametersGeneration = (
  customerData,
  billingData,
  electricityMeterData,
  gasMeterData,
  propaneData,
  energyContractData,
  energyCharacteristicsData,
  energyConsumptionData,
  energyEstimationData,
  mainAddressData,
  invoicingAddressData,
  id,
  country,
  action,
  pdlEligibilityData = {},
) => {
  const options = new URLSearchParams()
  if (action !== '') {
    options.append('action_requested', action)
  }

  options.append('id', id)
  options.append('zoho_id', customerData.zohoId)
  options.append('prestation_type', energyCharacteristicsData.prestationType)
  options.append('energy_type', energyCharacteristicsData.energyType)
  options.append('contract_type', energyCharacteristicsData.contractType)

  options.append('cdf_delayed', energyCharacteristicsData.cdfDelayed ?? '')
  if (energyCharacteristicsData.disclaimerCommitmentPro !== null) {
    options.append('disclaimer_commitment_pro', energyCharacteristicsData.disclaimerCommitmentPro ?? '')
  }

  options.append('referral_firstname', customerData.referralFirstName)
  options.append('referral_lastname', customerData.referralSecondName)
  options.append('city_id', mainAddressData.inseeCode ?? '')
  options.append('city', mainAddressData.city ?? '')
  options.append('province', mainAddressData.province ?? '')
  options.append('zipcode', mainAddressData.zipcode ?? '')
  options.append('conso_street_name', mainAddressData.address ?? '')
  options.append('street_number', mainAddressData.streetNumber ?? '')
  options.append('is_resident', mainAddressData.isResident ?? '')
  options.append('conso_residence_type', mainAddressData.residenceType ?? '-1')
  options.append('housing_type', mainAddressData.housingType ?? '')
  options.append('tenant_type', mainAddressData.tenantType ?? '-1')
  options.append('conso_floor', mainAddressData.floor ?? '')
  options.append('conso_appartment_number', mainAddressData.appartmentNumber ?? '')
  options.append('conso_additional_information', mainAddressData.additionalInformation ?? '')
  options.append('household', mainAddressData.household ?? '')
  options.append('occupancy', mainAddressData.occupancy ?? '')
  options.append('moving_start_date', mainAddressData.movingStartDate ?? '')

  options.append('number_of_occupants', energyEstimationData.number_of_occupants ?? '')
  options.append(
    'residence_presence_main',
    mainAddressData.residenceType === 'secondary' ? 1 : energyEstimationData.residence_presence_main ?? '',
  )

  if (country === 'it') {
    options.append('city_id', mainAddressData.cityId ?? '')
  }

  options.append('offer_id', energyContractData.offerId ? parseInt(energyContractData.offerId) : '')
  options.append('organisation_id', energyContractData.providerId ? parseInt(energyContractData.providerId) : '')

  if (energyContractData.commitmentEndDate !== null) {
    options.append('commitment_end_date', energyContractData.commitmentEndDate ?? '')
  }

  options.append('lang', country)
  options.append('_form_mode', 'telesales')
  options.append('is_debordement', energyContractData.isDebordement ?? '0')
  options.append('payment_mode_real', billingData.realPayment ?? '')
  options.append('mes_emergency', energyContractData.mesEmergency ?? '')
  options.append('provider_external_id', energyContractData.providerExternalId)
  options.append('surface', energyConsumptionData.surface ?? '0')
  options.append('hotwater_energy', energyConsumptionData.hotwater)
  options.append('cooking_energy', energyConsumptionData.cooking)
  options.append('heating_energy', energyConsumptionData.heating)
  if (energyConsumptionData.monthlyAmount && parseInt(energyConsumptionData.monthlyAmount) > 0) {
    options.append('monthly_amount', energyConsumptionData.monthlyAmount)
  }
  // todo check when we full migrate to step 1 if we use monthly_amount_calculated and refactor rules

  if (parseInt(customerData.callReason) > 0) {
    options.append('call_reason_id', customerData.callReason)
  }

  if (invoicingAddressData.showCheckbox === true) {
    options.append('facturation_address_different', '1')
    options.append('fact_street_name', invoicingAddressData.address ?? '')
    options.append('fact_street_number', invoicingAddressData.streetNumber ?? '')
    options.append('fact_floor', invoicingAddressData.floor ?? '')
    options.append('fact_appartment_number', invoicingAddressData.appartmentNumber ?? '')
    options.append('fact_complement_address', invoicingAddressData.additionalInformation ?? '')
    options.append('fact_city', invoicingAddressData.city)
    options.append('fact_zipcode', invoicingAddressData.zipcode)
    options.append('fact_country', invoicingAddressData.country)
  }

  if (energyCharacteristicsData.energyType === 'dual' || energyCharacteristicsData.energyType === 'elec') {
    options.append('meter_id_elec', electricityMeterData.pdlNo)
    if (electricityMeterData.effectiveDate) {
      options.append('effective_date_elec', electricityMeterData.effectiveDate)
    }
    if (electricityMeterData.retractionDelayBypass === true) {
      options.append('bypass_retractation_elec', '1')
    }
    if (electricityMeterData.enedisAuth === true) {
      options.append('meter_consent_acceptation_elec', electricityMeterData.consentDate)
    }
    if (electricityMeterData.meterType) {
      options.append('meter_type_elec', electricityMeterData.meterType)
    }
    if (electricityMeterData.meterOpening) {
      options.append('meter_opening_elec', electricityMeterData.meterOpening)
    }
    if (electricityMeterData.energySupplier) {
      options.append('energy_supplier_id_elec', electricityMeterData.energySupplier ?? '')
    }
    if (electricityMeterData.smartMeter) {
      options.append('is_smart_meter_elec', parseInt(electricityMeterData.smartMeter) === 1 ? '1' : '0')
    }
    if (electricityMeterData.billAmount) {
      options.append('last_bill_amount_elec', electricityMeterData.billAmount ?? '')
    }
    if (electricityMeterData.meterPower) {
      options.append('meter_power_elec', electricityMeterData.meterPower)
    }
    if (electricityMeterData.originalMeterPower) {
      options.append('original_meter_power_elec', electricityMeterData.originalMeterPower)
    }
    if (electricityMeterData.tariffOption) {
      options.append('meter_option_elec', electricityMeterData.tariffOption)
    }
    if (electricityMeterData.originalTariffOption) {
      options.append('original_meter_option_elec', electricityMeterData.originalTariffOption)
    }
    options.append('main_index_elec', electricityMeterData.indexBaseHP ?? 0)
    options.append('secondary_index_elec', electricityMeterData.indexHC ?? 0)
    if (electricityMeterData.readingDate) {
      options.append('reading_date_elec', electricityMeterData.readingDate)
    }
    if (electricityMeterData.meterState) {
      options.append('meter_state_elec', electricityMeterData.meterState)
    }
    options.append('car_elec_base', energyConsumptionData.carBase ?? 0)
    options.append('consumption_base', energyConsumptionData.consumptionBase ?? 0)
    options.append('car_elec_hp', energyConsumptionData.carHp ?? 0)
    options.append('consumption_biorariaF1', energyConsumptionData.consumptionBiorariaF1 ?? 0)
    options.append('car_elec_hc', energyConsumptionData.carHc ?? 0)
    options.append('consumption_biorariaF2', energyConsumptionData.consumptionBiorariaF2 ?? 0)
    options.append('consumption_biorariaF3', energyConsumptionData.consumptionBiorariaF3 ?? 0)

    if (electricityMeterData.energyOrigin) {
      options.append('energy_origin_elec', electricityMeterData.energyOrigin)
    }

    options.append('car_elec_base_original', energyConsumptionData.carBaseOriginal ?? 0)
    options.append('car_elec_hp_original', energyConsumptionData.carHpOriginal ?? 0)
    options.append('car_elec_hc_original', energyConsumptionData.carHcOriginal ?? 0)
    options.append('enedis_original_car', pdlEligibilityData?.elec?.consumption?.yearlyExtrapolation ?? 0)
  }

  if (energyCharacteristicsData.energyType === 'dual' || energyCharacteristicsData.energyType === 'gas') {
    options.append('meter_id_gas', gasMeterData.pceNo)
    options.append('main_index_gas', gasMeterData.index ?? 0)

    if (gasMeterData.meterAuth === true) {
      options.append('meter_consent_acceptation_gas', gasMeterData.consentDate)
    }

    if (gasMeterData.effectiveDate) {
      options.append('effective_date_gas', gasMeterData.effectiveDate)
    }
    options.append('car_gas', energyConsumptionData.carGas ?? 0)
    options.append('car_gas_original', energyConsumptionData.carGasOriginal ?? 0)
    if (gasMeterData.energyOrigin) {
      options.append('energy_origin_gas', gasMeterData.energyOrigin)
    }

    if (gasMeterData.readingDate) {
      options.append('reading_date_gas', gasMeterData.readingDate)
    }
    if (gasMeterData.retractionDelayBypass === true) {
      options.append('bypass_retractation_gas', '1')
    }
    if (gasMeterData.meterType) {
      options.append('meter_type_gas', gasMeterData.meterType)
    }
    if (gasMeterData.energySupplier) {
      options.append('energy_supplier_id_gas', gasMeterData.energySupplier ?? '')
    }
    if (gasMeterData.smartMeter) {
      options.append('is_smart_meter_gas', parseInt(gasMeterData.smartMeter) === 1 ? '1' : '0')
    }
    if (gasMeterData.billAmount) {
      options.append('last_bill_amount_gas', gasMeterData.billAmount ?? '')
    }
  }

  if (energyConsumptionData.modificationReason) {
    options.append('car_modification_reason', energyConsumptionData.modificationReason)
  }

  if (billingData.providersBlacklist) {
    options.append('blacklist_providers', JSON.stringify(billingData.providersBlacklist))
  }

  if (billingData.frequency) {
    options.append('invoice_frequency', billingData.frequency ?? '-1')
  }

  if (billingData.paymentMode) {
    options.append('payment_mode', billingData.paymentMode ?? '-1')
  }

  if (billingData.paymentModeAlt) {
    options.append('payment_mode_alt', billingData.paymentModeAlt ?? '-1')
  }

  options.append('invoice_sending_mode', billingData.sendingMode ?? '')
  options.append('payment_date', billingData.paymentDate ?? '')
  options.append('iban', billingData.iban ?? '')
  options.append('promotional_code', billingData.promoCode ?? '')

  if (billingData.businessCard === true) {
    options.append('business_card', '1')
  }

  if (billingData.bic.length > 0) {
    options.append('bic', billingData.bic)
  }
  if (billingData.differentAccountHolder === true) {
    options.append('owner_name_different', 1)
    options.append('owner_lastname', billingData.accountHolderLastname ?? '')
    options.append('owner_firstname', billingData.accountHolderFirstname ?? '')
  }
  if (energyCharacteristicsData.energyType === 'propane') {
    if (propaneData.alreadyClient === true) {
      options.append('already_client_propane', '1')
    } else {
      options.append('already_client_propane', '0')
    }
    if (propaneData.garden === true) {
      options.append('garden_propane', '1')
    } else {
      options.append('garden_propane', '0')
    }
    options.append('quote_reason_propane', propaneData.quoteReason ?? '')
    options.append('explanations_propane', propaneData.explanations ?? '')
    options.append('energy_supplier_id_propane', propaneData.energySupplier ?? '')
    options.append('contact_time_propane', propaneData.contactTime ?? '')
    options.append('car_propane', propaneData.car ?? '')
    options.append('presence_propane', propaneData.presence ?? '')
    options.append('current_use_propane', propaneData.currentUse ?? '')
    options.append('bottle_number_propane', propaneData.bottleNumber ?? '')
    options.append('bottle_size_propane', propaneData.bottleSize ?? '')
    options.append('delivery_frequency_propane', propaneData.deliveryFrequency ?? '')
    options.append('installation_age_propane', propaneData.installationAge ?? '')
    options.append('project_deadline_propane', propaneData.projectDeadline ?? '')
    options.append('tank_access_propane', propaneData.tankAccess ?? '')
    options.append('tank_type_propane', propaneData.tankType ?? '')
    options.append('garden_area_propane', propaneData.gardenArea ?? '')
  }

  options.append('disclaimer', customerData.dataCollectionAuth ? '1' : '0')
  options.append('civility', customerData.civility ?? '-1')
  options.append('lastname', customerData.surname ?? '')
  options.append('firstname', customerData.name ?? '')
  options.append('email', customerData.email ?? '')
  options.append('neverbounce_result', customerData.neverbounce_result)
  options.append('phone1', customerData.phone1 ?? '')
  options.append('bac_voucher', customerData.bacVoucher ? '1' : '0')
  options.append('tax_id_code', customerData.tax_id_code ?? '')
  if (customerData.web_callback.callbackTime !== '' && customerData.web_callback.callbackDate !== '') {
    const date = new Date(customerData.web_callback.callbackDate + ' ' + customerData.web_callback.callbackTime)
    options.append('web_callback', formatUTCDate(date) ?? '')
  }

  if (customerData.birthdate?.length > 0) {
    options.append('birthday', customerData.birthdate ?? '')
  }

  options.append('phone2', customerData.phone2 ?? '')

  if (energyCharacteristicsData.contractType === 'pro') {
    options.append('pro_siret', customerData.pro_siret)
    options.append('pro_social_reason', customerData.pro_social_reason)
    options.append('pro_ape_code', customerData.pro_ape_code)
    options.append('pro_activity', customerData.pro_activity)
  }

  if (customerData.secondName) {
    options.append('secondName', '1')
    options.append('civility2', customerData.civility2 ?? '-1')
    options.append('lastname2', customerData.surname2)
    options.append('firstname2', customerData.name2)
  }

  if (mainAddressData.providedByGas) {
    options.append('conso_provided_by_gas', parseInt(mainAddressData.providedByGas) === 1 ? '1' : '0')
  }

  if (customerData.comments !== '') {
    options.append('subscription_comments', customerData.comments)
  }

  return options
}
