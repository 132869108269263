const clientSubscriptionData = {
  contractLoaded: false,
  personal: {
    zohoId: null,
    firstName: null,
    firstName2: null,
    lastName: null,
    lastName2: null,
    birthdate: null,
    civility: null,
    civility2: null,
    email: null,
    phone1: null,
    phone2: null,
    secondName: null,
  },
  preferences: {
    clientType: null,
    dataCollectionAuth: false,
    neverBounceResult: null,
    isNewsletterRegistered: false,
    isEkomiRegistered: false,
    callbackDate: null,
    callbackTime: null,
  },
  professional: {
    socialReason: null,
    siret: null,
    activity: null,
    apeCode: null,
    taxId: null,
  },
  additional: {
    callReason: null,
    comments: null,
  },
}

export default clientSubscriptionData
