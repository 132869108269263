export default function isValidBirthdate(birthdate) {
  const today = new Date()
  today.setHours(0, 0, 0)

  if (today.getFullYear() - birthdate.getFullYear() > 120) {
    return false
  }

  return today.getFullYear() - birthdate.getFullYear() > 18
    ? true
    : birthdate.getMonth() < today.getMonth()
      ? true
      : birthdate.getDate() <= today.getDate()
}
