import { useSelector } from 'react-redux'
import { selectScripts } from '../SubscriptionFormSelectors'
import useGarankaEhsScript from './useGarankaEhsScript'
import useSurveillanceCameraScript from './useSurveillanceCameraScript'
import useLeadTelecomScript from './useLeadTelecomScript'
import { lazy } from 'react'

const icons = {
  leadTelecom: lazy(() => import('@/shared/icons/IconCarbonSatelliteRadar')),
  surveillanceCamera: lazy(() => import('@/shared/icons/IconCarbonVideo')),
  leadGaranka: lazy(() => import('@/shared/icons/IconCustomGaranka')),
  leadEngieHomeService: lazy(() => import('@/shared/icons/IconCustomEngie')),
}

const useScripts = () => {
  useGarankaEhsScript()
  useSurveillanceCameraScript()
  useLeadTelecomScript()
  const scripts = useSelector(selectScripts)
  const matchIcon = (leadName, props) => {
    const Element = icons[leadName] ?? null
    return Element ? <Element {...props} /> : null
  }

  return {
    scripts: Object.entries(scripts),
    matchIcon,
  }
}

export default useScripts
