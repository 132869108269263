export const mapInsuranceSubscriptionData = viewParameters => {
  const currentContract = viewParameters?.current_subscriber ?? {}

  return {
    characteristics: {
      productType: currentContract.product_insurance?.product_type ?? '',
      prestationType: currentContract.product_insurance?.prestation_type ?? '',
    },
    contract: {
      providerId: currentContract.product_insurance?.organisation_id ?? '',
      offerId: currentContract.product_insurance?.offer_id ?? '',
      providerExternalId: currentContract.product_insurance?.provider_external_id ?? '',
      annualPremium: currentContract.product_insurance?.annual_premium ?? '',
      annualCommission: currentContract.product_insurance?.annual_commission ?? '',
    },
  }
}
