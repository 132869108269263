const useTabBarStyle = () => {
  const tabStyle = {
    Tab: {
      style: ({ $isActive }) => ({
        borderRadius: '0.5rem 0.5rem 0 0',
        marginRight: '0.3rem',
        backgroundColor: $isActive ? 'white' : 'lightgrey',
        fontWeight: 'bold',
        fontSize: '1rem',
      }),
    },
    TabPanel: {
      style: () => ({
        backgroundColor: 'white',
        borderRadius: '0 0.5rem 0.5rem 0.5rem',
        padding: '24px',
        display: 'flex',
        alignItems: 'flex-start',
        gap: '48px',
        alignSelf: 'stretch',
      }),
    },
  }

  const tabsOverrides = {
    TabBorder: {
      style: () => ({
        height: '0rem',
      }),
    },
    TabHighlight: {
      style: () => ({
        display: 'none',
      }),
    },
  }

  const mainContainerStyle = {
    display: 'flex',
    flexDirection: 'inline-flex',
    gap: '24px',
    alignItems: 'flex-start',
  }

  const tabsContainerStyle = {
    width: '75%',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }

  const rightColumnContainerStyle = {
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }

  const rightColumnElemntStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '24px',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '24px',
  }

  return {
    tabStyle,
    tabsOverrides,
    mainContainerStyle,
    tabsContainerStyle,
    rightColumnContainerStyle,
    rightColumnElemntStyle,
  }
}

export default useTabBarStyle
