import { createSelector } from 'reselect'

export const selectAuth = state => state.auth

export const selectAuthData = createSelector(
  selectAuth,
  auth => JSON.parse(localStorage?.getItem('hop-telecom-auth')) || auth.data,
)

export const selectAuthError = createSelector(selectAuth, auth => auth.error)

export const selectAuthIsLoading = createSelector(selectAuth, auth => auth.isLoading)

export const selectAuthCommonData = createSelector(selectAuthData, auth => auth.commonData)

export const selectEnergyAuthCommonData = createSelector(selectAuthData, auth => auth.commonData.energy)

export const selectTelecomAuthCommonData = createSelector(selectAuthData, auth => auth.commonData.telecom)

export const selectInsuranceAuthCommonData = createSelector(selectAuthData, auth => auth.commonData.insurance)
