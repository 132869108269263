import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbarNotification } from '../../shared/tools/notifications/useSnackbarNotification'

export const useNotificationReceivedListener = () => {
  const showNotification = useSnackbarNotification()
  const { t } = useTranslation()

  useEffect(() => {  
    window.Echo.channel('notifications.connected-users').listen('.notification.received', (event) => {
      showNotification({
        type: event.type,
        message: event.isCustomMessage
          ? event.message
          : t('common:notifications.' + event.message),
      })
    })
  }, [])
}
